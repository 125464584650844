import moment from "moment";
import React from "react";

import ErrorBoundary from "../../HOC/ErrorBoundary/errorBoundary.component";

import InforTooltip from "./clientInfoTooltip";

import classes from "./index.module.scss";

interface iProps {
  jobDivaDetails: ProjectDetailsAPIResponse["data"] | null;
  jobId?: string;
  jobTitle?: string;
  type: string;
  selectedRecordId?: string;
  selectedTabIndex?: number;
  removeScroll?: (e: boolean) => void;
  removeAvatar?: boolean;
  noMargin?: boolean;
  selectedJobDivaId?: string;
  billRate?: string;
  payRate?: string;
  workLocation?: string;
  deliveryLocation?: string;
  startDate?: string;
  endDate?: string;
}

const ClientDetails: React.FC<iProps> = ({
  jobDivaDetails,
  jobId,
  jobTitle,
  type,
  selectedTabIndex,
  selectedRecordId,
  removeScroll,
  removeAvatar = false,
  noMargin,
  selectedJobDivaId,
  billRate,
  payRate,
  workLocation,
  deliveryLocation,
  startDate,
  endDate,
}) => {
  const billInfoDetails = jobDivaDetails?.billInfo.filter((e) => {
    return !e.approved && e.select;
  });

  const payInfoDetals = jobDivaDetails?.payInfo.filter((e) => {
    return (
      (billInfoDetails && e?.recordId === billInfoDetails[0]?.recordId) || false
    );
  });

  const isQuad = window.location.pathname === "/quad/";
  const isSow = Boolean(jobDivaDetails?.sowInfo?.sowMarkup);
  const isSupplier = Boolean(jobDivaDetails?.job?.supplier?.name);

  return (
    <div
      data-testid="clientdetails"
      className={[
        noMargin
          ? classes.parent_container_2
          : isQuad
          ? classes.quad_parent_container
          : classes.parent_container,
      ].join(" ")}
    >
      {type === "workorder" && (
        <>
          {!removeAvatar &&
            (jobDivaDetails?.candidate?.firstName ||
              jobDivaDetails?.candidate?.lastName) && (
              <div className={classes.avatar}>
                &nbsp;&nbsp;&nbsp;
                {jobDivaDetails?.candidate?.firstName.slice(0, 1)}
                {jobDivaDetails?.candidate?.lastName.slice(0, 1)}
                &nbsp;&nbsp;&nbsp;
              </div>
            )}
          <div
            className={[
              !(isSow || isSupplier)
                ? classes.candidate_parent_without_sow_supplier
                : isQuad
                ? classes.quad_candidate_parent
                : classes.candidate_parent,
            ].join(" ")}
          >
            {(jobDivaDetails?.candidate?.firstName ||
              jobDivaDetails?.candidate?.lastName) && (
              <InforTooltip
                removeScroll={removeScroll}
                name={"Candidate"}
                value={`${jobDivaDetails?.candidate?.firstName || ""} ${
                  jobDivaDetails?.candidate?.lastName || ""
                }`}
                className={classes.candidate_name}
                isSow={isSow}
                isSupplier={isSupplier}
              />
            )}

            {jobDivaDetails?.job?.client?.name && (
              <InforTooltip
                removeScroll={removeScroll}
                name={"Client"}
                value={jobDivaDetails?.job?.client?.name}
                className={classes.client_name}
                isSow={isSow}
                isSupplier={isSupplier}
              />
            )}

            {isQuad && selectedJobDivaId && (
              <InforTooltip
                removeScroll={removeScroll}
                name={"StartID"}
                value={selectedJobDivaId}
                className={classes.client_name}
                isSow={isSow}
                isSupplier={isSupplier}
              />
            )}

            {!isQuad && jobDivaDetails?.job?.startDate?.value && (
              <InforTooltip
                removeScroll={removeScroll}
                name={"Start Date"}
                value={
                  <div className={classes.divTableCell}>
                    <p>
                      {jobDivaDetails?.job?.startDate?.value
                        ?.split(" ")
                        ?.join(" | ") || <br />}
                    </p>
                  </div>
                }
                className={classes.start_date}
                isSow={isSow}
                isSupplier={isSupplier}
              />
            )}

            {isQuad && startDate && (
              <InforTooltip
                removeScroll={removeScroll}
                name={"Start Date"}
                value={
                  <div className={classes.divTableCell}>
                    <p>{startDate?.split(" ")?.join(" | ") || <br />}</p>
                  </div>
                }
                className={classes.start_date}
                isSow={isSow}
                isSupplier={isSupplier}
              />
            )}

            {isQuad && endDate && (
              <InforTooltip
                removeScroll={removeScroll}
                name={"End Date"}
                value={
                  <div className={classes.divTableCell}>
                    <p>{endDate?.split(" ")?.join(" | ") || <br />}</p>
                  </div>
                }
                className={classes.start_date}
                isSow={isSow}
                isSupplier={isSupplier}
              />
            )}

            {isQuad && billRate && (
              <InforTooltip
                removeScroll={removeScroll}
                name={"Bill Rate"}
                value={billRate}
                className={classes.engagement_type}
                isSow={isSow}
                isSupplier={isSupplier}
              />
            )}

            {isQuad && payRate && (
              <InforTooltip
                removeScroll={removeScroll}
                name={"Pay Rate"}
                value={payRate}
                className={classes.engagement_type}
                isSow={isSow}
                isSupplier={isSupplier}
              />
            )}

            {jobDivaDetails?.candidate?.engagementType && (
              <InforTooltip
                removeScroll={removeScroll}
                name={"Engagement"}
                value={jobDivaDetails?.candidate?.engagementType || ""}
                className={classes.engagement_type}
                isSow={isSow}
                isSupplier={isSupplier}
              />
            )}

            {!isQuad && jobDivaDetails?.job?.location && (
              <InforTooltip
                removeScroll={removeScroll}
                name={"Work Location"}
                value={jobDivaDetails?.job?.location || ""}
                className={classes.location}
                isSow={isSow}
                isSupplier={isSupplier}
              />
            )}

            {isQuad && workLocation && (
              <InforTooltip
                removeScroll={removeScroll}
                name={"Work Location"}
                value={workLocation || ""}
                className={classes.location}
                isSow={isSow}
                isSupplier={isSupplier}
              />
            )}
            {isQuad && deliveryLocation && (
              <InforTooltip
                removeScroll={removeScroll}
                name={"Delivery Location"}
                value={deliveryLocation || ""}
                className={classes.location}
                isSow={isSow}
                isSupplier={isSupplier}
              />
            )}

            {jobDivaDetails &&
              jobDivaDetails.sowInfo &&
              jobDivaDetails.sowInfo.sowMarkup && (
                <InforTooltip
                  removeScroll={removeScroll}
                  name={"Sow Markup"}
                  value={`${jobDivaDetails?.sowInfo?.sowMarkup?.raw}%`}
                  className={classes.sowMarkup}
                  isSow={isSow}
                  isSupplier={isSupplier}
                />
              )}
            {jobDivaDetails &&
              jobDivaDetails.sowInfo &&
              jobDivaDetails.sowInfo.sowName && (
                <InforTooltip
                  removeScroll={removeScroll}
                  name={"Sow Name"}
                  value={jobDivaDetails?.sowInfo?.sowName}
                  className={classes.sowName}
                  isSow={isSow}
                  isSupplier={isSupplier}
                />
              )}

            {jobDivaDetails &&
              jobDivaDetails.job &&
              jobDivaDetails.job.supplier &&
              jobDivaDetails.job.supplier.name && (
                <InforTooltip
                  removeScroll={removeScroll}
                  name={"Supplier Name"}
                  value={jobDivaDetails?.job?.supplier?.name}
                  className={classes.sowMarkup}
                  isSow={isSow}
                  isSupplier={isSupplier}
                />
              )}
            {jobDivaDetails &&
              jobDivaDetails.job &&
              jobDivaDetails.job.supplier && (
                <InforTooltip
                  removeScroll={removeScroll}
                  name={"Supplier Engagement"}
                  value={
                    jobDivaDetails?.job?.supplier?.engagementType || <br />
                  }
                  className={classes.sowName}
                  isSow={isSow}
                  isSupplier={isSupplier}
                />
              )}
            {jobTitle && (
              <InforTooltip
                removeScroll={removeScroll}
                name={"Job Title"}
                value={jobTitle}
                className={classes.sowName}
                isSow={isSow}
                isSupplier={isSupplier}
              />
            )}
          </div>
        </>
      )}
      {type === "simulation" && (
        <div className={classes.simulation_client_details_parent}>
          <div className={classes.simlation_avatar_parent}>
            <div className={classes.simulation_avatar}></div>
            <div className={classes.simulation_candidate_info}>
              <p className={classes.simulation_candidate_name}>{`${
                jobDivaDetails?.candidate?.firstName || ""
              } ${jobDivaDetails?.candidate?.lastName || ""}`}</p>
            </div>
          </div>
          <div className={[classes.simulation_candidate_parent].join(" ")}>
            <InforTooltip
              removeScroll={removeScroll}
              name={"Start ID"}
              value={selectedRecordId || ""}
              className={classes.candidate_name}
              isSow={isSow}
              isSupplier={isSupplier}
            />

            {jobDivaDetails?.job?.client?.name && (
              <InforTooltip
                removeScroll={removeScroll}
                name={"Client"}
                value={jobDivaDetails?.job?.client?.name}
                className={classes.client_name}
                isSow={isSow}
                isSupplier={isSupplier}
              />
            )}

            <InforTooltip
              removeScroll={removeScroll}
              name={"Approved"}
              value={
                <div className={classes.divTableCell}>
                  <p>
                    {moment(jobDivaDetails?.job?.startDate?.raw).format("MMM")}{" "}
                    | {moment(jobDivaDetails?.job?.startDate?.raw).format("DD")}{" "}
                    |{" "}
                    {moment(jobDivaDetails?.job?.startDate?.raw).format("YYYY")}
                  </p>
                </div>
              }
              className={classes.start_date}
              isSow={isSow}
              isSupplier={isSupplier}
            />

            <InforTooltip
              removeScroll={removeScroll}
              name={"Overtime exempt"}
              value={
                selectedTabIndex === 0
                  ? payInfoDetals && payInfoDetals[0]?.overTimeExempt
                    ? "Yes"
                    : "No"
                  : billInfoDetails && billInfoDetails[0]?.overTimeExempt
                  ? "Yes"
                  : "No" || ""
              }
              className={classes.engagement_type}
              isSow={isSow}
              isSupplier={isSupplier}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ErrorBoundary(ClientDetails);
