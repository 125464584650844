import React from "react";

import IconsName from "../../../constants/IconsName";
import Icon from "../../Icon";

interface RulesProps {
  id: number;
  ruleType: string;
  ruleName: string;
  ruleDescription: string;
}
interface AttributionType {
  code: string;
  name: string;
  comment: string;
  rate: {
    localized: string;
    culture: string;
    currency: string;
    value: string;
    raw: number;
  };
  rules: RulesProps[];
}

interface AttributionProps {
  attributions: AttributionType[];
  index: number;
  alterGridSpacing?: string;
}

const validateNegativeValue = (value: number) => {
  const negativeSign = Math.sign(value) === -1 ? "-" : "";
  const rate = Math.abs(value);

  return `${negativeSign}$${(rate / 100).toFixed(2)}`;
};

const Attribution: React.FC<AttributionProps> = ({
  attributions,
  index,
  alterGridSpacing,
}) => (
  <div className={`simulatepay__body__attribution ${alterGridSpacing || ""}`}>
    <div className="simulatepay__body__attribution__list">
      <h5 className="simulatepay__body__attribution__list__label">
        Attributions
      </h5>
      {attributions.map((obj, i) => (
        <div
          key={`${obj.code}-${index + i}`}
          className="simulatepay__body__attribution__list__object"
          style={{ position: "relative" }}
        >
          <span className="simulatepay__body__attribution__list__object__attrList">
            {obj.name}
            <Icon width={100} height={35} name={IconsName.INFOICON} />
            <div className="simulatepay__body__attribution__list__object__rules">
              <span className="simulatepay__body__attribution__list__object__rules__attrheader">
                <span>Rule Name</span>
                <span>Rule Type</span>
                <span>Description</span>
              </span>
              {obj.rules.map((row, rowindex) => (
                <span
                  key={`${row.ruleName}-${index + rowindex}`}
                  className="simulatepay__body__attribution__list__object__rules__attrbody"
                >
                  <span>{row.ruleName || "N/A"}</span>
                  <span>{row.ruleType || "N/A"}</span>
                  <span>{row.ruleDescription || "N/A"}</span>
                </span>
              ))}
            </div>
          </span>
          <span className="simulatepay__body__attribution__list__object__attrList">
            {validateNegativeValue(obj.rate.raw)}
          </span>
          <span className="simulatepay__body__attribution__list__object__attrList">
            {obj.comment}
          </span>
        </div>
      ))}
    </div>
  </div>
);

export default Attribution;
