import { FaPrint } from "react-icons/fa";

import "./FloatingActionButton.scss";
interface Iprops {
  setPrintDocument: (e: boolean) => void;
  openPrintDocument: boolean;
}

const FloatingActionButton = ({
  setPrintDocument,
  openPrintDocument,
}: Iprops) => {
  return (
    <div className="container">
      <div className="floating_button">
        <input id="button" type="checkbox" className="main_button" />
        <label htmlFor="button"></label>
        <div
          className="roundButton buttonPrint"
          onClick={() => setPrintDocument(!openPrintDocument)}
        >
          <FaPrint />
        </div>
      </div>
    </div>
  );
};

export default FloatingActionButton;
