import HTMLReactParser from "html-react-parser";
import React, { useEffect, useRef } from "react";

import IconsName from "../../../constants/IconsName";
import Icon from "../../Icon";
import NumberInput from "../../Input/Number/Number";

interface ProposedRateProps {
  proporsedPayRate: number | string;
  handleCalculate: (payrate: string, type: string) => void;
  changeProposedValue: (value: number, type: string) => void;
  jdRate?: number | null | string;
  type: string;
  typeOfRate: string;
  payRateError: string;
  disableBtn?: boolean;
}

const ProposedRateComponent: React.FC<ProposedRateProps> = ({
  changeProposedValue,
  handleCalculate,
  proporsedPayRate,
  jdRate,
  type,
  typeOfRate,
  payRateError,
  disableBtn,
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!Number.isNaN(Math.abs(+event.target.value))) {
      const amountToAdd = event.target.value.trim();

      if (
        amountToAdd.includes(".") &&
        amountToAdd.split(".")[1].length > 2 &&
        inputRef.current
      ) {
        inputRef.current.value = `${proporsedPayRate}`;

        return;
      }

      changeProposedValue(+amountToAdd, type);
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (jdRate && jdRate !== "" && inputRef.current) {
      const payRate = Number(jdRate) / 100;

      inputRef.current.value = `${payRate}`;
      const value: number = +payRate;

      handleCalculate(`${Math.abs(value)}`, type);
      changeProposedValue(Math.abs(value), type);
    }
  }, [jdRate]);
  useEffect(() => {
    const keyPress = (e: KeyboardEvent) => {
      const event = e || window.event;

      const charCode =
        typeof event.which === "undefined" ? event.keyCode : event.which;

      const charStr = String.fromCharCode(charCode);

      if (event.code === "Enter" && inputRef.current) {
        const input = event.target as HTMLInputElement;

        handleCalculate(input.value, type);
      }

      if (event.key === "-" || !charStr.match(/^[0-9.]+$/)) {
        event.preventDefault();

        return false;
      }

      return true;
    };

    if (inputRef.current) {
      inputRef.current?.addEventListener("keypress", keyPress);
    }

    return () => inputRef.current?.removeEventListener("keypress", keyPress);
  }, [inputRef]);

  return (
    <>
      {" "}
      <div className="simulatepay__header">
        <span className="simulatepay__header__label">{typeOfRate}</span>
        <div className="simulatepay__header__label__parent">
          <NumberInput
            labelClassName="simulatepay__header__label__parent__input_label"
            onChange={(val) => {
              handleInputChange(val);
            }}
            value={proporsedPayRate}
            placeholder="$"
            inputRef={inputRef}
          />
          <button
            type="button"
            className={`simulatepay__header__label__parent__calcbtn ${
              disableBtn
                ? "simulatepay__header__label__parent__disableCalculatebtn"
                : ""
            }`}
            onClick={disableBtn ? () => {} : () => handleCalculate("", type)}
          >
            Calculate
          </button>
        </div>
      </div>
      {payRateError && (
        <div className="simulatepay__error">
          <Icon width={100} height={35} name={IconsName.CANCELICON} />

          <p>{HTMLReactParser(payRateError)}</p>
        </div>
      )}
    </>
  );
};

export default ProposedRateComponent;
