import React from "react";

export const SearchClose: React.FC<IconProp> = (props) => (
  <svg
    onClick={props.onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill=""
      fillRule="evenodd"
      d="M15.763 15.778a.783.783 0 0 0 .015-1.107l-.282-.289-.777-.794a760.628 760.628 0 0 0-3.612-3.66 698.955 698.955 0 0 1 4.37-4.304l.286-.28a.783.783 0 1 0-1.092-1.122l-.289.282a648.642 648.642 0 0 0-3.468 3.412l-.912.904a319.594 319.594 0 0 0-4.678-4.602.783.783 0 1 0-1.083 1.13 318.274 318.274 0 0 1 4.652 4.577 322.07 322.07 0 0 0-4.675 4.75.783.783 0 1 0 1.13 1.084 320.662 320.662 0 0 1 4.65-4.726 733.172 733.172 0 0 1 4.378 4.443l.28.287a.782.782 0 0 0 1.107.015ZM14.671 4.222l.395.405c-.191-.196-.29-.297-.34-.35l-.055-.055Z"
      clipRule="evenodd"
    />
  </svg>
);
