import { toRelativeUrl } from "@okta/okta-auth-js";
import { Security, LoginCallback } from "@okta/okta-react";
import { useState, useMemo, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import LoadRouteOnDemand from "../HOC/LazyLoading/LoadRouteOnDemand";
import { oktaAuth } from "../auth/oktaConfig";
import MangeRules from "../components/ManageClientRules/manageRules";
import QuotesRateWorkOrder from "../components/QuotesRatesOutsideWorkorder/quoteRates";
import SpinnerComponent from "../components/Spinner/spinner";
import Spinner from "../componentsPhase1/Spinner/spinner";
import GlobalSpinnerContext from "../contexts/LoaderContext";
import GlobalToastContext from "../contexts/ToastContext";
import UnAuthorised from "../pages/403page/unauthorized";
import Details from "../pages/Details/Details";
import Home from "../pages/Home/Home";
import NotFound from "../pages/NotFound";
import Ruleset from "../pages/ruleSets/displayRulesets";
import Simulation from "../pages/simulation";

import ProtectedRoutes from "./ProtectedRoutes";
import ProtectedRoutesPhase1 from "./ProtectedRoutesPhase1";

const CALLBACK_PATH = process.env.REACT_APP_CALLBACK_PATH;
const WorkOrder = LoadRouteOnDemand(() => import("../pages/workOrder"));
const ClientRules = LoadRouteOnDemand(() => import("../pages/clientRules"));

const AppWithRouter = () => {
  const [enableLoader, SetLoader] = useState(false);
  const [toastMessage, EnableToast] = useState({ message: "", type: "" });

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(
      toRelativeUrl(originalUri || "/", window.location.origin)
    );
  };

  useEffect(() => {
    return () => {
      oktaAuth.options.restoreOriginalUri = undefined;
    };
  }, []);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <GlobalSpinnerContext.Provider
        value={useMemo(
          () => ({
            enableLoader,
            SetLoader,
          }),
          [enableLoader]
        )}
      >
        <GlobalToastContext.Provider
          value={useMemo(
            () => ({
              toastMessage,
              EnableToast,
            }),
            [toastMessage.message]
          )}
        >
          <SpinnerComponent />
          <Spinner />

          <Routes>
            <Route path={CALLBACK_PATH} element={<LoginCallback />} />
            <Route path="/phase1" element={<ProtectedRoutes />}>
              <Route path="/phase1" element={<Home />} />
              <Route path="/phase1/start/:id" element={<Details />} />
              <Route path="/phase1/managerules" element={<MangeRules />} />
              <Route path="/phase1/qrates" element={<QuotesRateWorkOrder />} />
            </Route>
            <Route path="/" element={<ProtectedRoutesPhase1 />}>
              <Route path="/" element={<WorkOrder />} />
              <Route path="/start/:id" element={<WorkOrder />} />
              <Route path="/managerules" element={<ClientRules />} />
              <Route path="/qrates" element={<Simulation />} />
              <Route path="/ruleset" element={<Ruleset />} />
              <Route path="/quad/" element={<WorkOrder />} />
              <Route path="/quad/start/:id" element={<WorkOrder />} />
            </Route>
            <Route path="*" element={<NotFound />} />

            <Route path="/unauthorized" element={<UnAuthorised />} />
            <Route path="/phase1/unauthorized" element={<UnAuthorised />} />
          </Routes>
        </GlobalToastContext.Provider>
      </GlobalSpinnerContext.Provider>
    </Security>
  );
};

export default AppWithRouter;
