export const ENV: { [k: string]: string } = {
  LOCAL: "LOCAL",
  DEV: "DEV",
  QA: "QA",
  PREPROD: "PREPROD",
  PROD: "PROD",
};

const HOST_ENV: { [k: string]: string } = {
  [process.env.REACT_APP_LOCAL_HOST || ""]: ENV.LOCAL,
  [process.env.REACT_APP_DEV_HOST || ""]: ENV.DEV,
  [process.env.REACT_APP_QA_HOST || ""]: ENV.QA,
  [process.env.REACT_APP_STAGING_HOST || ""]: ENV.PREPROD,
  [process.env.REACT_APP_PROD_HOST || ""]: ENV.PROD,
};

export const CURRENT_ENV = HOST_ENV[window.location.host] || ENV.PROD;

const GTM_IDS: { [k: string]: string } = {
  [ENV.LOCAL]: process.env.REACT_APP_LOCAL_GTM_ID || "",
  [ENV.DEV]: process.env.REACT_APP_DEV_GTM_ID || "",
  [ENV.QA]: process.env.REACT_APP_QA_GTM_ID || "",
  [ENV.PREPROD]: process.env.REACT_APP_STAGING_GTM_ID || "",
  [ENV.PROD]: process.env.REACT_APP_PROD_GTM_ID || "",
};

export const GTM_ID = GTM_IDS[CURRENT_ENV] || "";
export const APP_VERSION = process.env.REACT_APP_VERSION || "";
