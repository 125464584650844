import { useEffect, useRef, useState, useContext } from "react";

import GlobalToastContext from "../../../contexts/ToastContext";
import Button from "../../Button/Button";
import Input from "../../Input";

interface Iprops {
  className: string;
  optionalRuleToManage: ManageOptionalRules[] | [];
  standardRuleToManage: ManageStandardRules[] | [];
  dropDownData: {
    operationData: DropdownOptionsType[] | null;
    groupData: DropdownOptionsType[] | null;
    attributionData: DropdownOptionsType[] | null;
    engagementData: DropdownOptionsType[] | null;
  };
  updateStateHandler: (
    obj: { [key: string]: string | number } | unknown,
    type: string
  ) => void;
  vendorData: SuppliersVendorData[] | null;
  updateRuleHandler: (type: string) => void;
  manageRuleHandler: (recordid: number | null, type: string) => void;
  editRule: string;
  addNewRule: string;
  LoggedInUser: string | undefined;
}
export interface optionsProp {
  value: number;
  label: string;
}

interface FormValidationProps {
  Description: boolean;
  Name: boolean;
  Amount: boolean;
  Attribution: boolean;
  Operation: boolean;
  Group: boolean;
}

const UpdateRule = ({
  className,
  optionalRuleToManage,
  standardRuleToManage,
  dropDownData,
  updateStateHandler,
  vendorData,
  updateRuleHandler,
  manageRuleHandler,
  editRule,
  addNewRule,
  LoggedInUser,
}: Iprops) => {
  const isStandardRule = () =>
    (editRule !== "" && editRule === "editStandardRule") ||
    (addNewRule !== "" && addNewRule === "addStandardRule");

  const { groupData, attributionData, operationData, engagementData } =
    dropDownData;

  const { EnableToast } = useContext(GlobalToastContext);

  const [isMangeRuleBtnClicked, setManageRuleBtnClick] =
    useState<boolean>(false);

  const [validateForm, setValidateForm] = useState<FormValidationProps>({
    Name: false,
    Description: false,
    Group: false,
    Operation: false,
    Amount: false,
    Attribution: false,
  });

  const groupHandler = groupData?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const vendorhandler = vendorData?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const operationHandler = operationData?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const attributeHandler = attributionData?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const engagementTypeHandler = engagementData?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  useEffect(() => {
    if (amountRef.current) {
      amountRef.current.value = `${
        isStandardRule()
          ? (standardRuleToManage[0]?.amount.raw &&
              standardRuleToManage[0]?.amount.raw) ||
            ""
          : (optionalRuleToManage[0]?.amount.raw &&
              optionalRuleToManage[0]?.amount.raw) ||
            ""
      }`;
    }
  }, []);
  useEffect(() => {
    if (
      standardRuleToManage[0].operation.name !== "" ||
      (optionalRuleToManage[0].operation.name !== "" && amountRef.current)
    ) {
      appendSign();
    }
  }, [
    standardRuleToManage[0].operation.name,
    optionalRuleToManage[0].operation.name,
  ]);
  const amountRef = useRef<HTMLInputElement | null>(null);

  const RateHandler = (
    data: React.ChangeEvent<HTMLInputElement>,
    typeOfkey: string,
    value: string | number
  ) => {
    const type = addNewRule === "" ? editRule : addNewRule;

    if (
      (data.target.value.length === 1 && data.target.value === "-") ||
      !Number.isNaN(Math.sign(+data.target.value))
    ) {
      const amountToAdd = data.target.value.trim();

      if (amountToAdd.includes(".") && amountToAdd.split(".")[1].length > 2) {
        updateStateHandler(
          {
            [typeOfkey]: value ? `${value}` : "",
          },
          type
        );

        return;
      }

      updateStateHandler(
        {
          [typeOfkey]: data.target.value,
        },
        type
      );
    } else {
      updateStateHandler(
        {
          [typeOfkey]: value ? `${value}` : "",
        },
        type
      );
    }
  };

  const amountHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (amountRef.current) {
      if (
        (amountRef.current.value.length === 1 &&
          amountRef.current.value === "-") ||
        !Number.isNaN(Math.sign(+amountRef.current.value))
      ) {
        const amountToAdd = e.target.value.trim();

        if (amountToAdd.includes(".") && amountToAdd.split(".")[1].length > 2) {
          amountRef.current.value = `${
            isStandardRule()
              ? standardRuleToManage[0].amount.raw
              : optionalRuleToManage[0].amount.raw
          }`;

          return;
        }

        const amountObj = isStandardRule()
          ? standardRuleToManage[0]?.amount
          : optionalRuleToManage[0]?.amount;

        const type = addNewRule === "" ? editRule : addNewRule;

        updateStateHandler(
          {
            amount: {
              ...amountObj,
              raw: amountToAdd,
            },
          },
          type
        );
        setValidateForm({
          ...validateForm,
          Amount: !amountRef.current.value.trim(),
        });

        return;
      }

      amountRef.current.value = `${
        isStandardRule()
          ? standardRuleToManage[0]?.amount.raw
          : optionalRuleToManage[0]?.amount.raw
      }`;
    }
  };

  const appendSign = () => {
    const amount = `${
      isStandardRule()
        ? standardRuleToManage[0]?.amount.raw
        : optionalRuleToManage[0]?.amount.raw
    }`;

    const operation = isStandardRule()
      ? standardRuleToManage[0]?.operation.name
      : optionalRuleToManage[0]?.operation.name;

    const amountToAdd = amount.trim();

    if (amountToAdd.includes(".") && amountToAdd.split(".")[1].length > 2) {
      return;
    }

    const negativeSign = Math.sign(+amount) === -1 ? "-" : "";
    const rate = Math.abs(+amount);

    if (amountRef.current) {
      if (!rate) {
        amountRef.current.value = "";
        const amountObj = isStandardRule()
          ? standardRuleToManage[0]?.amount
          : optionalRuleToManage[0]?.amount;

        const type = addNewRule === "" ? editRule : addNewRule;

        updateStateHandler(
          {
            amount: {
              ...amountObj,
              raw: amountToAdd,
            },
          },
          type
        );

        return;
      }

      if (operation && operation !== "null" && amount !== "") {
        amountRef.current.value =
          operation === "flat fee"
            ? `${negativeSign}$${rate}`
            : `${negativeSign}${rate}%`;
      } else {
        amountRef.current.value = `${amount}`;
      }
    }
  };

  useEffect(() => {
    if (isMangeRuleBtnClicked) {
      setManageRuleBtnClick(false);

      if (validateForm && !Object.values(validateForm).includes(true)) {
        updateRuleHandler(editRule !== "" ? editRule : addNewRule);

        return;
      }

      const errorMesasgeValue = Object.keys(validateForm)
        .map((e) => (validateForm[e as keyof typeof validateForm] ? e : ""))
        .filter((e) => e !== "");

      if (errorMesasgeValue.length > 0) {
        EnableToast({
          message: `Please Correct the below errors`,
          type: "error",
        });
      }
    }
  }, [validateForm, isMangeRuleBtnClicked]);

  return (
    <>
      <div className={className}>
        <div className={`${className}__section`}>
          <div className={`${className}__section__Name`}>
            <Input.Search
              value={
                isStandardRule()
                  ? standardRuleToManage[0].name
                  : optionalRuleToManage[0].name
              }
              onChange={(item: string | unknown) => {
                if (item) {
                  const data = item as React.ChangeEvent<HTMLInputElement>;
                  const type = addNewRule === "" ? editRule : addNewRule;

                  setValidateForm({
                    ...validateForm,
                    Name: !data.target.value || data.target.value === "",
                  });
                  updateStateHandler(
                    {
                      name: data.target.value,
                    },
                    type
                  );
                }
              }}
              placeholder="Name"
              label="Name*"
              required={validateForm.Name}
            />
          </div>
          <div className={`${className}__section__Description`}>
            <Input.Search
              value={
                isStandardRule()
                  ? standardRuleToManage[0].description
                  : optionalRuleToManage[0]?.description
              }
              onChange={(item: string | unknown) => {
                if (item) {
                  const data = item as React.ChangeEvent<HTMLInputElement>;
                  const type = addNewRule === "" ? editRule : addNewRule;

                  setValidateForm({
                    ...validateForm,
                    Description:
                      !data.target.value || data.target.value.trim() === "",
                  });
                  updateStateHandler(
                    {
                      description: data.target.value,
                    },
                    type
                  );
                }
              }}
              placeholder="Description"
              required={validateForm.Description}
              label="Description*"
            />
          </div>
        </div>
        <div className={`${className}__section`}>
          <div className={`${className}__section__Effectivedate`}>
            <span className="label" data-name="Effective Date">
              Effective Date
            </span>

            <Input.Date
              value={
                isStandardRule()
                  ? (standardRuleToManage[0].effectiveDate &&
                      standardRuleToManage[0].effectiveDate.raw &&
                      new Date(standardRuleToManage[0].effectiveDate.raw)) ||
                    null
                  : (optionalRuleToManage[0].effectiveDate &&
                      optionalRuleToManage[0].effectiveDate.raw &&
                      new Date(optionalRuleToManage[0].effectiveDate.raw)) ||
                    null
              }
              className="calcDatePicker"
              onChange={(item: unknown) => {
                let time = item as Date | number;

                time = new Date(time).getTime();
                const type = addNewRule === "" ? editRule : addNewRule;

                updateStateHandler(
                  {
                    effectiveDate: { raw: time },
                  },
                  type
                );
              }}
            />
          </div>
          <div className={`${className}__section__Expiredate`}>
            <span className="label" data-name="Expire Date">
              Expire Date
            </span>

            <Input.Date
              value={
                isStandardRule()
                  ? (standardRuleToManage[0].expireDate &&
                      standardRuleToManage[0].expireDate.raw &&
                      new Date(standardRuleToManage[0].expireDate.raw)) ||
                    null
                  : (optionalRuleToManage[0].expireDate &&
                      optionalRuleToManage[0].expireDate.raw &&
                      new Date(optionalRuleToManage[0].expireDate.raw)) ||
                    null
              }
              className="calcDatePicker"
              onChange={(item: unknown) => {
                let time = item as Date | number;

                time = new Date(time).getTime();
                const type = addNewRule === "" ? editRule : addNewRule;

                updateStateHandler(
                  {
                    expireDate: { raw: time },
                  },
                  type
                );
              }}
            />
          </div>
        </div>
        <div className={`${className}__section`}>
          <div className={`${className}__section__group`}>
            <span className="label" data-name="Group">
              Group*
            </span>

            <Input.Select
              placeholder="Group*"
              onChange={(item: unknown) => {
                const data = item as { value: number; label: string };
                const type = addNewRule === "" ? editRule : addNewRule;

                setValidateForm({
                  ...validateForm,
                  Group: !data,
                });
                updateStateHandler(
                  {
                    groupId: data ? data.value : null,
                    groupName: data ? data.label : null,
                  },
                  type
                );
              }}
              options={groupHandler || []}
              value={[
                isStandardRule()
                  ? {
                      label: standardRuleToManage[0]?.group.name,
                      value: standardRuleToManage[0]?.group.name,
                    }
                  : {
                      label: optionalRuleToManage[0]?.group.name,
                      value: optionalRuleToManage[0]?.group.name,
                    },
              ]}
              required={validateForm.Group}
            />
          </div>
          <div className={`${className}__section__operation`}>
            <span className="label" data-name="Operation">
              Operation*
            </span>

            <Input.Select
              placeholder="Operation*"
              onChange={(item: unknown) => {
                const data = item as { value: number; label: string };
                const type = addNewRule === "" ? editRule : addNewRule;

                setValidateForm({
                  ...validateForm,
                  Operation: !data,
                });
                updateStateHandler(
                  {
                    operationId: data ? data.value : null,
                    operationName: data ? data.label : null,
                  },
                  type
                );
              }}
              options={operationHandler || []}
              value={[
                isStandardRule()
                  ? {
                      label: standardRuleToManage[0]?.operation.name,
                      value: standardRuleToManage[0]?.operation.id,
                    }
                  : {
                      label: optionalRuleToManage[0]?.operation.name,
                      value: optionalRuleToManage[0]?.operation.id,
                    },
              ]}
              required={validateForm.Operation}
            />
          </div>
        </div>
        <div className={`${className}__section`}>
          <div className={`${className}__section__Amount`}>
            <Input.Search
              innerRef={amountRef}
              onBlur={appendSign}
              onFocus={() => {
                if (amountRef.current) {
                  amountRef.current.value = isStandardRule()
                    ? (standardRuleToManage[0]?.amount.raw &&
                        `${standardRuleToManage[0]?.amount.raw}`) ||
                      ""
                    : (optionalRuleToManage[0]?.amount.raw &&
                        `${optionalRuleToManage[0]?.amount.raw}`) ||
                      "";
                }
              }}
              onChange={amountHandler}
              placeholder=""
              label="Amount*"
              required={validateForm.Amount}
            />
          </div>
          <div className={`${className}__section__Attribution`}>
            <span className="label" data-name="Attribution">
              Attribution*
            </span>

            <Input.Select
              placeholder="Attribution"
              onChange={(item: unknown) => {
                const data = item as { value: number; label: string };
                const type = addNewRule === "" ? editRule : addNewRule;

                setValidateForm({
                  ...validateForm,
                  Attribution: !data,
                });
                updateStateHandler(
                  {
                    attributionId: data ? data.value : null,
                    attributionName: data ? data.label : null,
                  },
                  type
                );
              }}
              options={attributeHandler || []}
              value={[
                isStandardRule()
                  ? {
                      label: standardRuleToManage[0]?.attribution.name,
                      value: standardRuleToManage[0]?.attribution.id,
                    }
                  : {
                      label: optionalRuleToManage[0]?.attribution.name,
                      value: optionalRuleToManage[0]?.attribution.id,
                    },
              ]}
              required={validateForm.Attribution}
            />
          </div>
        </div>
        <div className={`${className}__section`}>
          <div className={`${className}__section__PerformedBy`}>
            <Input.Search
              disableComponent
              value={
                isStandardRule()
                  ? `${
                      standardRuleToManage[0].performedBy === ""
                        ? LoggedInUser || ""
                        : standardRuleToManage[0].performedBy
                    }`
                  : `${
                      optionalRuleToManage[0].performedBy === ""
                        ? LoggedInUser || ""
                        : optionalRuleToManage[0].performedBy
                    }`
              }
              placeholder=""
              label="Performed By"
            />
          </div>
          <div className={`${className}__section__UpdateDate`}>
            <span className="label" data-name="Effective Date">
              Vendor
            </span>

            <Input.Select
              placeholder="Vendor*"
              onChange={(item: unknown) => {
                const data = item as { value: number; label: string };
                const type = addNewRule === "" ? editRule : addNewRule;

                updateStateHandler(
                  {
                    vendorId: data ? data.value : null,
                    vendorName: data ? data.label : null,
                  },
                  type
                );
              }}
              options={vendorhandler || []}
              value={[
                isStandardRule()
                  ? {
                      label:
                        (standardRuleToManage[0]?.vendor &&
                          standardRuleToManage[0]?.vendor.vendorName) ||
                        null,
                      value:
                        (standardRuleToManage[0]?.vendor &&
                          standardRuleToManage[0]?.vendor.id) ||
                        null,
                    }
                  : {
                      label:
                        (optionalRuleToManage[0]?.vendor &&
                          optionalRuleToManage[0]?.vendor.vendorName) ||
                        null,
                      value:
                        (optionalRuleToManage[0]?.vendor &&
                          optionalRuleToManage[0]?.vendor.id) ||
                        null,
                    },
              ]}
            />
          </div>
        </div>
        {((editRule !== "" && editRule === "editStandardRule") ||
          (addNewRule !== "" && addNewRule === "addStandardRule")) && (
          <>
            <div className={`${className}__section`}>
              <div className={`${className}__section__Country`}>
                <Input.Search
                  label="Country"
                  value={
                    standardRuleToManage[0].country
                      ? `${standardRuleToManage[0].country}`
                      : ""
                  }
                  onChange={(item: string | unknown) => {
                    if (item) {
                      const data = item as React.ChangeEvent<HTMLInputElement>;
                      const type = addNewRule === "" ? editRule : addNewRule;

                      updateStateHandler(
                        {
                          country: data.target.value,
                        },
                        type
                      );
                    }
                  }}
                  placeholder="Country"
                />
              </div>
              <div className={`${className}__section__MainDivision`}>
                <Input.Search
                  value={
                    standardRuleToManage[0].mainDivision
                      ? `${standardRuleToManage[0].mainDivision}`
                      : ""
                  }
                  onChange={(item: string | unknown) => {
                    if (item) {
                      const data = item as React.ChangeEvent<HTMLInputElement>;
                      const type = addNewRule === "" ? editRule : addNewRule;

                      updateStateHandler(
                        {
                          mainDivision: data.target.value,
                        },
                        type
                      );
                    }
                  }}
                  placeholder=""
                  label="State"
                />
              </div>
            </div>
            <div className={`${className}__section`}>
              <div className={`${className}__section__City`}>
                <Input.Search
                  value={
                    standardRuleToManage[0].city
                      ? `${standardRuleToManage[0].city}`
                      : ""
                  }
                  onChange={(item: string | unknown) => {
                    if (item) {
                      const data = item as React.ChangeEvent<HTMLInputElement>;
                      const type = addNewRule === "" ? editRule : addNewRule;

                      updateStateHandler(
                        {
                          city: data.target.value,
                        },
                        type
                      );
                    }
                  }}
                  placeholder="City"
                  label="City"
                />
              </div>
              <div className={`${className}__section__EngagementType`}>
                <span className="label" data-name="Attribution">
                  Engagement Type
                </span>
                <Input.Select
                  placeholder="Engagement Type"
                  onChange={(item: unknown) => {
                    const data = item as { value: number; label: string };
                    const type = addNewRule === "" ? editRule : addNewRule;

                    setValidateForm({
                      ...validateForm,
                      Operation: !data,
                    });
                    updateStateHandler(
                      {
                        engagementTypeId: data ? data.value : null,
                        engagementTypeName: data ? data.label : null,
                      },
                      type
                    );
                  }}
                  options={engagementTypeHandler || []}
                  value={[
                    {
                      label: standardRuleToManage[0].engagementType,
                      value: engagementTypeHandler?.find(
                        (e) =>
                          standardRuleToManage[0].engagementType === e.label
                      )?.value,
                    },
                  ]}
                />
              </div>
            </div>
            <div className={`${className}__section`}>
              <div className={`${className}__section__RateLow`}>
                <Input.Search
                  value={
                    standardRuleToManage[0].rateLow
                      ? `${standardRuleToManage[0].rateLow}`
                      : ""
                  }
                  onChange={(e) =>
                    RateHandler(e, "rateLow", standardRuleToManage[0].rateLow)
                  }
                  placeholder=""
                  label="Rate Low"
                />
              </div>
              <div className={`${className}__section__Country`}>
                <Input.Search
                  value={
                    standardRuleToManage[0].rateHigh
                      ? `${standardRuleToManage[0].rateHigh}`
                      : ""
                  }
                  onChange={(e) =>
                    RateHandler(e, "rateHigh", standardRuleToManage[0].rateHigh)
                  }
                  placeholder=""
                  label="Rate High"
                />
              </div>
            </div>
          </>
        )}
      </div>
      <Button
        name="Cancel"
        className="btn-cancelButtonStyle"
        onClick={() => manageRuleHandler(null, "")}
      />
      <Button
        name={`${addNewRule === "" ? "Update" : "Add"}`}
        className="btn-editButtonStyle"
        onClick={() => {
          const rule = isStandardRule()
            ? standardRuleToManage[0]
            : optionalRuleToManage[0];

          setManageRuleBtnClick(true);
          setValidateForm({
            ...validateForm,
            Description: rule.description === "",
            Name: rule.name === "",
            Operation:
              rule.operation.name === "" || rule.operation.name === null,
            Attribution:
              rule.attribution.name === "" || rule.attribution.name === null,
            Group: rule.group.name === "" || rule.group.name === null,
            Amount: rule.amount && rule.amount.raw === "",
          });
        }}
      />
    </>
  );
};

export default UpdateRule;
