import MBOTooltip from "../MBOTooltip";

import classes from "./index.module.scss";

interface Iprops {
  name: string;
  value: string | number | React.ReactNode;
  className?: string;
  isSupplier: boolean;
  isSow: boolean;
  removeScroll?: (e: boolean) => void;
}

const ClientInfoTooltip: React.FC<Iprops> = ({
  name,
  value,
  className,
  isSupplier,
  isSow,
  removeScroll,
}) => {
  return (
    <div
      onMouseEnter={() => removeScroll && removeScroll(true)}
      onMouseLeave={() => removeScroll && removeScroll(false)}
      className={[
        classes.container_tip,
        isSow && isSupplier ? "" : classes.is_sow_not_available,
      ].join(" ")}
    >
      <div className={className}>
        <p
          className={
            typeof name === "string" && name.length > 7
              ? classes.name_styling
              : ""
          }
        >
          {name}
        </p>
        <p
          className={[
            typeof value === "string" && value.length > 12
              ? classes.value_styling
              : "",
            isSupplier ? classes.reduce_width_for_supplier : "",
          ].join(" ")}
        >
          {value}
        </p>

        <MBOTooltip arrowBottom bottom={classes.visible_tip}>
          <div className={classes.tooltip_container}>
            <p>{name}</p>
            <p>{value}</p>
          </div>
        </MBOTooltip>
      </div>
    </div>
  );
};

export default ClientInfoTooltip;
