import React, { useEffect, useState } from "react";
import { UseQueryResult } from "react-query";

import {
  RulesetCreationProps,
  dataFlowProps,
} from "../../../../@types/component";
import Button from "../../../../componentsPhase1/MBOButton/Button";
import MBOFormInput from "../../../../componentsPhase1/MBOFormInput";
import MBOFormTextarea from "../../../../componentsPhase1/MBOFormTextarea";

import classes from "./index.module.scss";

interface UpdatedRulesetProps {
  rulesetName: string;
  rulesetDesc: string;
  id: string;
}

interface createRulesetProps {
  setCreateRulesetPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setDataFlow: React.Dispatch<React.SetStateAction<dataFlowProps[]>>;
  setRulesetCreationState: React.Dispatch<
    React.SetStateAction<RulesetCreationProps>
  >;
  ruleSetCreationState: RulesetCreationProps;
  getAllRulesetList: UseQueryResult;
  getSubbmittedRulesforRuleset: UseQueryResult;
  revokeState: () => void;
  mapError?: string;
  existingRulesetDetails: UpdatedRulesetProps;
  assignRulesetId: (e: boolean) => void;
  setManageRuleBtnClick: React.Dispatch<React.SetStateAction<boolean>>;
  isMangeRuleBtnClicked: boolean;
}

const CreateRuleset: React.FC<createRulesetProps> = ({
  setCreateRulesetPopup,
  setDataFlow,
  setRulesetCreationState,
  ruleSetCreationState,
  getAllRulesetList,
  revokeState,
  mapError,
  getSubbmittedRulesforRuleset,
  existingRulesetDetails,
  assignRulesetId,
  isMangeRuleBtnClicked,
  setManageRuleBtnClick,
}) => {
  const [validateForm, setValidateForm] = useState<{
    rulesetName: boolean;
    rulesetDesc: boolean;
  }>({
    rulesetName: false,
    rulesetDesc: false,
  });

  const updateFlowOne = (key: string, value: string) => {
    setValidateForm({
      ...validateForm,
      [key]: value.trim() === "",
    });

    setRulesetCreationState((prevState: RulesetCreationProps) => {
      return {
        ...prevState,
        screen1: {
          ...prevState.screen1,
          [key]: value,
        },
      };
    });
  };

  useEffect(() => {
    if (getAllRulesetList.data) {
      assignRulesetId(isMangeRuleBtnClicked);
    }
  }, [validateForm, getAllRulesetList.data]);

  return (
    <>
      <div className={classes.icon_close_positon}>
        <p className={classes.header}>
          Let’s start by giving your set a name and a description
        </p>
      </div>
      <div className={classes.aligning_container}>
        <MBOFormInput
          placeholder="Rule set name"
          value={ruleSetCreationState?.screen1.rulesetName}
          changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
            updateFlowOne("rulesetName", e.target.value);
          }}
          className={[
            classes.name_width,
            validateForm.rulesetName ? classes.form_error_style : "",
          ].join(" ")}
          showRequiredFieldMessage={validateForm.rulesetName}
        />
        <MBOFormTextarea
          maxlength={-1}
          placeholder="Rule set description"
          value={ruleSetCreationState?.screen1.rulesetDesc}
          changeHandler={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            updateFlowOne("rulesetDesc", e.target.value);
          }}
          className={
            validateForm.rulesetDesc
              ? `${classes.form_error_style} ${classes.description_width}`
              : classes.description_width
          }
          showRequiredFieldMessage={validateForm.rulesetDesc}
          errorMesasgePositionStyle={classes.alter_desctiption_mandatory}
        />
      </div>
      <div className={classes.btn_position}>
        {mapError !== "" ? (
          <p className={classes.rules_error_while_mapping}>{mapError}</p>
        ) : (
          <div></div>
        )}
        <div>
          <Button
            name="Cancel"
            className={classes.btn_calcel_styling}
            onClick={() => {
              setCreateRulesetPopup(false);
              revokeState();
            }}
          />
          <Button
            name={"Next Step"}
            className={classes.btn_create}
            onClick={() => {
              setManageRuleBtnClick(true);

              if (
                ruleSetCreationState.screen1.rulesetName.trim() !== "" &&
                ruleSetCreationState.screen1.rulesetDesc.trim() !== ""
              ) {
                getAllRulesetList.refetch();
              }

              setValidateForm({
                ...validateForm,
                rulesetName:
                  ruleSetCreationState.screen1.rulesetName.trim() === "",
                rulesetDesc:
                  ruleSetCreationState.screen1.rulesetDesc.trim() === "",
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CreateRuleset;
