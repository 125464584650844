import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";

import API from "../../../API";
import PricingCalcApi from "../../../API/PricingCalcApi";
import GlobalSpinnerContext from "../../../contexts/LoaderContext";
import Button from "../../Button/Button";
import SwitchButton from "../../SwitchButton/switchButton";

interface Props {
  onClick: (e: PricingCalcSettingsObj) => void;
  data: PricingCalcSettingsObjData | null;
  userAccess: boolean | null;

  saveDataToRouteState: (
    searchItem: number,
    jobDivaDetailsData: ProjectDetailsAPIResponse,
    calcSettings: CalcSettings,
    isEditDisabled: boolean
  ) => void;
  saveEditedCalcSettingsId: string;
  setAddingNew: (e: boolean) => void;
  setIsEdit: (e: boolean) => void;
  SetEditedCalcSettingsId: (e: string) => void;
}

const PCTable: React.FC<Props> = ({
  data,
  onClick,
  userAccess,
  saveDataToRouteState,
  saveEditedCalcSettingsId,
  setAddingNew,
  setIsEdit,
  SetEditedCalcSettingsId,
}) => {
  const fnEdit = (obj: PricingCalcSettingsObj) => {
    onClick(obj);
  };

  const location = useLocation();
  const { id } = useParams();
  const pricingCalcInfo = location.state?.pricingCalcInfo;
  const jobDivaInfo = location.state?.jobDivaInfo;
  const { SetLoader } = useContext(GlobalSpinnerContext);

  const [pricingCalcData, setPricingCalcData] = useState<
    PricingCalcSettingsObj[] | []
  >([]);

  useEffect(() => {
    if (data) {
      setPricingCalcData(
        data.startIdDetails.sort(
          (firstObj, secondObj) =>
            Number(secondObj.lastUpdatedDate.raw) -
            Number(firstObj.lastUpdatedDate.raw)
        )
      );
    }
  }, [data]);

  const editToggleSwitchHandler = async (item: PricingCalcSettingsObj) => {
    SetLoader(true);

    try {
      const statusOfRule = item.isActive !== undefined ? !item.isActive : true;

      const token =
        JSON.parse(localStorage.getItem("okta-token-storage") || "{}") || "";

      const loggedInUserMailId = token.accessToken.claims.sub;

      const request = PricingCalcApi.updateCalcSettingsConfiguration(
        +item.settingsId,
        statusOfRule,
        loggedInUserMailId
      );

      const calcSettingsUpdatedSettings = await API.performRequest(request);

      if (Object.keys(calcSettingsUpdatedSettings.data).length > 0) {
        setPricingCalcData(
          pricingCalcData.map((row) =>
            row.settingsId === calcSettingsUpdatedSettings.data.data.settingsId
              ? { ...row, isActive: !row.isActive }
              : { ...row }
          )
        );
        const calcSettingsData = pricingCalcInfo.data.startIdDetails.map(
          (e: PricingCalcSettingsObj) => {
            if (e.settingsId === item.settingsId) {
              return {
                ...e,
                isActive: !e.isActive,
                updatedBy: loggedInUserMailId,
              };
            }

            return e;
          }
        );

        if (`${item.settingsId}` === saveEditedCalcSettingsId) {
          SetEditedCalcSettingsId("");
          setAddingNew(false);
          setIsEdit(false);
        }

        const saveTohistory = {
          data: { startIdDetails: calcSettingsData },
          correlationId: "",
          errors: [],
        };

        id && saveDataToRouteState(+id, jobDivaInfo, saveTohistory, true);
      }
    } catch (e) {
      console.log(e);
    }

    SetLoader(false);
  };

  return (
    <table className="pricingCalc__table">
      <thead className="pricingCalc__table__header">
        <tr>
          <th>Effective Date</th>
          <th className="pricingCalc__table__header__cell">Comment</th>
          <th>Last Updated</th>
          <th className="pricingCalc__table__header__cell">Updated by</th>
          <th> </th>
        </tr>
      </thead>
      <tbody className="pricingCalc__table__body">
        {pricingCalcData.map((item) => (
          <tr
            key={item.settingsId}
            className={
              !item.isActive ? "pricingCalc__table__body__strikethrough" : ""
            }
          >
            <td>{moment(item.effectiveDate.raw).format("MM/DD/YYYY")}</td>
            <td className="pricingCalc__table__body__strikethrough__cell">
              {item.comment}
            </td>
            <td>
              {moment(item.lastUpdatedDate.raw).local().format("MM/DD/YYYY")}
            </td>
            <td className="pricingCalc__table__body__strikethrough__cell">
              {item.updatedBy}
            </td>
            <td>
              <Button
                className="btn-table"
                name={userAccess ? "View" : "Edit"}
                onClick={() => fnEdit(item)}
                disabled={!item.isActive}
              />
              <SwitchButton
                switchOn={!item.isActive}
                toggleSwitch={() => {
                  void editToggleSwitchHandler(item);
                }}
                disabled={userAccess}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default React.memo(PCTable);
