import React from "react";

import classes from "./index.module.scss";

interface ToggleIProps {
  setToggle: ([{ id, checked }]: {
    id: number;
    checked: boolean;
  }[]) => void;
  id: number | null;
  checkedState: { id: number; checked: boolean }[];
  alterToggleSwitch?: string;
  alterToggleSlider?: string;
  needText?: boolean;
  writeAccess?: boolean;
  labelText?: string;
}

const MBOToggle: React.FC<ToggleIProps> = ({
  id,
  setToggle,
  checkedState,
  alterToggleSwitch,
  alterToggleSlider,
  needText,
  writeAccess,
  labelText = "",
}) => {
  const ToggleRow = () => {
    setToggle(
      checkedState.map((e) => {
        if (id) {
          if (e.id === id) {
            return {
              id,
              checked: !e.checked,
            };
          }
        }

        return e;
      })
    );
  };

  const isChecked =
    checkedState && checkedState.length > 0
      ? checkedState[checkedState.findIndex((e) => Number(e.id) === Number(id))]
          ?.checked
      : false;

  return (
    <div
      className={[classes.toggle_parent, classes.toggle_parent_helper].join(
        " "
      )}
    >
      {checkedState && checkedState.length > 0 && (
        <label
          className={[
            classes.switch,
            alterToggleSwitch,
            !writeAccess ? classes.input_opacity : "",
          ].join(" ")}
        >
          <input
            type="checkbox"
            onChange={() => ToggleRow()}
            checked={isChecked || false}
            disabled={!writeAccess}
          />

          <span
            className={[classes.slider, classes.round, alterToggleSlider].join(
              " "
            )}
          ></span>
        </label>
      )}

      {(checkedState && checkedState.length > 0 && needText && (
        <p>
          {labelText && labelText !== "" ? labelText : isChecked ? "On" : "Off"}
        </p>
      )) ||
        null}
    </div>
  );
};

export default MBOToggle;
