const BusinessStandardRules: StandardRules = {
  country: "",
  mainDivision: "",
  city: "",
  rateLow: "",
  rateHigh: "",
  engagementType: "",
  attributionName: "",
  groupName: "",
  operationName: "",
  s4VendorName: "",
  name: "",
  description: "",
  effectiveDate: { localized: "", timeZone: "", raw: 0 },
  expireDate: { localized: "", timeZone: "", raw: 0 },
  currency: "",
  amount: { localized: "", raw: 0, currency: "" },
  group: { id: "", name: "" },
  operation: { id: "", name: "" },
  attribution: { id: "", name: "" },
  ruleType: "",
};

export default BusinessStandardRules;
