import Input from "../../Input";
import { optionsProp } from "../manageRules";

interface ClientProp {
  clientList: optionsProp[];
  clientChangeHandler: (client: optionsProp | unknown) => void;
  selectedClient: optionsProp | null;
  clientsComponentsClasses: {
    parent: string;
    label: string;
    list: string;
  };
}

const ClientList = ({
  clientList,
  selectedClient,
  clientChangeHandler,
  clientsComponentsClasses,
}: ClientProp) => (
  <div className={clientsComponentsClasses.parent}>
    <Input.Select
      value={selectedClient && selectedClient}
      onChange={(item: optionsProp | unknown) => clientChangeHandler(item)}
      options={clientList}
      placeholder="Search for Client"
      className={clientsComponentsClasses.list}
    />
    {selectedClient && selectedClient ? (
      <span>{selectedClient.label}</span>
    ) : null}
  </div>
);

export default ClientList;
