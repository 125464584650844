import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";

import { initializeTagManager } from "./config/gtm";
import AppWithRouter from "./routes/AppWithRouter";

const reactQueryClient = new QueryClient();

initializeTagManager();

const App = () => (
  <Router>
    <QueryClientProvider client={reactQueryClient}>
      <AppWithRouter />
    </QueryClientProvider>
  </Router>
);

export default App;
