import React, { useEffect, useRef, useState } from "react";

import Button from "../Button/Button";
import Input from "../Input";

export interface optionsProp {
  value: number;
  label: string;
}

export interface workOrderStateProps {
  id: number | null;
  description: string;
  group: optionsProp | null | unknown;
  operation: optionsProp | null;
  attribution: optionsProp | null;
  vendor: optionsProp | null;
  amount: string;
}

interface WorkOrderBodyProps {
  deleteHandler: () => void;
  getSelectedValues: (values: workOrderStateProps) => void;
  vendorData: optionsProp[];
  attributionData: optionsProp[];
  operationData: optionsProp[];
  groupData: optionsProp[];
  customerAppliedWorkOrderData: workOrderStateProps;
  requiredWorkorderFields: WorkOrderRequiredFields;
  userAccess: boolean | null;
}

export const dummyData = {
  id: null,
  description: "",
  group: null,
  operation: null,
  attribution: null,
  vendor: null,
  amount: "",
};

const WorkOrderBody: React.FC<WorkOrderBodyProps> = ({
  deleteHandler,
  getSelectedValues,
  vendorData,
  operationData,
  attributionData,
  groupData,
  customerAppliedWorkOrderData,
  requiredWorkorderFields,
  userAccess,
}) => {
  const [workOrderState, setWorkOrderState] = useState<workOrderStateProps>(
    customerAppliedWorkOrderData && customerAppliedWorkOrderData
  );

  const amountRef = useRef<HTMLInputElement | null>(null);

  const descriptionHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = {
      ...workOrderState,
      description: e.target.value,
    };

    setWorkOrderState((prev) => ({
      ...prev,
      description: e.target.value,
    }));
    getSelectedValues(data);
  };

  const amountHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (amountRef.current) {
      if (
        (amountRef.current.value.length === 1 &&
          amountRef.current.value === "-") ||
        !Number.isNaN(Math.sign(+amountRef.current.value))
      ) {
        const amountToAdd = e.target.value.trim();

        if (amountToAdd.includes(".") && amountToAdd.split(".")[1].length > 2) {
          amountRef.current.value = `${workOrderState.amount}`;

          return;
        }

        amountRef.current.value = `${amountToAdd}`;
        const data = {
          ...workOrderState,
          amount: `${amountToAdd}`,
        };

        setWorkOrderState((prev) => ({
          ...prev,
          amount: `${amountToAdd}`,
        }));
        getSelectedValues(data);

        return;
      }

      amountRef.current.value = `${workOrderState.amount}`;
    }
  };

  const groupHandler = (item: unknown) => {
    const data = {
      ...workOrderState,
      group: item ? (item as optionsProp) : null,
    };

    if (item) {
      setWorkOrderState((prev) => ({
        ...prev,
        group: item as optionsProp,
      }));
    } else {
      setWorkOrderState((prev) => ({
        ...prev,
        group: null,
      }));
    }

    getSelectedValues(data);
  };

  const operationHandler = (item: unknown) => {
    const data = {
      ...workOrderState,
      operation: item ? (item as optionsProp) : null,
    };

    if (item) {
      setWorkOrderState((prev) => ({
        ...prev,
        operation: item as optionsProp,
      }));
    } else {
      setWorkOrderState((prev) => ({
        ...prev,
        operation: null,
      }));
    }

    getSelectedValues(data);
  };

  const attributionHanlder = (item: unknown) => {
    const data = {
      ...workOrderState,
      attribution: item ? (item as optionsProp) : null,
    };

    if (item) {
      setWorkOrderState((prev) => ({
        ...prev,
        attribution: item as optionsProp,
      }));
    } else {
      setWorkOrderState((prev) => ({
        ...prev,
        attribution: null,
      }));
    }

    getSelectedValues(data);
  };

  const vendorHandler = (item: unknown) => {
    const data = {
      ...workOrderState,
      vendor: item ? (item as optionsProp) : null,
    };

    if (item) {
      setWorkOrderState((prev) => ({
        ...prev,
        vendor: item as optionsProp,
      }));
    } else {
      setWorkOrderState((prev) => ({
        ...prev,
        vendor: null,
      }));
    }

    getSelectedValues(data);
  };

  const appendSign = () => {
    const amount = `${workOrderState.amount}`;

    const operation =
      workOrderState.operation && workOrderState.operation.label;

    const amountToAdd = amount.trim();

    if (amountToAdd.includes(".") && amountToAdd.split(".")[1].length > 2) {
      return;
    }

    const negativeSign = Math.sign(+amount) === -1 ? "-" : "";
    const rate = Math.abs(+amount);

    if (amountRef.current) {
      if (!rate) {
        amountRef.current.value = "";
        setWorkOrderState((prev) => ({
          ...prev,
          amount: "",
        }));

        return;
      }

      if (operation && operation !== "null" && amount !== "") {
        amountRef.current.value =
          operation === "flat fee"
            ? `${negativeSign}$${rate}`
            : `${negativeSign}${rate}%`;
      } else {
        amountRef.current.value = `${amount}`;
      }
    }
  };

  useEffect(() => {
    if (workOrderState.operation && workOrderState.operation.label) {
      appendSign();
    }
  }, [workOrderState.operation?.label]);

  return (
    <div
      className={`workOrder__body__row  ${
        userAccess ? "workOrder__body__disableAccess" : ""
      }`}
    >
      <Input.Search
        value={workOrderState.description}
        label=" "
        onChange={descriptionHandler}
        placeholder="description"
      />
      <Input.Select
        placeholder="Group*"
        onChange={(item) => groupHandler(item)}
        options={groupData}
        value={workOrderState.group}
        required={requiredWorkorderFields && requiredWorkorderFields.group}
      />
      <Input.Select
        placeholder="Operation*"
        onChange={(item) => operationHandler(item)}
        options={operationData}
        value={workOrderState.operation}
        required={requiredWorkorderFields && requiredWorkorderFields.operation}
      />
      <Input.Select
        value={workOrderState.attribution}
        onChange={(item) => attributionHanlder(item)}
        options={attributionData}
        placeholder="Attribution*"
        required={
          requiredWorkorderFields && requiredWorkorderFields.attribution
        }
      />
      <Input.Select
        value={workOrderState.vendor}
        onChange={(item) => vendorHandler(item)}
        options={vendorData.map((vendor) => ({
          ...vendor,
          label: `${vendor.value} - ${vendor.label}`,
        }))}
        placeholder="Supplier"
      />
      <Input.Search
        onBlur={appendSign}
        onFocus={() => {
          if (amountRef.current) {
            amountRef.current.value = workOrderState.amount;
          }
        }}
        label=" "
        onChange={amountHandler}
        placeholder="Amount*"
        required={requiredWorkorderFields && requiredWorkorderFields.amount}
        innerRef={amountRef}
      />

      <Button
        name="delete"
        disabled={userAccess !== null && userAccess}
        onClick={deleteHandler}
      />
    </div>
  );
};

export default WorkOrderBody;
