import React from "react";

import JDHeader, { JDHeaderProps } from "./components/JDHeader";
import JDTable from "./components/JDTable";

import "./JobDiva.scss";

interface JobDivaProps extends JDHeaderProps {
  headerA: { id: number; name: string }[];
  headerB: { id: number; name: string }[];
  payRate: ProjectDetailsPayInfo[];
  billRate: ProjectDetailsBillInfo[];
}

const JobDiva: React.FC<JobDivaProps> = ({
  name = "",
  clientName = "",
  startDate = "",
  engagementType = "",
  location = "",
  supplierInfo,
  sowInfo,
  headerA,
  headerB,
  payRate,
  billRate,
}) => (
  <div className="jobdiva">
    <JDHeader
      name={name}
      clientName={clientName}
      startDate={startDate}
      engagementType={engagementType}
      location={location}
      supplierInfo={supplierInfo}
      sowInfo={sowInfo}
    />
    <hr />
    <div className="jobdiva__tables">
      <div className="jobdiva__tables__content">
        <JDTable header={headerA} payrate paybody={payRate} />
      </div>
      <hr />
      <div className="jobdiva__tables__content">
        <JDTable header={headerB} payrate={false} billbody={billRate} />
      </div>
    </div>
  </div>
);

export default React.memo(JobDiva);
