import React from "react";

import "./SimulatePayBill.scss";

export interface SimulationPayBillProps {
  children: React.ReactNode;
}

const SimulatePaybill: React.FC<SimulationPayBillProps> = ({ children }) => (
  <div className="simulatepay">{children}</div>
);

export default React.memo(SimulatePaybill);
