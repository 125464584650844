import React from "react";
import { AiOutlineClose } from "react-icons/ai";

import { optionsProp } from "../../../../../../@types/component";
import ErrorBoundary from "../../../../../../HOC/ErrorBoundary/errorBoundary.component";
import JobDivaSearch from "../../../../../../componentsPhase1/JobDivaSearch";

import classes from "./index.module.scss";

interface iProps {
  setSelectedClient: React.Dispatch<React.SetStateAction<optionsProp>>;
  selectedClient: optionsProp;
  setJobDiva: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchClient: React.FC<iProps> = ({
  selectedClient,
  setSelectedClient,
  setJobDiva,
}) => {
  return (
    <>
      <button
        data-testid="button"
        onClick={() => {
          setJobDiva(false);
        }}
        className={classes.close_icon}
      >
        <AiOutlineClose />
      </button>
      <JobDivaSearch
        setSelectedClient={setSelectedClient}
        selectedClient={selectedClient}
        isJobDiva={true}
        alterStyle={`${classes.search_parent} ${classes.search_parent_helper}`}
      />
    </>
  );
};

export default ErrorBoundary(SearchClient);
