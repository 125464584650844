import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/src/stylesheets/datepicker.scss";

import "./Date.scss";

interface DateProps {
  value: Date | null;
  onChange: (date: Date) => void;
  className?: string;
  placeholderText?: string;
}

const Date: React.FC<DateProps> = ({
  value,
  onChange,
  className,
  placeholderText,
}) => (
  <div className={`datePicker ${className || ""}`}>
    <DatePicker
      dateFormat="MMM/dd/yyy"
      onChange={onChange}
      selected={value || null}
      placeholderText={placeholderText}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      isClearable
    />
  </div>
);

export default Date;
