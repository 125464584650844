import React from "react";

import classes from "./tooltip.module.scss";

interface MBOTooltipProps {
  content?: string;
  bottom?: string;
  children?: React.ReactNode;
  arrowBottom?: boolean;
}

const MBOTooltip: React.FC<MBOTooltipProps> = ({
  content,
  bottom,
  children,
  arrowBottom,
}) => {
  return (
    <div className={[classes.content, bottom].join(" ")} data-testid="tooltip">
      <div
        className={!arrowBottom ? classes.arrowContainer : classes.arrowDown}
      >
        <span className={classes.arrow} />
      </div>
      <div>
        <span
          className={
            !arrowBottom ? classes.borderline : classes.borderline_down
          }
        ></span>
        <div className={classes.options}>{content || children}</div>
      </div>
    </div>
  );
};

export default MBOTooltip;
