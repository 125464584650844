import UserRoles from "./userRoleNames";

interface IObjectKeys {
  [key: string]: string;
}
export const componentNames: IObjectKeys = {
  WORKORDER: "WorkOrder",
};

const getRoles = (ENV: string) => UserRoles(ENV);

export const createUserRoles = () => {
  const appEnv = window.location.host;

  let userRoles: string[] = [];

  switch (true) {
    case appEnv === process.env.REACT_APP_LOCAL_HOST:
      userRoles = getRoles("LOCAL");
      break;

    case appEnv === process.env.REACT_APP_DEV_HOST:
      userRoles = getRoles("DEV");
      break;

    case appEnv === process.env.REACT_APP_STAGING_HOST ||
      appEnv === process.env.REACT_APP_QA_HOST:
      userRoles = getRoles("STAGING");
      break;

    case appEnv === process.env.REACT_APP_PROD_HOST:
      userRoles = getRoles("PROD");
      break;

    default:
      userRoles = [];
  }

  return userRoles.map((rule) => {
    if (rule) {
      const rulesInformation = rule.split(" ");

      return {
        user: rulesInformation[0],
        accessLevel: rulesInformation[1],
        readonly: rulesInformation
          .slice(2)
          .map((element) => componentNames[element]),
      };
    }

    return {};
  });
};

export const getMBOId = (): string => {
  const authAccessToken =
    JSON.parse(localStorage.getItem("okta-token-storage") || "{}") || {};

  const authIdToken = authAccessToken?.idToken;

  return authIdToken?.claims?.mbo_id || "";
};

export const getUserEmailId = (): string => {
  const authAccessToken =
    JSON.parse(localStorage.getItem("okta-token-storage") || "{}") || {};

  const authIdToken = authAccessToken?.idToken;

  return authIdToken?.claims?.email || "";
};
