import moment from "moment";
import { useMemo } from "react";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import { Column } from "react-table";

import Table from "../../TableComponent/parentTableComponent";

interface StandardRulesIProps {
  businessStandardRules: ManageStandardRules[] | null | undefined;
  deleteRulehandler: (obj: { [key: string]: number[] }) => void;
  manageRuleHandler: (record: number | null, type: string) => void;
}

interface TableDataProps {
  [key: string]: string | number | React.ReactNode | null;
}
interface TableColIProps {
  [key: string]: string | number | React.ReactNode;
}

const StandardRules = ({
  businessStandardRules,
  deleteRulehandler,
  manageRuleHandler,
}: StandardRulesIProps) => {
  const data: TableDataProps[] = [];

  let StandardRulesHeaders: Column<TableColIProps>[] | [] = [];

  const validateAmount = (rule: ManageStandardRules) => {
    if (rule.amount.localized === "percentage") {
      return rule.amount.value;
    }

    return rule.amount.raw && rule.amount.raw !== ""
      ? `$${rule.amount.raw}`
      : "";
  };

  if (businessStandardRules) {
    businessStandardRules.forEach((rule) => {
      data.push({
        id: rule.id || "",
        clientName: rule.client.name,
        name: rule.name,
        description: rule.description,
        effectiveDate: rule.effectiveDate
          ? moment(rule.effectiveDate.raw).format("MMM DD, YYYY")
          : "",
        expireDate: rule.expireDate
          ? moment(rule.expireDate.raw).local().format("MMM DD, YYYY")
          : "",
        performedBy: rule.performedBy,
        country: rule.country,
        mainDivision: rule.mainDivision,
        city: rule.city,

        rateLow: rule.rateLow,
        rateHigh: rule.rateHigh,
        engagementType: rule.engagementType || null,
        attributionId: rule.attribution.id,
        attributionName: rule.attribution.name,
        groupId: rule.group.id,
        groupName: rule.group.name,
        operationId: rule.operation.id,
        operationName: rule.operation.name,
        amount: validateAmount(rule),

        s4VendorId: (rule.vendor && rule.vendor.id) || null,
        s4VendorName: (rule.vendor && rule.vendor.vendorName) || null,
        createdDate: rule.createdDate
          ? moment(rule.createdDate.raw).local().format("MMM DD, YYYY")
          : "",
        updatedDate: rule.updatedDate
          ? moment(rule.updatedDate.raw).local().format("MMM DD, YYYY")
          : "",
        action: ((record) => (
          <div className="action_btn_styles">
            <FaPencilAlt
              onClick={() =>
                manageRuleHandler(Number(record.id), "editStandardRule")
              }
            />
            <FaTrashAlt
              onClick={() =>
                deleteRulehandler({
                  deletedOptionalRuleIds: [],
                  deletedStandardRuleIds: [Number(record.id)],
                })
              }
            />
          </div>
        ))(rule),
      });
    });

    StandardRulesHeaders =
      data && data.length > 0
        ? useMemo(
            () =>
              [...Object.keys(data[0])]
                .filter(
                  (rule) =>
                    ![
                      "id",
                      "clientId",
                      "clientName",
                      "s4VendorId",
                      "operationId",
                      "groupId",
                      "attributionId",
                      "isChecked",
                      "currency",
                    ].includes(rule)
                )
                .map((e, i) => ({
                  Header:
                    e === "mainDivision"
                      ? "State"
                      : e
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^./, (str) => str.toUpperCase()),
                  accessor: e,
                  width: [
                    "name",
                    "description",
                    "attributionName",
                    "groupName",
                    "operationName",
                    "performedBy",
                    "engagementType",
                  ].includes(e)
                    ? (e === "performedBy" && 350) || 250
                    : 150,
                  sticky: ((index) => {
                    if (index === 0) {
                      return "left";
                    }

                    if (index > Object.keys(data[0]).length - 8) {
                      return "right";
                    }

                    return "";
                  })(i),
                })),

            []
          )
        : [];
  }

  return <Table data={data} columns={StandardRulesHeaders} />;
};

export default StandardRules;
