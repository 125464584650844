import { toRelativeUrl } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { Outlet } from "react-router-dom";

import Header from "../componentsPhase1/Header/Header";

import classes from "./protectedRoute.module.scss";

const ProtectedRoutesPhase1 = () => {
  const { oktaAuth, authState } = useOktaAuth();

  const login = async (): Promise<void> => {
    const originalUri = toRelativeUrl(
      window.location.href,
      window.location.origin
    );

    oktaAuth.setOriginalUri(originalUri);
    await oktaAuth.signInWithRedirect();
  };

  if (!authState) {
    return;
  }

  if (!authState?.isAuthenticated) {
    login();
  }

  return (
    authState?.isAuthenticated && (
      <div className={classes.main_parent} id="main_container">
        <Header />

        <div className={classes.main_body_styling}>
          <Outlet />
        </div>
      </div>
    )
  );
};

export default ProtectedRoutesPhase1;
