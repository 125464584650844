import React from "react";

interface RatesProps {
  rates: {
    [key: string]: {
      payRate: number;
      netPayRate?: number;
      billRate: number;
      billRateDt?: number | null;
      billRateOt?: number | null;
      payRateDt: number | null;
      payRateOt: number | null;
      payRateOtDt: number | null;
      markUp: string | "";
      label: string;
      index: string;
      isJdRate: boolean;
    };
  };
  screen: "work-order" | "simulation" | null;
  paytoBill?: boolean;
  alterClassName?: string;
}

const Rates: React.FC<RatesProps> = ({
  rates,
  alterClassName,
  paytoBill = false,
  screen,
}) => {
  const validateNegativeValue = (
    value: number | string,
    multiPlyWith: number
  ) => {
    if (value && value !== "") {
      const negativeSign = Math.sign(Number(value)) === -1 ? "-" : "";
      const rate = Math.abs(Number(value));

      return `${negativeSign}$${
        multiPlyWith > 0
          ? ((rate / 100) * multiPlyWith).toFixed(2)
          : (rate / 100).toFixed(2)
      }`;
    }

    return "";
  };

  return (
    <>
      {Object.keys(rates).map((rate) => (
        <div
          className={`simulatepay__body__rates ${alterClassName || ""}`}
          key={`${rates[rate].payRate}-${rates[rate].index}`}
        >
          {rates[rate].label === "Calculated Rates" ? (
            <h5 className="simulatepay__body__rates__label">
              {rates[rate].label}
            </h5>
          ) : null}
          <div className="simulatepay__body__rates__parent">
            {rates[rate].label !== "Calculated Rates" ? (
              <h5 className="simulatepay__body__rates__label">
                {rates[rate].label}
              </h5>
            ) : null}
            <div className="simulatepay__body__rates__parent__child">
              <div>
                <span>Pay Rate</span>
                <span data-rate>
                  {validateNegativeValue(rates[rate].payRate, 0) || "N/A"}
                </span>
              </div>
              {rates[rate].netPayRate && (
                <div>
                  <i>Net Pay Rate</i>
                  <span data-rate>
                    {validateNegativeValue(rates[rate].netPayRate as number, 0)}
                  </span>
                </div>
              )}

              {rates[rate].isJdRate && rates[rate].payRateDt ? (
                <div>
                  <i>
                    {paytoBill ? "OverTime Bill Rate" : "OverTime Pay Rate"}
                  </i>
                  <span data-rate>
                    {paytoBill
                      ? validateNegativeValue(rates[rate].billRateOt || "", 0)
                      : validateNegativeValue(rates[rate].payRateOt || "", 0)}
                  </span>
                </div>
              ) : (
                ""
              )}

              {!rates[rate].isJdRate &&
                rates[rate].payRateOtDt &&
                !paytoBill &&
                screen === "work-order" && (
                  <div>
                    <i>OverTime Pay Rate</i>
                    <span data-rate>
                      {validateNegativeValue(
                        rates[rate].payRateOtDt || "",
                        1.5
                      )}
                      &nbsp;(1.5 Times of Pay Rate)
                    </span>
                  </div>
                )}
              {!rates[rate].isJdRate &&
                rates[rate].payRateOtDt &&
                screen === "simulation" &&
                (!paytoBill ? (
                  <div>
                    <i>OverTime Pay Rate</i>
                    <span data-rate>
                      {validateNegativeValue(
                        rates[rate].payRateOtDt || "",
                        1.5
                      )}
                      &nbsp;(1.5 Times of Pay Rate)
                    </span>
                  </div>
                ) : (
                  <div>
                    <i>OverTime Bill Rate</i>
                    <span data-rate>
                      {validateNegativeValue(
                        rates[rate].payRateOtDt || "",
                        1.5
                      )}
                      &nbsp;(1.5 Times of Bill Rate)
                    </span>
                  </div>
                ))}

              {rates[rate].isJdRate && rates[rate].payRateDt ? (
                <div>
                  <i>
                    {paytoBill ? "DoubleTime Bill Rate" : "DoubleTime Pay Rate"}
                  </i>
                  <span data-rate>
                    {paytoBill
                      ? validateNegativeValue(rates[rate].billRateDt || "", 0)
                      : validateNegativeValue(rates[rate].payRateDt || "", 0)}
                  </span>
                </div>
              ) : (
                ""
              )}

              {!rates[rate].isJdRate &&
                rates[rate].payRateOtDt &&
                !paytoBill &&
                screen === "work-order" && (
                  <div>
                    <i>DoubleTime Pay Rate</i>
                    <span data-rate>
                      {validateNegativeValue(rates[rate].payRateOtDt || "", 2)}
                      &nbsp;(2.0 Times of Pay Rate)
                    </span>
                  </div>
                )}
              {!rates[rate].isJdRate &&
                rates[rate].payRateOtDt &&
                screen === "simulation" &&
                (!paytoBill ? (
                  <div>
                    <i>DoubleTime Pay Rate</i>
                    <span data-rate>
                      {validateNegativeValue(
                        rates[rate].payRateOtDt || "",
                        2.0
                      )}
                      &nbsp;(2.0 Times of Pay Rate)
                    </span>
                  </div>
                ) : (
                  <div>
                    <i>DoubleTime Bill Rate</i>
                    <span data-rate>
                      {validateNegativeValue(
                        rates[rate].payRateOtDt || "",
                        2.0
                      )}
                      &nbsp;(2.0 Times of Bill Rate)
                    </span>
                  </div>
                ))}

              {rates[rate].label !== "Calculated Rates" ? (
                <div data-name="billrate">
                  <span>Bill Rate</span>
                  <span data-rate>
                    {validateNegativeValue(rates[rate].billRate, 0) || "N/A"}
                  </span>
                </div>
              ) : null}
            </div>
            {rates[rate].label === "Calculated Rates" ? (
              <div className="simulatepay__body__rates__parent__child">
                <div data-name="billrate">
                  <span>Bill Rate</span>
                  <span data-rate>
                    {validateNegativeValue(rates[rate].billRate, 0)}
                  </span>
                </div>
                {rates[rate].billRate &&
                paytoBill &&
                screen === "work-order" &&
                rates?.jdRates?.billRateOt !== 0 ? (
                  <div>
                    <i>OverTime Bill Rate</i>
                    <span data-rate>
                      {validateNegativeValue(rates[rate].billRate || "", 1.5)}
                      &nbsp;(1.5 Times of Bill Rate)
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {rates[rate].billRate &&
                paytoBill &&
                screen === "work-order" &&
                rates?.jdRates?.billRateDt !== 0 ? (
                  <div>
                    <i>DoubleTime Bill Rate</i>
                    <span data-rate>
                      {validateNegativeValue(rates[rate].billRate || "", 2.0)}
                      &nbsp;(2.0 Times of Bill Rate)
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {rates[rate].markUp && rates[rate].markUp !== "" && (
                  <div data-name="billrate">
                    <span>Mark Up</span>
                    <span data-rate>{rates[rate].markUp || "N/A"}</span>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      ))}
    </>
  );
};

export default Rates;
