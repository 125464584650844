import React from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { optionsProp } from "../../WorkOrder/WorkOrderBody";

import "./Select.scss";

interface onChangeProp {
  value?: number;
  label?: string;
}
interface SelectInputProps {
  options: optionsProp[] | null;
  onChange: (option: onChangeProp | unknown) => void;
  value: onChangeProp | null | unknown;
  className?: string;
  placeholder?: string;
  required?: boolean;
  allowCustomSearch?: boolean;
}

const SelectInput: React.FC<SelectInputProps> = ({
  options,
  onChange,
  value,
  className,
  placeholder = "Select...",
  required,
  allowCustomSearch = false,
}) => {
  return allowCustomSearch ? (
    <CreatableSelect
      options={options || []}
      isMulti={false}
      onChange={onChange}
      value={value}
      isClearable
      className={`select ${className || ""}`}
      classNamePrefix={`${required ? "error_border" : "select"}`}
      placeholder={placeholder}
    />
  ) : (
    <Select
      options={options || []}
      isMulti={false}
      onChange={onChange}
      value={value}
      isClearable
      className={`select ${className || ""}`}
      classNamePrefix={`${required ? "error_border" : "select"}`}
      placeholder={placeholder}
    />
  );
};

export default SelectInput;
