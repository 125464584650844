/* eslint-disable @typescript-eslint/no-explicit-any */
import { createUserRoles } from "../constants/userGroups";

const ValidateUser = (): any[] => {
  const token =
    JSON.parse(localStorage.getItem("okta-token-storage") || "{}") || "";

  const OktaUserGroupUsersList = token.accessToken?.claims?.groups;
  const existUserGroups: any[] = [];
  const allUserRoles = createUserRoles();

  if (OktaUserGroupUsersList && OktaUserGroupUsersList.length > 0) {
    allUserRoles.forEach((gUser) => {
      const { user } = gUser;

      if (OktaUserGroupUsersList.indexOf(user) > -1) {
        existUserGroups.push(gUser);
      }
    });
  }

  return existUserGroups && existUserGroups.length > 0
    ? [existUserGroups.sort((a, b) => b.accessLevel - a.accessLevel)[0]]
    : [];
};

export default ValidateUser;
