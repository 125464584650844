import { useTable, Column, useBlockLayout } from "react-table";
import { useSticky } from "react-table-sticky";
import styled from "styled-components";

interface TableIProps {
  [key: string]: string | number | React.ReactNode;
}
interface TableColIProps {
  [key: string]: string | number | React.ReactNode;
}

const Styles = styled.div`
  .table {
    border: 1px solid #ddd;
    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }
    .td {
      background-color: white;
    }
    .th {
      background-color: #007f9c;
    }
    .th,
    .td {
      padding: 5px;

      overflow: hidden;

      :last-child {
        border-right: 0;
      }
    }

    &.sticky {
      border-radius: 4px;
      min-height: max-content;
      max-height: 42vh;
      padding-bottom: 10px;
      overflow: auto;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        padding: 0;
        height: 3rem;
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
        & .tr {
          height: 100%;
        }
        & .th {
          border-right: 1px solid white;
          padding: 12px;
          &:last-child {
            border-right: none;
            border-left: 1px solid white;
          }
        }
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
        & .td {
          font-weight: normal;
          padding: 13px 0px 0px 10px;
          border-bottom: 1px solid rgb(33, 21, 81);
          border-right: 1px solid rgb(33, 21, 81);
          &:nth-last-child(2) {
            border-right: none;
          }
          &:last-child {
            border-right: none;
            border-left: 1px solid rgb(33, 21, 81);
          }
        }
      }

      [data-sticky-td] {
        position: sticky;
      }
    }
  }
`;

const Table = ({
  columns,
  data,
}: {
  columns: Column<TableColIProps>[];
  data: TableIProps[];
}) => {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useBlockLayout,
      useSticky
    );

  // Render the UI for your table
  return (
    <Styles className="tableMaxHeight">
      <div {...getTableProps()} className="table sticky">
        <div className="header">
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column) => (
                // eslint-disable-next-line react/jsx-key
                <div {...column.getHeaderProps()} className="th">
                  {column.render("Header")}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="body">
          {rows.map((row) => {
            prepareRow(row);

            return (
              // eslint-disable-next-line react/jsx-key
              <div {...row.getRowProps()} className="tr">
                {row.cells.map((cell) => (
                  // eslint-disable-next-line react/jsx-key
                  <div {...cell.getCellProps()} className="td">
                    {cell.render("Cell")}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </Styles>
  );
};

export default Table;
