import React from "react";
import { UseQueryResult } from "react-query";

import {
  dataFlowProps,
  hourlyJobDivaRatesRequest,
} from "../../../../../../@types/component";
import Button from "../../../../../../componentsPhase1/MBOButton/Button";
import { hourlyJobDivaRatesState } from "../../../../../../data/dummyData";

import classes from "./index.module.scss";

interface jobDivaidList {
  id: string | number;
  value: string | number;
  startId: string | number;
  client: { id: string; name: string };
  month: string;
  day: string | number;
  year: string | number;
  selected: boolean;
}

interface iProps {
  SubmitRatesToJobDiva: UseQueryResult;
  setJobDiva: React.Dispatch<React.SetStateAction<boolean>>;
  setDataFlow: (e: dataFlowProps[]) => void;
  dataFlow: dataFlowProps[];
  setRatesNonApprovedRecord: React.Dispatch<
    React.SetStateAction<boolean | null>
  >;

  setHourlyRatesforJobDiva: React.Dispatch<
    React.SetStateAction<hourlyJobDivaRatesRequest>
  >;

  setSelectedRateId: React.Dispatch<React.SetStateAction<number | null>>;

  setJobDivalist: React.Dispatch<React.SetStateAction<jobDivaidList[] | null>>;

  setJobDivaId: React.Dispatch<React.SetStateAction<string>>;

  setJobDivaRecordError: React.Dispatch<
    React.SetStateAction<{
      code: number;
      message: string;
    } | null>
  >;

  setJobDivaDetails: React.Dispatch<
    React.SetStateAction<ProjectDetailsAPIResponse | null>
  >;
}

const SearchClient: React.FC<iProps> = ({
  setJobDiva,
  dataFlow,
  setDataFlow,
  setRatesNonApprovedRecord,
  setHourlyRatesforJobDiva,
  setSelectedRateId,
  setJobDivaDetails,
  setJobDivaId,
  setJobDivaRecordError,
  setJobDivalist,
  SubmitRatesToJobDiva,
}) => {
  return (
    <div className={classes.parent}>
      <div className={classes.success_message}>
        <div className={classes.tick_mark}>&#x2713;</div>{" "}
        <h2>The new rates were successfully submitted to JobDiva</h2>
      </div>
      <div className={classes.button_container}>
        <Button
          name="Start new simulation"
          className={classes.btn_update_styling}
          onClick={() => {
            const updatedState = dataFlow.map((e: dataFlowProps) => {
              return { ...e, data: e.level === 0 };
            });

            SubmitRatesToJobDiva.remove();
            setRatesNonApprovedRecord(null);
            setHourlyRatesforJobDiva(hourlyJobDivaRatesState);
            setSelectedRateId(null);
            setJobDivaDetails(null);
            setJobDivaId("");
            setJobDivaRecordError(null);
            setJobDivalist(null);
            setDataFlow(updatedState);

            setJobDiva(false);
          }}
        />
      </div>
    </div>
  );
};

export default SearchClient;
