import { useContext, useEffect } from "react";

import GlobalToastContext from "../../contexts/ToastContext";

import "./Toast.scss";

const ToastComponent = () => {
  const { toastMessage, EnableToast } = useContext(GlobalToastContext);

  useEffect(() => {
    if (toastMessage.message !== "") {
      setTimeout(() => {
        EnableToast({ message: "", type: "" });
      }, 5000);
    }
  }, [toastMessage]);

  return (
    <div
      className={`toast ${
        toastMessage.type !== "" ? `toast_bg_${toastMessage.type}` : ""
      } show_toast`}
      aria-hidden="true"
    >
      {toastMessage.message}
      <span
        className="toast__close"
        onClick={() => EnableToast({ message: "", type: "" })}
      />
    </div>
  );
};

export default ToastComponent;
