import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/src/stylesheets/datepicker.scss";

import CustomInput from "./datecistomComponent";

import "./index.scss";

interface DateProps {
  value: null | Date;
  onChange: (date: Date) => void;
  className?: string;
  placeholderText?: string;
  errorStyle?: string;
  errorText?: string;
  writeAccess?: boolean;
  topPlaceholderText?: boolean;
}

const Date: React.FC<DateProps> = ({
  value,
  onChange,
  className,
  placeholderText,
  errorStyle,
  errorText,
  writeAccess = true,
  topPlaceholderText = true,
}) => {
  return (
    <div
      className={`datePicker1 ${className || ""} ${errorStyle || ""}`}
      data-testid="date_parent"
    >
      <DatePicker
        dateFormat="MMM | dd | yyy"
        data-testid="datepicker"
        onChange={onChange}
        selected={value}
        placeholderText={placeholderText}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        disabled={!writeAccess}
        customInput={
          <CustomInput
            value={moment(value).format("MMM | dd | yyy")}
            onClick={() => onchange}
            placeholder={placeholderText || ""}
            topPlaceholderText={topPlaceholderText}
          />
        }
        isClearable={writeAccess}
        className={`test_class ${value ? "selected_fill" : ""}`}
      />
      {errorStyle && (
        <span className={"datepicket1_errortext"}>{errorText}</span>
      )}
    </div>
  );
};

export default Date;
