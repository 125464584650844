const UserRoleNames = (ENV: string) => [
  `${process.env[`REACT_APP_${ENV}_PRICING_LEAD`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_PRICING_USER`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_JD_PROGRAM_SPECIALIST`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_JD_ENROLLMENT_MANAGER`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_JD_CLIENT_PWC_CARE`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_JD_ADVANTAGE_DIRECT`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_JD_OPS_MANAGER`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_JD_SUPER_USER`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_JD_TIER2`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_JD_ACCOUNT_DIRECTOR`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_JD_ADVISOR`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_JD_RECRUITING_MANAGER`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_JD_HR_OPERATIONS_MANAGER`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_JD_COMPLINACE`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_JD_PAYROLL`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_JD_CONTROLLER`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_JD_ACCOUNTS_PAYABLE`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_JD_ACCOUNTS_RECEIVABLE`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_JD_INTEGRATIONS`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_JD_IMPLEMENTATION`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_JD_OUTSOURCED`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_JD_JIFFY`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_JD_EXECUTIVE`] || ""}`,
  `${process.env[`REACT_APP_${ENV}_JD_REPORTING`] || ""}`,
];

export default UserRoleNames;
