import React from "react";

import { inputProps } from "../../@types/component";

import "./input.scss";

const MBOInput: React.FC<inputProps> = ({
  type,
  placeholder,
  inputValue,
  changeHandler,
  name,
  disabled,
  clearHandler,
  handleDropdownShow,
  isClearIconRequired = true,
  appendStyles,
  onMouseUp,
  searching = false,
  ref,
  onKeyDown,
}) => {
  return (
    <div
      data-testid="input-container"
      className={[
        "mboInputPhase1",
        appendStyles && appendStyles !== "" ? appendStyles : "",
        searching ? "show_non_editing_cursor" : "",
      ].join(" ")}
    >
      <input
        name={name}
        autoFocus
        ref={ref}
        type={type}
        disabled={disabled || searching}
        placeholder={placeholder}
        value={inputValue || ""}
        onKeyUp={onMouseUp && onMouseUp}
        onChange={changeHandler}
        onFocus={() => handleDropdownShow && handleDropdownShow(true)}
        autoComplete="off"
        onKeyDown={onKeyDown}
      />
      {isClearIconRequired && (
        <span
          data-testid="icon"
          className={`${
            searching ? "loader" : !inputValue ? "search" : "clear"
          }`}
          onClick={() => {
            if (!searching) {
              !inputValue
                ? handleDropdownShow && handleDropdownShow(true)
                : clearHandler && clearHandler();
            }
          }}
        ></span>
      )}
    </div>
  );
};

export default MBOInput;
