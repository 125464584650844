import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";

import { signOut } from "../../auth/oktaConfig";
import IconsName from "../../constants/IconsName";
import UserLogger from "../../helpers/UserLogging";
import useStore from "../../store/useStore";
import Icon from "../Icon";
import QuadIcon from "../Icon/Icons/QuadIcon";

import "./Header.scss";

interface iprops {
  userRole?: string;
}

const HeaderPhase1: React.FC<iprops> = ({ userRole }) => {
  const history = useNavigate();
  const enablePopUp = useStore((state) => state.enablePopup);

  const setClearManageRulesState = useStore(
    (state) => state.setClearManageRulesState
  );

  const setClearQratesState = useStore((state) => state.setClearQratesState);
  const { pathname } = useLocation();
  const { authState, oktaAuth } = useOktaAuth();
  const [logedOut, setLogoutState] = useState(false);
  const name = authState?.idToken?.claims.name;

  if (!authState?.isAuthenticated) {
    return null;
  }

  const logoutUser = async () => {
    setLogoutState(true);

    try {
      await UserLogger("Logged-out");
      signOut();
    } catch (e) {
      console.log("something went wrong ");
    }
  };

  useEffect(() => {
    if (authState.isAuthenticated) {
      if (!logedOut) {
        oktaAuth.tokenManager.start();

        return;
      }

      oktaAuth.tokenManager.stop();
    }
  }, [authState, oktaAuth, logedOut]);

  const navigateToHomePage = () => {
    if (window.location.pathname === "/") {
      history("/", { state: { clearWorkorder: true } });

      return;
    }

    if (window.location.pathname === "/qrates") {
      setClearQratesState(true);

      return;
    }

    if (window.location.pathname === "/managerules") {
      setClearManageRulesState(true);

      return;
    }

    if (window.location.pathname === "/qrates") {
      setClearQratesState(true);

      return;
    }

    if (window.location.pathname === "/managerules") {
      setClearManageRulesState(true);

      return;
    }

    if (window.location.pathname === "/quad/") {
      history("/quad/", { state: { clearQuad: true } });

      return;
    }

    history("/");
  };

  return (
    <div
      className={`header ${enablePopUp ? "disable_header" : ""}`}
      data-testid="header"
    >
      <div className="header__container">
        <div className="header__container__items">
          <div
            onClick={() => navigateToHomePage()}
            className="header__container__items__icon"
          >
            <Icon
              width={100}
              height={35}
              className="logo"
              name={IconsName.LOGO}
            />
            <span>Pricing Calculator</span>
          </div>
          <div className="header__container__items__navParent">
            <>
              <NavLink
                onClick={() => navigateToHomePage()}
                to="/"
                className={`header__container__items__navParent__navlink ${
                  pathname === "/"
                    ? "header__container__items__navParent__active"
                    : ""
                }`}
              >
                Workorder Rules
              </NavLink>

              <>
                <NavLink
                  onClick={() => navigateToHomePage()}
                  to="/managerules"
                  className={`header__container__items__navParent__navlink ${
                    pathname === "/managerules"
                      ? "header__container__items__navParent__active"
                      : ""
                  }`}
                >
                  Client Rules
                </NavLink>
              </>

              <NavLink
                onClick={() => navigateToHomePage()}
                to="/qrates"
                className={`header__container__items__navParent__navlink ${
                  pathname === "/qrates"
                    ? "header__container__items__navParent__active"
                    : ""
                }`}
              >
                Simulation
              </NavLink>

              <NavLink
                to="/ruleset"
                className={`header__container__items__navParent__navlink ${
                  pathname === "/ruleset"
                    ? "header__container__items__navParent__active"
                    : ""
                }`}
              >
                Ruleset
              </NavLink>

              <NavLink
                onClick={() => navigateToHomePage()}
                to="/quad/"
                className={`header__container__items__navParent__navlink ${
                  pathname === "/quad/"
                    ? "header__container__items__navParent__active"
                    : ""
                }`}
              >
                <QuadIcon />
                <span>QUAD</span>
              </NavLink>
            </>
          </div>
          <ul className="links">
            <li className="link opacity-60">
              Hello, <span className="name">{name}</span>
            </li>
            <li
              onClick={() => {
                void logoutUser();
              }}
              className="link"
            >
              Log out
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HeaderPhase1;
