import moment from "moment";
import React, { useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useDebounce } from "use-debounce";

import {
  engagementTypeListProps,
  optionsProp,
  selectedItemsProps,
} from "../../@types/component";
import API from "../../API/PricingCalcApiPhaseone";
import AuthorizeUserHOC from "../../HOC/Authorisation/authorizeuser.component";
import ErrorBoundary from "../../HOC/ErrorBoundary/errorBoundary.component";
import TakeScreenshot from "../../HOC/TakeScreenshot/takescreenshot";
import { UserAccessForFeature } from "../../constants/componentLevelUserRoles";
import useQueryData from "../../hooks/useQueryData";
import useStore from "../../store/useStore";

import QRates from "./qRates";
import JobDiva from "./qRates/SimulateSection/JobDiva";
import SimulationSearchScreen from "./simulationSearchScreen/SimulationSearchScreen";

interface Iprops {
  writeAccess?: boolean;
}

const Simulation: React.FC<Iprops> = ({ writeAccess }) => {
  const { showBoundary } = useErrorBoundary();
  const clearQratesState = useStore((state) => state.clearQratesSate);
  const setClearQratesState = useStore((state) => state.setClearQratesState);

  const initialClientList = {
    value: 0,
    label: "",
    clientId: 0,
  };

  const initialEngagementType = { id: 0, name: "" };

  const inititalSelectedFilters = {
    engagementType: initialEngagementType,
    deliverableType: initialEngagementType,
    country: initialEngagementType,
    mainDivision: initialEngagementType,
    city: initialEngagementType,
  };

  const [screen2Transition, setScreen2Transition] = useState<boolean>(false);

  const [selectedClient, setSelectedClient] =
    useState<optionsProp>(initialClientList);

  const [selectedFilters, setSelectedFilters] = useState<selectedItemsProps>(
    inititalSelectedFilters
  );

  const [clientList, setClientList] = useState<optionsProp[]>([
    initialClientList,
  ]);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [rateFilter, setRateFilter] = useState<number | null>(null);
  const [rateFilterChange, setRateFilterChange] = useState<boolean>(false);

  const [engagementTypeList, setEngagementTypeList] = useState<
    [engagementTypeListProps]
  >([{ id: 0, name: "" }]);

  const [deliverableTypeList, setDeliverableTypeList] = useState<
    [engagementTypeListProps]
  >([{ id: 0, name: "" }]);

  const setLoader = useStore((state) => state.setLoader);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [input, setInput] = useState<string>("");
  const [debouncedText] = useDebounce(input, 800);
  const [errorCode, setErrorCode] = useState<number | null>(null);
  const [warningMsg, setWarningMsg] = useState(false);
  const [openJobDiva, setJobDiva] = useState<boolean>(false);

  const [receivedOptionalRules, setReceivedOptionalRules] = useState<
    ManageOptionalRules[] | null
  >([]);

  const [updatedOptionalRules, setUpdatedOptionalRules] =
    useState<{ id: number; checked: boolean }[]>();

  const [responseData, setResponseData] = useState<
    CalcAtributes["data"] | null
  >(null);

  const [sowResponseData, setSowResponseData] = useState<
    CalcAtributes["data"] | null
  >(null);

  const clientListQury = useQueryData(
    "client-list",
    {},
    false,
    API.getClientList
  );

  const engagementTypeQuery = useQueryData(
    "engagementType-list",
    {},
    false,
    API.getDrpOptions
  );

  const getClients = async (): Promise<void> => {
    clientListQury.refetch();
  };

  const getEngagementType = async (): Promise<void> => {
    engagementTypeQuery.refetch();
  };

  const handleCreateSimulate = (): void => {
    setSelectedDate(new Date());
    setScreen2Transition(true);
  };

  const handleReset = (): void => {
    setScreen2Transition(false);
    setSelectedClient(initialClientList);
    setSelectedFilters(inititalSelectedFilters);
    setSelectedDate(null);
    setRateFilter(null);
    setResponseData(null);
    setSowResponseData(null);
  };

  const rulesForClint = useQueryData(
    "qrates_rules-for-client",
    {
      querystring: `?${
        selectedFilters.engagementType.name !== ""
          ? `filter=engagementType in (${selectedFilters.engagementType.name})`
          : ""
      }${
        selectedDate !== null
          ? `&filter=effectiveDate in (${moment(selectedDate).format(
              "YYYY-MM-DD"
            )})`
          : ""
      }${
        selectedFilters.country.name !== ""
          ? `&filter=country in (${selectedFilters.country.name})`
          : ""
      }${
        selectedFilters.mainDivision.name !== ""
          ? `&filter=mainDivision in (${selectedFilters.mainDivision.name})`
          : ""
      }${
        selectedFilters.city.name !== ""
          ? `&filter=city in (${selectedFilters.city.name})`
          : ""
      }${
        rateFilter && (rateFilter !== null || rateFilter !== 0)
          ? `&filter=rate in (${rateFilter || ""})`
          : ""
      }`,
      clientid: selectedClient.value,
    },
    false,
    API.getBusinessRules
  );

  useEffect(() => {
    getClients();
    getEngagementType();
  }, []);

  useEffect(() => {
    try {
      if (clientListQury.error) {
        showBoundary("");
        setLoader(false);

        return;
      }

      if (!clientListQury.isLoading && clientListQury.data) {
        const { clients }: { clients: ClientListObj[] } =
          clientListQury.data.data;

        setClientList(
          clients?.map((e: ClientListObj) => ({
            label: e.jobdivaCompanyName,
            value: e.id,
            clientId: "parentClientId" in e ? e.parentClientId : "",
          }))
        );
      }
    } catch (error) {
      console.log(error);
      showBoundary("");
      setLoader(false);
    }
  }, [clientListQury.data, clientListQury.isLoading, clientListQury.error]);

  useEffect(() => {
    try {
      if (engagementTypeQuery.error) {
        showBoundary("");
        setLoader(false);

        return;
      }

      if (!engagementTypeQuery.isLoading && engagementTypeQuery.data) {
        setEngagementTypeList(engagementTypeQuery.data.data.engagementTypes);
        setDeliverableTypeList(engagementTypeQuery.data.data.deliverableTypes);
      }
    } catch (error) {
      console.log(error);
      showBoundary("");
      setLoader(false);
    }
  }, [
    engagementTypeQuery.data,
    engagementTypeQuery.isLoading,
    engagementTypeQuery.error,
  ]);

  const clearEngagementType = (filterType: string): void => {
    const filterKeys = Object.keys(selectedFilters);
    const updatedFilters = { ...selectedFilters };
    const filterIndex = filterKeys.indexOf(filterType);

    for (let i = filterIndex; i < filterKeys.length; i++) {
      const currentFilter = filterKeys[i];

      updatedFilters[currentFilter] = initialEngagementType;
    }

    setSelectedFilters(updatedFilters);
  };

  const handleFilterChange = (
    filterType: string,
    filterValue: engagementTypeListProps
  ): void => {
    const filterKeys = Object.keys(selectedFilters);
    const updatedFilters = { ...selectedFilters };
    const filterIndex = filterKeys.indexOf(filterType);

    for (let i = filterIndex; i < filterKeys.length; i++) {
      const currentFilter = filterKeys[i];

      updatedFilters[currentFilter] = initialEngagementType;
    }

    if (
      filterType === "engagementType" &&
      filterValue.name === "Payroll Services"
    ) {
      setSelectedFilters({
        ...updatedFilters,
        [filterType]: filterValue,
        deliverableType: { id: 0, name: "hourly/milestone" },
      });

      return;
    }

    setSelectedFilters({ ...updatedFilters, [filterType]: filterValue });
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleFetchRules = (): void => {
    setResponseData(null);
    setSowResponseData(null);

    rulesForClint.refetch();
    setLoader(true);
  };

  const handleRateFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRateFilter(Number(e.target.value));
    setInput(e.target.value);
    setRateFilterChange(true);
  };

  const clearDateChange = () => {
    setSelectedDate(null);
  };

  useEffect(() => {
    try {
      if (selectedClient && selectedClient.label !== "") {
        console.log("");
      }
    } catch (e) {
      showBoundary(
        "there is a problem while fetching the rules please try again later"
      );
      setLoader(false);
    }
  }, [selectedClient]);

  useEffect(() => {
    if (!rateFilterChange && rateFilter) {
      handleFetchRules();
    }

    setInput(rateFilter === 0 ? "" : rateFilter ? rateFilter.toString() : "");
  }, [debouncedText, rateFilter]);

  useEffect(() => {
    if (clearQratesState) {
      setSelectedFilters(inititalSelectedFilters);
      clientListQury.remove();
      setSelectedClient(initialClientList);
      setClearQratesState(false);
      setScreen2Transition(false);
      handleReset();
    }
  }, [clearQratesState]);

  setLoader(
    clientListQury.isLoading ||
      clientListQury.isLoading ||
      rulesForClint.isLoading
  );

  return (
    <>
      {openJobDiva && (
        <JobDiva
          selectedTabIndex={selectedTabIndex}
          responseData={sowResponseData || responseData}
          setJobDiva={setJobDiva}
          selectedOptionsRules={
            receivedOptionalRules &&
            receivedOptionalRules
              .filter((e) => {
                return (
                  updatedOptionalRules &&
                  updatedOptionalRules.findIndex(
                    (selectedRule) =>
                      selectedRule.id === e.id && selectedRule.checked
                  ) > -1
                );
              })
              .map((e, i) => {
                return {
                  id: null,
                  customRuleId: Number(e.id),
                  displayOrder: i,
                  isActive: true,
                };
              })
          }
          selectedDate={selectedDate}
          deliverableType={selectedFilters.deliverableType.name.toLowerCase()}
          calculatedMarkup={sowResponseData?.markup?.value || ""}
        />
      )}
      {!screen2Transition && (
        <SimulationSearchScreen
          data-testid="simulation_screen"
          clientList={clientList}
          setSelectedClient={setSelectedClient}
          selectedClient={selectedClient}
          engagementTypeList={engagementTypeList}
          clearEngagementType={clearEngagementType}
          handleFilterChange={handleFilterChange}
          selectedFilters={selectedFilters}
          handleCreateSimulate={handleCreateSimulate}
          deliverableTypeList={deliverableTypeList}
          writeAccess={writeAccess}
        />
      )}

      {screen2Transition && (
        <QRates
          setSelectedTabIndex={setSelectedTabIndex}
          selectedTabIndex={selectedTabIndex}
          setInput={setInput}
          input={input}
          textTocompareAfterDebouce={debouncedText}
          setErrorCode={setErrorCode}
          errorCode={errorCode}
          setWarningMsg={setWarningMsg}
          warningMsg={warningMsg}
          setJobDiva={setJobDiva}
          openJobDiva={openJobDiva}
          setResponseData={setResponseData}
          setSowResponseData={setSowResponseData}
          sowResponseData={sowResponseData}
          responseData={responseData}
          clientList={clientList}
          setSelectedClient={setSelectedClient}
          selectedClient={selectedClient}
          engagementTypeList={engagementTypeList}
          clearEngagementType={clearEngagementType}
          handleFilterChange={handleFilterChange}
          selectedFilters={selectedFilters}
          handleReset={handleReset}
          selectedDate={selectedDate}
          handleDateChange={handleDateChange}
          handleDateClear={clearDateChange}
          handleRateFilterChange={handleRateFilterChange}
          rateFilter={rateFilter}
          setScreen2Transition={setScreen2Transition}
          setReceivedOptionalRules={setReceivedOptionalRules}
          receivedOptionalRules={receivedOptionalRules}
          setUpdatedOptionalRules={setUpdatedOptionalRules}
          updatedOptionalRules={updatedOptionalRules}
          writeAccess={writeAccess}
          rulesForClint={rulesForClint}
          standardRules={rulesForClint?.data?.data}
          optionalRules={rulesForClint?.data?.data}
          setRateFilter={setRateFilter}
          setRateFilterChange={setRateFilterChange}
          rateFilterChange={rateFilterChange}
        />
      )}
    </>
  );
};

const userRoles = UserAccessForFeature.SIMULATION.CLIENT_SIMULATION;
const AuthorizeUser = AuthorizeUserHOC(Simulation, userRoles);

export default ErrorBoundary(TakeScreenshot(AuthorizeUser));
