import React from "react";

import { dataFlowProps } from "../../../../../../@types/component";
import SelectStartID from "../../../../../../componentsPhase1/SelectStartID";

import classes from "./index.module.scss";

interface jobDivaidList {
  id: string | number;
  value: string | number;
  startId: string | number;
  client: { id: string; name: string };
  month: string;
  day: string | number;
  year: string | number;
  selected: boolean;
}

interface Iprops {
  children?: React.ReactNode;
  setDataFlow: (e: dataFlowProps[]) => void;
  dataFlow: dataFlowProps[];
  jobDivaList:
    | {
        id: string | number;
        value: string | number;
        startId: string | number;
        client: { id: string; name: string };
        month: string;
        day: string | number;
        year: string | number;
        selected: boolean;
      }[]
    | null;
  setJobDivaId: React.Dispatch<React.SetStateAction<string>>;
  jobDivaDetails: ProjectDetailsAPIResponse | null;
  levelToRemve: number;
  setJobDivaDetails: React.Dispatch<
    React.SetStateAction<ProjectDetailsAPIResponse | null>
  >;
  setJobDivalist: React.Dispatch<React.SetStateAction<jobDivaidList[] | null>>;
  selectedRatesRecordidNonApproved: boolean | null;
  setRatesNonApprovedRecord: React.Dispatch<
    React.SetStateAction<boolean | null>
  >;
  jobDivaRecordError: { code: number; message: string } | null;
  setJobDivaRecordError: React.Dispatch<
    React.SetStateAction<{ code: number; message: string } | null>
  >;
  setSelectedRateId: React.Dispatch<React.SetStateAction<number | null>>;
  deliverableType: string;
}

const SearchClient: React.FC<Iprops> = ({
  children,
  jobDivaList,
  setJobDivaId,
  jobDivaDetails,
  setDataFlow,
  dataFlow,
  levelToRemve,
  setJobDivaDetails,
  setJobDivalist,
  selectedRatesRecordidNonApproved,
  setRatesNonApprovedRecord,
  jobDivaRecordError,
  setJobDivaRecordError,
  setSelectedRateId,
  deliverableType,
}) => {
  return (
    <div data-testid="radiolist" className={classes.jobdiva_list_parent}>
      <SelectStartID
        jobDivaRecordError={jobDivaRecordError}
        showErrorMessageForSendToJobDiva={true}
        deliverableType={deliverableType}
        data={jobDivaList || []}
        onBack={() => {
          setJobDivaDetails(null);
          setJobDivalist(null);
          setJobDivaId("");
          setJobDivaRecordError(null);
          setRatesNonApprovedRecord(null);
          setSelectedRateId(null);
          const updatedState = dataFlow.map((e: dataFlowProps) => {
            return { ...e, data: e.level === levelToRemve };
          });

          setDataFlow(updatedState);
        }}
        elableContinue={
          deliverableType === "hourly/milestone"
            ? !selectedRatesRecordidNonApproved ||
              (jobDivaRecordError && jobDivaRecordError.code === 70107)
              ? false
              : selectedRatesRecordidNonApproved
            : deliverableType === "sow/milestone"
            ? deliverableType === "sow/milestone" && jobDivaList
              ? jobDivaList.findIndex((e) => e.selected) > -1
              : false
            : false
        }
        setJobDivaId={setJobDivaId}
        onContinue={() => {
          const updatedStaet = dataFlow.map((e) => {
            return { ...e, data: e.level === 2 };
          });

          setDataFlow(updatedStaet);
        }}
        title="Now, select Jobdiva work ID"
      >
        {children}
      </SelectStartID>
    </div>
  );
};

export default SearchClient;
