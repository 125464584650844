import classes from "./fallback.component.module.scss";

interface Error {
  error: string | React.ReactNode;
}

const ErrorBoundaryFallBackComponent = ({ error }: Error) => {
  if (window.location.pathname.includes("/quad/")) {
    window.location.reload();
  }

  return (
    <div className={classes.error_component_parent}>
      <div className={classes.error_style}>
        {error === ""
          ? "This component couldn’t be displayed due to system failure. Please refresh the page and try agan."
          : error}
      </div>
    </div>
  );
};

export default ErrorBoundaryFallBackComponent;
