import { useQuery, QueryFunction, QueryKey, UseQueryResult } from "react-query";

import {
  ApiCallFunction,
  hourlyJobDivaRatesRequest,
  sowPayloadProps,
} from "../@types/component";

function useTanstankQuery<Data>(
  queryKey: QueryKey,
  params: {
    [key: string]:
      | string
      | number
      | number[]
      | null
      | boolean
      | { [key: string]: string | number }
      | SaveCalcSettingsD
      | hourlyJobDivaRatesRequest
      | sowPayloadProps;
  },
  enableRefetch: boolean = false,
  apiCall: ApiCallFunction<Data>
): UseQueryResult<Data> {
  const queryFn: QueryFunction<Data, QueryKey> = async () => {
    const response = await apiCall(params);

    return response.data;
  };

  return useQuery<Data, QueryKey>(queryKey, queryFn, {
    enabled: enableRefetch,
    retry: 0,
  });
}

export default useTanstankQuery;
