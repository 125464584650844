import React, { useEffect, useState } from "react";

import WorkOrderBody, {
  dummyData,
  optionsProp,
  workOrderStateProps,
} from "./WorkOrderBody";
import WorkOrderHeader from "./WorkOrderHeader";

import "./WorkOrder.scss";

interface WorkOrderProps {
  selectedValues: (e: workOrderStateProps[]) => void;
  vendorData: optionsProp[];
  attributionData: optionsProp[];
  operationData: optionsProp[];
  groupData: optionsProp[];
  customerAppliedWorkOrderData: workOrderStateProps[] | null;
  requiredWorkorderFields: WorkOrderRequiredFields[] | [];
  SetRequiredWOrkorderFields: (e: WorkOrderRequiredFields[]) => void;
  userAccess: boolean | null;
}

const WorkOrder: React.FC<WorkOrderProps> = ({
  selectedValues,
  vendorData,
  operationData,
  attributionData,
  groupData,
  customerAppliedWorkOrderData,
  requiredWorkorderFields,
  SetRequiredWOrkorderFields,
  userAccess,
}) => {
  const [workOrderState, setWorkOrderState] = useState<
    workOrderStateProps[] | []
  >([]);

  const [orders, setOrders] = useState<number[]>([]);

  const valueHandler = (values: workOrderStateProps, i: number) => {
    const newData = workOrderState.map((workOrders, index) => {
      if (i === index) {
        return values;
      }

      return workOrders;
    });

    setWorkOrderState(newData);
    const UpdatedRequiredWorkorderFields = requiredWorkorderFields.map(
      (order, index) => ({
        group: !newData[index].group,
        operation: !newData[index].operation,
        attribution: !newData[index].attribution,
        amount: newData[index].amount.trim() === "",
      })
    );

    SetRequiredWOrkorderFields(UpdatedRequiredWorkorderFields);
  };

  const addHandler = () => {
    setOrders((prev) => [...prev, prev.length + 1]);
    setWorkOrderState((prev) => [...prev, dummyData]);
    const UpdatedRequiredWorkorderFields = JSON.parse(
      JSON.stringify(requiredWorkorderFields)
    );

    SetRequiredWOrkorderFields([
      ...UpdatedRequiredWorkorderFields,
      {
        group: false,
        operation: false,
        attribution: false,
        amount: false,
      },
    ]);
  };

  const deleteHandler = (i: number) => {
    const items = orders.filter((_, index) => index !== i);
    const updatedWorkOrders = workOrderState.filter((_, index) => index !== i);

    const UpdatedRequiredWorkorderFields = requiredWorkorderFields.filter(
      (_, index) => index !== i
    );

    setWorkOrderState(updatedWorkOrders);
    setOrders(items);
    SetRequiredWOrkorderFields(UpdatedRequiredWorkorderFields);
  };

  useEffect(() => {
    selectedValues(workOrderState);
  }, [workOrderState]);

  useEffect(() => {
    if (
      customerAppliedWorkOrderData &&
      customerAppliedWorkOrderData.filter(
        (obj1) => !workOrderState.some((obj2) => obj1.id === obj2.id)
      ).length > 0
    ) {
      setOrders(
        customerAppliedWorkOrderData.map((a, i) =>
          customerAppliedWorkOrderData.length > i ? i + 1 : -1
        )
      );
      const preAppliedWorkOrder = customerAppliedWorkOrderData.map((e) => ({
        id: e.id,
        description: e.description,
        group: e.group,
        operation: e.operation,
        attribution: e.attribution,
        vendor: e.vendor,
        amount: e.amount,
      }));

      setWorkOrderState((prev) => [...prev, ...preAppliedWorkOrder]);
      SetRequiredWOrkorderFields(
        preAppliedWorkOrder.map(() => ({
          group: false,
          operation: false,
          attribution: false,
          amount: false,
        }))
      );
    }
  }, [customerAppliedWorkOrderData]);

  return (
    <div className="workOrder">
      <WorkOrderHeader userAccess={userAccess} onClick={addHandler} />
      <div className="workOrder__body">
        {orders &&
          orders.map((order, i) => (
            <WorkOrderBody
              userAccess={userAccess}
              getSelectedValues={(value) => valueHandler(value, i)}
              key={order}
              deleteHandler={() => deleteHandler(i)}
              groupData={groupData}
              attributionData={attributionData}
              operationData={operationData}
              vendorData={vendorData}
              customerAppliedWorkOrderData={workOrderState && workOrderState[i]}
              requiredWorkorderFields={requiredWorkorderFields[i]}
            />
          ))}
      </div>
    </div>
  );
};

export default WorkOrder;
