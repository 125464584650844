import moment from "moment";
import { useErrorBoundary } from "react-error-boundary";

import {
  manageStandardRuleForClientProp,
  stateIprops,
} from "../../../../../@types/component";
import ErrorBoundaryFallBackComponent from "../../../../../HOC/ErrorBoundary/errorBoundary.component";
import MBOTable from "../../../../../componentsPhase1/MBOTable";

const StandardRules: React.FC<manageStandardRuleForClientProp> = ({
  rule,
  getCLickPosition,
  setForm,
  getDeletedId,
  setDeleteForm,
  clearPopOver,
  showAddNewButton,
  children,
}) => {
  const data: stateIprops[] = [];
  const { showBoundary } = useErrorBoundary();

  try {
    const standardRules =
      (rule.data &&
        rule.data.standardRules &&
        rule.data.standardRules.map((rule) => {
          return {
            ...rule,
            amount:
              rule.amount.localized === "percentage"
                ? `${Number(rule.amount.raw)}%`
                : `$${Number(rule.amount.raw) / 100}`,
          };
        })) ||
      [];

    standardRules.forEach((rule) => {
      data.push({
        id: Number(rule?.id),
        clientId: Number(rule?.client?.id),
        name: rule.name,
        description: rule.description,
        effectiveDate:
          (rule.effectiveDate &&
            rule.effectiveDate.raw &&
            moment(rule.effectiveDate.value, "MMM DD YYYY")) ||
          null,
        expireDate:
          (rule.expireDate &&
            rule.expireDate.raw &&
            moment(rule.expireDate.value, "MMM DD YYYY")) ||
          null,
        engagementType: rule.engagementType || "",
        operation:
          (rule &&
            rule.operation && {
              label: rule.operation.name,
              value: Number(rule.operation.id),
            }) ||
          null,
        amount: `${rule.amount}`,
        country: rule.country,
        state: rule.mainDivision || "",
        city: rule.city,

        performedBy: rule.performedBy,

        rateLow: (rule.rateLow && rule.rateLow.toString()) || "",
        rateHigh: (rule.rateHigh && rule.rateHigh.toString()) || "",
        attribution:
          (rule &&
            rule.attribution && {
              label: rule.attribution.name,
              value: Number(rule.attribution.id),
            }) ||
          null,
        group:
          (rule &&
            rule.group && {
              label: rule.group.name,
              value: Number(rule.group.id),
            }) ||
          null,
        vendor:
          (rule &&
            rule.vendor && {
              label: rule.vendor.vendorName,
              value: Number(rule.vendor.id),
            }) ||
          null,
        createdDate:
          rule.createdDate && rule.createdDate.raw
            ? moment(rule.createdDate.raw).local()
            : null,
        updatedDate:
          rule.updatedDate && rule.updatedDate.raw
            ? moment(rule.updatedDate.raw).local()
            : null,
      });
    });
  } catch (e) {
    showBoundary("no rules found");
  }

  return (
    <MBOTable
      rule={data}
      headerName="Standard Rules"
      typeOfRule={"standard"}
      isEditRequired={true}
      isToggleRequired={false}
      getCLickPosition={getCLickPosition}
      setForm={setForm}
      getDeletedId={getDeletedId}
      setDeleteForm={setDeleteForm}
      clearPopOver={clearPopOver}
      showAddNewButton={showAddNewButton || false}
    >
      {children}
    </MBOTable>
  );
};

export default ErrorBoundaryFallBackComponent(StandardRules);
