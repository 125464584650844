import html2canvas from "html2canvas";

import classes from "../watermarkimages/watermark.module.scss";

let env = "";

const applicationURl = window.location.origin;

if (applicationURl?.includes("pricing-preprod.mbopartners.com")) {
  env = "preprod";
} else if (
  applicationURl?.includes("pricing-dev.mbopartners.com") ||
  applicationURl?.includes("localhost")
) {
  env = "dev";
} else if (applicationURl?.includes("pricing-qa.mbopartners.com")) {
  env = "qa";
} else if (applicationURl?.includes("pricing.mbopartners.com")) {
  env = "prod";
}

const divelement = document.createElement("div");

const dataURLtoBlob = (dataURL: string) => {
  const arr = dataURL.match(/:(.*?);/);

  if (arr && arr[1]) {
    const mime = arr[1];
    const bstr = atob(dataURL.split(",")[1]);

    let n = bstr.length;

    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  } else {
    // Handle the case where the regular expression doesn't match
    console.error("Invalid data URL format");

    return null;
  }
};

export const CaptureScreen = async (captureIconId: string) => {
  divelement.classList.add(classes.background);
  divelement.classList.add(classes[env]);

  const captureElement = document.getElementById(captureIconId);

  if (captureElement) {
    captureElement.style.display = "none";
  }

  setTimeout(() => {
    document.body.append(divelement);

    html2canvas(document.body)
      .then(async (canvas) => {
        const location = window.location.pathname;

        const imageName =
          location === "/"
            ? "workorder"
            : location === "/quad/"
            ? "quad"
            : location === "/qrates"
            ? "simulation"
            : location === "/managerules"
            ? "clientrules"
            : location === "/ruleset"
            ? "ruleset"
            : "";

        const image = canvas.toDataURL("image/png");
        const a = document.createElement("a");
        const getBlob = dataURLtoBlob(image);

        try {
          await navigator.clipboard.write([
            new ClipboardItem({
              [getBlob?.type || ""]: image,
            }),
          ]);

          console.log("Success");
        } catch (e) {
          console.log(e);
        }

        a.href = image;
        a.download = `${imageName}.png`;
        a.click();

        divelement.remove();
      })
      .catch((e) => {
        console.log(e);
        divelement.remove();
      });
  });
};
