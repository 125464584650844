import { dataFlowProps } from "../../../../../../@types/component";
import BackIcon from "../../../../../../componentsPhase1/Icon/Icons/backIcon";
import Button from "../../../../../../componentsPhase1/MBOButton/Button";

import classes from "./index.module.scss";

interface Iprops {
  setDataFlow: (e: dataFlowProps[]) => void;
  levelToRemve: number;
  dataFlow: dataFlowProps[];
}

const GoBack: React.FC<Iprops> = ({ setDataFlow, levelToRemve, dataFlow }) => {
  return (
    <Button
      name="Back"
      data-testid="button"
      className={classes.btn_calcel_styling}
      icon={<BackIcon width={16} height={16} />}
      onClick={() => {
        const updatedState = dataFlow.map((e: dataFlowProps) => {
          return { ...e, data: e.level === levelToRemve };
        });

        setDataFlow(updatedState);
      }}
    />
  );
};

export default GoBack;
