import moment from "moment";
import React from "react";

export interface JDHeaderProps {
  name: string;
  clientName: string;
  startDate: string | number;
  engagementType: string;
  location: string;
  supplierInfo?: { id: string; name: string; engagementType: string };
  sowInfo?: {
    sowMarkup: { localized: string; raw: number };
    sowName: string;
  };
}

const JDHeader: React.FC<JDHeaderProps> = ({
  name = "",
  clientName = "",
  startDate = "",
  engagementType = "",
  location = "",
  supplierInfo,
  sowInfo,
}) => {
  const validateSowMarkUp = (amount: number | string, localized: string) => {
    if (amount && amount !== "") {
      const negativeSign = Math.sign(Number(amount)) === -1 ? "-" : "";
      const rate = Math.abs(Number(amount)).toFixed(2);

      if (localized === "money") {
        return `${negativeSign}$${rate}`;
      }

      return `${negativeSign}${amount}%`;
    }

    return "N/A";
  };

  return (
    <>
      <div className="jobdiva__header">
        <div className="jobdiva__header__titles">
          <span>Candidate: {name}</span>
        </div>
        <div className="jobdiva__header__titles">
          <span>Client: {clientName}</span>
        </div>
        <div className="jobdiva__header__titles">
          <span>
            Start Date: {moment(startDate).local().format("MMM DD, YYYY")}
          </span>
        </div>
        <div className="jobdiva__header__titles">
          <span>Engagement Type: {engagementType}</span>
        </div>
        <div className="jobdiva__header__titles">
          <span>Location: {location}</span>
        </div>
        {supplierInfo && (
          // <div className="jobdiva__header2">
          <>
            <div className="jobdiva__header__titles">
              <span>Supplier Name: {supplierInfo?.name}</span>
            </div>
            <div className="jobdiva__header__titles">
              <span>
                Supplier Engagement Type:{" "}
                {supplierInfo?.engagementType || "N/A"}
              </span>
            </div>
          </>
          // </div>
        )}
        {sowInfo && (
          // <div className="jobdiva__header2">
          <>
            <div className="jobdiva__header__titles">
              <span>
                Sow Markup:
                {validateSowMarkUp(
                  (sowInfo.sowMarkup && sowInfo.sowMarkup.raw) || "",
                  (sowInfo?.sowMarkup && sowInfo.sowMarkup.localized) || ""
                )}
              </span>
            </div>
            <div className="jobdiva__header__titles">
              <span>Sow Name: {sowInfo?.sowName}</span>
            </div>
          </>
          // </div>
        )}
      </div>
    </>
  );
};

export default React.memo(JDHeader);
