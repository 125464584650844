import React, { useEffect, useState } from "react";

import ValidateUser from "../../auth/validateUser";
import Input from "../Input";

import "./RulesTable.scss";

export interface rulesTableDataProp {
  id: number;
  name: string;
  description: string;
  operation: string;
  amount: string;
  isApplied: boolean;
}

export interface selectedRulesTableProps {
  customRuleId: number;
  displayOrder: number;
  isActive: boolean;
}
interface RulesTableProps {
  onChange: (e: rulesTableDataProp[]) => void;
  data: rulesTableDataProp[] | null;
}

const RulesTable: React.FC<RulesTableProps> = ({ data, onChange }) => {
  const [search, setSearch] = useState("");

  const [checkBoxValues, setcheckBoxValues] = useState<
    rulesTableDataProp[] | []
  >(data || []);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    const { checked } = e.target;

    const selectedRule: rulesTableDataProp | undefined = data?.find(
      (rule, index) => index === i
    );

    if (selectedRule) {
      setcheckBoxValues((prev) =>
        prev.map((rule: rulesTableDataProp) =>
          rule.id === selectedRule.id
            ? { ...rule, isApplied: checked }
            : { ...rule }
        )
      );
    }
  };

  useEffect(() => {
    onChange(checkBoxValues);
  }, [checkBoxValues]);
  const validUser = ValidateUser() as UserGroups[] | [];

  return (
    <>
      <Input.Search
        className="calcSearch"
        value={search}
        onChange={(e) => setSearch(e.target.value.toLowerCase())}
        placeholder="Search for customer specific optional rules..."
      />
      <table className="rulesTable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Operation</th>
            <th>Amount</th>
            <th>Apply</th>
          </tr>
        </thead>
        <tbody>
          {checkBoxValues.length > 0 &&
            checkBoxValues.map((item, i) => (
              <tr
                className={
                  search.length !== 0 &&
                  !item.name.toLowerCase().startsWith(search.toLowerCase())
                    ? "hide"
                    : ""
                }
                key={`${item.name}${i + 1}`}
              >
                <td>{item.name}</td>
                <td>{item.description}</td>
                <td>{item.operation}</td>
                <td>{item.amount}</td>
                <td>
                  <Input.CheckBox
                    onChange={(e) => changeHandler(e, i)}
                    value={item.name}
                    isChecked={item.isApplied}
                    name={item.name}
                    disabled={
                      validUser !== null &&
                      validUser[0].readonly.length === 0 &&
                      +validUser[0].accessLevel < 1
                    }
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default RulesTable;
