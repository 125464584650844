import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useRef, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineKeyboardDoubleArrowUp } from "react-icons/md";
import { UseQueryResult } from "react-query";

import {
  RulesetCreationProps,
  dataFlowProps,
  stateIprops,
} from "../../../@types/component";
import NewAPi from "../../../API/PricingCalcApiPhaseone";
import ErrorBoundary from "../../../HOC/ErrorBoundary/errorBoundary.component";
import EditRules from "../../../componentsPhase1/EditRules";
import Button from "../../../componentsPhase1/MBOButton/Button";
import { RulesetCreationInitialState } from "../../../data/dummyData";
import useQueryData from "../../../hooks/useQueryData";
import useStore from "../../../store/useStore";
import ConfirmationPopup from "../../clientRules/manageClientRules/confirmationPopup";
import ApplyRulesetToClients from "../applyRulesetToclient";

import CreateRuleset from "./createRuleset";
import OptionalRules from "./displayAddedRules/optionalRules";
import StandardRules from "./displayAddedRules/standardRules";

// import RuleTypeSelection from "./ruletypeselection";

import classes from "./index.module.scss";

interface UpdatedRulesetProps {
  rulesetName: string;
  rulesetDesc: string;
  id: string;
}
interface clientListProps {
  client: string;
  id: number;
  select: boolean;
  disable: boolean;
}

interface addRulesetprops {
  setCreateRulesetPopup: React.Dispatch<React.SetStateAction<boolean>>;
  updateRuleset?: boolean;
  showCreateRulesetPopup: boolean;
  setCreateRulesetPayload: React.Dispatch<
    React.SetStateAction<ManageRulesetPayload>
  >;
  existingRulesetDetails: UpdatedRulesetProps;
  existingRulesetDetailsState: React.Dispatch<
    React.SetStateAction<UpdatedRulesetProps>
  >;
  clientList: clientListProps[];
  setClientList: React.Dispatch<React.SetStateAction<clientListProps[]>>;
  applyRulesetToClient: UseQueryResult;
  clientListForRulesetApplied: UseQueryResult;
  parentsetRulesetCreationState: React.Dispatch<
    React.SetStateAction<UpdatedRulesetProps>
  >;
  appliedClientids: number[];
  setAppliedClientIds?: React.Dispatch<React.SetStateAction<number[]>>;
}

interface ManageRulesetPayload {
  id: number | null;
  name: string;
  description: string;
  type: string;
}

const rulesetParent: React.FC<addRulesetprops> = ({
  setCreateRulesetPopup,
  updateRuleset,
  showCreateRulesetPopup,
  setCreateRulesetPayload,
  existingRulesetDetails,
  existingRulesetDetailsState,
  clientList,
  setClientList,
  applyRulesetToClient,
  clientListForRulesetApplied,
  parentsetRulesetCreationState,
  appliedClientids,
  setAppliedClientIds,
}) => {
  const { showBoundary } = useErrorBoundary();

  const [isMangeRuleBtnClicked, setManageRuleBtnClick] =
    useState<boolean>(false);

  const setLoader = useStore((state) => state.setLoader);
  const updateDeletePositon = useRef<HTMLDivElement>(null);
  const [mapShowRulesError, setMappingError] = useState<string>("");
  const [deleteform, setDeleteForm] = useState<boolean>(false);
  const [updateForm, setUpdateForm] = useState<boolean>(false);
  const [clientListForRule, setClientListForRule] = useState<string[]>([]);
  const [typeOfButtonClicked, setTypeOfButtonClicked] = useState<string>("");
  const [clickThreeDotsType, setClickThreeDotsType] = useState<string>("");

  const [ruleToDelete, setRuleToDelete] = useState<{
    id: number | null;
    type: string;
  } | null>(null);

  // const [ruleToDelete, setRuleToDelete] = useState<{
  //   id: number | null;
  //   type: string;
  // } | null>(null);

  // const containerRef = useRef<HTMLDivElement>(null);

  const [dataFlow, setDataFlow] = useState<dataFlowProps[]>([
    { level: 0, data: true },
    { level: 1, data: false },
    { level: 2, data: false },
  ]);

  const [manageRulesetPayload, setManageRulesetPayload] =
    useState<ManageRulesetPayload>({
      id: null,
      name: "",
      description: "",
      type: "PUT",
    });

  console.log(setManageRulesetPayload);

  const [ruleSetCreationState, setRulesetCreationState] =
    useState<RulesetCreationProps>(RulesetCreationInitialState);

  const [rulesOfRuleset, setRulesOfRuleset] = useState<{
    standardRules: ManageStandardRules[];
    optionalRules: ManageOptionalRules[];
  } | null>(null);

  const [openForm, setForm] = useState<boolean>(false);

  const [showRecordAddedMessage, setRecordAddedMessage] =
    useState<boolean>(false);

  const [rulesData, setRulesData] = useState<{
    rules: stateIprops | null;
    type: string;
  } | null>(null);

  const { authState } = useOktaAuth();
  const LoggedInUser = authState?.idToken?.claims.email;

  const [groupData, setGroupData] = useState<DropdownOptionsType[] | null>(
    null
  ); // Drop Down Data

  const [vendorData, setVendorData] = useState<SuppliersVendorData[] | null>(
    null
  );

  const supplierListQuery = useQueryData(
    "vendor-list",
    {},
    false,
    NewAPi.getSuppliers
  );

  const dropdownOptionsQuery = useQueryData(
    "dropdown_option-list",
    {},
    false,
    NewAPi.getDrpOptions
  );

  const getSubbmittedRulesforRuleset = useQueryData(
    "get-submitted-rules-for-ruleset",
    {
      selectedRulesetId: ruleSetCreationState?.screen1?.id,
    },
    false,
    NewAPi.getRulesForRuleset
  );

  const deletRule = useQueryData(
    "new_ruleset_delete-rule-for-client-from-ruleset",
    {
      standardRuleIds: ruleToDelete?.type === "standard" ? ruleToDelete.id : "",
      optionalRuleIds: ruleToDelete?.type === "optional" ? ruleToDelete.id : "",
    },
    false,
    NewAPi.deleteRuleFromRulesetForClient
  );

  const getRuleIdToDelete = (
    ruleId: number | null,
    rulesetId: number | null,
    type: string
  ) => {
    setRuleToDelete({ id: ruleId || null, type });
  };

  // const [loadParentRules, setLoadParentRules] = useState<boolean>(false);

  const [attributionData, setAttributionData] = useState<
    DropdownOptionsType[] | null
  >(null); // attribution dropdown data

  const [operationData, setOperationData] = useState<
    DropdownOptionsType[] | null
  >(null); // operation dropdown Data

  const [engagementTypeData, setEngagementTypeData] = useState<
    DropdownOptionsType[] | null
  >(null); // engagement type dropdown Data

  console.log(dataFlow, setDataFlow, setRulesData);

  const getAllRulesetList = useQueryData(
    "add-ruleset",

    {
      ...manageRulesetPayload,
      id: ruleSetCreationState.screen1.id,
      name: ruleSetCreationState?.screen1.rulesetName,
      description: ruleSetCreationState?.screen1.rulesetDesc,
    },
    false,
    NewAPi.getAllRuleSets
  );

  const getClientListForRuleId = useQueryData(
    "ruleset-get-client-list-for-ruleid",
    {
      Id: ruleSetCreationState?.screen1?.id,
      type:
        openForm && ruleToDelete?.type === "standard"
          ? "STANDARD"
          : "OPTIONAL" || "",
    },
    false,
    NewAPi.getClientListForRuleId
  );

  const clearPopOver = () => {
    const ref = updateDeletePositon;

    if (ref && ref.current) {
      ref.current.style.opacity = "0";

      ref.current.style.left = "-8%";

      ref.current.style.top = "-8%";
    }
  };

  const sendDataToAddRulesetParent = (type: string, ruletoadd: stateIprops) => {
    setMappingError("");
    setRulesetCreationState((prevState: RulesetCreationProps) => {
      return {
        ...prevState,
        screen3: {
          ...prevState.screen2,
          [type === "standard" ? "standardRules" : "optionalRules"]:
            type === "standard"
              ? [
                  ...prevState.screen2.standardRules,
                  ...[ruletoadd].map((e) => {
                    return {
                      ...e,
                      group: {
                        id: e.group?.value,
                        name: e.group?.label,
                      },
                      operation: {
                        id: e.operation?.value,
                        name: e.operation?.label,
                      },
                      vendor: {
                        id: e.vendor?.value,
                        name: e.vendor?.label,
                      },
                      attribution: {
                        id: e.attribution?.value,
                        name: e.attribution?.label,
                      },
                    };
                  }),
                ]
              : [
                  ...prevState.screen2.optionalRules,
                  ...[ruletoadd].map((e) => {
                    return {
                      ...e,
                      group: {
                        id: e.group?.value,
                        name: e.group?.label,
                      },
                      operation: {
                        id: e.operation?.value,
                        name: e.operation?.label,
                      },
                      vendor: {
                        id: e.vendor?.value,
                        name: e.vendor?.label,
                      },
                      attribution: {
                        id: e.attribution?.value,
                        name: e.attribution?.label,
                      },
                    };
                  }),
                ],
        },
      };
    });
    setDataFlow((prevstate) =>
      prevstate.map((e) => ({ ...e, data: e.level === 2 }))
    );
    setForm(false);
  };

  const revokeState = () => {
    existingRulesetDetailsState({ rulesetName: "", rulesetDesc: "", id: "" });
    getAllRulesetList.remove();
    getSubbmittedRulesforRuleset.remove();
    setMappingError("");
    setRulesetCreationState(RulesetCreationInitialState);
    setCreateRulesetPayload({
      id: null,
      name: "",
      description: "",
      type: "GET",
    });
    setForm(false);
    setCreateRulesetPopup(false);
  };

  const getCLickPosition = (rule: stateIprops | null, type: string) => {
    setClickThreeDotsType(rule?.uniqueid || "");

    setRulesData({ rules: rule, type });
  };

  useEffect(() => {
    (async () => {
      try {
        if (getClientListForRuleId.error) {
          showBoundary("");

          return;
        }

        if (!getClientListForRuleId.isLoading && getClientListForRuleId.data) {
          setClientListForRule(
            getClientListForRuleId?.data?.data?.ruleSet?.map(
              ({ clients }: { clients: { name: string }[] }) => {
                return clients?.map(({ name }: { name: string }) => {
                  return name;
                });
              }
            )[0]
          );
        }
      } catch (e) {}
    })();
  }, [
    getClientListForRuleId.data,
    getClientListForRuleId.isLoading,
    getClientListForRuleId.error,
  ]);

  useEffect(() => {
    (async () => {
      try {
        if (supplierListQuery.error) {
          showBoundary("");

          return;
        }

        if (!supplierListQuery.isLoading && supplierListQuery.data) {
          const { vendors } = supplierListQuery.data.data;

          setVendorData(vendors);
        }
      } catch (e) {}
    })();
  }, [
    supplierListQuery.data,
    supplierListQuery.isLoading,
    supplierListQuery.error,
  ]);

  useEffect(() => {
    (async () => {
      try {
        if (dropdownOptionsQuery.error) {
          showBoundary("");

          return;
        }

        if (!dropdownOptionsQuery.isLoading && dropdownOptionsQuery.data) {
          const { attribution, group, operation, engagementTypes } =
            dropdownOptionsQuery.data.data;

          setAttributionData(attribution);
          setGroupData(group);
          setOperationData(operation);
          setEngagementTypeData(engagementTypes);
        }
      } catch (e) {
        showBoundary("");
      }
    })();
  }, [
    dropdownOptionsQuery.data,
    dropdownOptionsQuery.isLoading,
    dropdownOptionsQuery.error,
  ]);

  useEffect(() => {
    try {
      if (getAllRulesetList.error) {
        console.log(getAllRulesetList.error);
      }

      if (
        getAllRulesetList.data &&
        getAllRulesetList.isFetched &&
        !getAllRulesetList.isLoading
      ) {
        if (
          "errors" in getAllRulesetList.data &&
          getAllRulesetList.data.errors.length > 0 &&
          getAllRulesetList.data.errors[0].code !== 70100
        ) {
          setMappingError(getAllRulesetList.data.errors[0].message);

          return;
        }

        setRulesetCreationState((prevState: RulesetCreationProps) => {
          return {
            ...prevState,
            screen1: {
              ...prevState.screen1,
              id: getAllRulesetList?.data?.data?.ruleSet?.id,
            },
          };
        });
        parentsetRulesetCreationState({
          rulesetName: ruleSetCreationState.screen1.rulesetName || "",
          rulesetDesc: ruleSetCreationState.screen1.rulesetDesc || "",
          id: getAllRulesetList?.data?.data?.ruleSet?.id,
        });

        setDataFlow((prevstate) =>
          prevstate.map((e) => ({ ...e, data: e.level === 1 }))
        );
      }
    } catch (e) {
      showBoundary("");
    }
  }, [
    getAllRulesetList.isLoading,
    getAllRulesetList.error,
    getAllRulesetList.data,
  ]);

  const assignRulesetId = (isNextBtnClicked: boolean) => {
    setRulesetCreationState((prevState: RulesetCreationProps) => {
      return {
        ...prevState,
        screen1: {
          ...prevState.screen1,
          id: getAllRulesetList?.data?.data?.ruleSet?.id,
        },
      };
    });
  };

  useEffect(() => {
    if (ruleSetCreationState.screen1.id !== "" && isMangeRuleBtnClicked) {
      getSubbmittedRulesforRuleset.refetch();
      setManageRuleBtnClick(false);
    }
  }, [ruleSetCreationState.screen1.id]);

  useEffect(() => {
    console.log("");

    try {
      if (
        getSubbmittedRulesforRuleset?.data &&
        "errors" in getSubbmittedRulesforRuleset?.data &&
        getSubbmittedRulesforRuleset?.data?.errors?.length > 0 &&
        getSubbmittedRulesforRuleset?.data?.errors[0]?.code !== 70100
      ) {
        setMappingError(getSubbmittedRulesforRuleset.data.errors[0].message);

        return;
      }

      if (getSubbmittedRulesforRuleset.data) {
        setRulesOfRuleset({
          standardRules:
            getSubbmittedRulesforRuleset?.data?.data?.ruleSet?.standardRules,
          optionalRules:
            getSubbmittedRulesforRuleset?.data?.data?.ruleSet?.optionalRules,
        });
        setDataFlow((prevstate) =>
          prevstate.map((e) => ({ ...e, data: e.level === 1 }))
        );
      }
    } catch (e) {
      showBoundary("");
    }
  }, [
    getSubbmittedRulesforRuleset.isLoading,
    getSubbmittedRulesforRuleset.error,
    getSubbmittedRulesforRuleset.data,
  ]);

  useEffect(() => {
    clientListForRulesetApplied.refetch();
    supplierListQuery.refetch();
    dropdownOptionsQuery.refetch();

    setRulesetCreationState((prevState: RulesetCreationProps) => {
      return {
        ...prevState,
        screen1: {
          ...existingRulesetDetails,
          id: existingRulesetDetails.id?.toString(),
        },
      };
    });
  }, []);

  useEffect(() => {
    if (dataFlow) {
      setMappingError("");
    }
  }, [dataFlow]);

  useEffect(() => {
    if (updateForm || deleteform) {
      getClientListForRuleId.refetch();
    }
  }, [updateForm, deleteform]);

  useEffect(() => {
    if (deletRule.isSuccess) {
      deletRule.remove();
      getSubbmittedRulesforRuleset.refetch();
    }
  }, [deletRule.isSuccess]);

  const deleteRule = () => {
    deletRule.refetch();

    setDeleteForm(false);
  };

  setLoader(getAllRulesetList.isLoading);

  return (
    <>
      {dataFlow.map((step) => {
        if (step.level === 0 && step.data) {
          return (
            <>
              <div className={classes.blocker}></div>
              <div className={classes.overlay}></div>
              <div className={classes.popup_blur}></div>
              <div className={classes.rules_edit_popup}>
                <CreateRuleset
                  setCreateRulesetPopup={setCreateRulesetPopup}
                  key={step.level}
                  setDataFlow={setDataFlow}
                  setRulesetCreationState={setRulesetCreationState}
                  ruleSetCreationState={ruleSetCreationState}
                  getAllRulesetList={getAllRulesetList}
                  getSubbmittedRulesforRuleset={getSubbmittedRulesforRuleset}
                  revokeState={revokeState}
                  mapError={mapShowRulesError}
                  existingRulesetDetails={existingRulesetDetails}
                  assignRulesetId={assignRulesetId}
                  setManageRuleBtnClick={setManageRuleBtnClick}
                  isMangeRuleBtnClicked={isMangeRuleBtnClicked}
                />
              </div>
            </>
          );
        }

        if (step.level === 1 && step.data) {
          return (
            <>
              <div className={classes.blocker}></div>
              <div className={classes.overlay}></div>
              <div className={classes.popup_blur}></div>
              <div className={classes.rules_edit_popup}>
                <div
                  key={step.level}
                  className={classes.rules_parent_section}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    clearPopOver && clearPopOver();
                  }}
                >
                  <div
                    className={classes.rules_component}
                    onClick={() => {
                      setClickThreeDotsType("");
                    }}
                    onScroll={() => {
                      const popoverElement = document.getElementById(
                        `${clickThreeDotsType}popover`
                      );

                      if (
                        document.getElementById(clickThreeDotsType) &&
                        popoverElement
                      ) {
                        const element =
                          document.getElementById(clickThreeDotsType);

                        const threDotsTop =
                          element?.getBoundingClientRect().top;

                        if (popoverElement) {
                          popoverElement.style.top = `${
                            Number(threDotsTop) + 5
                          }px`;
                        }
                      }
                    }}
                  >
                    <StandardRules
                      getDeletedId={getRuleIdToDelete}
                      showAddNewButton={true}
                      rule={{
                        data: {
                          standardRules:
                            rulesOfRuleset?.standardRules?.map((e, i) => ({
                              ...e,
                              uniqueid: `standard${i}`,
                            })) || [],
                        },
                      }}
                      getCLickPosition={getCLickPosition}
                      setForm={setForm}
                      clearPopOver={() => clearPopOver()}
                    >
                      {" "}
                      <div
                        className={[
                          classes.edit_popover_styling,
                          classes.edit_popover_styling_helper,
                        ].join(" ")}
                        onClick={() => {
                          setTypeOfButtonClicked("update");
                          document
                            .getElementById(`${clickThreeDotsType}button`)
                            ?.click();

                          if (appliedClientids.length > 0) {
                            setUpdateForm(true);

                            return;
                          }

                          setForm(true);
                        }}
                      >
                        <MdOutlineKeyboardDoubleArrowUp />
                        <p>Update</p>
                      </div>
                      <div
                        className={[
                          classes.edit_popover_styling,
                          classes.edit_popover_styling_helper,
                        ].join(" ")}
                        onClick={() => {
                          setTypeOfButtonClicked("delete");

                          document
                            .getElementById(`${clickThreeDotsType}button`)
                            ?.click();

                          if (appliedClientids.length > 0) {
                            setUpdateForm(true);

                            return;
                          }

                          setDeleteForm(true);
                        }}
                      >
                        <AiOutlineDelete />
                        <p>Delete</p>
                      </div>
                    </StandardRules>

                    <OptionalRules
                      getDeletedId={getRuleIdToDelete}
                      showAddNewButton={true}
                      rule={{
                        data: {
                          optionalRules:
                            rulesOfRuleset?.optionalRules?.map((e, i) => ({
                              ...e,
                              uniqueid: `optional${i}`,
                            })) || [],
                        },
                      }}
                      getCLickPosition={getCLickPosition}
                      setForm={setForm}
                      clearPopOver={() => clearPopOver()}
                    >
                      {" "}
                      <div
                        className={[
                          classes.edit_popover_styling,
                          classes.edit_popover_styling_helper,
                        ].join(" ")}
                        onClick={() => {
                          setTypeOfButtonClicked("update");
                          document
                            .getElementById(`${clickThreeDotsType}button`)
                            ?.click();

                          if (appliedClientids.length > 0) {
                            setUpdateForm(true);

                            return;
                          }

                          setForm(true);
                        }}
                      >
                        <MdOutlineKeyboardDoubleArrowUp />
                        <p>Update</p>
                      </div>
                      <div
                        className={[
                          classes.edit_popover_styling,
                          classes.edit_popover_styling_helper,
                        ].join(" ")}
                        onClick={() => {
                          setTypeOfButtonClicked("delete");
                          document
                            .getElementById(`${clickThreeDotsType}button`)
                            ?.click();

                          if (appliedClientids.length > 0) {
                            setUpdateForm(true);

                            return;
                          }

                          setDeleteForm(true);
                        }}
                      >
                        <AiOutlineDelete />
                        <p>Delete</p>
                      </div>
                    </OptionalRules>
                  </div>
                  <div className={classes.btn_position}>
                    <div>
                      {mapShowRulesError ? (
                        <p className={classes.rules_error_while_mapping}>
                          {mapShowRulesError}
                        </p>
                      ) : (
                        rulesOfRuleset &&
                        (rulesOfRuleset?.standardRules ||
                          rulesOfRuleset?.optionalRules) &&
                        showRecordAddedMessage && (
                          <p className={classes.rules_success_creation}>
                            Ruleset{" "}
                            <b>{ruleSetCreationState.screen1.rulesetName}</b>{" "}
                            with{" "}
                            {(rulesOfRuleset?.optionalRules?.length || 0) +
                              (rulesOfRuleset?.standardRules?.length || 0)}{" "}
                            rules(s) was successfully created
                          </p>
                        )
                      )}
                    </div>
                    <div className={classes.btn_rules_position}>
                      <Button
                        name={"Close"}
                        className={classes.btn_create}
                        onClick={() => {
                          revokeState();
                        }}
                      />
                      {appliedClientids && appliedClientids.length === 0 && (
                        <Button
                          disabled={
                            rulesOfRuleset
                              ? rulesOfRuleset.optionalRules
                                ? Boolean(!rulesOfRuleset.optionalRules)
                                : rulesOfRuleset.standardRules
                                ? Boolean(!rulesOfRuleset.standardRules)
                                : true
                              : true
                          }
                          name={"Apply set"}
                          className={classes.btn_create}
                          onClick={() => {
                            setDataFlow((prevstate) =>
                              prevstate.map((e) => ({
                                ...e,
                                data: e.level === 2,
                              }))
                            );
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }

        if (step.level === 2 && step.data) {
          return (
            <>
              <div className={classes.blocker}></div>
              <div className={classes.overlay}></div>
              <div className={classes.popup_blur}></div>
              <div className={classes.expand_container}>
                <ApplyRulesetToClients
                  key={step.level}
                  ruleSetData={clientList}
                  setClientList={setClientList}
                  applyRulesetToClient={applyRulesetToClient}
                  clientListForRulesetApplied={clientListForRulesetApplied}
                  revokeState={revokeState}
                  setAppliedClientIds={setAppliedClientIds}
                  isUpdatePopUpopened={true}
                />
              </div>
            </>
          );
        }

        return null;
      })}
      {deleteform && (
        <ConfirmationPopup
          secondaryButton={setDeleteForm}
          primaryButton={deleteRule}
          primaryText="This rule will be deleted"
          secondaryText="Are you sure you want to delete this rule?"
        />
      )}
      {updateForm && (
        <ConfirmationPopup
          secondaryButton={() => {
            setUpdateForm(false);
            getClientListForRuleId.remove();
            setClientListForRule([]);
            setTypeOfButtonClicked("");
          }}
          showLoader={getClientListForRuleId.isLoading}
          primaryButton={() => {
            setUpdateForm(false);

            if (typeOfButtonClicked === "delete") {
              setDeleteForm(true);
            } else if (typeOfButtonClicked === "update") {
              setForm(true);
            }

            setTypeOfButtonClicked("");
          }}
          primaryText={`The ruleset you are about to ${typeOfButtonClicked} is applied to ${
            clientListForRule?.length || 0
          } ${clientListForRule?.length < 2 ? "company" : "companies"}`}
          secondaryText="Below is a list of the affected companies"
          primaryButtonText="Continue"
          alertDimentions={classes.alter_delete_popup_dimentions}
          clientList={clientListForRule}
        />
      )}
      {openForm && (
        <EditRules
          update={true}
          headerText={
            rulesData?.type.includes("standard")
              ? "Create a standard rule under Ruleset name"
              : "Create a optional rule under Ruleset name"
          }
          primaryButtonText="Create"
          secondaryButtonText="Create and start another rule"
          rulesData={rulesData}
          setForm={setForm}
          className="manageRulesParent__main__businessModal__bodyParent"
          ternaryButton="Cancel"
          dropDownData={{
            operationData,
            groupData,
            attributionData,
            engagementData: engagementTypeData,
          }}
          vendorData={vendorData}
          LoggedInUser={LoggedInUser}
          setIsUpdatedClicked={(e: boolean) => setRecordAddedMessage(e)}
          editingQuadRule={false}
          sendDataToAddRulesetParent={sendDataToAddRulesetParent}
          setDataFlow={setDataFlow}
          ruleSetCreationid={ruleSetCreationState?.screen1?.id}
          setRulesData={setRulesData}
          setRulesetCreationState={setRulesetCreationState}
          getSubbmittedRulesforRuleset={getSubbmittedRulesforRuleset}
          disableRulesetField={true}
          ruleSetInfo={[
            {
              value: ruleSetCreationState.screen1.id || "",
              label: ruleSetCreationState.screen1.rulesetName,
            },
          ]}
          setRulesOfRuleset={setRulesOfRuleset}
        />
      )}
      ;
    </>
  );
};

export default ErrorBoundary(rulesetParent);
