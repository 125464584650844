import OldScreensHeader from "../../components/Header/Header";
import NewScreensHeader from "../../componentsPhase1/Header/Header";

import "./unauthorized.scss";

const UnAuthorized = () => (
  <>
    {window.location.pathname.includes("/phase1") ? (
      <OldScreensHeader />
    ) : (
      <NewScreensHeader />
    )}
    <div className="parent403">
      <div className="lock" />
      <div className="message">
        <h1>Application Access is Restricted</h1>
        <p>Please Contact Administrator.</p>
      </div>
    </div>
  </>
);

export default UnAuthorized;
