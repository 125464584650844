import axios, { AxiosRequestHeaders, AxiosResponse } from "axios";

import {
  hourlyJobDivaRatesRequest,
  requestParams,
  sowPayloadProps,
} from "../@types/component";

const BASE_URL = () => {
  if (window.location.host === process.env.REACT_APP_STAGING_HOST) {
    return process.env.REACT_APP_STAGING_API_BASEURL; // replace url from ENV pre-prod
  }

  if (window.location.host === process.env.REACT_APP_PROD_HOST) {
    return process.env.REACT_APP_PROD_API_BASEURL; // replace url from ENV prod
  }

  if (window.location.host === process.env.REACT_APP_DEV_HOST) {
    return process.env.REACT_APP_DEV_API_BASEURL; // replace url from ENV prod
  }

  if (window.location.host === process.env.REACT_APP_QA_HOST) {
    return process.env.REACT_APP_QA_API_BASEURL; // replace url from ENV prod
  }

  return process.env.REACT_APP_LOCAL_API_BASEURL;
};

const baseURL = BASE_URL() || "";

export const HTTP = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

const performRequest = async ({
  url,
  options,
  contentType,
}: requestParams): Promise<AxiosResponse> => {
  const token =
    JSON.parse(localStorage.getItem("okta-token-storage") || "{}") || "";

  const accessToken: string = token?.accessToken?.accessToken;
  const method = options?.method || HTTP.GET;
  const data = options?.data || {};

  const contentTypeHeader =
    contentType && contentType !== ""
      ? contentType
      : "application/vnd.pricing-exp-api.v1+json";

  const headers: AxiosRequestHeaders = {
    ...(options?.headers || {}),
    Accept: contentTypeHeader,
    "Content-Type": contentTypeHeader,
  };
  // "Content-Type": "application/vnd.pricing-exp-api.v1+json",

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  const response = await axios(url, {
    method,
    headers,
    data,
  });

  return response;
};

export default {
  logUserActivity(data: UserLogActivity, mboId: Mboid) {
    const payLoad = { ...data };

    return performRequest({
      url: `${baseURL}/pricingCalc/users/${mboId.mboid}/activity`,
      options: {
        data: payLoad, // request body
        method: HTTP.POST, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
      },
    });
  },
  async getProjectDetails(params?: {
    [key: string]:
      | string
      | number
      | null
      | number[]
      | boolean
      | { [key: string]: string | number }
      | SaveCalcSettingsD
      | hourlyJobDivaRatesRequest
      | sowPayloadProps;
  }) {
    const id = params && (params.id as string);

    return await performRequest({
      url: `${BASE_URL() || ""}/pricingCalc/projectDetails/${id || ""}`,
      options: {
        // this is an options field
        data: {}, // request body
        method: HTTP.GET, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
      },
      contentType: "application/vnd.pricing-exp-api.v2+json",
    });
  },
  async getSuppliers() {
    return await performRequest({
      url: `${baseURL}/pricingCalc/suppliers`,
      options: {
        // this is an options field
        data: {}, // request body
        method: HTTP.GET, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
      },
    });
  },
  async getCustSpecificRules(params: {
    clientId?: number;
    effectiveDate?: string;
    engagementType?: string;
    supplierEngagementType?: string;
  }) {
    const { clientId, effectiveDate, engagementType, supplierEngagementType } =
      params;

    let filter = "";

    if (engagementType && engagementType !== "Non-Associate") {
      filter = `engagementType in (${engagementType})`;
    } else if (supplierEngagementType) {
      filter = `engagementType in (${supplierEngagementType})`;
    }

    return await performRequest({
      url: `${baseURL}/pricingCalc/customerSpecificRules/${clientId || ""}/${
        effectiveDate || ""
      }${filter ? `?filter=${filter}` : ""}`,
      options: {
        // this is an options field
        data: {}, // request body
        method: HTTP.GET, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
      },
    });
  },
  async getCalcSettings(params?: {
    [key: string]:
      | string
      | number
      | null
      | number[]
      | boolean
      | { [key: string]: string | number }
      | SaveCalcSettingsD
      | hourlyJobDivaRatesRequest
      | sowPayloadProps;
  }) {
    const id = params && (params.id as string);

    return await performRequest({
      url: `${baseURL}/pricingCalc/calcSettings/${id || ""}`,
      options: {
        // this is an options field
        data: {}, // request body
        method: HTTP.GET, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
      },
    });
  },
  getCalcAttributes({
    settingsId,
    payRate,
    type,
    isMilestone,
  }: attributionPayloadTYpe) {
    return {
      url: `${baseURL}/pricingCalc/${type}`,
      options: {
        // this is an options field
        data: {
          settingsId,
          [type === "calculatePayToBill" ? "payRate" : "billRate"]: payRate,
          isMilestone,
        }, // request body
        method: HTTP.POST, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
      },
    };
  },
  async getCalcSetingsDetails(params: { settingsId?: number }) {
    const settingsId = params && (params.settingsId as number);

    return await performRequest({
      url: `${baseURL}/pricingCalc/calcSettingDetails/${settingsId}`,
      options: {
        // this is an options field
        data: {}, // request body
        method: HTTP.GET, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
      },
    });
  },
  async SaveCalcSettings(params: {
    [key: string]:
      | string
      | number
      | null
      | number[]
      | boolean
      | { [key: string]: string | number }
      | SaveCalcSettingsD
      | hourlyJobDivaRatesRequest
      | sowPayloadProps;
  }) {
    const { data } = params;

    return await performRequest({
      url: `${baseURL}/pricingCalc/saveCalcSettings`,
      options: {
        // this is an options field
        data, // request body
        method: HTTP.PUT, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
      },
    });
  },
  async getDrpOptions(params: {
    isAvailableCustomRule?: string;
    engagementType?: string;
  }) {
    return await performRequest({
      url: `${baseURL}/pricingCalc/dropDownOptions?sortOrder=ASC&sort=GROUP_ORDER${
        params.isAvailableCustomRule && params.isAvailableCustomRule !== ""
          ? `&filter=${params.isAvailableCustomRule}`
          : ""
      }${
        params.engagementType && params.engagementType !== ""
          ? `&filter=engagementType in (${params.engagementType})`
          : ""
      }`,
      options: {
        // this is an options field
        data: {}, // request body
        method: HTTP.GET, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
      },
    });
  },
  async getLocationOpts(params: { clientId?: string }) {
    return await performRequest({
      url: `${baseURL}/pricingCalc/clients/rules/configuration${`?filter=${
        params.clientId ? params.clientId : ""
      }`}`,
      options: {
        // this is an options field
        data: {}, // request body
        method: HTTP.GET, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
      },
    });
  },
  async getBusinessRules(params?: {
    [key: string]:
      | string
      | number
      | null
      | number[]
      | boolean
      | { [key: string]: string | number }
      | SaveCalcSettingsD
      | hourlyJobDivaRatesRequest
      | sowPayloadProps;
  }) {
    const clientId = params && (params.clientid as string);
    const querystring = params && (params.querystring as string);
    const selectedRulesetId = params && (params.selectedRulesetId as number);

    return await performRequest({
      url: selectedRulesetId
        ? `${baseURL}/pricingCalc/clientRuleSet`
        : `${baseURL}/pricingCalc/clients/${clientId || ""}/ruleSets${
            querystring || ""
          }`,
      options: {
        // this is an options field
        data: selectedRulesetId && {
          clientRuleSet: [
            {
              clientId,
              rulesetIds: [selectedRulesetId],
            },
          ],
        },
        method: selectedRulesetId ? HTTP.DELETE : HTTP.GET, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
      },
      contentType: "application/vnd.pricing-exp-api.v1+json",
    });
  },
  async updateCalcSettingsConfiguration(params?: {
    [key: string]:
      | string
      | number
      | null
      | number[]
      | boolean
      | { [key: string]: string | number }
      | SaveCalcSettingsD
      | hourlyJobDivaRatesRequest
      | sowPayloadProps;
  }) {
    const settingsId = params && (params.settingsId as string);
    const isActive = params && (params.isActive as boolean);
    const updatedBy = params && (params.updatedBy as string);

    return await performRequest({
      url: `${baseURL}/pricingCalc/calcSettings/status`,
      options: {
        // this is an options field
        data: {
          settingsId,
          isActive,
          updatedBy,
        }, // request body
        method: HTTP.PATCH, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
      },
    });
  },
  async getClientList() {
    return await performRequest({
      url: `${baseURL}/pricingCalc/clients`,
      options: {
        // this is an options field
        data: {}, // request body
        method: HTTP.GET, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
      },
    });
  },
  async submitBusinessRules(params?: {
    [key: string]:
      | string
      | number
      | null
      | number[]
      | boolean
      | { [key: string]: string | number }
      | SaveCalcSettingsD
      | hourlyJobDivaRatesRequest
      | sowPayloadProps;
  }) {
    return await performRequest({
      url: `${baseURL}/pricingCalc/ruleSet/rule `,
      options: {
        // this is an options field
        data: params, // request body
        method: HTTP.PUT, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
      },
    });
  },
  quoteRateSiulation(data: quoteRateWorkorderPayloadProps, type: string) {
    return {
      url: `${baseURL}/pricingCalc/rules/${type}`,
      options: {
        data, // request body
        method: HTTP.POST, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
      },
    };
  },
  async getCandidateList(params?: {
    [key: string]:
      | string
      | number
      | null
      | number[]
      | boolean
      | { [key: string]: string | number }
      | SaveCalcSettingsD
      | hourlyJobDivaRatesRequest
      | sowPayloadProps;
  }) {
    let querystring = "";
    let id = "";

    const isSow =
      params && "sow/milestone" in params && (params.sow as boolean);

    if (params && "text" in params) {
      querystring = params && (params.text as string);
    } else {
      id = `filter=candidateId in (${(params && (params.id as string)) || ""})${
        isSow ? `&filter=sow in (${"true"})` : ""
      }`;
    }

    const queryString = id
      ? `/workorders/candidates?${id}`
      : `/candidates?filter=name in (${querystring.trim()})&sort=NAME&sortOrder=asc`;

    return await performRequest({
      url: `${baseURL}/pricingCalc${queryString}`,
      options: {
        method: HTTP.GET, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
      },
    });
  },

  async submitHourlyJobDivaRats(params?: {
    [key: string]:
      | string
      | number
      | null
      | number[]
      | boolean
      | { [key: string]: string | number }
      | SaveCalcSettingsD
      | hourlyJobDivaRatesRequest
      | sowPayloadProps;
  }) {
    const payload = params && (params.payload as hourlyJobDivaRatesRequest);

    return await performRequest({
      url: `${baseURL}/pricingCalc/workorder/payBillInfo`,
      options: {
        method: HTTP.PUT, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
        data: payload,
      },
    });
  },
  async submitSowRatesToJobDiva(params?: {
    [key: string]:
      | string
      | number
      | null
      | number[]
      | boolean
      | { [key: string]: string | number }
      | SaveCalcSettingsD
      | hourlyJobDivaRatesRequest
      | sowPayloadProps;
  }) {
    const payload = params && (params.payload as sowPayloadProps);

    return await performRequest({
      url: `${baseURL}/pricingCalc/workorder/sowInfo`,
      options: {
        method: HTTP.PUT, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
        data: payload,
      },
    });
  },

  async getQuadissues(params?: {
    [key: string]:
      | string
      | number
      | null
      | number[]
      | boolean
      | { [key: string]: string | number }
      | SaveCalcSettingsD
      | hourlyJobDivaRatesRequest
      | sowPayloadProps;
  }) {
    const startId = params && (params.startId as number);
    const billRecordId = params && (params.billRecordId as number);
    const payRecordId = params && (params.payRecordId as number);

    return await performRequest({
      url: `${baseURL}/pricingCalc/workorders/${startId || ""}/validate/${
        billRecordId || ""
      }/${payRecordId || ""}`,
      options: {
        method: HTTP.GET, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
      },
    });
  },

  async getAllRuleSets(params?: {
    [key: string]:
      | string
      | number
      | null
      | number[]
      | boolean
      | { [key: string]: string | number }
      | SaveCalcSettingsD
      | hourlyJobDivaRatesRequest
      | sowPayloadProps;
  }) {
    const type: string = (params && (params.type as string)) || "";

    const querystring: string =
      (params && (params.querystring as string)) || "";

    const payload =
      type === "PUT"
        ? {
            id: params && (params.id as number | null),
            name: params && (params.name as string),
            description: params && (params.description as string),
          }
        : type === "DELETE"
        ? { rulesets: [params && (params.id as number | null)] }
        : null;

    return await performRequest({
      url: `${baseURL}/pricingCalc/ruleSets${querystring}`,
      options: {
        method: type, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
        data: ["PUT", "DELETE"].includes(type) && payload,
      },
    });
  },

  async getClientRulesForRuleset(params?: {
    [key: string]:
      | string
      | number
      | null
      | number[]
      | boolean
      | { [key: string]: string | number }
      | SaveCalcSettingsD
      | hourlyJobDivaRatesRequest
      | sowPayloadProps;
  }) {
    const selectedRulesetId = params && (params.selectedRulesetId as number);
    const type = params && (params.type as string);
    const clientId = params && (params.clientId as number);

    return await performRequest({
      url: `${baseURL}/pricingCalc/clients/${clientId || ""}/ruleSets`,
      options: {
        // this is an options field
        data: type === "PUT" && {
          rulesets: [selectedRulesetId],
        },
        method: type, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
      },
      contentType: "application/vnd.pricing-exp-api.v1+json",
    });
  },

  async getRulesForRuleset(params?: {
    [key: string]:
      | string
      | number
      | null
      | number[]
      | boolean
      | { [key: string]: string | number }
      | SaveCalcSettingsD
      | hourlyJobDivaRatesRequest
      | sowPayloadProps;
  }) {
    const selectedRulesetId = params && (params.selectedRulesetId as number);

    return await performRequest({
      url: `${baseURL}/pricingCalc/ruleSets/${selectedRulesetId || ""}/rules`,
      options: {
        // this is an options field

        method: HTTP.GET, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
      },
      contentType: "application/vnd.pricing-exp-api.v1+json",
    });
  },

  async deleteRuleFromRulesetForClient(params?: {
    [key: string]:
      | string
      | number
      | null
      | number[]
      | boolean
      | { [key: string]: string | number }
      | SaveCalcSettingsD
      | hourlyJobDivaRatesRequest
      | sowPayloadProps;
  }) {
    const standardRuleIds = params && (params.standardRuleIds as number);
    const optionalRuleIds = params && (params.optionalRuleIds as number);

    return await performRequest({
      url: `${baseURL}/pricingCalc/ruleSet/rules`,
      options: {
        // this is an options field
        data: {
          standardRules: standardRuleIds ? [standardRuleIds] : [],
          optionalRules: optionalRuleIds ? [optionalRuleIds] : [],
        },
        method: HTTP.DELETE, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
      },
      contentType: "application/vnd.pricing-exp-api.v1+json",
    });
  },

  async getClientListForRuleId(params?: {
    [key: string]:
      | string
      | number
      | null
      | number[]
      | boolean
      | { [key: string]: string | number }
      | SaveCalcSettingsD
      | hourlyJobDivaRatesRequest
      | sowPayloadProps;
  }) {
    const id = params && Number(params.Id as number);

    return await performRequest({
      url: `${baseURL}/pricingCalc/ruleSet/ruleSetDetails`,
      options: {
        // this is an options field
        data: {
          ruleSets: [id],
        },
        method: HTTP.POST, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
      },
      contentType: "application/vnd.pricing-exp-api.v1+json",
    });
  },

  async setClientRulesForRuleset(params?: {
    [key: string]:
      | string
      | number
      | number[]
      | null
      | boolean
      | { [key: string]: string | number }
      | SaveCalcSettingsD
      | hourlyJobDivaRatesRequest
      | sowPayloadProps;
  }) {
    const selectedRulesetId = params && (params.selectedRulesetId as number);
    const clientId = params && (params.clientId as number[]);
    const type = params && (params.type as string);

    const finalPayload =
      clientId &&
      clientId.map((e) => ({
        clientId: e,
        rulesetIds: [Number(selectedRulesetId)],
      }));

    return await performRequest({
      url: `${baseURL}/pricingCalc/clientRuleSet`,
      options: {
        // this is an options field
        data: {
          clientRuleSet: finalPayload,
        },
        method: type, // pass this only if it is not a GET request
        headers: {}, // if additional header certain request required then pass it here.
      },
      contentType: "application/vnd.pricing-exp-api.v1+json",
    });
  },

  async quadZendeskTickets(params: {
    startId?: string;
    billRecordId?: number;
    payRecordId?: number;
  }) {
    const { startId = "", billRecordId = "", payRecordId = "" } = params;

    return await performRequest({
      url: `${baseURL}/pricingCalc/workorders/${startId}/assignments/${billRecordId}/${payRecordId}/tickets`,
      options: {
        data: {},
        method: HTTP.GET,
        headers: {},
      },
    });
  },
  async quadSubmitForApproval(params: {
    startId?: string;
    billRecordId?: number;
    payRecordId?: number;
  }) {
    return await performRequest({
      url: `${baseURL}/pricingCalc/workorders/zendeskTicket`,
      options: {
        data: { ...params },
        method: HTTP.POST,
        headers: {},
      },
    });
  },
  async quadApproveAssignment(params: {
    startId?: number;
    candidateId?: string;
    payRecordId?: number;
    billRecordId?: number;
    isPayRecordApproved?: boolean;
    isBillRecordApproved?: boolean;
  }) {
    return await performRequest({
      url: `${baseURL}/pricingCalc/workorder`,
      options: {
        data: { ...params },
        method: HTTP.PUT,
        headers: {},
      },
    });
  },
  async quadSubmitFeedback(params: {
    startId?: number;
    candidateId?: string;
    billRecordId?: number;
    payRecordId?: number;
    assignmentName?: string;
    assignmentStartDate?: string;
    assignmentEndDate?: string;
    rating?: "positive" | "negative";
    comments?: string;
    ruleNumber?: number;
    ruleName?: string;
    feedbackType?: "Rule is incorrect" | "Response in unclear" | "Other";
  }) {
    return await performRequest({
      url: `${baseURL}/pricingCalc/workorders/feedback`,
      options: {
        data: { ...params },
        method: HTTP.POST,
        headers: {},
      },
    });
  },
};
