import moment from "moment";
import React from "react";

export interface JDTableBodyProps {
  endDate: {
    localized: string;
    value: string;
    culture: string;
    timeZone: string;
    raw: number;
  };
  startDate: {
    localized: string;
    value: string;
    culture: string;
    timeZone: string;
    raw: number;
  };
  payrate: {
    localized: string;
    culture: string;
    currency: string;
    value: string;
    raw: number;
  };
}
interface JDTableProps {
  header: {
    id: number | string;
    name: string;
  }[];
  paybody?: ProjectDetailsPayInfo[];
  billbody?: ProjectDetailsBillInfo[];
  payrate?: boolean;
}

const JDTable: React.FC<JDTableProps> = ({
  header,
  paybody,
  billbody,
  payrate,
}) => {
  return (
    <table>
      <thead>
        <tr>
          {header.map((heading) => (
            <th key={heading.id}>{heading.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {(payrate &&
          (paybody
            ? paybody.map((items: ProjectDetailsPayInfo, i: number) => (
                <tr key={`${String(items.startDate.raw)}-${i}`}>
                  <td>
                    $
                    {(items.regularPayrate &&
                      items.regularPayrate.raw &&
                      items.regularPayrate.raw / 100) ||
                      ""}
                  </td>
                  <td>
                    {(items.startDate &&
                      items.startDate.raw &&
                      moment(items.startDate.raw)
                        .local()
                        .format("MM/DD/YYYY")) ||
                      "N/A"}
                  </td>
                  <td>
                    {(items.endDate &&
                      items.endDate.raw &&
                      moment(items.endDate.raw).local().format("MM/DD/YYYY")) ||
                      "N/A"}
                  </td>
                </tr>
              ))
            : [])) ||
          (billbody
            ? billbody.map((items: ProjectDetailsBillInfo, i: number) => (
                <tr key={`${String(items.startDate.raw)}-${i}`}>
                  <td>
                    $
                    {(items.regularBillrate &&
                      items.regularBillrate.raw &&
                      items.regularBillrate.raw / 100) ||
                      ""}
                  </td>
                  <td>
                    {(items.startDate &&
                      items.startDate.raw &&
                      moment(items.startDate.raw)
                        .local()
                        .format("MM/DD/YYYY")) ||
                      "N/A"}
                  </td>
                  <td>
                    {(items.endDate &&
                      items.endDate.raw &&
                      moment(items.endDate.raw).local().format("MM/DD/YYYY")) ||
                      "N/A"}
                  </td>
                </tr>
              ))
            : [])}
      </tbody>
    </table>
  );
};

export default React.memo(JDTable);
