import React from "react";

import "./Radio.scss";

interface RadioButtonProps {
  options: radioOptionsPorp[];
  onChange?: (x: radioOptionsPorp) => void;
  className?: string;
  checked: boolean[];
  requiredLable?: boolean;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  options,
  onChange,
  className,
  checked,
  requiredLable = true,
}) => (
  <span className={`radio ${className || ""}`}>
    {options.map((option, i) => (
      <div key={option.id} className="radio__radio_btn_parent">
        <input
          id={`${option.id}`}
          onChange={() => onChange && onChange(option)}
          value={option.value}
          type="radio"
          checked={checked[i]}
        />
        {requiredLable && (
          <label htmlFor={`${option.id}`}>{option.value}</label>
        )}
      </div>
    ))}
  </span>
);

export default RadioButton;
