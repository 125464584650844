import React from "react";

import "./SectionContainer.scss";

interface SectionConatinerProps {
  children: React.ReactNode;
  title: string;
  icon?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

const SectionConatiner: React.FC<SectionConatinerProps> = ({
  children,
  className,
  title,
  icon,
  onClick,
}) => (
  <div className={`sectionContainer ${className || ""}`}>
    <span className="title" onClick={onClick}>
      {icon}
      {title}
    </span>
    {children}
  </div>
);

export default SectionConatiner;
