import React from "react";

import classes from "./header.module.scss";

interface SimulateHeaderProps {
  selectedTabIndex: number;
  selectTab: (index: number) => void;
  sow: boolean;
  simulateDisable: boolean;
  showPayRateBillRate?: boolean;
  tabIndex?: boolean;
}

const SimulateHeader: React.FC<SimulateHeaderProps> = ({
  selectedTabIndex,
  selectTab,
  sow = false,
  simulateDisable,
  showPayRateBillRate = false,
  tabIndex,
}) => {
  return (
    <div
      className={[
        !sow ? classes.header_section : classes.markup_header,
        showPayRateBillRate ? classes.adjust_tab_alignment : "",
        simulateDisable && classes.simulate_disabled,
      ].join(" ")}
    >
      {!sow ? (
        <>
          <div
            className={[
              classes.tab,
              selectedTabIndex === 0 && classes.active_tab,
            ].join(" ")}
            onClick={() => selectTab(0)}
          >
            Simulate pay to bill
          </div>
          <div
            className={[
              classes.tab,
              selectedTabIndex === 1 && classes.active_tab,
            ].join(" ")}
            onClick={() => selectTab(1)}
          >
            Simulate bill to pay
          </div>
        </>
      ) : (
        !showPayRateBillRate && (
          <div className={[classes.tab, classes.active_tab].join(" ")}>
            Markup Calculation
          </div>
        )
      )}

      {showPayRateBillRate && (
        <>
          <div
            className={[
              classes.tab,

              selectedTabIndex === 0 && classes.active_tab,
            ].join(" ")}
            onClick={() => selectTab(0)}
          >
            Simulate pay to bill
          </div>
          <div
            className={[
              classes.tab,

              selectedTabIndex === 1 && classes.active_tab,
            ].join(" ")}
            onClick={() => selectTab(1)}
          >
            Simulate bill to pay
          </div>
        </>
      )}

      <div
        className={[
          classes.select_notifier,
          simulateDisable && classes.disable_simulate,
        ].join(" ")}
        style={{
          transform: `translateX(${
            tabIndex ? 0 : selectedTabIndex * 100
          }%) scaleX(1)`,
        }}
      ></div>
    </div>
  );
};

export default SimulateHeader;
