const CancelIcon = (props: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.86374 3.75092C7.57085 3.45803 7.09598 3.45803 6.80308 3.75092L2.80308 7.75092C2.66243 7.89157 2.58341 8.08234 2.58341 8.28125C2.58341 8.48016 2.66243 8.67093 2.80308 8.81158L6.80308 12.8116C7.09598 13.1045 7.57085 13.1045 7.86374 12.8116C8.15664 12.5187 8.15664 12.0438 7.86374 11.7509L5.14408 9.03125H12.6667C13.081 9.03125 13.4167 8.69546 13.4167 8.28125C13.4167 7.86704 13.081 7.53125 12.6667 7.53125H5.14408L7.86374 4.81158C8.15664 4.51869 8.15664 4.04381 7.86374 3.75092Z"
      fill="#352281"
    />
  </svg>
);

export default CancelIcon;
