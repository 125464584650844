import React, { useState } from "react";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";

import ErrorBoundary from "../../../../HOC/ErrorBoundary/errorBoundary.component";

import classes from "./index.module.scss";

interface iProps {
  jobDivaDetails: ProjectDetailsAPIResponse | null;
  alterStyle?: string;
  combinePayandBillTable?: boolean;
  children?: React.ReactNode;
  selectedJobDivaId?: string;
  setJobDivaDetails?: React.Dispatch<
    React.SetStateAction<ProjectDetailsAPIResponse | null>
  >;
  selectRate?: (id: number | null) => void;
  ratesFixedHeightClass?: string;
}

const PayrateBillRate: React.FC<iProps> = ({
  jobDivaDetails,
  alterStyle = "",
  combinePayandBillTable = false,
  children,
  setJobDivaDetails,
  selectedJobDivaId,
  selectRate,
  ratesFixedHeightClass,
}) => {
  const [showAllData, setShowAllData] = useState(false);

  return (
    <div
      data-testid="payratetestid"
      className={[classes.rate_parent_with_showall, alterStyle].join(" ")}
    >
      {combinePayandBillTable && (
        <div data-testid="displayid" className={classes.job_diva_rates_style}>
          <p>JobDiva Rates for Start ID:</p> <p> {selectedJobDivaId}</p>
        </div>
      )}
      <div
        data-testid="rateparent"
        className={[
          classes.rate_parent,
          showAllData ? classes.rate_parent_full_data : "",
          combinePayandBillTable ? classes.rate_parent_remove_gap : "",
          combinePayandBillTable ? ratesFixedHeightClass : "",
        ].join(" ")}
      >
        {/* {pricingCalcDetails && pricingCalcDetails.map((e) => e.updatedBy)} */}
        <div
          className={[
            classes.divTable,
            combinePayandBillTable ? classes.simulation_divTable : "",
          ].join(" ")}
        >
          <div
            className={[
              classes.headers,
              combinePayandBillTable ? classes.headers_jobDiva : "",
            ].join(" ")}
          >
            <div className={classes.divTableCell}>Pay rate</div>
            <div className={classes.divTableCell}>Start date</div>
            <div className={classes.divTableCell}>End date</div>
          </div>
          <div className={classes.body}>
            {jobDivaDetails &&
              jobDivaDetails?.data?.payInfo?.map((e, i) => {
                return (
                  <div
                    className={[
                      classes.rowData,
                      combinePayandBillTable
                        ? `${classes.add_radio_btn} ${classes.rowData_jobDiva}`
                        : "",
                    ].join(" ")}
                    key={e.endDate?.raw + i}
                  >
                    <div className={classes.divTableCell}>
                      {e.regularPayrate && e.regularPayrate.raw
                        ? `$${e.regularPayrate.raw / 100}`
                        : "N/A"}
                    </div>
                    <div className={classes.divTableCell}>
                      <p>
                        {e?.startDate?.value?.split(" ")?.join(" | ") || "N/A"}
                      </p>
                    </div>
                    <div className={classes.divTableCell}>
                      <p>
                        {e?.endDate?.value?.split(" ")?.join(" | ") || "N/A"}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div
          className={[
            classes.divTable,
            combinePayandBillTable ? classes.simulation_divTable : "",
          ].join(" ")}
        >
          <div
            className={[
              classes.headers,
              combinePayandBillTable ? classes.headers_jobDiva : "",
            ].join(" ")}
          >
            <div className={classes.divTableCell}>Bill rate</div>
            <div className={classes.divTableCell}>Start date</div>
            <div className={classes.divTableCell}>End date</div>
            {combinePayandBillTable ? (
              <div className={classes.divTableCell}>OT Exempt</div>
            ) : null}
            {combinePayandBillTable ? (
              <div className={classes.divTableCell}>Approved</div>
            ) : null}
            {combinePayandBillTable ? (
              <div className={classes.divTableCell}>Select Rate</div>
            ) : null}
          </div>
          <div className={classes.body}>
            {jobDivaDetails &&
              jobDivaDetails?.data?.billInfo?.map((e, i) => {
                return (
                  <div
                    className={[
                      classes.rowData,
                      combinePayandBillTable
                        ? `${classes.add_radio_btn} ${classes.rowData_jobDiva}`
                        : "",
                    ].join(" ")}
                    key={e.endDate?.raw + i}
                  >
                    <div className={classes.divTableCell}>
                      {e.regularBillrate && e.regularBillrate.raw
                        ? `$${e.regularBillrate.raw / 100}`
                        : "N/A"}
                    </div>
                    <div className={classes.divTableCell}>
                      <p>
                        {e?.startDate?.value?.split(" ")?.join(" | ") || "N/A"}
                      </p>
                    </div>
                    <div className={classes.divTableCell}>
                      <p>
                        {e?.endDate?.value?.split(" ")?.join(" | ") || "N/A"}
                      </p>
                    </div>
                    {combinePayandBillTable && (
                      <>
                        <div className={classes.divTableCell}>
                          <p>{e.overTimeExempt ? "Yes" : "No"}</p>
                        </div>
                        <div className={classes.divTableCell}>
                          <p>{e.approved ? "Yes" : "No"}</p>
                        </div>
                        <div className={classes.divTableCell}>
                          <div
                            className={[
                              classes.flex,
                              classes.justifyCenter,
                              classes.itemsCenter,
                              classes.radio_marginLeft,
                            ].join(" ")}
                          >
                            <input
                              className={[
                                classes.radio,
                                classes.groupHover,
                                classes.pointerEventsNone,
                              ].join(" ")}
                              type="radio"
                              id={e?.recordId?.toString()}
                              value={e.recordId}
                              checked={e.select}
                              readOnly
                              onChange={() =>
                                selectRate && selectRate(e.recordId || null)
                              }
                            />
                            <label
                              className={[classes.pointerEventsNone].join(" ")}
                              htmlFor={e?.recordId?.toString()}
                            ></label>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {jobDivaDetails &&
        !combinePayandBillTable &&
        (jobDivaDetails?.data?.billInfo?.length > 3 ||
          jobDivaDetails?.data?.payInfo?.length > 3) && (
          <span
            className={classes.show_all}
            onClick={() => setShowAllData(!showAllData)}
          >
            {!showAllData ? "Show all" : "Show Less"}
            {(showAllData && (
              <SlArrowUp className={[classes.dropdown_arrow].join(" ")} />
            )) || (
              <SlArrowDown className={[classes.dropdown_arrow].join(" ")} />
            )}
          </span>
        )}
    </div>
  );
};

export default ErrorBoundary(PayrateBillRate);
