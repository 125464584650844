import { useContext } from "react";

import useSpinnerContext from "../../contexts/LoaderContext";

import "./spinner.scss";

const SpinnerComponent = () => {
  const { enableLoader } = useContext(useSpinnerContext);

  return (
    enableLoader && (
      <div className="parent">
        <div className="parent__loader">Loading...</div>
      </div>
    )
  );
};

export default SpinnerComponent;
