import { createContext } from "react";

interface ToastTypeProp {
  message: string;
  type: string;
}
interface ToastContext {
  EnableToast: (c: ToastTypeProp) => void;
  toastMessage: ToastTypeProp;
}
const GlobalToastContext = createContext<ToastContext>({
  EnableToast: () => {},
  toastMessage: { message: "", type: "" },
});

export default GlobalToastContext;
