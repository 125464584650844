import JSPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";
import { ReactNode, useEffect, useMemo } from "react";
import { FaDownload } from "react-icons/fa";
import ReactModal from "react-modal";

import { validateRate } from "../../pages/Details/details.logichandler";
import SectionConatiner from "../SectionConatiner";
import Table from "../TableComponent/parentTableComponent";
import { optionsProp } from "../WorkOrder/WorkOrderBody";

import "./printWorkOrder.scss";

interface TableIProps {
  [key: string]: string | number | optionsProp | ReactNode | amount;
}
interface TableDataProps {
  [key: string]: string | number | null;
}

interface amount {
  localized: string;
  culture: string;
  currency: string;
  value: string;
  raw: number;
}
interface Iprops {
  startId: string;
  effectiveDate: Date | null;
  setPrintDocument: (e: boolean) => void;
  clientDetails: ProjectDetailsAPIResponseData | null;
  pricingCalcData: PricingCalcSettingsObj[];
  businessRules: {
    basicInfo: {
      effectiveDate: string | null;
      comments: string;
      recutingServices: string;
      recutingPartner: optionsProp;
    }[];
    optionslRules: TableIProps[] | [];
    workorderRules: TableIProps[] | [];
  };
  jdRate: number | string | null;
  ptobrates: {
    [key: string]: {
      payRate: number | undefined;
      netPayRate?: number | undefined;
      billRate: number | undefined;
      payRateOtDt: number | undefined;
      label: string | null;
      index: string | null;
      isOt: boolean;
      isDt: boolean;
      payRateOt: string | undefined | number;
      payRateDt: string | undefined | number;
    };
  };
  btoprates: {
    [key: string]: {
      payRate: number | undefined;
      netPayRate?: number | undefined;
      billRate: number | undefined;
      payRateOtDt: number | undefined;
      label: string | null | string;
      index: string | null | string;
    };
  };
  btopjdRate: number | null;
  openPrintDocument: boolean;
  payToBillAttributions: CalcAtributes["data"]["attributions"] | [];
  billToPayAttributions: CalcAtributes["data"]["attributions"] | [];
  workorderPayRateSimulationdata: boolean;
  workorderBillRateSimulationdata: boolean;
}
const customStyles = {
  content: {
    top: "48%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    height: "90vh",
    boxShadow: "rgb(32 21 81 / 70%) 0px 2px 13px",
    zIndex: 5,
    padding: "0",
    borderRadius: "5px",
    border: "none",
  },
};

const PrintWorkOrderDetails = ({
  setPrintDocument,
  clientDetails,
  pricingCalcData,
  businessRules,
  jdRate,
  ptobrates,
  btoprates,
  btopjdRate,
  openPrintDocument,
  billToPayAttributions,
  payToBillAttributions,
  startId,
  effectiveDate,
  workorderPayRateSimulationdata,
  workorderBillRateSimulationdata,
}: Iprops) => {
  // function to create headers for table and pdf form
  const createHeaders = (
    data: {
      [key: string]: string | number | boolean | null | undefined | JSX.Element;
    }[]
  ) => {
    const tableHeadersLength =
      data && data.length > 0 ? Object.keys(data[0]).length : 0;

    const tableHeaders =
      (data &&
        data.length > 0 &&
        useMemo(
          () =>
            Object.keys(data[0]).map((e, i) => ({
              Header: e
                .replace(/([A-Z])/g, " $1")
                .replace(/^./, (str) => str.toUpperCase()),
              accessor: e,
              width: (() => {
                if (e === "payRate" || e === "billRate") {
                  return 60;
                }

                if (e.includes("Date")) {
                  return 100;
                }

                if (e === "client") {
                  return 250;
                }

                return 161;
              })(),
              sticky: i < 2 && tableHeadersLength > 8 ? "left" : "",
            })),

          [data]
        )) ||
      [];

    return tableHeaders;
  };

  // checkOperationTypeHandler, validateNegativeValue to append $ sign and format amout as per requirement
  const checkOperationTypeHandler = (
    operationType: string,
    negativeSign: string,
    multiPlyWith: number,
    rate: number,
    divideByHundred: boolean
  ) => {
    if (operationType && operationType !== "") {
      return operationType === "flat fee"
        ? `${negativeSign}$${
            multiPlyWith > 0
              ? (!divideByHundred ? rate : (rate / 100) * multiPlyWith).toFixed(
                  2
                )
              : (!divideByHundred ? rate : rate / 100).toFixed(2)
          }`
        : `${negativeSign}${rate}%`;
    } else {
      return `${negativeSign}$${
        multiPlyWith > 0
          ? ((!divideByHundred ? rate : rate / 100) * multiPlyWith).toFixed(2)
          : (!divideByHundred ? rate : rate / 100).toFixed(2)
      }`;
    }
  };

  const validateNegativeValue = (
    value: number | null | string | undefined,
    multiPlyWith: number,
    divideByHundred: boolean = true,
    operationType: string = ""
  ) => {
    if (value) {
      const price = Number(value);
      const negativeSign = Math.sign(price) === -1 ? "-" : "";
      const rate: number = Math.abs(price);

      return checkOperationTypeHandler(
        operationType,
        negativeSign,
        multiPlyWith,
        rate,
        divideByHundred
      );
    }

    return "";
  };

  // pay to bill data
  const ptoToBillJdRates = [
    {
      PayRate: validateNegativeValue(jdRate, 0) || "",
      OverTimePayRate:
        (ptobrates.jdRates.payRateOt &&
          `${validateNegativeValue(
            ptobrates.jdRates.payRateOt,
            0
          )} (1.5 Times of Pay Rate)`) ||
        "",
      DoubleTimePayRate:
        (ptobrates.jdRates.payRateDt &&
          `${validateNegativeValue(
            ptobrates.jdRates.payRateDt,
            0
          )}  (2.0 Times of Pay Rate)`) ||
        "",
      BillRate: validateNegativeValue(btopjdRate, 0),
    },
  ];

  const ptoToBillCalculatedRates = [
    {
      PayRate: validateNegativeValue(
        ptobrates && ptobrates.ptobcalculateRates
          ? ptobrates.ptobcalculateRates.payRate
          : "",
        0
      ),
      NetPayRate: validateNegativeValue(
        ptobrates && ptobrates.ptobcalculateRates.netPayRate
          ? ptobrates.ptobcalculateRates.netPayRate
          : "",
        0
      ),
      OverTimePayRate:
        (ptobrates.ptobcalculateRates.payRate &&
          `${validateNegativeValue(
            ptobrates && ptobrates.ptobcalculateRates.payRate,
            1.5
          )} (1.5 Times of Pay Rate)`) ||
        "",
      DoubleTimePayRate:
        (ptobrates &&
          ptobrates.ptobcalculateRates.payRate &&
          `${validateNegativeValue(
            ptobrates && ptobrates.ptobcalculateRates.payRate,
            2.0
          )}  (2.0 Times of Pay Rate)`) ||
        "",
      BillRate: validateNegativeValue(ptobrates.ptobcalculateRates.billRate, 0),
    },
  ];

  const pToBAttributions = payToBillAttributions.map((rule) => {
    return {
      name: rule.name,
      rate: validateNegativeValue(rule.rate.raw, 0),
      comment: rule.comment,
      rules: rule.rules
        .map((e) => [e.ruleName, e.ruleType].join(" - "))
        .join(" , ")
        .replace(", ", "\n"),
    };
  });

  // bill to pay
  const bToPayJdRates = [
    {
      PayRate: validateNegativeValue(jdRate, 0) || "",
      OverTimePayRate: `${validateNegativeValue(
        jdRate,
        1.5
      )} (1.5 Times of Pay Rate)`,
      DoubleTimePayRate: `${validateNegativeValue(
        jdRate,
        2.0
      )}  (2.0 Times of Pay Rate)`,
      BillRate: validateNegativeValue(btopjdRate, 0),
    },
  ];

  const bToPayCalculatedRates = [
    {
      PayRate: validateNegativeValue(
        btoprates && btoprates.calculateRates
          ? btoprates.calculateRates.payRate
          : "",
        0
      ),
      NetPayRate: validateNegativeValue(
        btoprates && btoprates.calculateRates.netPayRate
          ? btoprates.calculateRates.netPayRate
          : "",
        0
      ),
      OverTimePayRate:
        (btoprates &&
          btoprates.calculateRates.payRate &&
          `${validateNegativeValue(
            btoprates && btoprates.calculateRates.payRate,
            1.5
          )} (1.5 Times of Pay Rate)`) ||
        "",
      DoubleTimePayRate:
        (btoprates &&
          btoprates.calculateRates.payRate &&
          `${validateNegativeValue(
            btoprates && btoprates.calculateRates.payRate,
            2.0
          )}  (2.0 Times of Pay Rate)`) ||
        "",
      BillRate: validateNegativeValue(btoprates.calculateRates.billRate, 0),
    },
  ];

  const bToPAttributions = billToPayAttributions.map((rule) => {
    return {
      name: rule.name,
      rate: validateNegativeValue(rule.rate.raw, 0),
      comment: rule.comment,
      rules: rule.rules
        .map((e) => [e.ruleName, e.ruleType].join(" - "))
        .join(" , ")
        .replace(", ", "\n"),
    };
  });

  const doc = new JSPDF("l", "pt");

  // client detials
  const clientInfo: TableDataProps[] = clientDetails
    ? Array(clientDetails).map((client) => ({
        candidateName: `${client.candidate.firstName} ${client.candidate.lastName}`,
        client: client.job.client.name,
        payRate: `$${
          Number(validateRate(effectiveDate, client.payInfo, "payrate")) /
            100 || "N/A"
        }`.replace("$N/A", "N/A"),
        billRate: `$${
          Number(validateRate(effectiveDate, client.billInfo, "billrate")) /
            100 || "N/A"
        }`.replace("$N/A", "N/A"),
        engagementType: client.candidate.engagementType,
        location: client.job.location,
        startDate: moment(client.job.startDate.raw)
          .local()
          .format("YYYY-MM-DD"),
        endDate:
          (client.payInfo &&
            client.payInfo.length > 0 &&
            client.payInfo[0].endDate &&
            moment(client.payInfo[0].endDate.raw)
              .local()
              .format("YYYY-MM-DD")) ||
          "",
      }))
    : [];

  // pricing calc details
  const pricingCalcInfo: TableDataProps[] = pricingCalcData.map((data) => ({
    comment: data.comment,
    effectiveDate: moment(data.effectiveDate.raw).format("YYYY-MM-DD"),
    lastUpdatedDate: moment(data.lastUpdatedDate.raw)
      .local()
      .format("YYYY-MM-DD"),
    updatedBy: data.updatedBy,
  }));

  // business rules basic information
  const businessRulesBasicInfo: TableDataProps[] = businessRules.basicInfo.map(
    (e) => ({
      ...e,
      recutingPartner:
        (e.recutingPartner &&
          e.recutingPartner.label &&
          e.recutingPartner.label) ||
        "",
      recutingServices: `${e.recutingServices !== "false" ? "Yes" : "No"}`,
    })
  );

  // business work order rules
  const businessWorkOrderRules: TableDataProps[] =
    businessRules.workorderRules && businessRules.workorderRules.length > 0
      ? businessRules.workorderRules.map((e: unknown) => {
          const obj = e as workorderSpecificCustomAppliedRulesType;

          return {
            desctiption: obj.description,
            operation: obj.operation.name,
            amount: validateNegativeValue(
              obj.amount ? +obj.amount.raw : "",
              0,
              true,
              obj.operation.name
            ),
            group: obj.group.name,

            attribution: obj.attribution.name,
            supplier: obj.vendor.name,
          };
        })
      : [];

  // business optioansl rules
  const businessOptionsRules: TableDataProps[] =
    businessRules.optionslRules &&
    businessRules.optionslRules.map((e: unknown) => {
      const obj = e as calcSettingsDetailsCustomerSpecificCustomAppliedRules;

      return {
        name: obj.name,
        description: obj.description,
        operation: obj.operation.name,
        amount: validateNegativeValue(
          obj.amount ? +obj.amount.raw : "",
          0,
          true,
          obj.operation.name
        ),
      };
    });

  useEffect(() => {
    // preparing the data with auto table for pdf to print
    [
      [clientInfo, "Client Details"],
      [pricingCalcInfo, "Pricing Calc"],
      [businessRulesBasicInfo, "Business Rules Basic Information"],
      [businessOptionsRules, "Optional Rules"],
      [businessWorkOrderRules, "Work Order Specific Custom Rules"],
      [ptoToBillJdRates, "Pay To Bill Job Diva Rates"],
      [ptoToBillCalculatedRates, "Pay To Bill Calculated Rates"],
      [pToBAttributions, "Pay To Bill Attributions"],
      [bToPayJdRates, "Bill To Pay Job Diva Rates"],
      [bToPayCalculatedRates, "Bill To Pay Calculated Rates"],
      [bToPAttributions, "Bill To Pay Attributions"],
    ].forEach((rules) => {
      const headerText = rules[1] as string;

      if (rules[0] && rules[0].length > 0) {
        autoTable(doc, {
          styles: { fontStyle: "bold" },
          margin: { vertical: 50 },
          body: [
            [
              {
                content: headerText,
                styles: {
                  halign: "center",
                  fillColor: [218, 221, 243],
                  textColor: [33, 21, 81],
                  fontSize: 14,
                },
              },
            ],
          ],
        });
      }

      autoTable(doc, {
        theme: "grid",
        horizontalPageBreak: true,
        margin: { vertical: 15 },
        tableWidth: "auto",
        columnStyles: {
          rules: { cellWidth: 400 },
          comment: { cellWidth: 150 },
        },
        headStyles: {
          minCellWidth: 12,
          minCellHeight: 12,
          fillColor: [33, 21, 81],
          overflow: "linebreak",
        },
        bodyStyles: {
          cellWidth: "wrap",
          minCellWidth: 12,
          minCellHeight: 12,
          overflow: "linebreak",
        },
        body: rules[0] as TableDataProps[],
        columns:
          rules[0] && rules[0].length > 0
            ? Object.keys(rules[0][0]).map((e) => ({
                header: e
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase()),
                dataKey: e,
              }))
            : [],
      });
    });
  }, []);

  // funciton to download the pdf document
  const downLoadPdfFile = () => {
    const fileName: string | number | null = clientInfo[0].client;

    const { effectiveDate }: { effectiveDate: string | null } =
      businessRules.basicInfo[0];

    doc.save(
      `Rules-${String(fileName)}-${startId}-${String(effectiveDate)}.pdf`
    );
  };

  return (
    <ReactModal
      isOpen={openPrintDocument}
      style={customStyles}
      contentLabel="Minimal Modal Example"
      ariaHideApp={false}
    >
      <div className="print_workorder_modal">
        <header>
          <h3>Print Workorder Rules</h3>
          <div>
            <FaDownload onClick={() => downLoadPdfFile()} />
            <button type="button" onClick={() => setPrintDocument(false)}>
              x
            </button>
          </div>
        </header>
        <section className="print_workorder_modal__body">
          {clientInfo && clientInfo.length > 0 && (
            <SectionConatiner title="Client Details">
              <section
                className="print_workorder_modal__body__section"
                data-selector="standardrules"
              >
                <Table data={clientInfo} columns={createHeaders(clientInfo)} />
              </section>
            </SectionConatiner>
          )}
          {pricingCalcInfo && pricingCalcInfo.length > 0 && (
            <SectionConatiner title="Pricing Calc">
              <section
                className="print_workorder_modal__body__section"
                data-selector="standardrules"
              >
                <Table
                  data={pricingCalcInfo}
                  columns={createHeaders(pricingCalcInfo)}
                />
              </section>
            </SectionConatiner>
          )}

          {(businessRulesBasicInfo && businessRulesBasicInfo.length > 0) ||
          (businessOptionsRules && businessOptionsRules.length > 0) ||
          (businessWorkOrderRules && businessWorkOrderRules.length > 0) ? (
            <SectionConatiner title="Business Rules">
              <section
                className="print_workorder_modal__body__section"
                data-selector="standardrules"
              >
                {businessRulesBasicInfo && businessRulesBasicInfo.length > 0 && (
                  <SectionConatiner
                    className="businessRulesBasicRules"
                    title="Basic Information"
                  >
                    <Table
                      data={businessRulesBasicInfo}
                      columns={createHeaders(businessRulesBasicInfo)}
                    />
                  </SectionConatiner>
                )}
                {businessOptionsRules && businessOptionsRules.length > 0 && (
                  <SectionConatiner
                    className="businessOptionalRules"
                    title="Optional Rules"
                  >
                    <Table
                      data={businessOptionsRules}
                      columns={createHeaders(businessOptionsRules)}
                    />
                  </SectionConatiner>
                )}
                {businessWorkOrderRules && businessWorkOrderRules.length > 0 && (
                  <SectionConatiner
                    className="businesWorkOrderRules"
                    title="Work Order Specific Custom Rules"
                  >
                    <Table
                      data={businessWorkOrderRules}
                      columns={createHeaders(businessWorkOrderRules)}
                    />
                  </SectionConatiner>
                )}
              </section>
            </SectionConatiner>
          ) : null}
          {workorderPayRateSimulationdata && (
            <SectionConatiner title="Pay To Bill">
              <section
                className="print_workorder_modal__body__section"
                data-selector="standardrules"
              >
                {ptoToBillJdRates && ptoToBillJdRates.length > 0 && (
                  <SectionConatiner
                    className="businessRulesBasicRules"
                    title="Job Diva Rates"
                  >
                    <section
                      className="print_workorder_modal__body__section"
                      data-selector="standardrules"
                    >
                      <Table
                        data={ptoToBillJdRates}
                        columns={createHeaders(ptoToBillJdRates)}
                      />
                    </section>
                  </SectionConatiner>
                )}
                {ptoToBillCalculatedRates &&
                  ptoToBillCalculatedRates.length > 0 && (
                    <SectionConatiner
                      className="businessOptionalRules"
                      title="Calculated Rates"
                    >
                      <section
                        className="print_workorder_modal__body__section"
                        data-selector="standardrules"
                      >
                        <Table
                          data={ptoToBillCalculatedRates}
                          columns={createHeaders(ptoToBillCalculatedRates)}
                        />
                      </section>
                    </SectionConatiner>
                  )}
                {ptoToBillCalculatedRates &&
                  ptoToBillCalculatedRates.length > 0 && (
                    <SectionConatiner
                      className="businesWorkOrderRules"
                      title="Attributions"
                    >
                      <section
                        className="print_workorder_modal__body__section"
                        data-selector="standardrules"
                      >
                        <Table
                          data={pToBAttributions}
                          columns={createHeaders(pToBAttributions)}
                        />
                      </section>
                    </SectionConatiner>
                  )}
              </section>
            </SectionConatiner>
          )}
          {workorderBillRateSimulationdata && (
            <SectionConatiner title="Bill To Pay">
              <section
                className="print_workorder_modal__body__section"
                data-selector="standardrules"
              >
                {bToPayJdRates && bToPayJdRates.length > 0 && (
                  <SectionConatiner
                    className="businessRulesBasicRules"
                    title="Job Diva Rates"
                  >
                    <section
                      className="print_workorder_modal__body__section"
                      data-selector="standardrules"
                    >
                      <Table
                        data={bToPayJdRates}
                        columns={createHeaders(bToPayJdRates)}
                      />
                    </section>
                  </SectionConatiner>
                )}
                {bToPayCalculatedRates && bToPayCalculatedRates.length > 0 && (
                  <SectionConatiner
                    className="businessOptionalRules"
                    title="Calculated Rates"
                  >
                    <section
                      className="print_workorder_modal__body__section"
                      data-selector="standardrules"
                    >
                      <Table
                        data={bToPayCalculatedRates}
                        columns={createHeaders(bToPayCalculatedRates)}
                      />
                    </section>
                  </SectionConatiner>
                )}
                {bToPAttributions && bToPAttributions.length > 0 && (
                  <SectionConatiner
                    className="businesWorkOrderRules"
                    title="Attributions"
                  >
                    <section
                      className="print_workorder_modal__body__section"
                      data-selector="standardrules"
                    >
                      <Table
                        data={bToPAttributions}
                        columns={createHeaders(bToPAttributions)}
                      />
                    </section>
                  </SectionConatiner>
                )}
              </section>
            </SectionConatiner>
          )}
        </section>
      </div>
    </ReactModal>
  );
};

export default PrintWorkOrderDetails;
