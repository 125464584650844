import ReactModal from "react-modal";

interface ModalProps {
  children: React.ReactNode;
  styleObj: { [key: string]: { [key: string]: string | number } };
  isOpen: { [key: string]: number[] } | null | boolean;
}
const PopUpComponent = ({ children, styleObj, isOpen }: ModalProps) => (
  <ReactModal
    style={styleObj}
    isOpen={Boolean(isOpen)}
    contentLabel="Minimal Modal Example"
    ariaHideApp={false}
  >
    {children}
  </ReactModal>
);

export default PopUpComponent;
