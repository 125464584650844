import BackIcon from "../../Icon/Icons/backIcon";
import Button from "../../MBOButton/Button";

import classes from "./Footer.module.scss";

interface IProps {
  disableContinue?: boolean;
  onBack: () => void;
  onContinue: (value?: string | number) => void;
}

const Footer: React.FC<IProps> = ({ disableContinue, onBack, onContinue }) => {
  return (
    <div className={classes.container}>
      <Button
        name="Back"
        className={classes.btn_calcel_styling}
        icon={<BackIcon width={16} height={16} />}
        onClick={onBack}
        data-testid="back-button"
      />
      <Button
        name="Continue"
        className={classes.btn_update_styling}
        onClick={onContinue}
        disabled={disableContinue}
        data-testid="continue-button"
      />
    </div>
  );
};

export default Footer;
