import useStore from "../../store/useStore";

import "./spinner.scss";

const SpinnerComponent = () => {
  const enableLoader = useStore((state) => state.enableLoader);

  return enableLoader ? (
    <div className="parent" data-testid="spinner">
      <div className="parent__loader">Loading...</div>
    </div>
  ) : null;
};

export default SpinnerComponent;
