import React from "react";

import {
  engagementTypeListProps,
  optionsProp,
  selectedItemsProps,
} from "../../../@types/component";
import { CaretRight } from "../../../componentsPhase1/Icon/Icons/CaretRight";
import MBOButton from "../../../componentsPhase1/MBOButton";
import MBODropdown from "../../../componentsPhase1/MBODropdown";
import ClientSearchScreen from "../../clientRules/clientSearchScreen";

import classes from "./searchScreen.module.scss";

interface SimulationSearchScreenProps {
  clientList: optionsProp[];
  setSelectedClient: React.Dispatch<React.SetStateAction<optionsProp>>;
  selectedClient: optionsProp;
  engagementTypeList: engagementTypeListProps[];
  clearEngagementType: (filterType: string) => void;
  handleFilterChange: (
    filterType: string,
    filterValue: engagementTypeListProps
  ) => void;
  selectedFilters: selectedItemsProps;
  handleCreateSimulate: () => void;
  deliverableTypeList: engagementTypeListProps[];
  writeAccess?: boolean;
}

const SimulationSearchScreen: React.FC<SimulationSearchScreenProps> = ({
  clientList,
  setSelectedClient,
  selectedClient,
  engagementTypeList,
  clearEngagementType,
  handleFilterChange,
  selectedFilters,
  handleCreateSimulate,
  deliverableTypeList,
  writeAccess,
}) => {
  return (
    <div className={classes.container}>
      <div className={classes.main}>
        <div className={classes.simulationSearch}>
          <h2>Start a simulation</h2>
          <ClientSearchScreen
            receivedData={clientList}
            setSelectedClient={setSelectedClient}
            selectedClient={selectedClient}
          />
        </div>
        <div>
          <MBODropdown
            disabled={selectedClient.label === ""}
            name="Engagement Type"
            options={engagementTypeList}
            clearHandler={clearEngagementType}
            selectedValue={selectedFilters.engagementType}
            handleValueChange={handleFilterChange}
            propertyName="engagementType"
            tooltipContent="Now select engagement type"
          />
        </div>
        {(selectedFilters.engagementType.name === "Business Services" ||
          selectedFilters.engagementType.name === "Vendor Services") && (
          <div>
            <MBODropdown
              name="Deliverable Type"
              options={deliverableTypeList}
              clearHandler={clearEngagementType}
              selectedValue={selectedFilters.deliverableType}
              handleValueChange={handleFilterChange}
              propertyName="deliverableType"
              tooltipContent="Select deliverable type"
            />
          </div>
        )}
        <div>
          <MBOButton
            name="Create Simulation"
            onClick={handleCreateSimulate}
            symbol={<CaretRight fill="white" />}
            disabled={
              selectedFilters.engagementType.name === "" ||
              (selectedFilters.engagementType.name !== "Payroll Services" &&
                selectedFilters.deliverableType.name === "")
            }
            tooltipContent="Click to create your simulation"
          />
        </div>
      </div>
    </div>
  );
};

export default SimulationSearchScreen;
