import React from "react";

import Button from "../Button/Button";

interface WorkOrderHeaderProps {
  onClick: () => void;
  userAccess: boolean | null;
}

const WorkOrderHeader: React.FC<WorkOrderHeaderProps> = ({
  onClick,
  userAccess,
}) => (
  <div className="workOrder__header">
    <h2 className="workOrder__header__title">
      Work Order Specific Custom Rules
    </h2>

    <Button
      className="btn-workOrder"
      disabled={userAccess !== null && userAccess}
      onClick={onClick}
      name="+"
    />
  </div>
);

export default WorkOrderHeader;
