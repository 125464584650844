import { createContext } from "react";

interface SpinnerContext {
  enableLoader: boolean;
  SetLoader: (c: boolean) => void;
}
const GlobalSpinnerContext = createContext<SpinnerContext>({
  enableLoader: false,
  SetLoader: () => {},
});

export default GlobalSpinnerContext;
