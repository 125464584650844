import "./switchButton.scss";

interface Iprops {
  switchOn: boolean | undefined;
  toggleSwitch: () => void;
  disabled: boolean | null;
}
const SwitchButton = ({ switchOn, toggleSwitch, disabled }: Iprops) => (
  <div
    onClick={disabled !== null && !disabled ? toggleSwitch : () => {}}
    className={`switch toggle ${switchOn ? "toggle-on" : ""}`}
  >
    {switchOn && <div className="toggle-text-on">Enable</div>}
    <div className="glow-comp" />
    <div className="toggle-button" />
    {!switchOn && <div className="toggle-text-off">Disable</div>}
  </div>
);

export default SwitchButton;
