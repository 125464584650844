import { useRef } from "react";

const useHorizontalScroll = () => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollToStart = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const scrollToEnd = () => {
    if (scrollRef.current) {
      const { scrollWidth, clientWidth } = scrollRef.current;

      if (scrollRef.current) {
        scrollRef.current.scrollTo({
          left: scrollWidth - clientWidth,
          behavior: "smooth",
        });
      }
    }
  };

  return {
    scrollRef,
    scrollToStart,
    scrollToEnd,
  };
};

export default useHorizontalScroll;
