const NoDataFound = (props: IconProps) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.59945 10C8.53245 10 10.0994 8.433 10.0994 6.5C10.0994 4.567 8.53245 3 6.59945 3C4.66645 3 3.09945 4.567 3.09945 6.5C3.09945 8.433 4.66645 10 6.59945 10ZM17.5994 10C19.5324 10 21.0994 8.433 21.0994 6.5C21.0994 4.567 19.5324 3 17.5994 3C15.6665 3 14.0994 4.567 14.0994 6.5C14.0994 8.433 15.6665 10 17.5994 10ZM21.0994 17.5C21.0994 19.433 19.5324 21 17.5994 21C15.6665 21 14.0994 19.433 14.0994 17.5C14.0994 15.567 15.6665 14 17.5994 14C19.5324 14 21.0994 15.567 21.0994 17.5ZM6.59945 21C8.53245 21 10.0994 19.433 10.0994 17.5C10.0994 15.567 8.53245 14 6.59945 14C4.66645 14 3.09945 15.567 3.09945 17.5C3.09945 19.433 4.66645 21 6.59945 21Z"
      fill="white"
    />
    <path
      d="M9.59945 6.5C9.59945 8.15685 8.2563 9.5 6.59945 9.5V10.5C8.80859 10.5 10.5994 8.70914 10.5994 6.5H9.59945ZM6.59945 3.5C8.2563 3.5 9.59945 4.84315 9.59945 6.5H10.5994C10.5994 4.29086 8.80859 2.5 6.59945 2.5V3.5ZM3.59945 6.5C3.59945 4.84315 4.94259 3.5 6.59945 3.5V2.5C4.39031 2.5 2.59945 4.29086 2.59945 6.5H3.59945ZM6.59945 9.5C4.94259 9.5 3.59945 8.15685 3.59945 6.5H2.59945C2.59945 8.70914 4.39031 10.5 6.59945 10.5V9.5ZM20.5994 6.5C20.5994 8.15685 19.2563 9.5 17.5994 9.5V10.5C19.8086 10.5 21.5994 8.70914 21.5994 6.5H20.5994ZM17.5994 3.5C19.2563 3.5 20.5994 4.84315 20.5994 6.5H21.5994C21.5994 4.29086 19.8086 2.5 17.5994 2.5V3.5ZM14.5994 6.5C14.5994 4.84315 15.9426 3.5 17.5994 3.5V2.5C15.3903 2.5 13.5994 4.29086 13.5994 6.5H14.5994ZM17.5994 9.5C15.9426 9.5 14.5994 8.15685 14.5994 6.5H13.5994C13.5994 8.70914 15.3903 10.5 17.5994 10.5V9.5ZM17.5994 21.5C19.8086 21.5 21.5994 19.7091 21.5994 17.5H20.5994C20.5994 19.1569 19.2563 20.5 17.5994 20.5V21.5ZM13.5994 17.5C13.5994 19.7091 15.3903 21.5 17.5994 21.5V20.5C15.9426 20.5 14.5994 19.1569 14.5994 17.5H13.5994ZM17.5994 13.5C15.3903 13.5 13.5994 15.2909 13.5994 17.5H14.5994C14.5994 15.8431 15.9426 14.5 17.5994 14.5V13.5ZM21.5994 17.5C21.5994 15.2909 19.8086 13.5 17.5994 13.5V14.5C19.2563 14.5 20.5994 15.8431 20.5994 17.5H21.5994ZM9.59945 17.5C9.59945 19.1569 8.2563 20.5 6.59945 20.5V21.5C8.80859 21.5 10.5994 19.7091 10.5994 17.5H9.59945ZM6.59945 14.5C8.2563 14.5 9.59945 15.8431 9.59945 17.5H10.5994C10.5994 15.2909 8.80859 13.5 6.59945 13.5V14.5ZM3.59945 17.5C3.59945 15.8431 4.94259 14.5 6.59945 14.5V13.5C4.39031 13.5 2.59945 15.2909 2.59945 17.5H3.59945ZM6.59945 20.5C4.94259 20.5 3.59945 19.1569 3.59945 17.5H2.59945C2.59945 19.7091 4.39031 21.5 6.59945 21.5V20.5Z"
      fill="white"
    />
  </svg>
);

export default NoDataFound;
