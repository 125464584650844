import { isNull } from "lodash";
import React from "react";

import Button from "../../../../componentsPhase1/MBOButton/Button";

import classes from "./index.module.scss";

interface iprops {
  secondaryButton: (e: boolean) => void;
  primaryButton: () => void;
  primaryText: string | React.ReactNode;
  secondaryText: string;
  primaryButtonText?: string;
  alertDimentions?: string;
  clientList?: string[];
  showLoader?: boolean;
  checkBoxToAppend?: React.ReactNode;
  enableDisablePrimaryBtn?: boolean | null;
}

const DeleteRule: React.FC<iprops> = ({
  secondaryButton,
  primaryButton,
  primaryText,
  secondaryText,
  primaryButtonText,
  alertDimentions,
  clientList,
  showLoader = false,
  checkBoxToAppend,
  enableDisablePrimaryBtn = null,
}) => {
  return (
    <>
      <div className={classes.blocker}></div>
      <div className={classes.overlay}></div>
      <div className={classes.popup_blur}></div>
      <div
        className={[classes.rules_edit_popup, alertDimentions].join(" ")}
        data-testid="manage-client-rules-confirmation-popup"
      >
        {showLoader && <div className={classes.loader_styling}>Loading...</div>}
        <div
          className={[
            classes.aligning_container,
            clientList || checkBoxToAppend
              ? classes.client_rules_aligning_container
              : "",
          ].join(" ")}
        >
          <div>
            <h1 className={classes.header}>{primaryText}</h1>
            <p className={classes.sub_header}>{secondaryText}</p>
          </div>
          {checkBoxToAppend}
          {clientList && (
            <>
              <div className={classes.align_client_list}>
                {clientList.map((e) => {
                  return (
                    <p className={classes.client_card_styling} key={e}>
                      {e}
                    </p>
                  );
                })}
              </div>
            </>
          )}
          <div className={classes.btn_position} data-testid="buttons-parent">
            <Button
              name="Cancel"
              className={classes.btn_calcel_styling}
              onClick={() => secondaryButton(false)}
              data-testid="cancel"
            />
            <Button
              onClick={() => primaryButton()}
              name={
                primaryButtonText ||
                (clientList && clientList.length > 0
                  ? "Confirm deletion"
                  : "Confirm")
              }
              data-testid="confirm"
              disabled={
                (!isNull(enableDisablePrimaryBtn) &&
                  !enableDisablePrimaryBtn) ||
                false
              }
              className={classes.btn_update_styling}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteRule;
