import React from "react";
import { GoSearch } from "react-icons/go";

import "./Search.scss";

interface SearchProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  className?: string;
  placeholder: string;
  label?: string | React.ReactNode;
  innerRef?: React.RefObject<HTMLInputElement>;
  required?: boolean;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disableComponent?: boolean;
}

const Search: React.FC<SearchProps> = ({
  className,
  label,
  placeholder,
  onChange,
  value,
  innerRef,
  onBlur,
  required,
  onFocus,
  disableComponent,
}) => {
  const classes = typeof label === "string" ? "label" : "icon";

  return (
    <div className={`input ${className || ""}`}>
      {label && (
        <label className={classes} htmlFor="search">
          {label || <GoSearch />}
        </label>
      )}

      <input
        id="search"
        className={`search ${classes === "label" ? classes : ""} ${
          required ? "error_border" : ""
        }`}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        type="text"
        autoComplete="off"
        ref={innerRef}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disableComponent}
      />
    </div>
  );
};

export default Search;
