import React from "react";

export const CaretRight: React.FC<IconProp> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill=""
      d="M8.086 3.293a1 1 0 0 0 0 1.414L15.379 12l-7.293 7.293A1 1 0 1 0 9.5 20.707L18.207 12 9.5 3.293a1 1 0 0 0-1.414 0Z"
    />
  </svg>
);
