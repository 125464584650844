import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

import { optionsProp, selectedItemsProps } from "../../../../@types/component";
import API from "../../../../API";
import PricingCalcApi from "../../../../API/PricingCalcApi";
import useStore from "../../../../store/useStore";

import SimulateHeader from "./Header";
import SendJD from "./SendJD";
import SimulateContent from "./SimulateContent";

import classes from "./simulation.module.scss";

interface SimulateScreenProps {
  selectedFilters: selectedItemsProps;
  selectedClient: optionsProp;
  receivedStandardRules: ManageStandardRules[] | null;
  receivedOptionalRules: ManageOptionalRules[] | null;
  selectedDate: Date | null;
  simulateDisable: boolean;
  updatedOptionalRules?: {
    id: number;
    checked: boolean;
  }[];
  rateFilter: number | null;
  buttonClicked: boolean;
  setSelectedTabIndex: React.Dispatch<React.SetStateAction<number>>;
  selectedTabIndex: number;
  setInput: React.Dispatch<React.SetStateAction<string>>;
  input: string;
  setErrorCode: React.Dispatch<React.SetStateAction<number | null>>;
  errorCode: number | null;
  setWarningMsg: React.Dispatch<React.SetStateAction<boolean>>;
  warningMsg: boolean;
  setJobDiva: React.Dispatch<React.SetStateAction<boolean>>;
  openJobDiva: boolean;
  setResponseData: React.Dispatch<
    React.SetStateAction<CalcAtributes["data"] | null>
  >;
  responseData: CalcAtributes["data"] | null;
  setSowResponseData: React.Dispatch<
    React.SetStateAction<CalcAtributes["data"] | null>
  >;
  sowResponseData: CalcAtributes["data"] | null;
  writeAccess?: boolean;
  setRateFilter: React.Dispatch<React.SetStateAction<number | null>>;
  setRateFilterChange: React.Dispatch<React.SetStateAction<boolean>>;
  rateFilterChange: boolean;
}

const SimulateScreen: React.FC<SimulateScreenProps> = ({
  selectedFilters,
  selectedClient,
  receivedStandardRules,
  receivedOptionalRules,
  selectedDate,
  simulateDisable,
  updatedOptionalRules,
  rateFilter,
  buttonClicked,
  setSelectedTabIndex,
  selectedTabIndex,
  setInput,
  input,
  setErrorCode,
  errorCode,
  setWarningMsg,
  warningMsg,
  setJobDiva,
  openJobDiva,
  setResponseData,
  responseData,
  sowResponseData,
  setSowResponseData,
  writeAccess,
  setRateFilter,
  setRateFilterChange,
  rateFilterChange,
}) => {
  const setLoader = useStore((state) => state.setLoader);
  const [rateToSimulate, setRateToSimulate] = useState<string>(input);
  const [payBillChanges, setPayBillChanged] = useState<boolean>(false);
  const [debouncedText] = useDebounce(rateToSimulate, 800);

  useEffect(() => {
    setInput("");
    // selectedFilters.deliverableType.name.toLowerCase() !== "sow/milestone" &&
    setResponseData(null);
  }, [selectedFilters, selectedDate, rateFilter]);

  const selectTab = (index: number) => {
    setSelectedTabIndex(index);
    setResponseData(null);
    setPayBillChanged(true);
    setInput("");
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRateToSimulate(e.target.value);
  };

  const calculateRates = async (
    ratesForSow: boolean = false,
    isMilestone: boolean = false
  ): Promise<void> => {
    const payload = {
      [(ratesForSow &&
        selectedFilters.deliverableType.name.toLowerCase() ===
          "sow/milestone") ||
      selectedTabIndex === 0
        ? "payRate"
        : "billRate"]:
        ratesForSow &&
        selectedFilters.deliverableType.name.toLowerCase() === "sow/milestone"
          ? "10000"
          : input.toString(),
      isMilestone,
      clientName: selectedClient.label,
      optionalRuleIds:
        updatedOptionalRules && updatedOptionalRules.length > 0
          ? updatedOptionalRules
              .filter((id) => id.checked)
              .map((rule) => Number(rule.id))
          : // ? [6]
            [],
      standardRuleIds: receivedStandardRules
        ? receivedStandardRules.map((rule) => Number(rule.id))
        : //  ? [10]
          [],
      clientId: `${selectedClient.value}`,
    };

    setLoader(true);

    try {
      const request = PricingCalcApi.quoteRateSimulation(
        payload,
        (ratesForSow &&
          selectedFilters.deliverableType.name.toLowerCase() ===
            "sow/milestone") ||
          selectedTabIndex === 0
          ? "calculatePayToBill"
          : "calculateBillToPay"
      );

      const response = await API.performRequest(request);
      const { data, errors } = response.data;

      setErrorCode(null);

      if (errors.length > 0) {
        setLoader(false);
        console.log(errors[0].message);

        return setErrorCode(errors[0].code);
      }

      setLoader(false);

      if (ratesForSow) {
        setSowResponseData(data);
      } else {
        setResponseData(data);
      }
    } catch (error) {
      console.log(error);

      setLoader(false);
    }

    setWarningMsg(false);
  };

  useEffect(() => {
    setPayBillChanged(true);

    if (debouncedText === "") {
      setInput("");
      setRateFilter(null);

      return;
    }

    setInput(debouncedText);

    setRateFilter(Number(debouncedText));
  }, [debouncedText]);
  useEffect(() => {
    if (
      ((receivedStandardRules && receivedStandardRules?.length > 0) ||
        (receivedOptionalRules && receivedOptionalRules?.length > 0)) &&
      selectedFilters.deliverableType.name.toLowerCase() === "sow/milestone" &&
      selectedDate
    ) {
      setResponseData(null);
      setSowResponseData(null);

      calculateRates(true, true);
    }
  }, [updatedOptionalRules, buttonClicked, receivedStandardRules]);

  useEffect(() => {
    !simulateDisable && rateFilter && setInput(rateFilter.toString());
  }, [buttonClicked]);

  useEffect(() => {
    setRateToSimulate(input);

    if (
      !simulateDisable &&
      rateFilter !== null &&
      Number(input) !== rateFilter &&
      selectedTabIndex === 0 &&
      input !== ""
    ) {
      setWarningMsg(true);
    } else {
      setWarningMsg(false);
    }
  }, [input]);

  useEffect(() => {
    if (rateFilterChange) {
      return;
    }

    if (payBillChanges && !rateFilterChange) {
      setRateToSimulate(debouncedText);

      setPayBillChanged(false);
      setRateFilterChange(false);
    }
  }, [payBillChanges, debouncedText]);

  useEffect(() => {
    if (rateFilter || rateFilter === 0) {
      setPayBillChanged(false);
    }
  }, [rateFilter]);

  return (
    <div className={classes.container}>
      {selectedFilters.deliverableType.name.toLowerCase() !==
      "sow/milestone" ? (
        <>
          <SimulateHeader
            simulateDisable={simulateDisable}
            selectedTabIndex={selectedTabIndex}
            selectTab={selectTab}
            sow={
              selectedFilters.deliverableType.name.toLowerCase() ===
              "sow/milestone"
            }
          />
          <SimulateContent
            input={rateToSimulate}
            simulateDisable={simulateDisable}
            handleInputChange={handleInputChange}
            selectedTabIndex={selectedTabIndex}
            sow={
              selectedFilters.deliverableType.name.toLowerCase() ===
              "sow/milestone"
            }
            calculateRates={calculateRates}
            responseData={responseData}
            errorCode={errorCode}
            warningMsg={warningMsg}
            selectedFilters={selectedFilters}
          />
        </>
      ) : (
        <>
          {/* milestone component */}
          <SimulateHeader
            simulateDisable={simulateDisable}
            selectedTabIndex={selectedTabIndex}
            selectTab={selectTab}
            sow={
              selectedFilters.deliverableType.name.toLowerCase() ===
              "sow/milestone"
            }
            tabIndex={true}
          />
          <SimulateContent
            input={rateToSimulate}
            simulateDisable={simulateDisable}
            handleInputChange={handleInputChange}
            selectedTabIndex={selectedTabIndex}
            sow={
              selectedFilters.deliverableType.name.toLowerCase() ===
              "sow/milestone"
            }
            calculateRates={() => calculateRates(true, true)}
            responseData={sowResponseData}
            errorCode={errorCode}
            warningMsg={warningMsg}
            selectedFilters={selectedFilters}
          />

          {/* rates component for sow */}
          <SimulateHeader
            simulateDisable={simulateDisable}
            selectedTabIndex={selectedTabIndex}
            selectTab={selectTab}
            sow={true}
            showPayRateBillRate={true}
          />
          <SimulateContent
            input={rateToSimulate}
            simulateDisable={simulateDisable}
            handleInputChange={handleInputChange}
            selectedTabIndex={selectedTabIndex}
            sow={true}
            calculateRates={() => calculateRates(false, true)}
            responseData={responseData}
            errorCode={errorCode}
            warningMsg={warningMsg}
            selectedFilters={selectedFilters}
            showPayRateBillRate={true}
          />
        </>
      )}

      <SendJD
        responseData={
          selectedFilters.deliverableType.name.toLowerCase() === "sow/milestone"
            ? sowResponseData
            : responseData
        }
        selectedTabIndex={selectedTabIndex}
        selectedDate={selectedDate}
        selectedFilters={selectedFilters}
        setJobDiva={setJobDiva}
        writeAccess={writeAccess}
      />
    </div>
  );
};

export default SimulateScreen;
