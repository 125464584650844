import classes from "./index.module.scss";

interface MboFormInputProps {
  placeholder: string;
  changeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  innerRef?: React.RefObject<HTMLInputElement>;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  disabled?: boolean;
  className?: string;
  showRequiredFieldMessage?: boolean;
  errorMesasgePositionStyle?: string;
  writeAccess?: boolean;
}

const MBOFormInput: React.FC<MboFormInputProps> = ({
  placeholder,
  changeHandler,
  value,
  innerRef,
  type = "text",
  disabled,
  showRequiredFieldMessage,
  className,
  onFocus,
  onBlur,
  errorMesasgePositionStyle,
  writeAccess = true,
}) => {
  return (
    <div className={classes.parent} data-testid="input-container">
      {value && value !== "" && (
        <span className={classes.label_with_data}>{placeholder}</span>
      )}
      <div>
        <input
          ref={innerRef}
          type="text"
          className={[
            value && value !== "" ? classes.input_selected : classes.input,
            className,
            value && value?.trim() !== "" ? classes.selected_fill : "",
          ].join(" ")}
          value={(value && value.toString()) || ""}
          name="Name"
          placeholder={placeholder}
          autoComplete="off"
          onChange={changeHandler}
          disabled={disabled || !writeAccess}
          onFocus={onFocus}
          onBlur={onBlur}
          contentEditable="true"
        />
        <span></span>
      </div>
      {showRequiredFieldMessage && (
        <span
          className={[classes.mandatory, errorMesasgePositionStyle].join(" ")}
        >
          ! Required
        </span>
      )}
    </div>
  );
};

export default MBOFormInput;
