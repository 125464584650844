import CheckBox from "./CheckBox/CheckBox";
import Date from "./Date/Date";
import Number from "./Number/Number";
import Radio from "./Radio/Radio";
import Search from "./Search/Search";
import Select from "./Select/Select";
import TextArea from "./TextArea/TextArea";

export default {
  Radio,
  Search,
  Date,
  CheckBox,
  Select,
  Number,
  TextArea,
};
