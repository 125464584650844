import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { HiExclamationCircle } from "react-icons/hi";
import {
  MdOutlineKeyboardDoubleArrowUp,
  MdRemoveCircleOutline,
} from "react-icons/md";
import { TbTransferIn } from "react-icons/tb";

import { RulesetApiDataProps, stateIprops } from "../../../@types/component";
import API from "../../../API/PricingCalcApiPhaseone";
import AuthorizeUserHOC from "../../../HOC/Authorisation/authorizeuser.component";
import ErrorBoundary from "../../../HOC/ErrorBoundary/errorBoundary.component";
import TakeScreenshot from "../../../HOC/TakeScreenshot/takescreenshot";
import CheckBox from "../../../components/Input/CheckBox/CheckBox";
import Button from "../../../componentsPhase1/MBOButton/Button";
import { UserAccessForFeature } from "../../../constants/componentLevelUserRoles";
import useQueryData from "../../../hooks/useQueryData";
import useStore from "../../../store/useStore";
import ConfirmationPopup from "../../clientRules/manageClientRules/confirmationPopup";
import AddruleSet from "../addRuleset";
import ApplyRulesetToClients from "../applyRulesetToclient";
import RuleFilter from "../ruleSetHeader";

import RuleSetTable from "./ruleSetTable";

import classes from "./index.module.scss";

interface Iprops {
  writeAccess?: boolean;
}

interface RulesetListProps {
  name: string;
  id: number;
  description: string;
  createdOn: string;
  updatedDate?: string;
  createdBy: string;
}

interface UpdatedRulesetProps {
  rulesetName: string;
  rulesetDesc: string;
  id: string;
}

interface ManageRulesetPayload {
  id: number | null;
  name: string;
  description: string;
  type: string;
}

interface clientListProps {
  client: string;
  id: number;
  select: boolean;
  disable: boolean;
}

const RuleSets: React.FC<Iprops> = ({ writeAccess }) => {
  const setLoader = useStore((state) => state.setLoader);
  const [filterText, setFilterText] = useState<string>("");
  const [deleteform, setDeleteForm] = useState<boolean>(false);
  const [applyRulesetForm, setAplyRulesetForm] = useState<boolean>(false);
  const [removeRulesetForm, setRemoveRulesetForm] = useState<boolean>(false);
  const updateDeletePositon = useRef<HTMLDivElement>(null);
  const [clientList, setClientList] = useState<clientListProps[]>([]);
  const [appliedClientids, setAppliedClientIds] = useState<number[]>([]);
  const [clickThreeDotsType, setClickThreeDotsType] = useState<string>("");

  const [appliedClientidsToRemove, setAppliedClientIdsRemove] = useState<
    clientListProps[]
  >([]);

  const [rulesetInfoMessage, setRulesetInfoMessage] = useState<string>("");

  const [enableDeleteForClient, setEnableDeleteForClient] =
    useState<boolean>(false);

  const [rulesetCreationState, setRulesetCreationState] =
    useState<UpdatedRulesetProps>({
      rulesetName: "",
      rulesetDesc: "",
      id: "",
    });

  const [rulesetList, setRulesetList] = useState<RulesetListProps[] | null>(
    null
  );

  const [ruleSetFilter, setRulesetFilter] = useState<{
    createdBy: string;
    createdOn: Date | null;
    sortBy: string;
  }>({ createdBy: "", createdOn: null, sortBy: "" });

  const [showCreateRulesetPopup, setCreateRulesetPopup] =
    useState<boolean>(false);

  const [createRulesetPayload, setCreateRulesetPayload] =
    useState<ManageRulesetPayload>({
      id: null,
      name: "",
      description: "",
      type: "GET",
    });

  const generateQueryString = () => {
    const { sortBy } = ruleSetFilter;
    const { createdBy } = ruleSetFilter;
    const { createdOn } = ruleSetFilter;

    if (
      ruleSetFilter.createdBy !== "" &&
      !ruleSetFilter.createdOn &&
      ruleSetFilter.sortBy === ""
    ) {
      return `?filter=performedBy in (${
        ruleSetFilter.createdBy.includes("@mbopartners.com")
          ? ruleSetFilter.createdBy
          : ruleSetFilter.createdBy + "@mbopartners.com"
      })`;
    }

    if (
      ruleSetFilter.createdBy === "" &&
      ruleSetFilter.createdOn &&
      ruleSetFilter.sortBy === ""
    ) {
      return `?filter=createdDate in (${moment(createdOn).format(
        "YYYY-MM-DD"
      )})`;
    }

    if (
      ruleSetFilter.createdBy === "" &&
      !ruleSetFilter.createdOn &&
      ruleSetFilter.sortBy !== ""
    ) {
      return `?sort=performedBy&sortOrder=${sortBy}`;
    }

    if (
      ruleSetFilter.createdBy !== "" &&
      ruleSetFilter.createdOn &&
      ruleSetFilter.sortBy === ""
    ) {
      return `?filter=performedBy in (${
        createdBy.includes("@mbopartners.com")
          ? createdBy
          : createdBy + "@mbopartners.com"
      })&filter=createdDate in (${moment(createdOn).format("YYYY-MM-DD")})`;
    }

    if (
      ruleSetFilter.createdBy !== "" &&
      ruleSetFilter.sortBy !== "" &&
      !ruleSetFilter.createdOn
    ) {
      return `?filter=performedBy in (${
        createdBy.includes("@mbopartners.com")
          ? createdBy
          : createdBy + "@mbopartners.com"
      })&sort=performedBy&sortOrder=${sortBy}`;
    }

    if (
      ruleSetFilter.createdOn &&
      ruleSetFilter.sortBy !== "" &&
      ruleSetFilter.createdBy === ""
    ) {
      return `?filter=createdDate in (${moment(createdOn).format(
        "YYYY-MM-DD"
      )})&sort=performedBy&sortOrder=${sortBy}`;
    }

    if (
      ruleSetFilter.createdOn &&
      ruleSetFilter.sortBy !== "" &&
      ruleSetFilter.createdBy !== ""
    ) {
      return `?filter =performedBy in (${
        createdBy.includes("@mbopartners.com")
          ? createdBy
          : `${createdBy}@mbopartners.com`
      }@mbopartners.com) &filter=createdDate in
  (${moment(createdOn).format(
    "YYYY-MM-DD"
  )})&sort=performedBy&sortOrder=${sortBy}&?sort= performedBy&sortOrder=${sortBy}`;
    }

    return "";
  };

  const clientListQury = useQueryData(
    "ruleset-client-list",
    {},
    false,
    API.getClientList
  );

  const clientListForRulesetApplied = useQueryData(
    "ruleset-applied-client-list",
    { Id: rulesetCreationState.id },
    false,
    API.getClientListForRuleId
  );

  const prepareData = () => {
    if (!applyRulesetForm && !removeRulesetForm) {
      return [];
    }

    if (
      removeRulesetForm &&
      appliedClientidsToRemove &&
      appliedClientidsToRemove.length > 0
    ) {
      return appliedClientidsToRemove

        ?.filter((e) => !e.select && !e.disable)
        ?.map((e) => Number(e.id));
    }

    return clientList
      .filter((e) => e.select && !e.disable)
      .map((e) => Number(e.id));
  };

  const deleteOrapplyRulesetToClient = useQueryData(
    "set-ruleset-to-client-in-rulesetForm",
    {
      selectedRulesetId: rulesetCreationState.id,
      clientId:
        !applyRulesetForm && !removeRulesetForm
          ? clientList
              .filter((e) => e.select && !e.disable)
              .map((e) => Number(e.id))
          : prepareData(),

      type: removeRulesetForm ? "DELETE" : "PUT",
    },
    false,
    API.setClientRulesForRuleset
  );

  const getAllRulesetList = useQueryData(
    [
      createRulesetPayload.type === "GET"
        ? "get-allruleset-list"
        : "update-ruleset",
    ],
    {
      ...createRulesetPayload,
      querystring: generateQueryString(),
    },
    false,
    API.getAllRuleSets
  );

  // getClientListForRuleId

  const getCLickPosition = (rule: stateIprops | null, type: string) => {
    setRulesetInfoMessage("");
    setClickThreeDotsType(rule?.uniqueid || "");
    setRulesetCreationState({
      rulesetName: rule?.name?.toString() || "",
      rulesetDesc: rule?.description?.toString() || "",
      id: rule?.id?.toString() || "",
    });
  };

  useEffect(() => {
    if (getAllRulesetList.error) {
      console.log(getAllRulesetList.error);
    }

    if (
      getAllRulesetList.data &&
      getAllRulesetList.isFetched &&
      !getAllRulesetList.isLoading
    ) {
      if (["PUT", "DELETE"].includes(createRulesetPayload.type)) {
        setDeleteForm(false);
        setCreateRulesetPayload({
          id: null,
          type: "GET",
          name: "",
          description: "",
        });
        getAllRulesetList.remove();

        return;
      }

      setLoader(false);
      // setRulesetList
      const listOfRulesets: RulesetListProps[] =
        getAllRulesetList?.data?.data?.ruleSet?.map(
          (e: RulesetApiDataProps) => {
            return {
              name: e.name,
              id: e.id,
              description: e.description,
              createdOn: e.createdDate.value?.split(" ")?.join(" | "),
              updatedDate: e.updatedDate.value?.split(" ")?.join(" | "),
              createdBy: e.performedBy,
            };
          }
        );

      setRulesetList(listOfRulesets);
      getAllRulesetList.remove();
    }
  }, [
    getAllRulesetList.isLoading,
    getAllRulesetList.error,
    getAllRulesetList.data,
  ]);

  useEffect(() => {
    if (clientListQury.error) {
      console.log(clientListQury.error);
    }

    if (
      clientListQury.data &&
      clientListQury.isFetched &&
      !clientListQury.isLoading
    ) {
      const clientData = clientListQury?.data?.data?.clients
        ?.map((e: { jobdivaCompanyName: string; id: number }) => {
          return {
            select: false,
            client: e.jobdivaCompanyName,
            id: e.id,
            disable: false,
          };
        })
        .sort(
          (
            a: { client: string; id: number },
            b: { client: string; id: number }
          ) => a.client.localeCompare(b.client)
        );

      setClientList(clientData);
      clientListQury.remove();
    }
  }, [clientListQury.isLoading, clientListQury.error, clientListQury.data]);

  useEffect(() => {
    if (clientListForRulesetApplied.error) {
      console.log(clientListForRulesetApplied.error);
    }

    if (
      clientListForRulesetApplied.data &&
      clientListForRulesetApplied.isFetched &&
      !clientListForRulesetApplied.isLoading
    ) {
      if (removeRulesetForm) {
        const clientData =
          clientListForRulesetApplied?.data?.data?.ruleSet[0]?.clients
            ?.map((e: { name: string; id: number }) => {
              return {
                id: e.id,
                select: true,
                disable: false,
                client: e.name,
              };
            })
            .sort(
              (
                a: { client: string; id: number },
                b: { client: string; id: number }
              ) => a.client.localeCompare(b.client)
            );

        setAppliedClientIdsRemove(clientData || []);

        clientListForRulesetApplied.remove();

        return;
      }

      const clientData =
        clientListForRulesetApplied?.data?.data?.ruleSet[0]?.clients?.map(
          (e: { name: string; id: number }) => {
            return e.id;
          }
        );

      setAppliedClientIds(clientData || []);
      clientListForRulesetApplied.remove();
    }
  }, [
    clientListForRulesetApplied.isLoading,
    clientListForRulesetApplied.error,
    clientListForRulesetApplied.data,
  ]);

  useEffect(() => {
    if (deleteOrapplyRulesetToClient.error) {
      console.log(deleteOrapplyRulesetToClient.error);
    }

    if (
      deleteOrapplyRulesetToClient &&
      deleteOrapplyRulesetToClient.data &&
      "errors" in deleteOrapplyRulesetToClient.data &&
      deleteOrapplyRulesetToClient.data.errors.length > 0 &&
      deleteOrapplyRulesetToClient.data.errors[0].code !== 70100
    ) {
      setRulesetInfoMessage("error");

      return;
    }

    if (
      deleteOrapplyRulesetToClient.data &&
      deleteOrapplyRulesetToClient.isFetched &&
      !deleteOrapplyRulesetToClient.isLoading
    ) {
      setRulesetInfoMessage("success");
      setAplyRulesetForm(false);
      setRemoveRulesetForm(false);
      deleteOrapplyRulesetToClient.remove();
    }
  }, [
    deleteOrapplyRulesetToClient.isLoading,
    deleteOrapplyRulesetToClient.error,
    deleteOrapplyRulesetToClient.data,
  ]);

  useEffect(() => {
    getAllRulesetList.refetch();
    clientListQury.refetch();
  }, []);

  useEffect(() => {
    if (appliedClientids && appliedClientids.length > 0) {
      setClientList((prevstate) =>
        prevstate
          .map((e) => ({
            ...e,
            select: appliedClientids.includes(e.id),
            disable: appliedClientids.includes(e.id),
          }))
          .sort((a, b) => Number(b.select) - Number(a.select))
      );
    }
  }, [appliedClientids]);

  useEffect(() => {
    if (!applyRulesetForm) {
      setClientList((prevstate) =>
        prevstate
          .map((e) => ({
            ...e,
            select: false,
            disable: false,
          }))
          .sort(
            (
              a: { client: string; id: number },
              b: { client: string; id: number }
            ) => a.client.localeCompare(b.client)
          )
      );
    }
  }, [applyRulesetForm]);

  useEffect(() => {
    getAllRulesetList.refetch();
  }, [ruleSetFilter]);

  useEffect(() => {
    if (appliedClientids && appliedClientids.length > 0) {
      setClientList((prevstate) =>
        prevstate
          .map((e) => ({
            ...e,
            select: appliedClientids.includes(e.id),
            disable: appliedClientids.includes(e.id),
          }))
          .sort((a, b) => Number(b.select) - Number(a.select))
      );
    }
  }, [appliedClientids]);

  useEffect(() => {
    if (!applyRulesetForm) {
      setClientList((prevstate) =>
        prevstate
          .map((e) => ({
            ...e,
            select: false,
            disable: false,
          }))
          .sort(
            (
              a: { client: string; id: number },
              b: { client: string; id: number }
            ) => a.client.localeCompare(b.client)
          )
      );
    }
  }, [applyRulesetForm]);

  useEffect(() => {
    getAllRulesetList.refetch();
  }, [createRulesetPayload]);

  setLoader(
    getAllRulesetList.isLoading ||
      clientListForRulesetApplied.isLoading ||
      deleteOrapplyRulesetToClient.isLoading
  );

  return (
    <div className={classes.ruleset_table}>
      <RuleFilter
        setRulesetFilter={setRulesetFilter}
        ruleSetFilter={ruleSetFilter}
        setFilterText={setFilterText}
        filterText={filterText}
      />

      <div
        className={[
          classes.btn_create_parent,
          rulesetInfoMessage === "" ? classes.align_button_to_end : "",
        ].join(" ")}
      >
        {rulesetInfoMessage !== "" ? (
          <p className={classes.ruleset_client_udpate}>
            <HiExclamationCircle
              fill={rulesetInfoMessage === "success" ? "green" : "red"}
              fontSize={20}
            />
            {rulesetInfoMessage === "success"
              ? "Ruleset updated successfully"
              : "Something went wrong, please try again later"}
          </p>
        ) : null}
        <Button
          name={"Create new set"}
          className={[
            classes.btn_create,
            !writeAccess ? classes.disable_btn : "",
          ].join(" ")}
          onClick={() => {
            if (!writeAccess) {
              return;
            }

            setRulesetInfoMessage("");
            setCreateRulesetPopup(true);
            setRulesetCreationState({
              rulesetName: "",
              rulesetDesc: "",
              id: "",
            });
          }}
        />
      </div>
      <RuleSetTable
        writeAccess={writeAccess || false}
        clickThreeDotsType={clickThreeDotsType}
        getCLickPosition={getCLickPosition}
        ruleSetData={
          rulesetList?.filter((e) =>
            e?.name?.toLowerCase()?.includes(filterText?.toLowerCase())
          ) || null
        }
        updateDeletePositon={updateDeletePositon}
      >
        <div
          className={[
            classes.edit_popover_styling,
            classes.edit_popover_styling_helper,
            !writeAccess ? classes.disable_options : "",
          ].join(" ")}
          onClick={() => {
            if (!writeAccess) {
              return;
            }

            setCreateRulesetPopup(true);
            document.getElementById(`${clickThreeDotsType}button`)?.click();
          }}
        >
          <MdOutlineKeyboardDoubleArrowUp />
          <p>Update</p>
        </div>
        <div
          className={[
            classes.edit_popover_styling,
            classes.edit_popover_styling_helper,
            !writeAccess ? classes.disable_options : "",
          ].join(" ")}
          onClick={() => {
            if (!writeAccess) {
              return;
            }

            setDeleteForm(true);
            document.getElementById(`${clickThreeDotsType}button`)?.click();
          }}
        >
          <AiOutlineDelete />
          Delete
        </div>
        <div
          className={[
            classes.edit_popover_styling,
            classes.edit_popover_styling_helper,
            !writeAccess ? classes.disable_options : "",
          ].join(" ")}
          onClick={(e) => {
            if (!writeAccess) {
              return;
            }

            document.getElementById(`${clickThreeDotsType}button`)?.click();
            setAplyRulesetForm(true);
            setRulesetInfoMessage("");
          }}
        >
          <TbTransferIn />
          Apply set
        </div>
        <div
          className={[
            classes.edit_popover_styling,
            classes.edit_popover_styling_helper,
            !writeAccess ? classes.disable_options : "",
          ].join(" ")}
          onClick={() => {
            if (!writeAccess) {
              return;
            }

            document.getElementById(`${clickThreeDotsType}button`)?.click();
            setRemoveRulesetForm(true);
            setRulesetInfoMessage("");
          }}
        >
          <MdRemoveCircleOutline />
          Remove set
        </div>
      </RuleSetTable>

      {/* popups */}
      {deleteform && (
        <ConfirmationPopup
          secondaryButton={setDeleteForm}
          primaryButton={() => {
            setCreateRulesetPayload({
              id: Number(rulesetCreationState.id) || null,
              name: "",
              description: "",
              type: "DELETE",
            });
            setEnableDeleteForClient(false);
          }}
          checkBoxToAppend={
            <CheckBox
              isChecked={enableDeleteForClient}
              onChange={() => {
                setEnableDeleteForClient(!enableDeleteForClient);
              }}
              value=""
              label={"Rules associated with the ruleset will be deleted"}
              className={classes.checkBox_styling}
            />
          }
          enableDisablePrimaryBtn={enableDeleteForClient}
          primaryText="This ruleset will be deleted"
          secondaryText="Are you sure you want to delete this ruleset?"
        />
      )}
      {(applyRulesetForm || removeRulesetForm) && (
        <ApplyRulesetToClients
          ruleSetData={applyRulesetForm ? clientList : []}
          appliedRulesetIds={appliedClientids}
          appliedClientidsToRemove={appliedClientidsToRemove}
          setAppliedClientIdsRemove={setAppliedClientIdsRemove}
          setClientList={setClientList}
          clientListForRulesetApplied={clientListForRulesetApplied}
          setAppliedClientIds={setAppliedClientIds}
          setAplyRulesetForm={setAplyRulesetForm}
          applyRulesetToClient={deleteOrapplyRulesetToClient}
          setRemoveRulesetForm={setRemoveRulesetForm}
          isRemoveSetOpened={removeRulesetForm}
          rulesetCreationStateid={Number(rulesetCreationState.id)}
          rulesetCreationStateName={rulesetCreationState.rulesetName}
          isUpdatePopUpopened={false}
        />
      )}

      {showCreateRulesetPopup && (
        <AddruleSet
          setCreateRulesetPopup={setCreateRulesetPopup}
          showCreateRulesetPopup={showCreateRulesetPopup}
          setCreateRulesetPayload={setCreateRulesetPayload}
          existingRulesetDetails={rulesetCreationState}
          existingRulesetDetailsState={setRulesetCreationState}
          clientList={clientList}
          setClientList={setClientList}
          applyRulesetToClient={deleteOrapplyRulesetToClient}
          clientListForRulesetApplied={clientListForRulesetApplied}
          parentsetRulesetCreationState={setRulesetCreationState}
          appliedClientids={appliedClientids}
          setAppliedClientIds={setAppliedClientIds}
        />
      )}
    </div>
  );
};

const userRoles = UserAccessForFeature.CLIENT_RULES_AND_RULESET.MANAGE_RULES;
const AuthorizeUser = AuthorizeUserHOC(RuleSets, userRoles);

export default ErrorBoundary(TakeScreenshot(AuthorizeUser));
