import React from "react";

import IconsName from "../../constants/IconsName";

import MboLogo from "./Icons/MBOLogo";
import NoDataFound from "./Icons/NoData";
import AddnewRecord from "./Icons/addnewIcon";
import CancelIcon from "./Icons/cancelIcon";
import InfoIcon from "./Icons/infoIcon";
import MessageIcon from "./Icons/messageicon";

interface IconProps
  extends React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  > {
  name: string;
  className?: string;
  width?: string | number;
  height?: string | number;
}

const Icon: React.FC<IconProps> = ({
  className,
  height = 16,
  name,
  width = 20,
  ...rest
}) => {
  switch (name) {
    case IconsName.LOGO:
      return (
        <MboLogo
          className={className}
          height={height}
          width={width}
          {...rest}
        />
      );

    case IconsName.CANCELICON:
      return (
        <CancelIcon
          className={className}
          height={height}
          width={width}
          {...rest}
        />
      );

    case IconsName.INFOICON:
      return (
        <InfoIcon
          className={className}
          height={height}
          width={width}
          {...rest}
        />
      );

    case IconsName.NODATAFOUND:
      return (
        <NoDataFound
          className={className}
          height={height}
          width={width}
          {...rest}
        />
      );

    case IconsName.ADDNEWRECORD:
      return (
        <AddnewRecord
          className={className}
          height={height}
          width={width}
          {...rest}
        />
      );

    case IconsName.MESSAGEICON:
      return (
        <MessageIcon
          className={className}
          height={height}
          width={width}
          {...rest}
        />
      );

    default:
      return <span>No Icon Found</span>;
  }
};

export default Icon;
