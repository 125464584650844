import React, { useEffect, useState } from "react";
import { UseQueryResult } from "react-query";
import { Location, useNavigate } from "react-router-dom";

import {
  QuadDataTable,
  jobDivaidList,
  optionsProp,
} from "../../@types/component";
import ErrorBoundary from "../../HOC/ErrorBoundary/errorBoundary.component";
import BackIcon from "../Icon/Icons/backIcon";
import Button from "../MBOButton/Button";
import Footer from "../SelectAssignment/Footer";
import ClientDetails from "../clientdetails";

import RadioCard from "./RadioCard";

import classes from "./selectStartID.module.scss";

interface SelectStartIDProps {
  title: string;
  data: {
    id: string | number;
    value: string | number;
    startId: string | number;
    client: { id: string; name: string };
    month: string;
    day: string | number;
    year: string | number;
    selected: boolean;
  }[];
  onBack: () => void;
  onContinue: (value: string | number) => void;
  setJobDivaId?: (id: string) => void;
  children?: React.ReactNode;
  elableContinue: boolean;
  deliverableType?: string;
  setJobDivalist?: (e: jobDivaidList[] | null) => void;
  currentpath?: string;
  setSelectedClient?: (e: optionsProp) => void;
  setCurrentPath?: (e: string) => void;
  candidateWorkorderIdList?: UseQueryResult;
  routeState?: Location["state"];
  pricingCalcDetail?: UseQueryResult;
  jobIdavDetails?: UseQueryResult;
  jobDivaId?: string;
  setQuadTableData?: (e: QuadDataTable[] | null) => void;
  quadTableDataCall?: UseQueryResult;
  jobDivaRecordError?: { code: number; message: string } | null;
  showErrorMessageForSendToJobDiva?: boolean;
  candidateDetails?: {
    firstName: string;
    lastName: string;
    engagementService: string;
  };
}

const SelectStartID: React.FC<SelectStartIDProps> = ({
  data,
  title,
  onBack,
  onContinue,
  setJobDivaId,
  children,
  elableContinue = false,
  deliverableType,
  setJobDivalist,
  currentpath,
  setSelectedClient,
  setCurrentPath,
  candidateWorkorderIdList,
  routeState,
  pricingCalcDetail,
  jobIdavDetails,
  jobDivaId,
  setQuadTableData,
  quadTableDataCall,
  jobDivaRecordError,
  showErrorMessageForSendToJobDiva,
  candidateDetails,
}) => {
  const history = useNavigate();
  const [selectedValue, setSelectedValue] = useState<string | number>("");

  useEffect(() => {
    if (selectedValue) {
      setJobDivaId && setJobDivaId(selectedValue.toString());
    }
  }, [selectedValue]);

  useEffect(() => {
    setSelectedValue(data.filter((e) => e.selected)[0]?.value || "");
  }, []);

  const clearOverAllState = () => {
    setJobDivalist && setJobDivalist(null);
    setJobDivaId && setJobDivaId("");
    setSelectedClient &&
      setSelectedClient({
        value: 0,
        label: "",
        clientId: 0,
      });

    setCurrentPath && setCurrentPath(window.location.pathname);
    candidateWorkorderIdList?.remove();
    pricingCalcDetail?.remove();
    jobIdavDetails?.remove();

    setQuadTableData && setQuadTableData(null);
    quadTableDataCall && quadTableDataCall.remove();
  };

  useEffect(() => {
    if (currentpath && currentpath !== window.location.pathname) {
      setCurrentPath && setCurrentPath(window.location.pathname);

      if (
        currentpath &&
        currentpath !== window.location.pathname &&
        currentpath !== "/quad/" &&
        currentpath !== "/"
      ) {
        clearOverAllState();
      }
    }

    return () => {
      if (
        Boolean(
          currentpath !== window.location.pathname &&
            window.location.pathname !== "/quad/" &&
            window.location.pathname !== "/"
        ) ||
        jobDivaId === ""
      ) {
        clearOverAllState();
        setCurrentPath && setCurrentPath(window.location.pathname);
      }
    };
  }, [window.location.pathname]);

  useEffect(() => {
    if (routeState && "clearWorkorder" in routeState) {
      clearOverAllState();
      history("/", { state: {} });
    }
  }, [routeState]);

  const cDetails = {
    candidate: {
      firstName: candidateDetails?.firstName || "",
      lastName: candidateDetails?.lastName || "",
      engagementType: candidateDetails?.engagementService || "",
    },
    job: {
      location: "",
      client: {
        id: "",
        name: "",
      },
      startDate: {
        localized: "",
        value: "",
        culture: "",
        raw: 0,
      },
      endDate: {
        localized: "",
        value: "",
        culture: "",
        raw: 0,
      },
    },
    payInfo: [],
    billInfo: [],
    assignments: [],
  };

  return (
    <div className={classes.container}>
      <div className={classes.container12}>
        <div className={classes.container13}>
          <h2>{title}</h2>
          <div className={classes.container11}>
            {currentpath === "/quad/" && (
              <ClientDetails
                jobDivaDetails={cDetails}
                jobId={""}
                jobTitle={""}
                type="workorder"
                removeScroll={() => false}
                noMargin
              />
            )}
            <div
              className={[
                classes.flex,
                classes.itemsStart,
                classes.flexWrap,
                classes.gap1,
                classes.w2,
                deliverableType === "hourly/milestone"
                  ? classes.hourly_rates_height
                  : "",
                deliverableType === "sow/milestone"
                  ? classes.sow_rates_height
                  : "",
              ].join(" ")}
            >
              {data.map((d, i) => (
                <RadioCard
                  key={`${d.id}${i}`}
                  {...d}
                  checked={d.selected}
                  onSelect={(value) => setSelectedValue(value)}
                />
              ))}
            </div>
          </div>
          {children}
          {currentpath !== "/quad/" && (
            <div
              className={[
                classes.btn_position,
                deliverableType ? classes.btn_position_padding : "",
                showErrorMessageForSendToJobDiva &&
                jobDivaRecordError &&
                jobDivaRecordError.code === 70107
                  ? classes.add_space_for_errormessage
                  : "",
              ].join(" ")}
            >
              {showErrorMessageForSendToJobDiva &&
              jobDivaRecordError &&
              jobDivaRecordError.code === 70107 ? (
                <p className={classes.multiple_unapproved_records}>
                  {jobDivaRecordError.message}
                </p>
              ) : (
                ""
              )}
              <div
                className={[
                  classes.btn_action_position,
                  !showErrorMessageForSendToJobDiva
                    ? classes.buttons_position_withouterror
                    : "",
                ].join(" ")}
              >
                <Button
                  name="Back"
                  className={classes.btn_calcel_styling}
                  icon={<BackIcon width={16} height={16} />}
                  onClick={onBack}
                  data-testid="back-button"
                />
                <Button
                  name="Continue"
                  className={classes.btn_update_styling}
                  onClick={() => onContinue(selectedValue)}
                  disabled={!elableContinue}
                  data-testid="continue-button"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {currentpath === "/quad/" && (
        <Footer
          disableContinue={!selectedValue}
          onBack={onBack}
          onContinue={() => onContinue(selectedValue)}
        />
      )}
    </div>
  );
};

export default ErrorBoundary(SelectStartID);
