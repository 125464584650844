import { useRef } from "react";
import { GoSearch } from "react-icons/go";

import useAutosizeTextArea from "./useAutozizeTextArea";

import "./TextArea.scss";

interface TextAreaProps {
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  className?: string;
  placeholder: string;
  label?: string | React.ReactNode;
  innerRef?: React.RefObject<HTMLTextAreaElement>;
  required?: boolean;
  onFocus?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disableComponent?: boolean;
}

const TextArea: React.FC<TextAreaProps> = ({
  className,
  label,
  placeholder,
  onChange,
  value,
  innerRef,
  onBlur,
  required,
  onFocus,
  disableComponent,
}) => {
  const classes = typeof label === "string" ? "label" : "icon";
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(textAreaRef.current, value);

  return (
    <div className={`textAreaInput ${className || ""}`}>
      {label && (
        <label className={classes} htmlFor="textArea">
          {label || <GoSearch />}
        </label>
      )}
      <textarea
        id="textArea"
        className={`textArea ${classes === "label" ? classes : ""} ${
          required ? "error_border" : ""
        }`}
        ref={textAreaRef}
        rows={1}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        autoComplete="off"
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disableComponent}
      />
    </div>
  );
};

export default TextArea;
