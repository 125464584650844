import { useEffect, useState } from "react";
import { BsFilter } from "react-icons/bs";
import { FaCity, FaPlaceOfWorship } from "react-icons/fa";
import { MdOutlineClear, MdOutlinePlace } from "react-icons/md";
import { RiAddFill } from "react-icons/ri";

import Button from "../../Button/Button";
import Input from "../../Input";
import {
  getLocationProps,
  optionsProp,
  selectedfilterProps,
} from "../quoteRates";

interface Iprops {
  filteredData: {
    city: optionsProp[];
    country: optionsProp[];
    mainDivision: optionsProp[];
  };
  engagementTypeData: optionsProp[] | undefined;
  selectedFilterState: selectedfilterProps;
  className: string;
  FilterstandardRules: (e: boolean) => void;
  changeFilterData: (value: Date | optionsProp | unknown, type: string) => void;

  setOptionalRuleClicked: (bool: string) => void;
  locationData: getLocationProps | null;
}

const StandardRulesFilters = ({
  filteredData,
  className,
  selectedFilterState,
  FilterstandardRules,
  changeFilterData,
  engagementTypeData,
  setOptionalRuleClicked,
  locationData,
}: Iprops) => {
  const [enableOptionalFilters, setOptionalFilters] = useState({
    country: true,
    city: true,
    mainDivision: true,
  });

  const requiredCountry =
    (locationData &&
      locationData.clients &&
      locationData.clients.length > 0 &&
      locationData.clients[0].engagementTypes.find(
        (item) => item.name === selectedFilterState.engagementType?.label
      )?.countries) ||
    null;

  const requiredMainDivision =
    (requiredCountry &&
      requiredCountry.find(
        (item) => item.name === selectedFilterState.country?.label
      )?.states) ||
    null;

  const requiredCity =
    requiredMainDivision &&
    requiredMainDivision.find(
      (item) => item.name === selectedFilterState.mainDivision?.label
    )?.cities;

  useEffect(() => {
    ["country", "state", "city"].forEach((e) => {
      document
        .getElementsByClassName(e)[0]
        .addEventListener("click", (element) => {
          const ele = element.currentTarget as Element;

          const placeHolderElement = ele.querySelectorAll(
            ".select__placeholder"
          );

          const dropdownOptionName =
            (placeHolderElement &&
              placeHolderElement.length > 0 &&
              placeHolderElement[0].innerHTML) ||
            "";

          if (ele && dropdownOptionName) {
            setOptionalRuleClicked(dropdownOptionName);
          }
        });
    });
  }, []);

  return (
    <div className={className}>
      <div className={`${className}__filters`}>
        <Input.Date
          placeholderText="Effective Date*"
          value={
            selectedFilterState && selectedFilterState.effectiveDate
              ? selectedFilterState.effectiveDate
              : null
          }
          className="calcDatePicker"
          onChange={(e) => changeFilterData(e, "effectiveDate")}
        />
        <Input.Select
          placeholder="Engagement Type*"
          onChange={(e) => changeFilterData(e, "engagementType")}
          options={(engagementTypeData && engagementTypeData) || []}
          value={
            selectedFilterState &&
            selectedFilterState.engagementType &&
            selectedFilterState.engagementType.label !== ""
              ? selectedFilterState.engagementType
              : null
          }
        />
        {enableOptionalFilters.country && (
          <Input.Select
            placeholder="Country"
            className="country"
            onChange={(e) => changeFilterData(e, "country")}
            options={
              (requiredCountry &&
                requiredCountry
                  .map((item, index) => {
                    return { label: item.name, value: index };
                  })
                  .sort((a, b) => a.label.localeCompare(b.label))) ||
              null
            }
            value={
              selectedFilterState &&
              selectedFilterState.country &&
              selectedFilterState.country.label !== ""
                ? selectedFilterState.country
                : null
            }
            allowCustomSearch
          />
        )}
        {enableOptionalFilters.mainDivision && (
          <Input.Select
            className="state"
            placeholder="State"
            onChange={(e) => changeFilterData(e, "mainDivision")}
            options={
              (requiredMainDivision &&
                requiredMainDivision
                  .map((item, index) => {
                    return { label: item.name, value: index };
                  })
                  .sort((a, b) => a.label.localeCompare(b.label))) ||
              null
            }
            value={
              selectedFilterState &&
              selectedFilterState.mainDivision &&
              selectedFilterState.mainDivision.label !== ""
                ? selectedFilterState.mainDivision
                : null
            }
            allowCustomSearch
          />
        )}
        {enableOptionalFilters.city && (
          <Input.Select
            className="city"
            placeholder="City"
            onChange={(e) => changeFilterData(e, "city")}
            options={
              (requiredCity &&
                requiredCity
                  .map((item, index) => {
                    return { label: item.name, value: index };
                  })
                  .sort((a, b) => a.label.localeCompare(b.label))) ||
              null
            }
            value={
              selectedFilterState &&
              selectedFilterState.city &&
              selectedFilterState.city.label !== ""
                ? selectedFilterState.city
                : null
            }
            allowCustomSearch
          />
        )}
        <Input.Number
          placeholder={`$-Pay Rate${
            locationData &&
            locationData.clients &&
            locationData.clients[0].engagementTypes.find(
              (item) => item.name === selectedFilterState.engagementType?.label
            )?.isRatePresent === true
              ? " *"
              : ""
          }`}
          className="rateFilter"
          onChange={(e) => changeFilterData(e.target.value, "rate")}
          value={
            selectedFilterState &&
            selectedFilterState.rate &&
            selectedFilterState.rate !== ""
              ? selectedFilterState.rate
              : ""
          }
        />
      </div>

      <Button
        name="Clear"
        className="btn-filter_clear"
        onClick={() => FilterstandardRules(true)}
      />
      <Button
        name="Apply"
        className="btn-filter_apply"
        onClick={() => FilterstandardRules(false)}
        disabled={
          !selectedFilterState.effectiveDate ||
          !selectedFilterState.engagementType ||
          (selectedFilterState &&
            selectedFilterState.engagementType &&
            selectedFilterState.engagementType.label === "") ||
          ((
            locationData &&
            locationData.clients &&
            locationData.clients[0].engagementTypes.find(
              (item) => item.name === selectedFilterState.engagementType?.label
            )
          )?.isRatePresent === true &&
            selectedFilterState.rate === undefined)
        }
      />
      <div className="optional_filter_parent">
        <BsFilter />
        <span>Filters</span>
        <div className="filters_popover">
          <div
            onClick={() => {
              setOptionalFilters({
                ...enableOptionalFilters,
                country: !enableOptionalFilters.country,
              });

              if (enableOptionalFilters.country) {
                changeFilterData(null, "country");
              }
            }}
          >
            <div>
              <MdOutlinePlace />
              <span>Country</span>
            </div>

            {enableOptionalFilters.country ? <MdOutlineClear /> : <RiAddFill />}
          </div>
          <div
            onClick={() => {
              setOptionalFilters({
                ...enableOptionalFilters,
                mainDivision: !enableOptionalFilters.mainDivision,
              });

              if (enableOptionalFilters.mainDivision) {
                changeFilterData(null, "mainDivision");
              }
            }}
          >
            <div>
              <FaCity />
              <span>State</span>
            </div>

            {enableOptionalFilters.mainDivision ? (
              <MdOutlineClear />
            ) : (
              <RiAddFill />
            )}
          </div>
          <div
            onClick={() => {
              setOptionalFilters({
                ...enableOptionalFilters,
                city: !enableOptionalFilters.city,
              });

              if (enableOptionalFilters.city) {
                changeFilterData(null, "city");
              }
            }}
          >
            <div>
              <FaPlaceOfWorship />
              <span>City</span>
            </div>
            {enableOptionalFilters.city ? <MdOutlineClear /> : <RiAddFill />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandardRulesFilters;
