import React from "react";

import { selectedItemsProps } from "../../../../../@types/component";
import { CaretRight } from "../../../../../componentsPhase1/Icon/Icons/CaretRight";
import MBOButton from "../../../../../componentsPhase1/MBOButton";

interface Iprops {
  responseData: CalcAtributes["data"] | null;
  selectedTabIndex: number;
  selectedDate: Date | null;
  selectedFilters: selectedItemsProps;
  setJobDiva: React.Dispatch<React.SetStateAction<boolean>>;
  writeAccess?: boolean;
}

const SendJD: React.FC<Iprops> = ({
  responseData,
  selectedFilters,
  setJobDiva,
  writeAccess,
}) => {
  return (
    <div style={{ alignSelf: "flex-end" }}>
      <MBOButton
        name="Send to jobdiva"
        onClick={() => setJobDiva(true)}
        symbol={<CaretRight fill="white" />}
        disabled={
          !writeAccess ||
          !responseData ||
          (selectedFilters.deliverableType.name.toLowerCase() !==
            "hourly/milestone" &&
            selectedFilters.deliverableType.name.toLowerCase() !==
              "sow/milestone")
        }
      />
    </div>
  );
};

export default SendJD;
