import moment from "moment";
import React, { useEffect, useState } from "react";

import { stateIprops } from "../../../../../@types/component";
import MBOTable from "../../../../../componentsPhase1/MBOTable";
import classes from "../standardRules/index.module.scss";

interface RuleProp {
  rule: {
    data: {
      optionalRules: ManageOptionalRules[] | null;
    };
  };
  getOptionalRules: (
    updatedOR: {
      id: number;
      checked: boolean;
    }[]
  ) => void;
  writeAccess?: boolean;
}

const OptionalRules: React.FC<RuleProp> = ({
  rule,
  getOptionalRules,
  writeAccess,
}) => {
  const [formattedRules, setFormattedRules] = useState<stateIprops[] | null>(
    null
  );

  const { optionalRules } = rule.data;

  const validateAmount = (rule: ManageOptionalRules) => {
    if (rule.amount.localized === "percentage") {
      return `${Number(rule.amount.value.replace("%", "")).toFixed(5)}%`;
    }

    return rule.amount.raw && rule.amount.raw !== ""
      ? `$${Number(rule.amount.raw) / 100}`
      : "";
  };

  useEffect(() => {
    if (optionalRules && optionalRules.length > 0) {
      setFormattedRules(
        optionalRules.map((rule) => {
          return {
            id: Number(rule?.id),
            clientId: Number(rule?.client?.id),
            name: rule.name,
            operation: {
              label: rule.operation.name,
              value: Number(rule.operation.id),
            },
            amount: validateAmount(rule),
            engagementType: rule.engagementType || "",
            effectiveDate:
              (rule.effectiveDate &&
                rule.effectiveDate.raw &&
                moment(rule.effectiveDate.value, "MMM DD YYYY")) ||
              null,
            performedBy: rule.performedBy,
            description: rule.description,
            expireDate:
              (rule.expireDate &&
                rule.expireDate.raw &&
                moment(rule.expireDate.value, "MMM DD YYYY")) ||
              null,
            attribution: {
              label: rule.attribution.name,
              value: Number(rule.attribution.id),
            },
            group: {
              label: rule.group.name,
              value: Number(rule.group.id),
            },
            vendor: {
              label: rule.vendor?.vendorName,
              value: Number(rule.vendor?.id),
            },
            createdDate:
              rule.createdDate && rule.createdDate.raw
                ? moment(rule.createdDate.raw).local()
                : null,
            updatedDate:
              rule.updatedDate && rule.updatedDate.raw
                ? moment(rule.updatedDate.raw).local()
                : null,
          };
        })
      );

      return () => {
        setFormattedRules(null);
      };
    }
  }, [optionalRules]);

  return (
    <MBOTable
      rule={formattedRules || []}
      headerName="Optional Rules"
      showAddNewButton={false}
      typeOfRule={"optional"}
      isEditRequired={false}
      appendStyle={classes.table_positioning}
      isToggleRequired={true}
      getOptionalRules={getOptionalRules}
      numberOfColumnsToShow={4}
      writeAccess={writeAccess}
    />
  );
};

export default OptionalRules;
