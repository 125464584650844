import moment from "moment";
import React, { useMemo } from "react";
import { Column } from "react-table";

import Table from "../../TableComponent/parentTableComponent";

interface StandardRulesIProps {
  businessStandardRules: QratesManageStandardRules[] | null | undefined;
}

interface TableDataProps {
  [key: string]: string | number | React.ReactNode | null;
}
interface TableColIProps {
  [key: string]: string | number | React.ReactNode;
}

const StandardRules = ({ businessStandardRules }: StandardRulesIProps) => {
  const data: TableDataProps[] = [];

  let StandardRulesHeaders: Column<TableColIProps>[] | [] = [];

  (() => {
    const validateAmount = (rule: QratesManageStandardRules) => {
      const price = Number(rule.amount.raw);
      const negativeSign = Math.sign(price) === -1 ? "-" : "";
      const rate: number = Math.abs(price);

      if (rule.amount.localized === "percentage") {
        return rule.amount.value;
      }

      return rule.amount.raw && rule.amount.raw !== ""
        ? `${negativeSign}$${Number(rate)}`
        : "";
    };

    if (businessStandardRules) {
      businessStandardRules.forEach((rule) => {
        data.push({
          id: rule.id || "",
          clientName: rule.client.name,
          name: rule.name,
          description: rule.description,
          effectiveDate: rule.effectiveDate
            ? moment(rule.effectiveDate.raw).format("MMM DD, YYYY")
            : "",
          expireDate: rule.expireDate
            ? moment(rule.expireDate.raw).local().format("MMM DD, YYYY")
            : "",
          performedBy: rule.performedBy,
          country: rule.country,
          mainDivision: rule.mainDivision,
          city: rule.city,
          attributionId: rule.attribution.id,
          attributionName: rule.attribution.name,
          groupId: rule.group.id,
          groupName: rule.group.name,
          operationId: rule.operation.id,
          operationName: rule.operation.name,
          amount: validateAmount(rule),
          s4VendorId: (rule.vendor && rule.vendor.id) || null,
          s4VendorName: (rule.vendor && rule.vendor.vendorName) || null,
          createdDate: rule.createdDate
            ? moment(rule.createdDate.raw).local().format("MMM DD, YYYY")
            : "",
          updatedDate: rule.updatedDate
            ? moment(rule.updatedDate.raw).local().format("MMM DD, YYYY")
            : "",

          rateLow: rule.rateLow,
          rateHigh: rule.rateHigh,
          engagementType: rule.engagementType,
        });
      });
    }
  })();

  StandardRulesHeaders =
    data && data.length > 0
      ? useMemo(
          () =>
            [...Object.keys(data[0])]
              .filter(
                (rule) =>
                  ![
                    "id",
                    "clientId",
                    "clientName",
                    "s4VendorId",
                    "operationId",
                    "groupId",
                    "attributionId",
                    "isChecked",
                    "currency",
                  ].includes(rule)
              )
              .map((e, i) => ({
                Header:
                  e === "mainDivision"
                    ? "State"
                    : e
                        .replace(/([A-Z])/g, " $1")
                        .replace(/^./, (str) => str.toUpperCase()),
                accessor: e,
                width: [
                  "name",
                  "description",
                  "attributionName",
                  "groupName",
                  "operationName",
                  "performedBy",
                  "engagementType",
                ].includes(e)
                  ? (e === "performedBy" && 350) || 250
                  : 150,
                sticky: ((index) => {
                  if (index === 0) {
                    return "left";
                  }

                  if (index > Object.keys(data[0]).length - 8) {
                    return "right";
                  }

                  return "";
                })(i),
              })),

          []
        )
      : [];

  return <Table data={data} columns={StandardRulesHeaders} />;
};

export default React.memo(StandardRules);
