import React from "react";

import ValidateUser from "../../auth/validateUser";
import Button from "../Button/Button";

import PCTable from "./components/PCTable";

import "./PricingCalc.scss";

interface PricingCalcProps {
  addButtonHandler: () => void;
  editButtonHandler: (e: PricingCalcSettingsObj) => void;
  data: PricingCalcSettingsObjData | null;
  userAccess: boolean | null;
  saveDataToRouteState: (
    searchItem: number,
    jobDivaDetailsData: ProjectDetailsAPIResponse,
    calcSettings: CalcSettings
  ) => void;
  saveEditedCalcSettingsId: string;
  setAddingNew: (e: boolean) => void;
  setIsEdit: (e: boolean) => void;
  SetEditedCalcSettingsId: (e: string) => void;
}

const PricingCalc: React.FC<PricingCalcProps> = ({
  data,
  addButtonHandler,
  editButtonHandler,
  userAccess,
  saveDataToRouteState,
  saveEditedCalcSettingsId,
  setAddingNew,
  setIsEdit,
  SetEditedCalcSettingsId,
}) => {
  const readOnlyAccess = () => {
    const validUser = ValidateUser() as UserGroups[] | [];

    return (
      validUser !== null &&
      validUser[0].readonly.length === 0 &&
      +validUser[0].accessLevel < 1
    );
  };

  return (
    <div className="pricingCalc">
      <PCTable
        data={data}
        onClick={editButtonHandler}
        userAccess={userAccess}
        saveDataToRouteState={saveDataToRouteState}
        saveEditedCalcSettingsId={saveEditedCalcSettingsId}
        setAddingNew={setAddingNew}
        setIsEdit={setIsEdit}
        SetEditedCalcSettingsId={SetEditedCalcSettingsId}
      />
      {data && data.startIdDetails && data.startIdDetails.length === 0 && (
        <p className="pricingCalc__emptyState">
          No Calculations? Add a new one!
        </p>
      )}

      <Button
        className="btn-add_new"
        name="Add New"
        disabled={readOnlyAccess()}
        onClick={addButtonHandler}
      />
    </div>
  );
};

export default PricingCalc;
