import JSPDF from "jspdf";
import autoTable, { CellInput } from "jspdf-autotable";
import moment from "moment";
import React, { useMemo } from "react";
import { FaPrint } from "react-icons/fa";
import ReactModal from "react-modal";
import { Column } from "react-table";

import businessStandardRulesModel from "../../models/businessRulesModels/businessStandardRules";
import { validateRate } from "../../pages/Details/details.logichandler";
import SectionConatiner from "../SectionConatiner";

import StaticTable from "./BusinessRulesStaticTable";
import StickyTable from "./BusinessRulesStickyTable";

import "./businessRulesComponent.scss";

interface TableDataProps {
  [key: string]: string | number | null;
}
const customStyles = {
  content: {
    top: "48%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    height: "86vh",
    boxShadow: "0 2px 13px rgb(62 48 116)",
    zIndex: 5,
    padding: "0",
    borderRadius: "5px",
    border: "none",
  },
};

interface Iprops {
  toggleRulesModel: (open: boolean) => void;
  openRules: boolean;
  appliedRules: calcSettingsDetailsData | null;
  clientList: ClientListObj[] | [];
  startId: string;
  effectiveDate: Date | null;
  clientDetails: ProjectDetailsAPIResponseData | null;
  standardRuleErrorMessage: string | undefined;
  standardRuleErrorComponent: React.ReactNode | null;
}

const RulesTable = ({
  toggleRulesModel,
  openRules,
  appliedRules,
  clientList,
  startId,
  effectiveDate,
  clientDetails,
  standardRuleErrorMessage,
  standardRuleErrorComponent,
}: Iprops) => {
  const doc = new JSPDF("l", "pt");
  const businessStandardRulesData: TableDataProps[] = [];

  let standardRulesHeaders: Column<TableDataProps>[] | [] = [];
  let OptionalRulesHeaders: Column<TableDataProps>[] | [] = [];
  let WorkOrderRulesHeader: Column<TableDataProps>[] | [] = [];

  const clientInfo = clientDetails
    ? Array(clientDetails).map((client) => ({
        candidateName: `${client.candidate.firstName} ${client.candidate.lastName}`,
        client: client.job.client.name,
        payRate: `$${
          Number(validateRate(effectiveDate, client.payInfo, "payrate")) /
            100 || "N/A"
        }`.replace("$N/A", "N/A"),
        billRate: `$${
          Number(validateRate(effectiveDate, client.billInfo, "billrate")) /
            100 || "N/A"
        }`.replace("$N/A", "N/A"),
        engagementType: client.candidate.engagementType,
        location: client.job.location,
        startDate: moment(client.job.startDate.raw)
          .local()
          .format("YYYY-MM-DD"),
        endDate:
          (client.payInfo &&
            client.payInfo.length > 0 &&
            client.payInfo[0].endDate &&
            moment(client.payInfo[0].endDate.raw)
              .local()
              .format("YYYY-MM-DD")) ||
          "",
      }))
    : [];

  const clientInfoHeaders =
    clientInfo && clientInfo.length > 0
      ? useMemo(
          () =>
            Object.keys(clientInfo[0]).map((e, i) => ({
              Header: e
                .replace(/([A-Z])/g, " $1")
                .replace(/^./, (str) => str.toUpperCase()),
              accessor: e,
              width: (() => {
                if (e === "payRate" || e === "billRate") {
                  return 100;
                }

                if (e === "client") {
                  return 250;
                }

                return 161;
              })(),
            })),

          []
        )
      : [];

  const workOrderCustomRules: TableDataProps[] =
    appliedRules?.workOrderSpecificCustomAppliedRules &&
    appliedRules?.workOrderSpecificCustomAppliedRules.length > 0
      ? appliedRules?.workOrderSpecificCustomAppliedRules.map((rule) => ({
          description: rule.description,
          attribution: rule.attribution.name,
          group: rule.group.name,
          operation: rule.operation.name,
          amount:
            rule.amount.localized === "percentage"
              ? `${rule.amount.raw}%`
              : `$${rule.amount.raw / 100}`,
          supplier: rule.vendor.name,
        }))
      : [];

  const customOptionalRules: TableDataProps[] =
    appliedRules?.customerSpecificCustomAppliedRules &&
    appliedRules.customerSpecificCustomAppliedRules.length > 0
      ? appliedRules.customerSpecificCustomAppliedRules.map((e) => ({
          name: e.name,
          description: e.description,
          operation: e.operation.name,
          group: e.group.name,
          attribution: e.attribution.name,
          amount:
            e.amount.localized === "percentage"
              ? `${e.amount.raw}%`
              : `$${e.amount.raw / 100}`,
        }))
      : [];

  const generateRulesData = (
    rules: StandardRules[] | OptionalRules[],
    arraytoPush: TableDataProps[],
    BusinessRulesModel: OptionalRules | StandardRules,
    isOptionalRule: boolean
  ) => {
    const Arraykeys = Object.keys(BusinessRulesModel);

    rules.forEach((rule) => {
      if (isOptionalRule) {
        const existingRule =
          "id" in rule &&
          appliedRules?.customerSpecificCustomAppliedRules.findIndex(
            (customrule) => customrule.id === rule.id
          );

        if (existingRule && existingRule < 0) {
          return;
        }
      }

      const sumWithInitial = Object.keys(rule).reduce(
        (accumulator, currentValue: string) => {
          if (Arraykeys.includes(currentValue)) {
            const data = rule;

            return Object.assign(accumulator, {
              [currentValue]: data[currentValue as keyof typeof rule],
            });
          }

          return accumulator;
        },
        {}
      );

      const rulesdata = {
        ...sumWithInitial,
        effectiveDate: moment(rule.effectiveDate.raw).format("MMM DD, YYYY"),
        expireDate: rule.expireDate
          ? moment(rule.expireDate.raw).local().format("MMM DD, YYYY")
          : "",
        amount:
          rule.amount.localized === "percentage"
            ? `${rule.amount.raw}%`
            : `$${rule.amount.raw / 100}`,
      };

      if (!isOptionalRule) {
        const group = {
          group: rule.group.name,
          attribution: rule.attribution.name,
          operation: rule.operation.name,
        };

        arraytoPush.push({ ...rulesdata, ...group });

        return;
      }

      arraytoPush.push(rulesdata);
    });
  };

  const addRulesTablesToPdf = (
    rules: TableDataProps[],
    header: string,
    align: CellInput = "center"
  ) => {
    const alignment: CellInput[] = [
      align === "left"
        ? {
            content: header,
            styles: { halign: "left" },
          }
        : {
            content: header,
            styles: { halign: "center" },
          },
    ];

    autoTable(doc, {
      margin: { top: 10, bottom: 10 },
      styles: { fontSize: 14, textColor: [33, 21, 81], fillColor: "white" },
      body: [[...alignment]],
    });

    if (
      (!rules || (rules && rules.length < 1)) &&
      standardRuleErrorComponent &&
      header === "Applied Standard Rules"
    ) {
      autoTable(doc, {
        margin: { top: 10, bottom: 10 },

        body: [
          [
            {
              content: standardRuleErrorMessage,
              styles: {
                halign: "center",
                fillColor: [62, 48, 116],
                textColor: "white",
                fontSize: 12,
              },
            },
          ],
        ],
      });

      return;
    }

    if (rules && rules.length > 0) {
      autoTable(doc, {
        theme: "grid",
        horizontalPageBreak: true,
        headStyles: {
          cellWidth: "wrap",
          minCellWidth: 12,
          minCellHeight: 12,
          fillColor: [33, 21, 81],
        },
        bodyStyles: { cellWidth: "wrap", minCellWidth: 12, minCellHeight: 12 },
        body: rules,
        columns: Object.keys(rules[0]).map((e) => ({
          header: e
            .replace(/([A-Z])/g, " $1")
            .replace(/^./, (str) => str.toUpperCase()),
          dataKey: e,
        })),
      });
    }
  };

  (() => {
    if (appliedRules) {
      const standardRules = appliedRules.standardAppliedRules || [];

      generateRulesData(
        standardRules,
        businessStandardRulesData,
        businessStandardRulesModel,
        false
      );

      OptionalRulesHeaders =
        customOptionalRules && customOptionalRules.length > 0
          ? useMemo(
              () =>
                Object.keys(customOptionalRules[0]).map((e) => ({
                  Header: e
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, (str) => str.toUpperCase()),
                  accessor: e,
                })),

              []
            )
          : [];

      standardRulesHeaders =
        businessStandardRulesData && businessStandardRulesData.length > 0
          ? useMemo(
              () =>
                Object.keys(businessStandardRulesData[0]).map((e, i) => ({
                  Header: e
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, (str) => str.toUpperCase()),
                  accessor: e,
                  sticky: i < 2 ? "left" : "",
                  width: 160,
                })),

              []
            )
          : [];
      WorkOrderRulesHeader =
        workOrderCustomRules && workOrderCustomRules.length > 0
          ? useMemo(
              () =>
                Object.keys(workOrderCustomRules[0]).map((e) => ({
                  Header: e
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, (str) => str.toUpperCase()),
                  accessor: e,
                })),

              []
            )
          : [];
    }

    if (clientInfo && clientInfo.length > 0) {
      addRulesTablesToPdf(clientInfo, "Client Details");
    }

    if (!standardRuleErrorComponent) {
      addRulesTablesToPdf(
        [],
        "Note : Applied Standard Rules are based on the above Job Diva PayRate ",
        "left"
      );
    }

    if (
      (businessStandardRulesData && businessStandardRulesData.length > 0) ||
      standardRuleErrorComponent
    ) {
      addRulesTablesToPdf(businessStandardRulesData, "Applied Standard Rules");
    }

    if (customOptionalRules && customOptionalRules.length > 0) {
      addRulesTablesToPdf(customOptionalRules, "Applied Optional Rules");
    }

    if (workOrderCustomRules && workOrderCustomRules.length > 0) {
      addRulesTablesToPdf(
        workOrderCustomRules,
        "Applied Work Order Specific Custom Rules"
      );
    }
  })();

  const downloadToPdf = () => {
    doc.save(
      `Rules-${clientList[0].jobdivaCompanyName}-${startId}-${moment(
        effectiveDate
      )
        .local()
        .format("MMDDYYYY")}.pdf`
    );
  };

  return (
    <ReactModal
      style={customStyles}
      isOpen={openRules}
      contentLabel="Minimal Modal Example"
      ariaHideApp={false}
    >
      <div className="businessModal">
        <header>
          <h3>Client Business Rules</h3>
          <div>
            <FaPrint onClick={downloadToPdf} />
            <button type="button" onClick={() => toggleRulesModel(false)}>
              x
            </button>
          </div>
        </header>
        <div className="businessModal__bodyParent">
          {clientInfo && clientInfo.length > 0 && (
            <SectionConatiner title="Client Details">
              <section
                data-selector="standardrules"
                className="businessModal__bodyParent__section"
              >
                <StaticTable data={clientInfo} columns={clientInfoHeaders} />
              </section>
            </SectionConatiner>
          )}
          {!standardRuleErrorComponent && (
            <p className="businessModal__bodyParent__payRateClientNote">
              Note: Applied Standard Rules are based on the above Job Diva
              PayRate
            </p>
          )}
          {((businessStandardRulesData &&
            businessStandardRulesData.length > 0) ||
            standardRuleErrorComponent) && (
            <SectionConatiner title="Applied Standard Rules">
              <section
                data-selector="standardrules"
                className="businessModal__bodyParent__section"
              >
                {(standardRuleErrorComponent && standardRuleErrorComponent) || (
                  <StickyTable
                    data={businessStandardRulesData}
                    columns={standardRulesHeaders}
                  />
                )}
              </section>
            </SectionConatiner>
          )}
          {customOptionalRules && customOptionalRules.length > 0 && (
            <SectionConatiner title="Applied Optional Rules">
              <section
                data-selector="optionalrules"
                className="businessModal__bodyParent__section"
              >
                <StaticTable
                  data={customOptionalRules}
                  columns={OptionalRulesHeaders}
                />
              </section>
            </SectionConatiner>
          )}
          {workOrderCustomRules && workOrderCustomRules.length > 0 && (
            <SectionConatiner title="Applied Work Order Specific Custom Rules">
              <section
                data-selector="workorder"
                className="businessModal__bodyParent__section"
              >
                <StaticTable
                  data={workOrderCustomRules}
                  columns={WorkOrderRulesHeader}
                />
              </section>
            </SectionConatiner>
          )}
        </div>
      </div>
    </ReactModal>
  );
};

export default RulesTable;
