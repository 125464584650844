import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";

import { signOut } from "../../auth/oktaConfig";
import ValidateUser from "../../auth/validateUser";
import IconsName from "../../constants/IconsName";
import UserLogger from "../../helpers/UserLogging";
import Icon from "../Icon";

import "./Header.scss";

const Header = () => {
  const history = useNavigate();
  const { pathname } = useLocation();
  const { authState, oktaAuth } = useOktaAuth();
  const name = authState?.idToken?.claims.name;
  const [logedOut, setLogoutState] = useState(false);

  if (!authState?.isAuthenticated) {
    return null;
  }

  const authorizedUser = ValidateUser() as UserGroups[] | [];

  const isUserAuthorized =
    authorizedUser &&
    authorizedUser.length > 0 &&
    authorizedUser[0].accessLevel > -1;

  const logoutUser = async () => {
    setLogoutState(true);

    try {
      await UserLogger("Logged-out");
      signOut();
    } catch (e) {
      console.log("something went wrong ");
    }
  };

  const navigateToHomePage = () => {
    if (isUserAuthorized) {
      history("/phase1");
    }
  };

  useEffect(() => {
    if (authState.isAuthenticated) {
      if (!logedOut) {
        oktaAuth.tokenManager.start();

        return;
      }

      oktaAuth.tokenManager.stop();
    }
  }, [authState, oktaAuth, logedOut]);

  return (
    <div className="header">
      <div className="header__container">
        <div className="header__container__items">
          <div
            onClick={() => navigateToHomePage()}
            className="header__container__items__icon"
          >
            <Icon
              width={100}
              height={35}
              className="logo"
              name={IconsName.LOGO}
            />
            <span>Pricing Calculator</span>
          </div>
          <div className="header__container__items__navParent">
            {isUserAuthorized && (
              <>
                <NavLink
                  to="/phase1"
                  className={`header__container__items__navParent__navlink ${
                    pathname === "/phase1"
                      ? "header__container__items__navParent__active"
                      : ""
                  }`}
                >
                  Workorder Rules
                </NavLink>
                {authorizedUser[0].user === "pricing_lead" && (
                  <>
                    <hr />
                    <NavLink
                      to="/phase1/managerules"
                      className={`header__container__items__navParent__navlink ${
                        pathname === "/phase1/managerules"
                          ? "header__container__items__navParent__active"
                          : ""
                      }`}
                    >
                      Client Rules
                    </NavLink>
                  </>
                )}
                <hr />
                <NavLink
                  to="/phase1/qrates"
                  className={`header__container__items__navParent__navlink ${
                    pathname === "/phase1/qrates"
                      ? "header__container__items__navParent__active"
                      : ""
                  }`}
                >
                  Simulation
                </NavLink>
              </>
            )}
          </div>
          <ul className="links">
            <li className="link">
              Hello, <span className="name">{name}</span>
            </li>
            <li
              onClick={() => {
                void logoutUser();
              }}
              className="link"
            >
              Log out
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
