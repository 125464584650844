import { create } from "zustand";

import {
  QuadDataTable,
  jobDivaidList,
  optionsProp,
  stateIprops,
} from "../@types/component";

interface ToastProps {
  enableLoader: boolean;
  setLoader: (value: boolean) => void;
  enablePopup: boolean;
  setPopup: (value: boolean) => void;
  setSelectedJobDiva: (id: string) => void;
  jobDivaId: string;
  setSelectedClient: (e: optionsProp) => void;
  selectedClient: optionsProp;
  jobDivaList: jobDivaidList[] | null;
  setJobDivalist: (list: jobDivaidList[] | null) => void;
  ruleToEdit: stateIprops | null;
  setRuleToEdit: (list: stateIprops | null) => void;
  pricingCalcDetails: CalcSettings | null;
  setPricingCalcDetails: (e: CalcSettings | null) => void;
  jobDivaDetails: ProjectDetailsAPIResponse | null;
  setJobDivaDetails: (e: ProjectDetailsAPIResponse | null) => void;
  quadTableData: QuadDataTable[] | null;
  setQuadTableData: (e: QuadDataTable[] | null) => void;
  setCurrentPath: (e: string) => void;
  currentPath: string;
  setClearManageRulesState: (e: boolean) => void;
  clearManageRulesState: boolean;
  clearQratesSate: boolean;
  setClearQratesState: (e: boolean) => void;
  clearQuadSate: boolean;
  setClearQuadState: (e: boolean) => void;
}

const initialClientList = {
  value: 0,
  label: "",
  clientId: 0,
};

const useStore = create<ToastProps>((set) => ({
  enableLoader: false,
  setLoader: (value: boolean) => {
    set({ enableLoader: value });
  },
  enablePopup: false,
  setPopup: (value: boolean) => {
    set({ enablePopup: value });
  },
  jobDivaId: "",
  setSelectedJobDiva: (id: string) => {
    set({ jobDivaId: id });
  },
  selectedClient: initialClientList,
  setSelectedClient: (client: optionsProp) => {
    set({ selectedClient: { ...client } });
  },
  jobDivaList: null,
  setJobDivalist: (list: jobDivaidList[] | null) => {
    set({ jobDivaList: list });
  },
  ruleToEdit: null,
  setRuleToEdit: (list: stateIprops | null) => {
    set({ ruleToEdit: list });
  },
  pricingCalcDetails: null,
  setPricingCalcDetails: (e: CalcSettings | null) => {
    set({ pricingCalcDetails: e });
  },
  jobDivaDetails: null,
  setJobDivaDetails: (e: ProjectDetailsAPIResponse | null) => {
    set({ jobDivaDetails: e });
  },

  quadTableData: null,
  setQuadTableData: (e: QuadDataTable[] | null) => {
    set({ quadTableData: e });
  },
  setCurrentPath: (e: string) => {
    set({ currentPath: e });
  },
  currentPath: "",
  clearManageRulesState: false,
  setClearManageRulesState: (e: boolean) => {
    set({ clearManageRulesState: e });
  },
  clearQratesSate: false,
  setClearQratesState: (e: boolean) => {
    set({ clearQratesSate: e });
  },
  clearQuadSate: false,
  setClearQuadState: (e: boolean) => {
    set({ clearQuadSate: e });
  },
}));

export default useStore;
