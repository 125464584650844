import React from "react";

import "./Button.scss";

interface ButtonProps {
  name: string;
  onClick: () => void;
  className?: `btn-${string}`;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  name,
  onClick,
  className,
  disabled = false,
}) => (
  <button
    disabled={disabled}
    type="button"
    className={`btn ${className || ""} ${disabled ? "disabled" : ""}`}
    onClick={onClick}
  >
    {name}
  </button>
);

export default Button;
