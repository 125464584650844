import { CustomUserClaim } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import _ from "lodash";
import React, { ComponentType } from "react";
import { Navigate } from "react-router-dom";

import { UserRoles } from "../../@types/component";

export const ValidateUser = (
  allowedRoles: UserRoles[],
  loggedInUserRoles: CustomUserClaim | CustomUserClaim[] | undefined
) => {
  if (
    loggedInUserRoles &&
    _.isArray(loggedInUserRoles) &&
    loggedInUserRoles.length > 0 &&
    allowedRoles &&
    allowedRoles.length > 0
  ) {
    const filteredUsers: UserRoles[] = allowedRoles.filter((gUser) => {
      const { role } = gUser;

      return loggedInUserRoles.includes(role);
    });

    if (filteredUsers && filteredUsers.length > 1) {
      return _.orderBy(filteredUsers, ["access"], ["desc"])[0];
    }

    return filteredUsers.length > 0 ? filteredUsers[0] : "";
  }

  return "";
};

const withAuthorization = <P extends object>(
  WrappedComponent: ComponentType<P>,
  allowedRoles: UserRoles[]
) => {
  const WithAuthorization: React.FC<P> = (myProp) => {
    const { authState } = useOktaAuth();

    const loggedInUserRoles: CustomUserClaim | CustomUserClaim[] | undefined =
      authState?.accessToken?.claims.groups;

    const userRoleToValidate: UserRoles | string = ValidateUser(
      allowedRoles,
      loggedInUserRoles
    );

    if (
      typeof userRoleToValidate === "object" &&
      userRoleToValidate &&
      userRoleToValidate.access !== "NA"
    ) {
      return (
        <WrappedComponent
          {...myProp}
          userRole={userRoleToValidate}
          writeAccess={userRoleToValidate.access === "RW"}
          showQuadApproveBtn={userRoleToValidate.showQuadApproveBtn}
          showQuadSubmitBtn={userRoleToValidate.showQuadSubmitBtn}
        />
      );
    }

    return (
      <Navigate
        to={`${
          window.location.href.includes("/phase1") ? "/phase1" : ""
        }/unauthorized`}
      />
    );
  };

  return WithAuthorization;
};

export default withAuthorization;
