import { HTTP } from ".";

const BASE_URL = () => {
  if (window.location.host === process.env.REACT_APP_STAGING_HOST) {
    return process.env.REACT_APP_STAGING_API_BASEURL; // replace url from ENV pre-prod
  }

  if (window.location.host === process.env.REACT_APP_PROD_HOST) {
    return process.env.REACT_APP_PROD_API_BASEURL; // replace url from ENV prod
  }

  if (window.location.host === process.env.REACT_APP_DEV_HOST) {
    return process.env.REACT_APP_DEV_API_BASEURL; // replace url from ENV prod
  }

  if (window.location.host === process.env.REACT_APP_QA_HOST) {
    return process.env.REACT_APP_QA_API_BASEURL; // replace url from ENV prod
  }

  return process.env.REACT_APP_LOCAL_API_BASEURL;
};

const baseURL = BASE_URL() || "";

export default {
  logUserActivity(data: UserLogActivity, mboId: Mboid) {
    const payLoad = { ...data };

    return {
      url: `${baseURL}/pricingCalc/users/${mboId.mboid}/activity`,
      options: {
        data: payLoad, // request body
        method: HTTP.POST, // pass this only if it is not a GET request
        header: {}, // if additional header certain request required then pass it here.
      },
    };
  },
  getProjectDetails({ id }: getTodosProps) {
    return {
      url: `${BASE_URL() || ""}/pricingCalc/projectDetails/${id}`,
      options: {
        // this is an options field
        data: {}, // request body
        method: HTTP.GET, // pass this only if it is not a GET request
        header: {}, // if additional header certain request required then pass it here.
      },
      contentType: "application/vnd.pricing-exp-api.v2+json",
    };
  },
  getSuppliers() {
    return {
      url: `${baseURL}/pricingCalc/suppliers`,
      options: {
        // this is an options field
        data: {}, // request body
        method: HTTP.GET, // pass this only if it is not a GET request
        header: {}, // if additional header certain request required then pass it here.
      },
    };
  },
  getDrpOptions(isAvailableCustomRule: string, engagementType?: string) {
    return {
      url: `${baseURL}/pricingCalc/dropDownOptions?sortOrder=ASC&sort=GROUP_ORDER${
        isAvailableCustomRule && isAvailableCustomRule !== ""
          ? `&filter=${isAvailableCustomRule}`
          : ""
      }${
        engagementType && engagementType !== ""
          ? `&filter=engagementType in (${engagementType})`
          : ""
      }`,
      options: {
        // this is an options field
        data: {}, // request body
        method: HTTP.GET, // pass this only if it is not a GET request
        header: {}, // if additional header certain request required then pass it here.
      },
    };
  },
  getCustSpecificRules({ clientd, effectiveDate }: getDrpProps) {
    return {
      url: `${baseURL}/pricingCalc/customerSpecificRules/${clientd}/${effectiveDate}`,
      options: {
        // this is an options field
        data: {}, // request body
        method: HTTP.GET, // pass this only if it is not a GET request
        header: {}, // if additional header certain request required then pass it here.
      },
    };
  },
  getCalcSettings({ id }: getCalcSettingsProps) {
    return {
      url: `${baseURL}/pricingCalc/calcSettings/${id}`,
      options: {
        // this is an options field
        data: {}, // request body
        method: HTTP.GET, // pass this only if it is not a GET request
        header: {}, // if additional header certain request required then pass it here.
      },
    };
  },
  getCalcAttributes({
    settingsId,
    payRate,
    type,
    isMilestone,
  }: attributionPayloadTYpe) {
    return {
      url: `${baseURL}/pricingCalc/${type}`,
      options: {
        // this is an options field
        data: {
          settingsId,
          [type === "calculatePayToBill" ? "payRate" : "billRate"]: payRate,
          isMilestone,
        }, // request body
        method: HTTP.POST, // pass this only if it is not a GET request
        header: {}, // if additional header certain request required then pass it here.
      },
    };
  },
  getCalcSetingsDetails(settingsId: number) {
    return {
      url: `${baseURL}/pricingCalc/calcSettingDetails/${settingsId}`,
      options: {
        // this is an options field
        data: {}, // request body
        method: HTTP.GET, // pass this only if it is not a GET request
        header: {}, // if additional header certain request required then pass it here.
      },
    };
  },
  SaveCalcSettings(data: SaveCalcSettingsD) {
    return {
      url: `${baseURL}/pricingCalc/saveCalcSettings`,
      options: {
        // this is an options field
        data, // request body
        method: HTTP.PUT, // pass this only if it is not a GET request
        header: {}, // if additional header certain request required then pass it here.
      },
    };
  },
  getBusinessRules({
    clientId,
    params,
  }: {
    clientId: number;
    params?: string;
  }) {
    return {
      url: `${baseURL}/pricingCalc/clients/${clientId}/rules${params || ""}`,
      options: {
        // this is an options field
        data: {}, // request body
        method: HTTP.GET, // pass this only if it is not a GET request
        header: {}, // if additional header certain request required then pass it here.
      },
    };
  },
  updateCalcSettingsConfiguration(
    settingsId: number,
    isActive: boolean,
    updatedBy: string
  ) {
    return {
      url: `${baseURL}/pricingCalc/calcSettings/status`,
      options: {
        // this is an options field
        data: {
          settingsId,
          isActive,
          updatedBy,
        }, // request body
        method: HTTP.PATCH, // pass this only if it is not a GET request
        header: {}, // if additional header certain request required then pass it here.
      },
    };
  },
  getClientList() {
    return {
      url: `${baseURL}/pricingCalc/clients`,
      options: {
        // this is an options field
        data: {}, // request body
        method: HTTP.GET, // pass this only if it is not a GET request
        header: {}, // if additional header certain request required then pass it here.
      },
    };
  },
  submitBusinessRules(
    rulepayload: unknown,
    type: string,
    LoggedInUser: string
  ) {
    let rule: unknown;

    if (type === "editStandardRule" || type === "addStandardRule") {
      rule = rulepayload as StandardRulePayload;
    } else if (type === "editOptionalRule" || type === "addOptionalRule") {
      rule = rulepayload as OptionalRulePayload;
    }

    const payload = {
      rules: [rule],
      deletedOptionalRuleIds: [],
      deletedStandardRuleIds: [],
      performedBy: LoggedInUser,
    };

    return {
      url: `${baseURL}/pricingCalc/clients/rules `,
      options: {
        // this is an options field
        data: type === "delete" ? rulepayload : payload, // request body
        method: HTTP.PUT, // pass this only if it is not a GET request
        header: {}, // if additional header certain request required then pass it here.
      },
    };
  },
  quoteRateSiulation(data: quoteRateWorkorderPayloadProps, type: string) {
    return {
      url: `${baseURL}/pricingCalc/rules/${type}`,
      options: {
        data, // request body
        method: HTTP.POST, // pass this only if it is not a GET request
        header: {}, // if additional header certain request required then pass it here.
      },
    };
  },
  quoteRateSimulation(
    data: {
      payRate?: string;
      billRate?: string;
      clientName: string;
      optionalRuleIds: number[];
      standardRuleIds: number[];
      isMilestone: boolean;
    },
    type: string
  ) {
    return {
      url: `${baseURL}/pricingCalc/rules/${type}`,
      options: {
        data, // request body
        method: HTTP.POST, // pass this only if it is not a GET request
        header: {}, // if additional header certain request required then pass it here.
      },
    };
  },
};
