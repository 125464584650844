import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
} from "@nextui-org/react";
import _ from "lodash";
import React, { RefObject, useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FcDeleteDatabase } from "react-icons/fc";

import { stateIprops } from "../../../../@types/component";

import classes from "./index.module.scss";

interface Iprops {
  clearPopOver?: () => void;
  ruleSetData:
    | {
        name: string;
        description: string;
        updatedDate?: string;
        createdOn: string;
        createdBy: string;
        id: number;
      }[]
    | null;
  isToggleRequired?: boolean;
  isEditRequired?: boolean;
  setDeleteForm?: (e: boolean) => void;
  getDeletedId?: (e: number | null, type: string) => void;
  getCLickPosition?: (rule: stateIprops | null, type: string) => void;
  updateDeletePositon: RefObject<HTMLDivElement>;
  children?: React.ReactNode;
  clickThreeDotsType?: string;
  writeAccess: boolean;
}

const RulesetTable: React.FC<Iprops> = ({
  clearPopOver,
  ruleSetData,
  isEditRequired = true,
  setDeleteForm,
  getDeletedId,
  getCLickPosition,
  updateDeletePositon,
  children,
  clickThreeDotsType,
  writeAccess,
}) => {
  const [updateState, setUpdateState] = useState<stateIprops | null>(null);

  const [showEditPopUp, editPopUp] = useState<
    { id: number; checked: boolean }[]
  >(
    _.map(
      ruleSetData,

      (val, key) => {
        return { id: (val.id && Number(val.id)) || 0, checked: false };
      }
    )
  );

  useEffect(() => {
    if (updateState) {
      getCLickPosition && getCLickPosition(updateState, "" || "");
    }
  }, [updateState]);

  return (
    <div
      className={[classes.table_accordion].join(" ")}
      onMouseDown={(e) => {
        clearPopOver && clearPopOver();
      }}
      onScroll={() => {
        const popoverElement = document.getElementById(
          `${clickThreeDotsType || ""}popover`
        );

        if (
          document.getElementById(clickThreeDotsType || "") &&
          popoverElement
        ) {
          const element = document.getElementById(clickThreeDotsType || "");
          const threDotsTop = element?.getBoundingClientRect().top;
          // const threDotsLeft = element?.getBoundingClientRect().left;

          if (popoverElement) {
            popoverElement.style.top = `${Number(threDotsTop) + 5}px`;
          }
        }
      }}
    >
      <div className={[classes.group, classes.group2].join(" ")}>
        <ul
          className={[classes.ul, classes.two_ul_width_for_min_data].join(" ")}
        >
          {ruleSetData &&
            _.map(ruleSetData, (value, parentKey) => {
              return (
                <li
                  className={[
                    classes.row_bgcolor,
                    classes.group2_li_min_width_display,
                  ].join(" ")}
                  key={`${value.id || ""}${parentKey}`}
                >
                  {_.map(value, (val, key) => {
                    if (
                      key === "id" ||
                      key === "createdBy" ||
                      key === "updatedDate"
                    ) {
                      return null;
                    }

                    let valueToAppend: string = "";

                    valueToAppend = val?.toString() || "";

                    return (
                      <div key={`${parentKey}${key}`}>
                        {key === "createdOn" ? (
                          <div>
                            <span className={classes.createdon_parent}>
                              <span className={classes.createdon_header}>
                                <span>
                                  {key
                                    .replace(/([A-Z])/g, " $1")
                                    .replace(/^./, (str) =>
                                      str.toUpperCase()
                                    )}{" "}
                                  &nbsp;
                                </span>
                                <span>{valueToAppend}</span>
                                <br />
                              </span>
                              by{" "}
                              <b>
                                {ruleSetData &&
                                  ruleSetData[parentKey].createdBy.split(
                                    "@"
                                  )[0]}
                              </b>
                            </span>
                          </div>
                        ) : (
                          <div>
                            <span>
                              {key
                                .replace(/([A-Z])/g, " $1")
                                .replace(/^./, (str) => str.toUpperCase())}
                            </span>

                            <span
                              className={
                                key === "description"
                                  ? classes.comment_cellwidth
                                  : ""
                              }
                            >
                              {valueToAppend || <br />}
                            </span>
                          </div>
                        )}
                        <hr className={classes.cell_divider} />
                      </div>
                    );
                  })}

                  {isEditRequired && (
                    <div
                      className={[
                        classes.edit_row_cell,
                        classes.edit_row_position,
                      ].join(" ")}
                    >
                      <div>
                        {writeAccess ? (
                          <Popover
                            placement="left-start"
                            showArrow={true}
                            backdrop="blur"
                            id={`rulesettab${parentKey}popover`}
                            className={classes.alter_popover_style}
                          >
                            <PopoverTrigger>
                              <Button
                                className={[
                                  classes.alter_popover_button_style,
                                ].join(" ")}
                                id={`rulesettab${parentKey}button`}
                                onClick={() => {
                                  getDeletedId &&
                                    getDeletedId(
                                      (value && Number(value.id)) || null,
                                      "" || ""
                                    );
                                  setUpdateState({
                                    name: value.name,
                                    id: value.id,
                                    description: value.description,
                                    uniqueid: `rulesettab${parentKey}`,
                                  });

                                  editPopUp(
                                    showEditPopUp.map((e) => {
                                      if (value.id) {
                                        if (e.id === value.id) {
                                          return {
                                            id: value.id,
                                            checked: !e.checked,
                                          };
                                        }
                                      }

                                      return { id: e.id, checked: false };
                                    })
                                  );
                                }}
                              >
                                <BsThreeDotsVertical
                                  id={`rulesettab${parentKey}`}
                                />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent>{children}</PopoverContent>
                          </Popover>
                        ) : (
                          <BsThreeDotsVertical id={`rulesettab${parentKey}`} />
                        )}
                      </div>
                    </div>
                  )}
                </li>
              );
            })}
        </ul>
      </div>

      {((ruleSetData && ruleSetData.length === 0) || !ruleSetData) && (
        <span className={classes.empty_error_page}>
          <FcDeleteDatabase className={classes.empty_data_icon} /> Uh Oh! Looks
          like Rules are not available.
        </span>
      )}
    </div>
  );
};

export default RulesetTable;
