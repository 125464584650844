import React, { KeyboardEvent } from "react";
import DatePicker from "react-datepicker";

import {
  DateFilterDropdownprops,
  engagementTypeListProps,
} from "../../@types/component";
import { CaretDown } from "../Icon/Icons/CaretDown";
import { SearchClose } from "../Icon/Icons/SearchClose";
import MBOTooltip from "../MBOTooltip";

import "./datePicker.scss";
import classes from "./dropdown.module.scss";

interface MBODropdownProps {
  name: string;
  options?: engagementTypeListProps[] | [];
  selectedValue: DateFilterDropdownprops | engagementTypeListProps;
  selectedDate?: Date | null;
  clearHandler?: (filterType: string) => void;
  handleValueChange?: (
    filterType: string,
    filterValue: engagementTypeListProps
  ) => void;
  handleDateChange?: (date: Date | null) => void;
  disabled?: boolean;
  propertyName: string;
  tooltipContent?: string;
  dateDropdown?: boolean;
  handleDateClear?: () => void;
  removeNoDataText?: boolean;
  removeSearchTextBox?: boolean;
}

const MBODropdown: React.FC<MBODropdownProps> = ({
  name,
  options,
  selectedValue,
  clearHandler,
  handleValueChange,
  disabled,
  propertyName,
  tooltipContent,
  dateDropdown = false,
  selectedDate,
  handleDateChange,
  handleDateClear,
  removeNoDataText = false,
  removeSearchTextBox = true,
}) => {
  const [open, setopen] = React.useState(false);
  const MBOdropdownRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        MBOdropdownRef.current &&
        !MBOdropdownRef.current.contains(event.target as Node)
      ) {
        setopen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const setTypedText = (e: KeyboardEvent<HTMLInputElement>) => {
    const event = e.target as HTMLInputElement;

    if (e.code === "Enter") {
      handleValueChange &&
        handleValueChange(propertyName, { id: 0, name: event.value });
      setopen(false);
    }
  };

  return (
    <>
      <div className={classes.container} ref={MBOdropdownRef}>
        <button
          className={[
            classes.header,
            !dateDropdown && selectedValue.name !== ""
              ? classes.headerwithVal
              : "",
            dateDropdown && selectedDate ? classes.headerwithVal : "",
            disabled ? classes.disabled : "",
          ].join(" ")}
          disabled={disabled}
          onClick={() => setopen(!open)}
        >
          {!dateDropdown ? (
            <>
              <span>{selectedValue.name?.toString() || name}</span>
              {selectedValue.name === "" ? (
                <CaretDown fill={disabled ? "#B8B3BC" : "#352281"} />
              ) : (
                <SearchClose
                  fill="#352281"
                  onClick={() => clearHandler && clearHandler(propertyName)}
                  data-testid="clear-button"
                />
              )}
            </>
          ) : (
            <>
              <span>{selectedDate?.toLocaleDateString() || name}</span>
              {selectedDate === null ? (
                <CaretDown fill={disabled ? "#B8B3BC" : "#352281"} />
              ) : (
                <SearchClose
                  fill="#352281"
                  onClick={handleDateClear}
                  data-testid="clear-date"
                />
              )}
            </>
          )}
        </button>
        {open && (
          <div className={classes.content} data-testid="region">
            <div className={classes.arrowContainer}>
              <span className={classes.arrow} />
            </div>
            {!dateDropdown ? (
              <ul className={classes.options}>
                {!removeSearchTextBox && (
                  <input
                    autoFocus
                    type="search"
                    placeholder="Type a name"
                    className={classes.search_text_style}
                    onKeyUp={setTypedText}
                  />
                )}
                {options && options.length > 0
                  ? options.map((option) => (
                      <li
                        key={option.id}
                        onClick={() => {
                          handleValueChange &&
                            handleValueChange(propertyName, option);
                          setopen(false);
                        }}
                        className={classes.option}
                      >
                        {option.name}
                      </li>
                    ))
                  : !removeNoDataText && (
                      <li className={classes.option}>No Data</li>
                    )}
              </ul>
            ) : (
              <div className="date-dropdown" data-testid="date-dropdown">
                <DatePicker
                  selected={selectedDate}
                  onChange={(date: Date | null) => {
                    handleDateChange && handleDateChange(date);
                    setopen(false);
                  }}
                  dateFormat="mm/dd/yyyy"
                  inline
                />
              </div>
            )}
          </div>
        )}
      </div>
      {!disabled && tooltipContent && !open && selectedValue.name === "" && (
        <MBOTooltip content={tooltipContent} bottom={classes.tooltip} />
      )}
    </>
  );
};

export default MBODropdown;
