import moment from "moment";
import { useEffect, useState } from "react";

import { stateIprops } from "../../../../../@types/component";
import MBOTable from "../../../../../componentsPhase1/MBOTable";

import classes from "./index.module.scss";

interface RuleProp {
  rule: {
    data: {
      standardRules: ManageStandardRules[] | null;
    };
  };
}

const StandardRules: React.FC<RuleProp> = ({ rule }) => {
  const { standardRules } = rule.data;

  const [formattedRules, setFormattedRules] = useState<stateIprops[] | null>(
    null
  );

  const validateAmount = (rule: ManageStandardRules) => {
    if (rule.amount.localized === "percentage") {
      return `${Number(rule.amount.value.replace("%", "")).toFixed(5)}%`;
    }

    return rule.amount.raw && rule.amount.raw !== ""
      ? `$${Number(rule.amount.raw) / 100}`
      : "";
  };

  useEffect(() => {
    if (standardRules && standardRules.length > 0) {
      setFormattedRules(
        standardRules.map((rule) => {
          return {
            id: Number(rule?.id),
            name: rule.name,
            clientId: Number(rule?.client?.id) || null,
            engagementType: rule.engagementType || "",
            operation: {
              label: rule.operation.name,
              value: Number(rule.operation.id),
            },
            amount: validateAmount(rule),
            city: rule.city,
            effectiveDate:
              (rule.effectiveDate &&
                rule.effectiveDate.raw &&
                moment(rule.effectiveDate.value, "MMM DD YYYY")) ||
              null,
            performedBy: rule.performedBy,
            description: rule.description,
            expireDate:
              (rule.expireDate &&
                rule.expireDate.raw &&
                moment(rule.expireDate.value, "MMM DD YYYY")) ||
              null,
            country: rule.country,
            state: rule.mainDivision || "",
            rateLow: (rule && rule.rateLow && rule.rateLow.toString()) || "",
            rateHigh: (rule && rule.rateHigh && rule.rateHigh.toString()) || "",
            attribution: {
              label: rule.attribution.name,
              value: Number(rule.attribution.id),
            },
            group: {
              label: rule.group.name,
              value: Number(rule.group.id),
            },
            vendor:
              (rule.vendor && {
                label: rule.vendor.vendorName || "",
                value: Number(rule.vendor.id),
              }) ||
              null,
            createdDate:
              rule.createdDate && rule.createdDate.raw
                ? moment(rule.createdDate.raw).local()
                : null,
            updatedDate:
              rule.updatedDate && rule.updatedDate.raw
                ? moment(rule.updatedDate.raw).local()
                : null,
          };
        })
      );

      return () => {
        setFormattedRules(null);
      };
    }
  }, [standardRules]);

  return (
    <MBOTable
      rule={formattedRules || []}
      headerName="Standard Rules"
      showAddNewButton={false}
      typeOfRule={"standard"}
      isEditRequired={false}
      appendStyle={classes.table_positioning}
      isToggleRequired={false}
    />
  );
};

export default StandardRules;
