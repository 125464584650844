import React, { KeyboardEvent, useEffect, useState } from "react";

import { SuggestionProps } from "../../@types/component";
import "../../App.css";

import classes from "./index.module.scss";

const SuggestionWIndow: React.FC<SuggestionProps> = ({
  clients,
  selectClient,
  handleDropdownShow,
  searchedText,
  suggestionWindowRef,
  setFocusedIndex,
  focusedIndex,
  showNoDataFound,
}) => {
  const scrollThroughOptions = React.useRef<HTMLDivElement>(null);

  const [focusedElement, setFocusedElement] = useState<HTMLElement | null>(
    null
  );

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "ArrowUp") {
      e.preventDefault();
      setFocusedIndex &&
        setFocusedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : clients.length - 1
        );
    } else if (e.key === "ArrowDown") {
      if (focusedIndex === -1) {
        setFocusedIndex && setFocusedIndex(0);
      }

      e.preventDefault();
      setFocusedIndex &&
        setFocusedIndex((prevIndex) =>
          prevIndex === clients.length - 1 ? 0 : prevIndex + 1
        );

      if (
        focusedIndex &&
        focusedIndex >= 0 &&
        suggestionWindowRef.current &&
        focusedElement
      ) {
        focusedElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    } else if (e.key === "Enter" && Boolean(focusedIndex !== -1)) {
      (focusedIndex || 0 > -1) && selectClient(clients[focusedIndex || 0]);
      handleDropdownShow(false);
      setFocusedIndex && setFocusedIndex(-1);
    }
  };

  useEffect(() => {
    // Set the focused element when the index changes

    if (scrollThroughOptions.current) {
      const elements = scrollThroughOptions.current.querySelectorAll(".option");

      if (
        elements.length > 0 &&
        focusedIndex !== undefined &&
        focusedIndex !== null &&
        focusedIndex > -1
      ) {
        const element = elements[focusedIndex] as HTMLElement;

        setFocusedElement(element);
        element.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  }, [focusedIndex, clients]);

  return (
    <div
      className={classes.suggestion}
      onKeyDown={handleKeyPress}
      tabIndex={0}
      ref={suggestionWindowRef}
    >
      <div className={classes.triangle}></div>
      <div
        className={classes.suggestionContainer}
        data-testid="suggestion-container"
        ref={scrollThroughOptions}
      >
        {clients.length > 0 &&
          searchedText &&
          searchedText.length > 2 &&
          clients.map((item, index) => {
            return (
              <div
                key={`${index}${item.label}`}
                tabIndex={focusedIndex === -1 ? 0 : focusedIndex}
                className={`option ${
                  focusedIndex === index
                    ? "dropdown_options_keyboard_selected"
                    : ""
                } ${classes.dropdown}`}
                onClick={() => {
                  selectClient(item);
                  handleDropdownShow(false);
                }}
                data-testid="dropdown"
                ref={(element) => {
                  if (index === focusedIndex && element) {
                    setFocusedElement(element);
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "nearest",
                    });
                  }
                }}
              >
                <div> {item.label}</div>
                <div>
                  {item.label
                    .split(" ")
                    .map((str) => str[0])
                    .join("")}
                </div>
              </div>
            );
          })}
        {showNoDataFound &&
          showNoDataFound !== "" &&
          searchedText &&
          searchedText?.trim()?.length > 2 && (
            <div className={classes.dropdown}>{showNoDataFound}</div>
          )}
        {searchedText && searchedText.length === 0 && (
          <div className={classes.dropdown}>Search for a Client</div>
        )}
      </div>
    </div>
  );
};

export default SuggestionWIndow;
