import React from "react";

import MBOTooltip from "../MBOTooltip";

import classes from "./buttons.module.scss";

interface ButtonProps {
  onClick: () => void;
  symbol: React.ReactNode;
  name: string;
  disabled?: boolean;
  tooltipContent?: string;
  appendStyle?: string;
}

const MBOButton: React.FC<ButtonProps> = ({
  onClick,
  symbol,
  name,
  disabled,
  tooltipContent,
  appendStyle,
}) => {
  return (
    <>
      <button
        data-testid="test-button"
        className={[
          classes.button,
          appendStyle,
          disabled && classes.disabled,
        ].join(" ")}
        disabled={disabled}
        onClick={onClick}
      >
        <span>{name}</span>
        {symbol}
      </button>
      {!disabled && tooltipContent && (
        <MBOTooltip content={tooltipContent} bottom={classes.tooltip} />
      )}
    </>
  );
};

export default MBOButton;
