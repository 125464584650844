import React, { KeyboardEvent, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";

import { ClientSearchProps, optionsProp } from "../../../@types/component";
import ErrorBoundary from "../../../HOC/ErrorBoundary/errorBoundary.component";
import MBOInput from "../../../componentsPhase1/MBOInput";
import SuggestionWIndow from "../../../componentsPhase1/MBOSuggestion";
import MBOTooltip from "../../../componentsPhase1/MBOTooltip";

import classes from "./index.module.scss";

const ClientSeach: React.FC<ClientSearchProps> = ({
  receivedData,
  setSelectedClient,
  selectedClient,
}) => {
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const suggestionWindowRef = React.useRef<HTMLDivElement>(null);
  const { showBoundary } = useErrorBoundary();
  const [focusedIndex, setFocusedIndex] = useState<number>(-1);

  const initialClientList = {
    value: 0,
    label: "",
    clientId: 0,
  };

  const [input, setInput] = React.useState<string>(selectedClient?.label || "");
  const [dropdownShow, setdropdownShow] = useState(false);

  const [filteredData, setFilteredData] = useState<optionsProp[]>([
    initialClientList,
  ]);

  const handleDropdownShow = (val: boolean) => {
    try {
      setdropdownShow(val);
      setFocusedIndex(-1);
    } catch (e) {
      showBoundary("");
    }
  };

  const selectClient = (val: optionsProp) => {
    try {
      setSelectedClient(val);
      setInput(val.label);
      setFocusedIndex(-1);
    } catch (e) {
      showBoundary("");
    }
  };

  const clearSelectedClient = (): void => {
    try {
      setInput("");
      setSelectedClient(initialClientList);
      setFilteredData(receivedData);
      setFocusedIndex(-1);
    } catch (e) {
      showBoundary("");
    }
  };

  useEffect(() => {
    try {
      setFilteredData(receivedData);
    } catch (e) {
      showBoundary("");
    }
  }, [receivedData]);

  useEffect(() => {
    try {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node)
        ) {
          setdropdownShow(false);
          setFocusedIndex(-1);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    } catch (e) {
      showBoundary("");
    }
  }, []);

  useEffect(() => {
    try {
      if (input.length < 2) {
        return;
      }

      const filteredData = receivedData.filter((item) =>
        item.label.toLowerCase().includes(input.toLowerCase())
      );

      input.length > 0 && setFilteredData(filteredData);
    } catch (e) {
      showBoundary("");
    }
  }, [input]);

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "ArrowDown") {
      // Show or focus the suggestion window when down arrow is pressed
      handleDropdownShow(true);
      // Focus the suggestion window for keyboard navigation
      // Use refs or some other mechanism to focus on the suggestion window
      suggestionWindowRef.current?.focus();
      setFocusedIndex(0);
    }
  };

  return (
    <div ref={dropdownRef} data-testid="client-search-section">
      <MBOInput
        type="text"
        placeholder="Company"
        changeHandler={(e) => {
          setInput(e.target.value);
        }}
        name="clientName"
        inputValue={input}
        clearHandler={clearSelectedClient}
        handleDropdownShow={handleDropdownShow}
        isClearIconRequired={true}
        onKeyDown={handleKeyDown}
      />
      {dropdownShow && (
        <SuggestionWIndow
          searchedText={input}
          clients={filteredData}
          selectClient={selectClient}
          handleDropdownShow={handleDropdownShow}
          suggestionWindowRef={suggestionWindowRef}
          setFocusedIndex={setFocusedIndex}
          focusedIndex={focusedIndex}
        />
      )}
      {selectedClient?.label === "" && !dropdownShow && (
        <MBOTooltip
          content="Start by selecting a company"
          bottom={classes.tooltip}
        />
      )}
    </div>
  );
};

export default ErrorBoundary(ClientSeach);
