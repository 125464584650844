import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useRef, useState, useContext } from "react";
import { useNavigate, NavLink, useLocation } from "react-router-dom";

import API from "../../API";
import PricingCalcApi from "../../API/PricingCalcApi";
import ValidateUser from "../../auth/validateUser";
import Button from "../../components/Button/Button";
import Input from "../../components/Input";
import Toast from "../../components/Toast/Toast";
import GlobalSpinnerContext from "../../contexts/LoaderContext";
import GlobalToastContext from "../../contexts/ToastContext";

import "./Home.scss";

const Home: React.FC = () => {
  const { authState } = useOktaAuth();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const history = useNavigate();
  const [searchId, setSearchId] = useState<string>("");
  const location = useLocation();
  const { SetLoader } = useContext(GlobalSpinnerContext);
  const { EnableToast, toastMessage } = useContext(GlobalToastContext);

  const [jobDivaInfo, setJobDivaData] =
    useState<ProjectDetailsAPIResponse | null>(null);

  const [pricingCalcInfo, setPricingCalcData] = useState<CalcSettings | null>(
    null
  );

  const searchHandler = async (id: string) => {
    const startID = searchId || id;

    if (startID && !Number.isNaN(+startID)) {
      SetLoader(true);

      try {
        const request1 = PricingCalcApi.getProjectDetails({ id: +startID });
        const getJobDivaDetails = API.performRequest(request1);
        const request2 = PricingCalcApi.getCalcSettings({ id: +startID });
        const getcalcSettings = API.performRequest(request2);
        const data = await Promise.all([getJobDivaDetails, getcalcSettings]);
        const jobDivaDetails: ProjectDetailsAPIResponse = data[0]?.data;
        const calcSettings: CalcSettings = data[1]?.data;

        const isJobDivaDetailsAvailable =
          jobDivaDetails.data &&
          Boolean(Object.keys(jobDivaDetails.data).length);

        if (isJobDivaDetailsAvailable) {
          setJobDivaData(jobDivaDetails);
          setPricingCalcData(calcSettings);
        } else {
          EnableToast({ message: "No Job Diva data found", type: "error" });
        }

        SetLoader(false);
      } catch (error) {
        console.log(error);
        SetLoader(false);
      }
    } else {
      EnableToast({ message: "Not a Valid Start Id", type: "warning" });
    }
  };

  useEffect(() => {
    try {
      const searchParamId = location.search
        .replace("?startID=", "")
        .replace("?startid=", "");

      if (searchParamId !== "" && !Number.isNaN(+searchParamId)) {
        setSearchId(`${searchParamId}`);
        searchHandler(`${searchParamId}`);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);
  useEffect(() => {
    if (jobDivaInfo && pricingCalcInfo) {
      history(`/phase1/start/${searchId}`, {
        state: {
          jobDivaInfo,
          pricingCalcInfo,
          id: searchId,
        },
      });
    }
  }, [jobDivaInfo, pricingCalcInfo]);

  useEffect(() => {
    const validUser = ValidateUser();

    if (
      (validUser && validUser.length < 1) ||
      (validUser.length > 0 && +validUser[0].accessLevel < 0)
    ) {
      navigate("/phase1/unauthorized");
    }
  }, []);

  useEffect(() => {
    const keyEnter = (e: KeyboardEvent) => {
      if (e.code === "Enter") {
        searchHandler("");
      }
    };

    inputRef.current?.addEventListener("keypress", keyEnter);

    return () => inputRef.current?.removeEventListener("keypress", keyEnter);
  }, [inputRef, searchId]);

  useEffect(() => {
    if (toastMessage.message === "") {
      EnableToast({ message: "", type: "" });
    }
  }, [toastMessage]);

  return (
    <>
      {authState?.isAuthenticated && (
        <div className="home">
          {toastMessage.message !== "" ? <Toast /> : null}
          <p>Search with a Jobdiva Start id to get started</p>
          <div className="home__searchContainer">
            <Input.Search
              innerRef={inputRef}
              value={searchId}
              onChange={(e) => setSearchId(e.target.value)}
              placeholder="Start ID"
            />
            <Button
              name="Search"
              onClick={() => {
                void searchHandler("");
              }}
            />
          </div>
          <p data-title="quotesrates">
            Don&apos;t have Start id ?
            <NavLink to="/phase1/qrates">Click Here</NavLink> to Start
            Simulation
          </p>
        </div>
      )}
    </>
  );
};

export default Home;
