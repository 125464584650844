import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FcDeleteDatabase } from "react-icons/fc";
import { HiExclamationCircle } from "react-icons/hi";
import { UseQueryResult } from "react-query";

import API from "../../../API/PricingCalcApiPhaseone";
import CheckBox from "../../../components/Input/CheckBox/CheckBox";
import Button from "../../../componentsPhase1/MBOButton/Button";
import MBODropdown from "../../../componentsPhase1/MBODropdown";
import MBOInput from "../../../componentsPhase1/MBOInput";
import useQueryData from "../../../hooks/useQueryData";

import classes from "./index.module.scss";

interface RuleSetDataProps {
  client: string;
  id: number;
  select: boolean;
  disable: boolean;
}

interface Iprops {
  clearPopOver?: () => void;
  ruleSetData: RuleSetDataProps[];
  appliedClientidsToRemove?: RuleSetDataProps[];
  setAppliedClientIdsRemove?: React.Dispatch<
    React.SetStateAction<RuleSetDataProps[]>
  >;
  setClientList?: React.Dispatch<React.SetStateAction<RuleSetDataProps[]>>;
  appliedRulesetIds?: number[];
  setAplyRulesetForm?: React.Dispatch<React.SetStateAction<boolean>>;
  setRemoveRulesetForm?: React.Dispatch<React.SetStateAction<boolean>>;
  clientListForRulesetApplied: UseQueryResult;
  setAppliedClientIds?: React.Dispatch<React.SetStateAction<number[]>>;
  applyRulesetToClient: UseQueryResult;
  isRemoveSetOpened?: boolean;
  revokeState?: () => void;
  rulesetCreationStateid?: number;
  rulesetCreationStateName?: string;
  isUpdatePopUpopened?: boolean;
}

const ApplyRulesetToClients: React.FC<Iprops> = ({
  clearPopOver,
  ruleSetData,
  appliedRulesetIds,
  setClientList,
  setAplyRulesetForm,
  clientListForRulesetApplied,
  setAppliedClientIds,
  applyRulesetToClient,
  setRemoveRulesetForm,
  isRemoveSetOpened,
  appliedClientidsToRemove,
  setAppliedClientIdsRemove,
  revokeState,
  rulesetCreationStateid,
  rulesetCreationStateName,
  isUpdatePopUpopened,
}) => {
  const [filterText, setFilterText] = useState<string>("");

  const [selectedRulesetRules, setSelectedRulesetRules] = useState<{
    standardRules: ManageStandardRules[];
    optionalRules: ManageOptionalRules[];
  } | null>(null);

  const [ruleSetFilter, setRulesetFilter] = useState<{
    sortBy: string;
  }>({ sortBy: "ASC" });

  const getAllRulesForSelectedRuleset = useQueryData(
    "get-all-rule-for-selected-ruleset",
    { selectedRulesetId: rulesetCreationStateid || "" },
    false,
    API.getRulesForRuleset
  );

  useEffect(() => {
    if (getAllRulesForSelectedRuleset.error) {
      console.log(getAllRulesForSelectedRuleset.error);
    }

    if (
      getAllRulesForSelectedRuleset.data &&
      getAllRulesForSelectedRuleset.isFetched &&
      !getAllRulesForSelectedRuleset.isLoading
    ) {
      setSelectedRulesetRules({
        standardRules:
          getAllRulesForSelectedRuleset?.data?.data?.ruleSet?.standardRules,
        optionalRules:
          getAllRulesForSelectedRuleset?.data?.data?.ruleSet?.optionalRules,
      });
    }
  }, [
    getAllRulesForSelectedRuleset.isLoading,
    getAllRulesForSelectedRuleset.error,
    getAllRulesForSelectedRuleset.data,
  ]);

  useEffect(() => {
    clientListForRulesetApplied.refetch();

    if (rulesetCreationStateid) {
      getAllRulesForSelectedRuleset.refetch();
    }

    return () => {
      setClientList &&
        setClientList((prevState) =>
          prevState?.map((e) => {
            return {
              ...e,
              select: false,
            };
          })
        );
    };
  }, []);

  const disableButton = () => {
    if (
      selectedRulesetRules &&
      !selectedRulesetRules?.optionalRules &&
      !selectedRulesetRules?.standardRules &&
      !isRemoveSetOpened &&
      !isUpdatePopUpopened
    ) {
      return !(
        selectedRulesetRules &&
        selectedRulesetRules?.optionalRules?.length > 0 &&
        selectedRulesetRules?.standardRules?.length > 0
      );
    }

    if (!isRemoveSetOpened || !selectedRulesetRules || isUpdatePopUpopened) {
      return ruleSetData.filter((e) => e.select && !e.disable).length === 0;
    }

    if (
      (!isRemoveSetOpened && ruleSetData && ruleSetData.length === 0) ||
      (isRemoveSetOpened &&
        appliedClientidsToRemove &&
        appliedClientidsToRemove.length === 0)
    ) {
      return true;
    }

    if (
      appliedClientidsToRemove &&
      appliedClientidsToRemove.length > 0 &&
      isRemoveSetOpened
    ) {
      return appliedClientidsToRemove.filter((e) => !e.select).length === 0;
    }

    return false;
  };

  return (
    <>
      <div className={classes.blocker}></div>
      <div className={classes.overlay}></div>
      <div className={classes.popup_blur}></div>
      <div className={[classes.apply_ruleset_popup].join(" ")}>
        <div className={[classes.ruleset_filter].join(" ")}>
          <MBOInput
            type="text"
            placeholder="Type a filter"
            changeHandler={(e) => {
              setFilterText(e.target.value);
            }}
            name="Filter"
            inputValue={filterText}
            clearHandler={() => setFilterText("")}
            isClearIconRequired={true}
            appendStyles={classes.alter_search_style}
          />
          <div className={classes.filters}>
            <div>
              Sort by:{" "}
              <MBODropdown
                name="alphabetical order"
                options={[
                  { id: 0, name: "ASC" },
                  { id: 1, name: "DESC" },
                ]}
                clearHandler={() => {
                  setRulesetFilter({ ...ruleSetFilter, sortBy: "" });
                }}
                selectedValue={{ id: 0, name: ruleSetFilter.sortBy }}
                handleValueChange={(_, option) => {
                  setRulesetFilter({
                    sortBy: option.name,
                  });
                }}
                tooltipContent=""
                propertyName="alphabetical order"
                removeSearchTextBox={true}
                removeNoDataText={true}
              />
            </div>
          </div>
        </div>
        <div className={classes.ruleset_table}>
          <div
            className={[classes.table_accordion].join(" ")}
            onMouseDown={(e) => {
              clearPopOver && clearPopOver();
            }}
          >
            <div className={[classes.group, classes.group2].join(" ")}>
              <ul
                className={[classes.ul, classes.two_ul_width_for_min_data].join(
                  " "
                )}
              >
                {_.map(
                  isRemoveSetOpened
                    ? appliedClientidsToRemove
                    : ruleSetData
                        .sort((a, b) =>
                          ruleSetFilter.sortBy === "ASC" ||
                          ruleSetFilter.sortBy === ""
                            ? a.client.localeCompare(b.client)
                            : b.client.localeCompare(a.client)
                        )
                        .sort((a, b) => Number(b.select) - Number(a.select))
                        .filter((e) =>
                          e.client
                            .toLowerCase()
                            .includes(filterText.toLowerCase())
                        ),
                  (value, parentKey) => {
                    return (
                      <li
                        className={[
                          classes.row_bgcolor,

                          classes.group2_li_min_width_display,
                          value.select ? classes.selected_ruleset : "",
                          value.disable ? classes.disable_applied_client : "",
                        ].join(" ")}
                        key={`${value.id || ""}${parentKey}`}
                        onClick={() => {
                          if (value.disable) {
                            return;
                          }

                          if (isRemoveSetOpened) {
                            setAppliedClientIdsRemove &&
                              setAppliedClientIdsRemove((prevState) =>
                                prevState?.map((e) => {
                                  return value.id === e.id
                                    ? {
                                        ...e,
                                        select: !e.select,
                                      }
                                    : { ...e };
                                })
                              );

                            return;
                          }

                          setClientList &&
                            setClientList((prevState) =>
                              prevState?.map((e) => {
                                return value.id === e.id
                                  ? {
                                      ...e,
                                      select: !e.select,
                                    }
                                  : { ...e };
                              })
                            );
                        }}
                      >
                        {_.map(value, (val, key) => {
                          if (
                            key === "id" ||
                            key === "createdBy" ||
                            key === "disable"
                          ) {
                            return null;
                          }

                          let valueToAppend: string | Date = "";

                          valueToAppend = val?.toString() || "";

                          return (
                            <div
                              key={`${parentKey}${key}`}
                              className={[
                                value.select ? classes.selected_ruleset : "",
                              ].join(" ")}
                              onClick={() => {
                                if (appliedRulesetIds?.includes(value?.id)) {
                                  return null;
                                }
                              }}
                            >
                              {key === "select" ? (
                                <div>
                                  <CheckBox
                                    isChecked={value.select}
                                    className={classes.align_radio_btn}
                                    onChange={() => {}}
                                  />
                                </div>
                              ) : (
                                <div>
                                  <span>
                                    {key
                                      .replace(/([A-Z])/g, " $1")
                                      .replace(/^./, (str) =>
                                        str.toUpperCase()
                                      )}
                                  </span>

                                  <span
                                    className={
                                      key === "description"
                                        ? classes.comment_cellwidth
                                        : ""
                                    }
                                  >
                                    {valueToAppend.toString() || <br />}
                                  </span>
                                </div>
                              )}
                              <hr className={classes.cell_divider} />
                            </div>
                          );
                        })}
                      </li>
                    );
                  }
                )}
              </ul>
            </div>

            {((!isRemoveSetOpened && ruleSetData && ruleSetData.length === 0) ||
              !ruleSetData) && (
              <span className={classes.empty_error_page}>
                <FcDeleteDatabase className={classes.empty_data_icon} /> Uh Oh!
                Looks like Rules are not available.
              </span>
            )}

            {isRemoveSetOpened &&
              ((appliedClientidsToRemove &&
                appliedClientidsToRemove.length === 0) ||
                !appliedClientidsToRemove) && (
                <span className={classes.empty_error_page}>
                  <FcDeleteDatabase className={classes.empty_data_icon} /> Uh
                  Oh! Looks like Rules are not available.
                </span>
              )}
          </div>
          <div
            className={[
              classes.ruleset_footer,
              (!isUpdatePopUpopened &&
                !isRemoveSetOpened &&
                selectedRulesetRules &&
                !selectedRulesetRules.optionalRules &&
                !selectedRulesetRules.standardRules) ||
              (selectedRulesetRules?.optionalRules?.length === 0 &&
                selectedRulesetRules?.standardRules?.length === 0)
                ? classes.ruleset_footer_helper
                : "",
            ].join(" ")}
          >
            {!isUpdatePopUpopened && !isRemoveSetOpened
              ? ((selectedRulesetRules &&
                  !selectedRulesetRules.optionalRules &&
                  !selectedRulesetRules.standardRules) ||
                  (selectedRulesetRules?.optionalRules?.length === 0 &&
                    selectedRulesetRules?.standardRules?.length === 0)) && (
                  <p className={classes.rules_error}>
                    <HiExclamationCircle fill={"red"} fontSize={20} />
                    No Rules are available in the Ruleset
                    <b>{rulesetCreationStateName}</b> to apply for clients
                  </p>
                )
              : ""}
            <div>
              <Button
                name="Cancel"
                className={classes.btn_calcel_styling}
                onClick={() => {
                  revokeState && revokeState();
                  setAplyRulesetForm && setAplyRulesetForm(false);
                  setRemoveRulesetForm && setRemoveRulesetForm(false);
                  clientListForRulesetApplied.remove();
                  setAppliedClientIds && setAppliedClientIds([]);
                  setAppliedClientIdsRemove && setAppliedClientIdsRemove([]);
                  setSelectedRulesetRules(null);
                  setClientList &&
                    setClientList((prevState) =>
                      prevState?.map((e) => {
                        return {
                          ...e,
                          select: false,
                        };
                      })
                    );
                }}
              />
              <Button
                name={"Apply"}
                className={classes.btn_create}
                onClick={() => {
                  revokeState && revokeState();
                  applyRulesetToClient.refetch();
                }}
                disabled={disableButton()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplyRulesetToClients;
