import ValidateUser from "./validateUser";

const ManageRuleAccess = () => {
  const validUser = ValidateUser() as UserGroups[] | [];

  return (
    validUser &&
    (validUser.length < 1 ||
      (validUser.length > 0 &&
        (+validUser[0].accessLevel <= 0 ||
          (+validUser[0].accessLevel > 0 &&
            validUser[0].user.includes("jd_")) ||
          validUser[0].user.includes("pricing_user"))))
  );
};

export default ManageRuleAccess;
