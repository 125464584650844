import axios, { AxiosRequestHeaders } from "axios";

import { requestParams } from "../@types/component";

export const HTTP = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

export default {
  performRequest({ url, options, contentType }: requestParams) {
    const token =
      JSON.parse(localStorage.getItem("okta-token-storage") || "{}") || "";

    const accessToken: string = token?.accessToken?.accessToken;
    const method = options?.method || HTTP.GET;
    const data = options?.data || {};

    const headers: AxiosRequestHeaders = {
      ...(options?.headers || {}),
      Accept: contentType || "application/vnd.pricing-exp-api.v1+json",
      "Content-Type": contentType || "application/vnd.pricing-exp-api.v1+json",
    };
    // "Content-Type": "application/vnd.pricing-exp-api.v1+json",

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    const response = axios(url, {
      method,
      headers,
      data,
    });

    return response;
  },
};
