import classes from "../selectStartID.module.scss";

interface RadioCardProps {
  id: string | number;
  value: string | number;
  startId: string | number;
  client?: { id: string; name: string };
  month: string;
  day: string | number;
  year: string | number;
  checked: boolean;
  onSelect: (value: string | number) => void;
}

const RadioCard: React.FC<RadioCardProps> = ({
  id,
  value,
  checked,
  startId,
  client,
  month,
  day,
  year,
  onSelect,
}) => {
  const isQuad = window.location.pathname === "/quad/";
  const width = isQuad ? 483 : 320;

  return (
    <div
      className={[
        classes.flex,
        classes.flexRow,
        classes.bgColor1,
        classes.rounded1,
        classes.px16,
        classes.py12,
        classes.textRoboto,
        classes.spaceXAuto,
        classes.cursorPointer,
        classes.flexCol3,
        ...(checked
          ? []
          : [classes.group, classes.hoverShadow1, classes.hoverBgColor1]),
      ].join(" ")}
      style={{ width }}
      onClick={() => onSelect(value)}
    >
      <div
        className={[
          classes.flex,
          classes.flexRow,
          classes.spaceX1,
          classes.justifyCenter,
          classes.itemsCenter,
        ].join(" ")}
      >
        <div
          className={[
            classes.flex,
            classes.justifyCenter,
            classes.itemsCenter,
          ].join(" ")}
        >
          <input
            className={[
              classes.radio,
              classes.groupHover,
              classes.pointerEventsNone,
            ].join(" ")}
            type="radio"
            id={id.toString()}
            value={value}
            checked={checked}
            readOnly
          />
          <label
            className={[classes.pointerEventsNone].join(" ")}
            htmlFor={id.toString()}
          ></label>
        </div>
        <div
          className={[classes.flex, classes.flexCol, classes.spaceY1].join(" ")}
          style={{ width: 100 }}
        >
          <div className={[classes.textColor1, classes.textSize1].join(" ")}>
            Jobdiva start ID
          </div>
          <div className={[classes.textColor2, classes.textSize2].join(" ")}>
            {startId}
          </div>
        </div>
        {isQuad && client?.name && (
          <div
            className={[
              classes.flex,
              classes.flexCol,
              classes.spaceY1,
              classes.clientContainer,
            ].join(" ")}
          >
            <div className={[classes.textColor1, classes.textSize1].join(" ")}>
              Client
            </div>
            <div className={[classes.textColor2, classes.textSize2].join(" ")}>
              {client?.name}
            </div>
          </div>
        )}
      </div>
      <div
        className={[classes.flex, classes.flexCol, classes.spaceY1].join(" ")}
      >
        <div
          className={[
            classes.textColor1,
            classes.textSize1,
            classes.textRight,
          ].join(" ")}
        >
          Start date
        </div>
        <div
          className={[
            classes.flex,
            classes.flexRow,
            classes.textColor2,
            classes.textSize2,
            classes.childBorderX,
            classes.childBorderColor1,
            classes.spaceX1,
          ].join(" ")}
        >
          <div>{month}</div>
          <div>{day}</div>
          <div>{year}</div>
        </div>
      </div>
    </div>
  );
};

export default RadioCard;
