/**
 * Singleton OktaAuthAPI for handling Okta Actions
 * This module should be used to get access to Okta Auth Client
 */

import { OktaAuth } from "@okta/okta-auth-js";

const OktaAuthAPI = () => {
  let instance;
  let oktaAuthInstance;

  const initializeAuthClient = () => {
    /**
     * Returns the current auth client instance
     * This method will create the instance if not created already,
     * else this will return the existing initialized instance
     */
    const { protocol, host } = window.location;

    const getAuthClient = () => {
      const config = () => {
        // setting default issuer, cleintid and redirectURL to local setup
        let issuer = process.env.REACT_APP_LOCAL_ISSUER;
        let clientID = process.env.REACT_APP_LOCAL_CLIENT_ID;

        const redirectUri = `${protocol}//${host}${process.env.REACT_APP_CALLBACK_PATH}`;

        if (host === process.env.REACT_APP_PROD_HOST) {
          issuer = process.env.REACT_APP_PROD_ISSUER;
          clientID = process.env.REACT_APP_PROD_CLIENT_ID;
        }

        if (host === process.env.REACT_APP_STAGING_HOST) {
          issuer = process.env.REACT_APP_STAGING_ISSUER;
          clientID = process.env.REACT_APP_STAGING_CLIENT_ID;
        }

        if (host === process.env.REACT_APP_DEV_HOST) {
          issuer = process.env.REACT_APP_DEV_ISSUER;
          clientID = process.env.REACT_APP_DEV_CLIENT_ID;
        }

        if (host === process.env.REACT_APP_QA_HOST) {
          issuer = process.env.REACT_APP_QA_ISSUER;
          clientID = process.env.REACT_APP_QA_CLIENT_ID;
        }

        return {
          issuer: `${issuer}`,
          clientId: `${clientID}`,
          redirectUri: `${redirectUri}`,
          scopes: ["openid", "profile", "email"],
          pkce: false,
          tokenManager: {
            autoRenew: true,
            autoRenewMaxAge: 3600,
            storage: "localStorage",
          },
        };
      };

      if (!oktaAuthInstance) {
        oktaAuthInstance = new OktaAuth(config());
      }

      return oktaAuthInstance;
    };

    /**
     * Closes and signs out the current session by clearing all the tokens
     */
    const signOut = async () => {
      const oktaAuth = getAuthClient();

      const logoutURI = () => {
        if (host === process.env.REACT_APP_PROD_HOST) {
          return process.env.REACT_APP_PROD_LOGOUT_URI;
        }

        if (host === process.env.REACT_APP_STAGING_HOST) {
          return process.env.REACT_APP_STAGING_LOGOUT_URI;
        }

        if (host === process.env.REACT_APP_DEV_HOST) {
          return process.env.REACT_APP_DEV_LOGOUT_URI;
        }

        return process.env.REACT_APP_LOCAL_LOGOUT_URI;
      };

      await oktaAuth.signOut({
        revokeAccessToken: false,
        postLogoutRedirectUri: logoutURI(),
      });

      await oktaAuth.closeSession();

      localStorage.clear();
      sessionStorage.clear();
      oktaAuth.tokenManager.clear();
    };

    const getTokens = async () => {
      const oktaAuth = getAuthClient();
      const tokens = await oktaAuth.tokenManager.getTokens();

      return tokens;
    };

    const getTokensWithoutPrompt = async () => {
      const oktaAuth = getAuthClient();
      const tokens = await oktaAuth.token.getWithoutPrompt();

      return tokens;
    };

    return {
      getAuthClient,
      signOut,
      getTokens,
      getTokensWithoutPrompt,
    };
  };

  return {
    getAuthInstance() {
      if (!instance) {
        instance = initializeAuthClient();
      }

      return instance;
    },
  };
};

const authInstance = OktaAuthAPI().getAuthInstance();
const oktaAuth = authInstance.getAuthClient();
const { signOut } = authInstance;
const { getTokens } = authInstance;
const { getTokensWithoutPrompt } = authInstance;

export { oktaAuth, signOut, getTokens, getTokensWithoutPrompt };
