import React, { ComponentType } from "react";
import { ErrorBoundary } from "react-error-boundary";

import FallbackComponent from "./fallback.component";

const TrackComponentErrors = <P extends object>(
  WrappedComponent: ComponentType<P>
) => {
  const EnhancedComponent: React.FC<P> = (myProp) => {
    return (
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <WrappedComponent {...myProp} />
      </ErrorBoundary>
    );
  };

  return EnhancedComponent;
};

export default TrackComponentErrors;
