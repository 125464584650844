import { lazy, Suspense } from "react";

import SpinnerComponent from "../../componentsPhase1/Spinner/spinner";

const LoadRouteOnDemand = (loader) => {
  const ComponentToLoadOnDemand = lazy(loader);

  const LoadRouteOnDemand = (props) => {
    return (
      <Suspense fallback={<SpinnerComponent />}>
        <ComponentToLoadOnDemand {...props} />
      </Suspense>
    );
  };

  return LoadRouteOnDemand;
};

export default LoadRouteOnDemand;
