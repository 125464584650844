import classes from "./index.module.scss";

interface iProps {
  changeHandler: (type: string, value: string) => void;
  rateLow: string;
  rateHigh: string;
}

const MBOFormInput: React.FC<iProps> = ({
  changeHandler,
  rateHigh,
  rateLow,
}) => {
  return (
    <div className={classes.parent} data-testid="rangeparent">
      <div className={classes.rateLow_parent}>
        {rateLow && rateLow !== "" && (
          <span className={classes.rateLow_label_with_data}>
            {"Rate Low (Optional)"}
          </span>
        )}
        <input
          data-testid="ratelow"
          className={[
            classes.input,
            classes.rate_low,
            rateLow && rateLow?.trim() !== ""
              ? classes.rate_selected
              : classes.selected_fill,
          ].join(" ")}
          type="string"
          value={`${rateLow !== "" ? "$" : ""}${rateLow}`}
          placeholder="$ Rate Low (Optional)"
          name="Name"
          autoComplete="off"
          tabIndex={1}
          onChange={(e) => {
            const re = /^[0-9\b]+$/;
            const value = e.target.value.replace(/\$/gi, "");

            if (value === "" || (re.test(value) && !value.includes("."))) {
              changeHandler("rateLow", e.target.value === "$" ? "" : value);

              return;
            }

            if (
              re.test(value.replace(".", "")) &&
              value.includes(".") &&
              value.split(".")[1].length < 3
            ) {
              changeHandler("rateLow", e.target.value === "$" ? "" : value);
            }
          }}
        />
      </div>
      <div className={classes.rateHigh_parent} data-testid="rateHigh_parent">
        {rateHigh && rateHigh !== "" && (
          <span className={classes.rateHigh_label_with_data}>
            {"Rate High (Optional)"}
          </span>
        )}
        <input
          data-testid="ratehigh"
          className={[
            classes.input,
            classes.rate_high,
            rateHigh && rateHigh?.trim() !== ""
              ? classes.rate_selected
              : classes.selected_fill,
          ].join(" ")}
          type="string"
          value={`${rateHigh !== "" ? "$" : ""}${rateHigh}`}
          name="Name"
          autoComplete="off"
          placeholder="$ Rate High (Optional)"
          tabIndex={2}
          onChange={(e) => {
            const re = /^[0-9\b]+$/;
            const value = e.target.value.replace(/\$/gi, "");

            if (value === "" || (re.test(value) && !value.includes("."))) {
              changeHandler("rateHigh", e.target.value === "$" ? "" : value);

              return;
            }

            if (
              re.test(value.replace(".", "")) &&
              value.includes(".") &&
              value.split(".")[1].length < 3
            ) {
              changeHandler("rateHigh", e.target.value === "$" ? "" : value);
            }
          }}
        />
      </div>
      <select defaultChecked disabled className={classes.currency_drp}>
        <option value="first">USD</option>
      </select>
    </div>
  );
};

export default MBOFormInput;
