import API from "../API";
import PricingCalcApi from "../API/PricingCalcApi";

const UserLogActivity = async (logType: string) => {
  const authAccessToken =
    JSON.parse(localStorage.getItem("okta-token-storage") || "{}") || "";

  const authIdToken = authAccessToken?.idToken;
  const authIdTokenClaims = authIdToken?.claims;

  const userDetails: UserLogActivity = {
    name: authIdTokenClaims?.name,
    email: authIdTokenClaims?.email,
    preferredUserName: authIdTokenClaims?.preferred_username,
    groups: authIdTokenClaims?.groups,
    activity: logType,
  };

  const logUserLogicActivity = PricingCalcApi.logUserActivity(
    userDetails,
    authIdToken?.claims?.mbo_id
  );

  const userActivityDetails = await API.performRequest(logUserLogicActivity);

  return userActivityDetails;
};

export default UserLogActivity;
