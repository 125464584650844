import _ from "lodash";
import moment from "moment";
import { useEffect, useState, useContext } from "react";
import { AiFillMinusSquare, AiFillPlusSquare } from "react-icons/ai";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import API from "../../API";
import PricingCalcApi from "../../API/PricingCalcApi";
import NewAPI from "../../API/PricingCalcApiPhaseone";
import ValidateUser from "../../auth/validateUser";
import MinInfoPopUp from "../../components/ManageClientRules/components/MinDetalsPopUp.component";
import IconsName from "../../constants/IconsName";
import GlobalSpinnerContext from "../../contexts/LoaderContext";
import GlobalToastContext from "../../contexts/ToastContext";
import useQueryData from "../../hooks/useQueryData";
import {
  clientListApiHandler,
  businessRulesApiCallHandler,
  dropdownApicallHander,
} from "../../pages/Details/details.logichandler";
import Button from "../Button/Button";
import Icon from "../Icon";
import SectionConatiner from "../SectionConatiner";
import SimulatePayBill from "../SimulateComponent/SimulatePayBill";
import AttributionComponent from "../SimulateComponent/components/AttributionComponent";
import ProposedRateComponent from "../SimulateComponent/components/ProposedRateComponent";
import RatesComponent from "../SimulateComponent/components/RatesComponent";
import Toast from "../Toast/Toast";

import ClientListComponent from "./components/clientList.component";
import OptionalRulesComponent from "./components/optionalRules.component";
import StandardRulesComponent from "./components/standardRules.component";
import StandarddRuleFilters from "./components/standardRulesFilters.component";

import "./quoteRates.scss";

export interface optionsProp {
  value: number;
  label: string;
  clientId?: number;
  engagementType?: string;
  mainDivision?: string;
  country?: string;
}

export interface workOrderStateProps {
  name: string;
  description: string;
  group: optionsProp | null | unknown;
  operation: optionsProp | null;
  attribution: optionsProp | null;
  amount: string;
}

interface HandleClickIProps {
  payRate: string;
  type: string;
}

interface standardRuleFilterprop {
  city: optionsProp[];
  country: optionsProp[];
  mainDivision: optionsProp[];
}
export interface selectedfilterProps {
  city: optionsProp | null;
  country: optionsProp | null;
  mainDivision: optionsProp | null;
  engagementType: optionsProp | null;
  effectiveDate: Date | null;
  rate?: number | string;
}

export interface getLocationProps {
  clients: {
    id: number;
    name: string;
    engagementTypes: {
      name: string;
      isRatePresent: boolean;
      countries: {
        name: string;
        states: {
          name: string;
          cities: {
            name: string;
          }[];
        }[];
      }[];
    }[];
  }[];
}

const customStylesDelete = {
  content: {
    top: "48%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "35vh",
    width: "35vw",
    boxShadow: "0 2px 13px rgb(62 48 116)",
    zIndex: 9999,
    padding: "0",
    borderRadius: "5px",
    border: "none",
  },
};

const MangeRules = () => {
  const history = useNavigate();
  const [clientList, setClientList] = useState<optionsProp[] | []>([]);
  const { EnableToast, toastMessage } = useContext(GlobalToastContext);

  const [updateEngagementType, setUpdateEngagementType] = useState<
    string | undefined
  >("");

  const [toggleStandardRules, setToggleStandardRules] =
    useState<boolean>(false);

  const [businessStandardRules, setBusinessStandardRules] = useState<
    QratesManageStandardRules[] | []
  >();

  const [businessOptoinalRules, setBusinessOptionalRules] = useState<
    QratesManageOptionalRules[] | []
  >();

  const [selectedClient, setSelectedClient] = useState<optionsProp | null>(
    null
  );

  const [payToBillProposedRate, setPayToBillProposedRate] = useState<
    number | string
  >(""); // simulate proposed rate

  const [billToPayProposedRate, setBillToPayProposedRate] = useState<
    number | string
  >(""); // simulate proposed rate

  const [simulatePayToBillData, setSimulatePayToBillData] = useState<
    CalcAtributes["data"] | null
  >(null);

  const [simulateBillToPayData, setSimulateBillToPayData] = useState<
    CalcAtributes["data"] | null
  >(null);

  const [simulateBillRateError, SetSimulateBillRateError] =
    useState<string>("");

  const [simulatePayRateError, SetSimulatePayRateError] = useState<string>("");
  const { SetLoader } = useContext(GlobalSpinnerContext);
  const [loadParentRules, setLoadParentRules] = useState<boolean>(false);

  const [engagementTypeData, setEngagementTypeData] = useState<
    optionsProp[] | undefined
  >();

  const [locationInfo, setLocationInfo] = useState<boolean>(false);

  const [calculateBtnClick, setCalculateBtnClick] =
    useState<HandleClickIProps | null>(null);

  const [isOptionalRuleClicked, setOptionalRuleClicked] = useState<string>("");

  const standardRuleFilterinitialState = {
    city: { label: "", value: 0 },
    country: { label: "", value: 0 },
    mainDivision: { label: "", value: 0 },
    engagementType: { label: "", value: 0 },
    effectiveDate: null,
  };

  const [selectedFilterState, setSelectedFilterState] =
    useState<selectedfilterProps>(standardRuleFilterinitialState);

  const [locationData, setLocationData] = useState<getLocationProps | null>(
    null
  );

  const [showMandatoryError, setMandatoryError] = useState(true);

  const standardRulesFilterInitialSate = {
    city: [{ label: "", value: 0 }],
    country: [{ label: "", value: 0 }],
    mainDivision: [{ label: "", value: 0 }],
  };

  const validUser = ValidateUser() as UserGroups[] | [];

  const [standardRulesFilters, setStandarduRulesFilters] =
    useState<standardRuleFilterprop>(standardRulesFilterInitialSate);

  const [filteredStandardRules, setFilteredStandardRules] =
    useState<standardRuleFilterprop>(standardRulesFilterInitialSate);

  const [dropdownRules, setDropdownRules] = useState<standardRuleFilterprop>(
    standardRulesFilterInitialSate
  );

  function settingFilteredSR() {
    const city = standardRulesFilters.city.filter(
      (item) =>
        item.engagementType === selectedFilterState.engagementType?.label
    );

    city.length === 0 && city.push({ label: "", value: 0 });
    const country = standardRulesFilters.country.filter(
      (item) =>
        item.engagementType === selectedFilterState.engagementType?.label
    );

    country.length === 0 && country.push({ label: "", value: 0 });
    const mainDivision = standardRulesFilters.mainDivision.filter(
      (item) =>
        item.engagementType === selectedFilterState.engagementType?.label
    );

    mainDivision.length === 0 && mainDivision.push({ label: "", value: 0 });
    const newFilterValue = {
      ...standardRulesFilters,
      country,
      city,
      mainDivision,
    };

    return newFilterValue;
  }

  useEffect(() => {
    setDropdownRules(settingFilteredSR());
  }, [selectedFilterState.engagementType?.label]);

  useEffect(() => {
    const userPermission =
      (validUser && validUser.length < 1) ||
      (validUser.length > 0 && +validUser[0].accessLevel < 0);

    if (userPermission) {
      history("/phase1", { replace: true });

      return;
    }

    (async () => {
      try {
        SetLoader(true);
        const clientListData = await clientListApiHandler();

        setClientList(
          clientListData.data.clients.map((e: ClientListObj) => ({
            label: e.jobdivaCompanyName,
            value: e.id,
            clientId: "parentClientId" in e ? e.parentClientId : "",
          }))
        );
      } catch (e) {
        EnableToast({
          message: "Something went wrong, please try again later",
          type: "error",
        });
        SetLoader(false);

        return;
      }

      SetLoader(false);
    })();
  }, []);

  const filterData = (type: string) => {
    const filteredData = _.uniqBy(
      _.filter(
        _.map(businessStandardRules, (rule, i) => {
          const label = rule[type as keyof typeof rule];
          const { engagementType, country, mainDivision } = rule;

          return {
            value: i,
            label,
            engagementType,
            country,
            mainDivision,
          };
        }),
        (e) => e.label !== "" && e.label !== null
      ),
      "engagementType"
    );

    return filteredData;
  };

  useEffect(() => {
    (async () => {
      setLocationInfo(false);

      if (
        !engagementTypeData ||
        (engagementTypeData && engagementTypeData.length < 1)
      ) {
        const { engagementTypes } = await dropdownApicallHander();

        setEngagementTypeData(
          engagementTypes &&
            (engagementTypes.map((item) => ({
              value: item.id,
              label: item.name,
            })) ||
              [])
        );
      }

      if (businessStandardRules && businessStandardRules.length > 0) {
        const city: optionsProp[] =
          filterData("city").length > 0
            ? filterData("city")
            : standardRulesFilterInitialSate.city;

        const country: optionsProp[] =
          filterData("country").length > 0
            ? filterData("country")
            : standardRulesFilterInitialSate.country;

        const mainDivision: optionsProp[] =
          filterData("mainDivision").length > 0
            ? filterData("mainDivision")
            : standardRulesFilterInitialSate.mainDivision;

        const isStandardRulesFiltersDataAvailable =
          Object.values(standardRulesFilters)
            .map((e) => e[0].label)
            .findIndex((label) => label !== "") < 0;

        if (isStandardRulesFiltersDataAvailable) {
          setStandarduRulesFilters({
            ...standardRulesFilterInitialSate,
            city,
            country,
            mainDivision,
          });
          setFilteredStandardRules({
            ...standardRulesFilterInitialSate,
            city,
            country,
            mainDivision,
          });
        }
      }
    })();
  }, [businessStandardRules]);

  const rulesStateHandler = async (
    clientDetails: optionsProp,
    params?: string,
    isClientChanged: boolean = false
  ) => {
    const businessRulesResponse = await businessRulesApiCallHandler(
      clientDetails.value,
      params
    );

    const { standardRules, optionalRules }: QratesManageBusinessRules["data"] =
      businessRulesResponse.data;

    setBusinessStandardRules(
      standardRules
        ? standardRules.map((rule) => {
            let amoutToFormat: ManageBusinessRuleCurrency = rule.amount;

            if (rule.amount.localized === "percentage") {
              amoutToFormat = {
                ...rule.amount,
                raw: rule.amount.raw ? rule.amount.raw : "",
              };
            } else {
              amoutToFormat = {
                ...rule.amount,
                raw: rule.amount.raw ? Number(rule.amount.raw) / 100 : "",
              };
            }

            return {
              ...rule,
              amount: { ...amoutToFormat },
              isChecked: false,
            };
          })
        : []
    );

    if (isClientChanged) {
      setBusinessOptionalRules(
        optionalRules
          ? optionalRules.map((rule) => {
              let amoutToFormat: ManageBusinessRuleCurrency = rule.amount;

              if (rule.amount.localized === "percentage") {
                amoutToFormat = {
                  ...rule.amount,
                  raw: rule.amount.raw ? rule.amount.raw : "",
                };
              } else {
                amoutToFormat = {
                  ...rule.amount,
                  raw: rule.amount.raw ? Number(rule.amount.raw) / 100 : "",
                };
              }

              return {
                ...rule,
                amount: { ...amoutToFormat },
                isChecked: false,
              };
            })
          : []
      );
    }

    return clientDetails;
  };

  const getLocation = useQueryData(
    "location-list",
    {
      clientId: `clientid in (${selectedClient?.value || ""})`,
    },
    false,
    NewAPI.getLocationOpts
  );

  useEffect(() => {
    selectedClient && selectedClient.value && getLocation.refetch();
  }, [selectedClient?.value]);

  const clientChangeHandler = async (clientDetails: unknown) => {
    SetLoader(true);
    setSimulatePayToBillData(null);
    setBusinessOptionalRules([]);
    setBusinessStandardRules([]);
    setStandarduRulesFilters(standardRulesFilterInitialSate);
    setBillToPayProposedRate("");
    setPayToBillProposedRate("");
    setSelectedFilterState(standardRuleFilterinitialState);
    setToggleStandardRules(true);
    setLoadParentRules(false);
    setSelectedClient(null);
    setLocationInfo(false);
    setMandatoryError(true);
    const clientData = clientDetails as optionsProp;

    if (!clientData) {
      setSelectedClient(null);
      SetLoader(false);
      setBillToPayProposedRate("");
      setPayToBillProposedRate("");
      setSimulateBillToPayData(null);
      setSimulatePayToBillData(null);
      setToggleStandardRules(false);
      setEngagementTypeData(undefined);

      return;
    }

    const returnedClientDetails = await rulesStateHandler(clientData, "", true);

    setSelectedClient({
      value: returnedClientDetails.value,
      label: returnedClientDetails.label,
      clientId: returnedClientDetails.clientId,
    });
    SetLoader(false);
  };

  const manageRuleHandler = (recordid: string | number, type: string) => {
    if (type === "standard") {
      setBusinessStandardRules((prevState) =>
        prevState?.map((e) =>
          recordid === e.id ? { ...e, isChecked: !e.isChecked } : { ...e }
        )
      );
    }

    if (type === "optional") {
      setBusinessOptionalRules((prevState) =>
        prevState?.map((e) =>
          recordid === e.id ? { ...e, isChecked: !e.isChecked } : { ...e }
        )
      );
    }
  };

  useEffect(() => {
    try {
      if (getLocation.error) {
        // showBoundary("something went wrong");
        console.log(getLocation.error);

        return;
      }

      if (getLocation.data) {
        setLocationData(getLocation.data.data);
      }
    } catch (error) {
      console.log(error);
      // showBoundary("Something went wrong, Please try again later");
    }
  }, [getLocation.data, getLocation.isLoading, getLocation.error]);

  useEffect(() => {
    (async () => {
      if (calculateBtnClick) {
        const isEngagementTypeEmpty =
          !selectedFilterState.engagementType ||
          selectedFilterState.engagementType?.label === "";

        const isEffectiveEmtpy = !selectedFilterState.effectiveDate;

        if (
          calculateBtnClick.type === "calculatePayToBill" &&
          payToBillProposedRate === ""
        ) {
          EnableToast({
            message: "Proposed Pay Rate cannot be empty",
            type: "warning",
          });

          return;
        }

        if (
          calculateBtnClick.type !== "calculatePayToBill" &&
          billToPayProposedRate === ""
        ) {
          EnableToast({
            message: "Proposed Bill Rate cannot be empty",
            type: "warning",
          });

          return;
        }

        if (showMandatoryError) {
          EnableToast({
            message: "Apply mandatory filters to simulate rates",
            type: "warning",
          });

          return;
        }

        if (isEffectiveEmtpy) {
          EnableToast({
            message: "Effective Date cannot be empty",
            type: "warning",
          });
          setSimulatePayToBillData(null);
          setSimulateBillToPayData(null);

          return;
        }

        if (isEngagementTypeEmpty) {
          EnableToast({
            message: "Engagement Type cannot be empty",
            type: "warning",
          });

          setSimulatePayToBillData(null);
          setSimulateBillToPayData(null);

          return;
        }

        SetLoader(true);
        const proposedPayRate =
          calculateBtnClick.payRate ||
          (calculateBtnClick.type === "calculatePayToBill"
            ? payToBillProposedRate
            : billToPayProposedRate);

        const rateType =
          calculateBtnClick.type === "calculatePayToBill"
            ? "payRate"
            : "billRate";

        const payload = {
          [rateType]: `${proposedPayRate}`,
          isMilestone: false,
          clientName: selectedClient?.label || "",
          optionalRuleIds:
            businessOptoinalRules && businessOptoinalRules.length > 0
              ? businessOptoinalRules
                  ?.filter((e) => e.isChecked)
                  .map((e) => Number(e.id))
              : [],
          standardRuleIds: businessStandardRules
            ? businessStandardRules.map((rule) => Number(rule.id))
            : [],
        };

        const request = PricingCalcApi.quoteRateSiulation(
          payload,
          calculateBtnClick.type
        );

        const response = await API.performRequest(request);
        const { data, errors } = response.data;
        const isDataAvilable = Object.keys(data).length > 0;

        SetLoader(false);

        if (calculateBtnClick.type === "calculatePayToBill") {
          if (isDataAvilable && errors && errors.length === 0) {
            setSimulatePayToBillData(data);
            SetSimulatePayRateError("");

            return;
          }

          if (isDataAvilable && errors && errors.length > 0) {
            setSimulatePayToBillData(data);
            SetSimulatePayRateError(errors[0].message);

            return;
          }

          SetSimulatePayRateError(errors[0].message);
          setSimulatePayToBillData(null);
        }

        if (calculateBtnClick.type === "calculateBillToPay") {
          if (isDataAvilable && errors.length === 0) {
            setSimulateBillToPayData(data);
            SetSimulateBillRateError("");

            return;
          }

          if (isDataAvilable && errors.length > 0) {
            setSimulateBillToPayData(data);
            SetSimulateBillRateError(errors[0].message);

            return;
          }

          SetSimulateBillRateError(errors[0].message);
          setSimulateBillToPayData(null);
        }

        setCalculateBtnClick(null);
      }
    })();
  }, [calculateBtnClick]);

  const changeFilterData = (
    type: Date | optionsProp | unknown,
    property: string
  ) => {
    setSelectedFilterState({
      ...selectedFilterState,
      [property]: type,
    });

    if (
      selectedFilterState.effectiveDate &&
      selectedFilterState.engagementType &&
      selectedFilterState.engagementType.label !== "" &&
      selectedFilterState.city?.label !== "" &&
      selectedFilterState.mainDivision?.label !== "" &&
      selectedFilterState.country?.label !== "" &&
      selectedFilterState.rate !== ""
    ) {
      setMandatoryError(false);
    }

    if (
      (property === "city" && type !== selectedFilterState.city) ||
      (property === "mainDivision" &&
        type !== selectedFilterState.mainDivision) ||
      (property === "country" && type !== selectedFilterState.country) ||
      (property === "engagementType" &&
        type !== selectedFilterState.engagementType) ||
      (property === "rate" && type !== selectedFilterState.rate)
    ) {
      setMandatoryError(true);
      setBusinessStandardRules([]);
    }
  };

  useEffect(() => {
    setSelectedFilterState({
      ...selectedFilterState,
      city: { label: "", value: 0 },
    });
  }, [selectedFilterState.mainDivision]);

  useEffect(() => {
    setSelectedFilterState({
      ...selectedFilterState,
      mainDivision: { label: "", value: 0 },
    });
  }, [selectedFilterState.country]);

  useEffect(() => {
    setSelectedFilterState({
      ...selectedFilterState,
      country: { label: "", value: 0 },
    });
  }, [selectedFilterState.engagementType]);

  const simulationCalculation = async (payRate: string, type: string) => {
    setCalculateBtnClick((prevState) => ({ ...prevState, payRate, type }));
  };

  const changeProposedValue = (value: number, type: string) => {
    if (type === "calculateBillToPay") {
      setBillToPayProposedRate(value === 0 ? "" : value);

      return;
    }

    if (
      selectedFilterState.rate &&
      selectedFilterState.rate !== "" &&
      +selectedFilterState.rate !== value
    ) {
      SetSimulatePayRateError(
        "Proposed Pay rate is different from Rate values given in the above filter"
      );
    } else {
      SetSimulatePayRateError("");
    }

    if (type === "calculatePayToBill") {
      setPayToBillProposedRate(value === 0 ? "" : value);
    }
  };

  const FilterstandardRules = async (clearFilters: boolean) => {
    if (clearFilters) {
      setSimulatePayToBillData(null);
      setSimulateBillToPayData(null);
      setBillToPayProposedRate("");
      setPayToBillProposedRate("");
      setSelectedFilterState(standardRuleFilterinitialState);
      setSimulateBillToPayData(null);
      setMandatoryError(true);
      setLocationInfo(false);

      selectedClient && (await rulesStateHandler(selectedClient, "", false));

      return;
    }

    setSimulateBillToPayData(null);
    setSimulatePayToBillData(null);
    setBillToPayProposedRate("");
    selectedFilterState.rate &&
      setPayToBillProposedRate(selectedFilterState.rate);

    if (
      selectedFilterState.effectiveDate &&
      selectedFilterState &&
      selectedFilterState.engagementType &&
      selectedFilterState.engagementType.label !== ""
    ) {
      setMandatoryError(false);
    } else {
      setMandatoryError(true);
    }

    if (
      selectedFilterState.effectiveDate &&
      selectedFilterState?.engagementType?.label !== ""
    ) {
      setFilteredStandardRules(settingFilteredSR());
      setUpdateEngagementType(selectedFilterState.engagementType?.label);
    }

    SetLoader(true);
    setToggleStandardRules(true);
    let params = `?${
      selectedFilterState && selectedFilterState.effectiveDate
        ? `filter=effectiveDate in (${moment(
            selectedFilterState.effectiveDate
          ).format("YYYY-MM-DD")})`
        : ""
    }${
      selectedFilterState &&
      selectedFilterState.engagementType &&
      selectedFilterState.engagementType?.label !== ""
        ? `&filter=engagementType in (${selectedFilterState.engagementType.label})`
        : ""
    }${
      selectedFilterState &&
      selectedFilterState.country &&
      selectedFilterState.country.label !== ""
        ? `&filter=country in (${selectedFilterState.country.label})`
        : ""
    }${
      selectedFilterState &&
      selectedFilterState.mainDivision &&
      selectedFilterState.mainDivision.label !== ""
        ? `&filter=mainDivision in (${selectedFilterState.mainDivision.label})`
        : ""
    }${
      selectedFilterState &&
      selectedFilterState.city &&
      selectedFilterState.city.label !== ""
        ? `&filter=city in (${selectedFilterState.city.label})`
        : ""
    }${
      selectedFilterState &&
      selectedFilterState.rate &&
      selectedFilterState.rate !== ""
        ? `&filter=rate in (${selectedFilterState.rate})`
        : ""
    }`;

    if (selectedFilterState && !selectedFilterState.effectiveDate) {
      params = params.replace("&", "");
    }

    if (selectedClient) {
      await rulesStateHandler(selectedClient, params, false);
    }

    SetLoader(false);
  };

  useEffect(() => {
    if (
      businessOptoinalRules &&
      businessOptoinalRules.length === 0 &&
      businessStandardRules &&
      businessStandardRules.length === 0 &&
      selectedClient &&
      Boolean(selectedClient.clientId)
    ) {
      setLoadParentRules(true);
    }
  }, [businessOptoinalRules, businessStandardRules]);

  const toggleParentRuleConrimationPopUp = () => {
    setLoadParentRules(false);
  };

  useEffect(() => {
    if (
      isOptionalRuleClicked &&
      businessStandardRules &&
      businessStandardRules.length > 0
    ) {
      const isCountryAvailable =
        filteredStandardRules.country.length > 0 &&
        filteredStandardRules.country[0].label !== "";

      const isStateAvailable =
        filteredStandardRules.mainDivision.length > 0 &&
        filteredStandardRules.mainDivision[0].label !== "";

      const isCityAvailable =
        filteredStandardRules.city.length > 0 &&
        filteredStandardRules.city[0].label !== "";

      if (
        isOptionalRuleClicked &&
        ((isOptionalRuleClicked === "Country" && !isCountryAvailable) ||
          (isOptionalRuleClicked === "State" && !isStateAvailable) ||
          (isOptionalRuleClicked === "City" && !isCityAvailable))
      ) {
        setLocationInfo(true);
      }

      setOptionalRuleClicked("");
    }
  }, [isOptionalRuleClicked]);

  return (
    <div className="manageRulesParent">
      <MinInfoPopUp styleObj={customStylesDelete} isOpen={loadParentRules}>
        <div className="manageRulesParent__main__parentRuleconfirmation">
          <span>
            No Rules Available for
            <br /> <h4>{selectedClient && selectedClient.label}</h4>
          </span>
          <span>Do you want to load Parent Business Rules?</span>
          <div>
            <Button
              name="Cancel"
              onClick={() => toggleParentRuleConrimationPopUp()}
            />
            <Button
              onClick={() => {
                setSelectedClient(null);
                const parentDetails =
                  selectedClient &&
                  clientList.filter((e) => e.value === selectedClient.clientId);

                if (parentDetails && parentDetails.length > 0) {
                  parentDetails[0].value =
                    (selectedClient.clientId && selectedClient.clientId) || 0;

                  clientChangeHandler(parentDetails[0]);
                }
              }}
              name="Proceed"
            />
          </div>
        </div>
      </MinInfoPopUp>
      {toastMessage.message !== "" ? <Toast /> : null}
      <div className="manageRulesParent__main">
        <ClientListComponent
          clientList={clientList}
          selectedClient={selectedClient}
          clientChangeHandler={(item) => {
            void clientChangeHandler(item);
          }}
          clientsComponentsClasses={{
            parent: `manageRulesParent__main__clientListParent`,
            label: "manageRulesParent__main__clientListParent__clientListlabel",
            list: "manageRulesParent__main__clientListParent__clientList",
          }}
        />
        {locationInfo && selectedClient && selectedClient.label !== "" && (
          <div className="manageRulesParent__main__locationerror_parent">
            <div className="manageRulesParent__main__locationerror_parent__message">
              <div>
                <BsFillInfoCircleFill height={7} width={7} />
              </div>
              <span>
                No
                {filteredStandardRules.country[0].label === "" ? (
                  <p>
                    &nbsp;Country
                    {filteredStandardRules.city[0].label === "" &&
                    filteredStandardRules.mainDivision[0].label === ""
                      ? "/"
                      : ""}
                    {filteredStandardRules.city[0].label === "" &&
                    filteredStandardRules.mainDivision[0].label !== ""
                      ? "/"
                      : ""}
                  </p>
                ) : (
                  ""
                )}
                {filteredStandardRules.mainDivision[0].label === "" ? (
                  <p>
                    &nbsp;State
                    {filteredStandardRules.city[0].label === "" ? "/" : ""}
                  </p>
                ) : (
                  ""
                )}
                {filteredStandardRules.city[0].label === "" ? (
                  <p>&nbsp;City </p>
                ) : (
                  ""
                )}
                &nbsp;specific rules are available for&nbsp;
                <p>{selectedClient?.label}</p>
                &nbsp;when the effective date is&nbsp;
                <p>
                  {
                    selectedFilterState.effectiveDate
                      ?.toLocaleString()
                      .split(",")[0]
                  }
                </p>
                &nbsp;and the EngagementType is&nbsp;
                <p>{updateEngagementType}</p>
              </span>
            </div>
          </div>
        )}

        <SectionConatiner
          title="Standard Rules"
          className={`${
            !toggleStandardRules ? "toggleSectionContainerHeight" : ""
          } qratesSectionContainer`}
          onClick={
            businessStandardRules && businessStandardRules.length > 0
              ? () => {
                  setToggleStandardRules(!toggleStandardRules);
                }
              : () => {}
          }
          icon={
            toggleStandardRules ? <AiFillMinusSquare /> : <AiFillPlusSquare />
          }
        >
          <StandarddRuleFilters
            locationData={locationData}
            changeFilterData={changeFilterData}
            engagementTypeData={engagementTypeData}
            filteredData={dropdownRules}
            className={`manageRulesParent__main__standrdRuleFilterParent`}
            selectedFilterState={selectedFilterState}
            FilterstandardRules={(clearFilters: boolean) => {
              void FilterstandardRules(clearFilters);
            }}
            setOptionalRuleClicked={setOptionalRuleClicked}
          />
          {((businessStandardRules && businessStandardRules.length === 0) ||
            !businessStandardRules) && (
            <div
              className={`qrates_nodatafound_parent ${
                !toggleStandardRules ? "toggleSectionContainerHeight" : ""
              }`}
            >
              <p className="snodatafoundText">No Data </p>
            </div>
          )}

          {!toggleStandardRules &&
            businessStandardRules &&
            businessStandardRules.length > 0 && (
              <p className="standardruletoggleText">
                Expand to view the standard rules
              </p>
            )}

          {businessStandardRules && businessStandardRules.length > 0 ? (
            <StandardRulesComponent
              businessStandardRules={businessStandardRules}
            />
          ) : null}
        </SectionConatiner>

        <SectionConatiner
          title="Optional Rules"
          className="qratesSectionContainer"
        >
          {((businessOptoinalRules && businessOptoinalRules.length === 0) ||
            !businessOptoinalRules) && (
            <div className="qrates_nodatafound_parent">
              <Icon className="nodatafound" name={IconsName.NODATAFOUND} />
              <p className="nodatafoundText">No Data </p>
            </div>
          )}
          {businessOptoinalRules && businessOptoinalRules.length > 0 && (
            <OptionalRulesComponent
              businessOptionalRules={businessOptoinalRules}
              manageRuleHandler={manageRuleHandler}
            />
          )}
        </SectionConatiner>
        {((businessStandardRules && businessStandardRules.length > 0) ||
          (businessOptoinalRules && businessOptoinalRules.length > 0)) && (
          <SectionConatiner title="Simulate Pay to Bill">
            <div className="pricingbody__details__simulateSection">
              {showMandatoryError &&
                selectedClient &&
                selectedClient.label !== "" && (
                  <div className="manageRulesParent__main__calculate_validation_parent">
                    <div className="manageRulesParent__main__calculate_validation_parent__error">
                      <Icon
                        width={100}
                        height={35}
                        name={IconsName.MESSAGEICON}
                      />
                      <p>Apply mandatory filters to simulate rates</p>
                    </div>
                  </div>
                )}
              <SimulatePayBill>
                <ProposedRateComponent
                  proporsedPayRate={payToBillProposedRate}
                  handleCalculate={(payrate, type) => {
                    void simulationCalculation(payrate, type);
                  }}
                  changeProposedValue={changeProposedValue}
                  typeOfRate="Proposed Pay Rate"
                  type="calculatePayToBill"
                  payRateError={simulatePayRateError}
                  disableBtn={showMandatoryError}
                />
                {simulatePayToBillData && (
                  <div className="simulatepay__body">
                    <RatesComponent
                      paytoBill={true}
                      screen={
                        selectedFilterState.engagementType?.label ===
                        "Payroll Services"
                          ? "simulation"
                          : null
                      }
                      alterClassName="simulatepay__body__quoterate_rates"
                      rates={{
                        calculateRates: {
                          payRate: simulatePayToBillData.payRate.raw,
                          netPayRate: simulatePayToBillData.netPayRate.raw,
                          billRate: simulatePayToBillData.billRate.raw,
                          payRateOtDt: simulatePayToBillData.billRate.raw,
                          label: "Calculated Rates",
                          index: `c${0}`,
                          isJdRate: false,
                          payRateOt: null,
                          payRateDt: null,
                          markUp: "",
                        },
                      }}
                    />
                    <AttributionComponent
                      attributions={simulatePayToBillData.attributions}
                      index={0}
                      alterGridSpacing="simulatepay__body__quoterate_attribution"
                    />
                  </div>
                )}
              </SimulatePayBill>
            </div>
          </SectionConatiner>
        )}

        {((businessStandardRules && businessStandardRules.length > 0) ||
          (businessOptoinalRules && businessOptoinalRules.length > 0)) && (
          <SectionConatiner title="Simulate Bill to To Pay">
            <div className="pricingbody__details__simulateSection">
              {showMandatoryError &&
                selectedClient &&
                selectedClient.label !== "" && (
                  <div className="manageRulesParent__main__calculate_validation_parent">
                    <div className="manageRulesParent__main__calculate_validation_parent__error">
                      <Icon
                        width={100}
                        height={35}
                        name={IconsName.MESSAGEICON}
                      />
                      <p>Apply mandatory filters to simulate rates</p>
                    </div>
                  </div>
                )}
              <SimulatePayBill>
                <ProposedRateComponent
                  proporsedPayRate={billToPayProposedRate}
                  handleCalculate={(payrate, type) => {
                    void simulationCalculation(payrate, type);
                  }}
                  changeProposedValue={changeProposedValue}
                  typeOfRate="Proposed Bill Rate"
                  type="calculateBillToPay"
                  payRateError={simulateBillRateError}
                  disableBtn={showMandatoryError}
                />
                {simulateBillToPayData && (
                  <div className="simulatepay__body">
                    <RatesComponent
                      paytoBill={false}
                      screen={
                        selectedFilterState.engagementType?.label ===
                        "Payroll Services"
                          ? "simulation"
                          : null
                      }
                      alterClassName="simulatepay__body__quoterate_rates"
                      rates={{
                        calculateRates: {
                          payRate: simulateBillToPayData.payRate.raw,
                          netPayRate: simulateBillToPayData.netPayRate.raw,
                          billRate: simulateBillToPayData.billRate.raw,
                          payRateOtDt: simulateBillToPayData.payRate.raw,
                          label: "Calculated Rates",
                          index: `c${0}`,
                          isJdRate: false,
                          payRateOt: null,
                          payRateDt: null,
                          markUp: "",
                        },
                      }}
                    />
                    <AttributionComponent
                      attributions={simulateBillToPayData.attributions}
                      index={0}
                      alterGridSpacing="simulatepay__body__quoterate_attribution"
                    />
                  </div>
                )}
              </SimulatePayBill>
            </div>
          </SectionConatiner>
        )}
      </div>
    </div>
  );
};

export default MangeRules;
