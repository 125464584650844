import React, { useEffect } from "react";

import Button from "../components/Button/Button";

const NotFound: React.FC = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.replace("/");
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="bg-[#211551] text-3xl h-screen text-white grid place-content-center">
      <h1 className="text-center mb-5 font-bold">PAGE NOT FOUND</h1>
      <h1 className="text-base w-3/5 text-center mx-auto mb-5">
        We are sorry, the page you requested could not be found. Page will be
        redirected to home page in 5 seconds or please click the below button
      </h1>
      <Button
        name="GO HOME"
        onClick={() => window.location.replace("/")}
        disabled={false}
      />
    </div>
  );
};

export default NotFound;
