import React from "react";

import "./Number.scss";

interface InputProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: number | string;
  className?: string;
  placeholder: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  labelClassName?: string;
}

const NumberInput: React.FC<InputProps> = ({
  className,
  placeholder,
  onChange,
  value,
  inputRef,
  labelClassName,
}) => {
  return (
    <label className={["numLabel", labelClassName].join(" ")} htmlFor="num">
      {value && <span className="dollar">$</span>}
      <input
        id="num"
        type="number"
        className={["number_input", className].join(" ")}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        ref={inputRef}
      />
    </label>
  );
};

export default NumberInput;
