import { AxiosResponse } from "axios";
import hotkeys from "hotkeys-js";
import parseToHtml from "html-react-parser";
import moment from "moment";
import React, { useEffect, useRef, useState, useContext } from "react";
import { FaPrint } from "react-icons/fa";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import API from "../../API";
import PricingCalcApi from "../../API/PricingCalcApi";
import ValidateUser from "../../auth/validateUser";
import BusinessRulesComponent from "../../components/BusinessRulesComponent/businessRulesComponent";
import Button from "../../components/Button/Button";
import FloatingActionButton from "../../components/FloatingActionButton/floatingActionButton";
import Icon from "../../components/Icon";
import Input from "../../components/Input";
import JobDiva from "../../components/JobDiva/JobDiva";
import PricingCalc from "../../components/PricingCalc/PricingCalc";
import PrintWorkOrderRules from "../../components/PrintWorkOrderDetailsComponent/printWorkOrder";
import RulesTable, {
  rulesTableDataProp,
} from "../../components/RulesTable/RulesTable";
import SectionConatiner from "../../components/SectionConatiner";
import SimulatePayBill from "../../components/SimulateComponent/SimulatePayBill";
import AttributionComponent from "../../components/SimulateComponent/components/AttributionComponent";
import ProposedRateComponent from "../../components/SimulateComponent/components/ProposedRateComponent";
import RatesComponent from "../../components/SimulateComponent/components/RatesComponent";
import Toast from "../../components/Toast/Toast";
import WorkOrder from "../../components/WorkOrder/WorkOrder";
import {
  optionsProp,
  workOrderStateProps,
} from "../../components/WorkOrder/WorkOrderBody";
import IconsName from "../../constants/IconsName";
import { componentNames } from "../../constants/userGroups";
import GlobalSpinnerContext from "../../contexts/LoaderContext";
import GlobalToastContext from "../../contexts/ToastContext";
import { headerA, headerB } from "../../data/dummyData";
import formatText from "../../helpers/formatText";

import {
  createCustomAppliedRulesPayload,
  createWorkorderAppliedRulesPayload,
  getCalcSetingsDetails,
  getRulesTableData,
  checkExistingDateOld,
  clientListApiHandler,
  dropdownApicallHander,
  validateRate,
  updateTimeStamp,
} from "./details.logichandler";

import "./Details.scss";

type ProposedRateProps = number;

interface TableIProps {
  [key: string]: string | number;
}

const Details = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const PricingBodyRef = useRef<HTMLInputElement>(null);
  const printRightClick = useRef<HTMLInputElement>(null);
  const history = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const pricingCalcInfo = location.state?.pricingCalcInfo;
  const jobDivaInfo = location.state?.jobDivaInfo;
  const { SetLoader } = useContext(GlobalSpinnerContext);
  const { EnableToast, toastMessage } = useContext(GlobalToastContext);
  const [saveEditedCalcSettingsId, SetEditedCalcSettingsId] = useState("");
  const [validateEffectiveDate, setValidteEffectiveDate] = useState("");

  const [jobDivaDetails, setJobDivaDetails] =
    useState<ProjectDetailsAPIResponseData | null>(null); // user details from Job Diva

  const [pricingCalcData, setPricingCalcData] =
    useState<PricingCalcSettingsObjData | null>(null); // user applied pricing calc rules

  const [payToBillProposedRate, setPayToBillProposedRate] = useState<
    ProposedRateProps | string
  >(""); // simulate proposed rate

  const [billToPayProposedRate, setBillToPayProposedRate] = useState<
    ProposedRateProps | string
  >(""); // simulate proposed rate

  const [effectiveDate, setEffectiveDate] = useState<Date | null>(null); // effective Date

  const [editedRuleEffectiveDate, setEditedRuleEffectiveDate] =
    useState<Date | null>(null); // effective Date

  const [comments, setComments] = useState(""); // comments
  const [rsRadio, setRsRadio] = useState<boolean[]>([false, true]); // selected radio button data

  const [recutingPartner, setRercuitingPartner] = useState<unknown | null>(
    null
  ); // selected recruiting parnter

  const [addingNew, setAddingNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [simulatePayRateData, SetSimulatePayRateData] =
    useState<CalcAtributes | null>(null); // simulate API data

  const [simulateBillRateData, SetSimulateBillRateData] =
    useState<CalcAtributes | null>(null); // simulate API data

  const [simulatePayRateError, SetSimulatePayRateError] = useState<string>(""); // simulate API data

  const [simulateBillRateError, SetSimulateBillRateError] =
    useState<string>(""); // simulate API data

  const [vendorData, setVendorData] = useState<SuppliersVendorData[] | null>(
    null
  ); // suplier/Vendor Dropdown Data

  const [groupData, setGroupData] = useState<DropdownOptionsType[] | null>(
    null
  ); // Drop Down Data

  const [attributionData, setAttributionData] = useState<
    DropdownOptionsType[] | null
  >(null); // attribution dropdown data

  const [operationData, setOperationData] = useState<
    DropdownOptionsType[] | null
  >(null); // operation dropdown Data

  const [partnerData, setPartnerData] = useState<DropdownOptionsType[] | null>(
    null
  ); // recruiting Pratner dropdown Data

  const [customerSpeicificAppliedRules, setCustomerSpeicificAppliedRules] =
    useState<CalcSettingsDetails | null>(null); // rules customer has applied for calculation

  const [rulesTableData, setRulesTableData] =
    useState<CustSpecificRulesData | null>(null); // rules table data

  const [workOrderData, setWorkOrderData] = useState<
    workOrderMappingPayloadTypes[] | [] // work Order selected Data by customer
  >([]);

  const [customerAppliedWorkOrderData, setCustomerAppliedWorkOrderData] =
    useState<workOrderStateProps[] | null>(null); // customer applied work order data

  const [rulesTableUserSelected, setRulesTableUserSelected] = useState<
    rulesTableDataProp[] | []
  >([]);

  const [requiredWorkorderFields, SetRequiredWOrkorderFields] = useState<
    WorkOrderRequiredFields[] | []
  >([]);

  const [clientList, setClientList] = useState<ClientListObj[] | []>([]);
  const [openPrintDocument, setPrintDocument] = useState<boolean>(false);
  const [openRules, setRulesTable] = useState<boolean>(false);
  const [viewRulesError, setViewRulesError] = useState<boolean>(false);

  const groupHandler = groupData?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const vendorHandler = vendorData?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const operationHandler = operationData?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const attributeHandler = attributionData?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const isWorkOrderDataAvailable =
    groupHandler &&
    groupHandler?.length > 0 &&
    operationHandler &&
    operationHandler?.length > 0 &&
    attributeHandler &&
    attributeHandler?.length > 0;

  const rulesTableMappedData: rulesTableDataProp[] | null =
    rulesTableData &&
    rulesTableData.customerSpecificOptionalRules &&
    rulesTableData.customerSpecificOptionalRules.length > 0
      ? rulesTableData.customerSpecificOptionalRules.map((rule) => ({
          amount:
            rule.amount.localized === "percentage"
              ? `${rule.amount.raw}`
              : `${rule.amount.raw / 100}`,
          description: rule.description,
          id: rule.id,
          isApplied: !!(
            customerSpeicificAppliedRules &&
            customerSpeicificAppliedRules.data
              .customerSpecificCustomAppliedRules &&
            customerSpeicificAppliedRules.data
              .customerSpecificCustomAppliedRules.length > 0 &&
            customerSpeicificAppliedRules.data.customerSpecificCustomAppliedRules.findIndex(
              (custRule) => custRule.customerSpecificOptionalRuleId === rule.id
            ) > -1
          ),

          operation: rule.operation,
          name: rule.name,
        }))
      : [];

  const partnerHandler = partnerData?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const validUser = ValidateUser() as UserGroups[] | [];

  const enablePrintFeatureHandler = () => {
    hotkeys("f5,command+p,ctrl+p", function (event, handler) {
      // Prevent the default refresh event under WINDOWS system
      event.preventDefault();

      if (saveEditedCalcSettingsId && saveEditedCalcSettingsId !== "") {
        setPrintDocument(true);
      }
    });
  };

  const selectHandler = (selected: unknown) => {
    const value = selected as optionsProp;

    setRercuitingPartner(value);
  };

  const saveDataToRouteState = (
    searchItem: number,
    jobDivaDetailsData: ProjectDetailsAPIResponse,
    calcSettings: CalcSettings,
    isEditDisabled: boolean | null = false
  ) => {
    history(`/phase1/start/${searchItem}`, {
      state: {
        jobDivaInfo: jobDivaDetailsData,
        pricingCalcInfo: calcSettings,
        id,
      },
    });

    if (isEditDisabled) {
      setPricingCalcData(calcSettings.data);
    }
  };

  const getClientList = async (jobDivaCustomerId: string) => {
    const ClientListDetails: ClientList = await clientListApiHandler();

    const clientDetals = ClientListDetails.data.clients.filter(
      (clientInfo) => clientInfo.jobdivaCompanyId === jobDivaCustomerId
    );

    setClientList(clientDetals);

    return clientDetals;
  };

  const searchHandler = async (jobDivaID: number | null = null) => {
    SetLoader(true);
    setAddingNew(false);
    setIsEdit(false);
    SetSimulatePayRateData(null);
    SetSimulateBillRateData(null);
    SetSimulateBillRateError("");
    SetSimulatePayRateError("");
    setPayToBillProposedRate("");
    setBillToPayProposedRate("");
    SetEditedCalcSettingsId("");
    setPricingCalcData(null);
    setJobDivaDetails(null);
    let searchItem: number | null = null;

    if (jobDivaID) {
      searchItem = jobDivaID;
    } else if (inputRef.current && inputRef.current.value.trim() !== "") {
      searchItem = +inputRef.current.value;
    } else if (id) {
      searchItem = +id;

      if (inputRef.current) {
        inputRef.current.value = id;
      }
    }

    if (searchItem && !Number.isNaN(+searchItem)) {
      try {
        const request1 = PricingCalcApi.getProjectDetails({ id: searchItem });
        const getJobDivaDetails = API.performRequest(request1);
        const request2 = PricingCalcApi.getCalcSettings({ id: searchItem });
        const getcalcSettings = API.performRequest(request2);
        const data = await Promise.all([getJobDivaDetails, getcalcSettings]);
        const jobDivaDetailsData: ProjectDetailsAPIResponse = data[0]?.data;
        const calcSettings: CalcSettings = data[1]?.data;

        const isJobDivaDetailsAvailable =
          jobDivaDetailsData &&
          Boolean(Object.keys(jobDivaDetailsData.data).length);

        if (isJobDivaDetailsAvailable) {
          setRulesTableData(null);
          setIsEdit(false);

          saveDataToRouteState(searchItem, jobDivaDetailsData, calcSettings);
        } else {
          EnableToast({ message: "Job Diva details not found", type: "error" });
          history("/", { replace: true });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      EnableToast({ message: "Not a Valid Start Id", type: "warning" });
    }

    SetLoader(false);
  };

  const getVendorData = async () => {
    try {
      const request = PricingCalcApi.getSuppliers();

      const response: AxiosResponse<Suppliers> = await API.performRequest(
        request
      );

      const vendorsData = response.data.data.vendors;

      setVendorData(vendorsData);
    } catch (error) {
      console.log(error);
    }
  };

  const getdropDownData = async () => {
    try {
      const { attribution, group, partner, operation } =
        await dropdownApicallHander(
          "isavailabletocustomrules in (true)",
          jobDivaDetails?.candidate.engagementType
        );

      setGroupData(group);
      setAttributionData(attribution);
      setPartnerData(partner);
      setOperationData(operation);
    } catch (error) {
      console.log(error);
    }
  };

  const editHandler = async (editedRow: PricingCalcSettingsObj) => {
    setViewRulesError(false);
    SetLoader(true);
    setIsEdit(false);
    setAddingNew(false);
    setWorkOrderData([]);

    setCustomerAppliedWorkOrderData(null);
    setCustomerSpeicificAppliedRules(null);
    setRulesTableUserSelected([]);
    SetEditedCalcSettingsId("");
    SetSimulatePayRateData(null);
    SetSimulateBillRateData(null);
    SetSimulateBillRateError("");
    SetSimulatePayRateError("");
    setPayToBillProposedRate("");
    setBillToPayProposedRate("");
    setComments("");
    setValidteEffectiveDate("");

    try {
      const settingsId = +editedRow.settingsId;

      const response = await Promise.all([
        getCalcSetingsDetails(settingsId),
        getRulesTableData(
          jobDivaDetails ? +jobDivaDetails.job.client.id : 0,
          new Date(editedRow.effectiveDate.raw)
        ),
      ]);

      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      const customerAppliedRulesResponse: void | AxiosResponse<CalcSettingsDetails> =
        response[0];

      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      const rulesTableResponse: void | null | AxiosResponse<CustSpecificRules> =
        response[1];

      if (customerAppliedRulesResponse) {
        setCustomerSpeicificAppliedRules(customerAppliedRulesResponse.data);
      }

      if (rulesTableResponse) {
        setRulesTableData(rulesTableResponse.data.data);
      } else {
        setRulesTableData(null);
      }

      setEffectiveDate(new Date(editedRow.effectiveDate.raw));
      setEditedRuleEffectiveDate(new Date(editedRow.effectiveDate.raw));
      SetEditedCalcSettingsId(`${editedRow.settingsId}`);
      setIsEdit(true);
    } catch (error) {
      console.log(error);
      setIsEdit(false);
    }

    // SetLoader(false);
  };

  const addHandler = async () => {
    setAddingNew(true);
    setIsEdit(false);
    setEffectiveDate(null);
    setRulesTableData(null);
    setRsRadio([false, true]);
    setRercuitingPartner(null);
    setComments("");
    SetEditedCalcSettingsId("");
    setCustomerSpeicificAppliedRules(null);
    setWorkOrderData([]);
    setCustomerAppliedWorkOrderData(null);
    setViewRulesError(false);
  };

  const effectiveDateHandler = async (date: Date) => {
    if (!date) {
      setCustomerAppliedWorkOrderData(null);
      setCustomerSpeicificAppliedRules(null);
      setRulesTableUserSelected([]);
      SetEditedCalcSettingsId("");
      SetSimulatePayRateData(null);
      SetSimulateBillRateData(null);
      SetSimulateBillRateError("");
      SetSimulatePayRateError("");
      setPayToBillProposedRate("");
      setBillToPayProposedRate("");
      setComments("");
      setValidteEffectiveDate("");
    }

    const isEffectiveDateAvailable = checkExistingDateOld(
      date,
      null,
      pricingCalcData,
      isEdit,
      editedRuleEffectiveDate
    );

    if (pricingCalcData) {
      if (isEffectiveDateAvailable) {
        setValidteEffectiveDate("");
        SetLoader(true);

        try {
          // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
          const rulesTableResponse: void | null | AxiosResponse<CustSpecificRules> =
            await getRulesTableData(
              jobDivaDetails ? +jobDivaDetails.job.client.id : 0,
              date
            );

          if (rulesTableResponse) {
            setRulesTableData(rulesTableResponse.data.data);
          } else {
            setRulesTableData(null);
          }

          const isPayRateAvailable = validateRate(
            effectiveDate,
            jobDivaDetails && jobDivaDetails.payInfo,
            "payrate"
          );

          const isBillRateAvailable = validateRate(
            effectiveDate,
            jobDivaDetails && jobDivaDetails.billInfo,
            "billrate"
          );

          if (isPayRateAvailable) {
            setPayToBillProposedRate("");
          }

          if (isBillRateAvailable) {
            setBillToPayProposedRate("");
          }

          setEffectiveDate(date);
        } catch (error) {
          console.log(error);
        }
      } else {
        EnableToast({
          message: "Effective Date is already used",
          type: "warning",
        });
        setValidteEffectiveDate("Effective Date is Already used");
      }
    }

    SetLoader(false);
  };

  const changeProposedValue = (value: number, type: string) => {
    if (type === "calculateBillToPay") {
      setBillToPayProposedRate(value === 0 ? "" : value);

      return;
    }

    if (type === "calculatePayToBill") {
      setPayToBillProposedRate(value === 0 ? "" : value);
    }
  };

  const calculateProposedRate = async (payRate: string, type: string) => {
    const proposedPayRate =
      payRate ||
      (type === "calculatePayToBill"
        ? payToBillProposedRate
        : billToPayProposedRate);

    if (`${proposedPayRate}`.trim() !== "") {
      SetLoader(true);

      try {
        const editedRowRulesRequest = PricingCalcApi.getCalcAttributes({
          settingsId: saveEditedCalcSettingsId,
          payRate: `${proposedPayRate}`,
          type,
          isMilestone: Boolean(jobDivaDetails && jobDivaDetails.sowInfo),
        });

        const editedRowRulesResponse: AxiosResponse<CalcAtributes> =
          await API.performRequest(editedRowRulesRequest);

        const { data, errors } = editedRowRulesResponse.data;

        SetLoader(false);

        if (Object.keys(data).length > 0 && errors && errors.length === 0) {
          if (type === "calculateBillToPay") {
            SetSimulateBillRateData(editedRowRulesResponse.data);
            SetSimulateBillRateError("");

            return;
          }

          SetSimulatePayRateData(editedRowRulesResponse.data);
          SetSimulatePayRateError("");
        } else if (
          Object.keys(data).length > 0 &&
          errors &&
          errors.length > 0
        ) {
          if (type === "calculateBillToPay") {
            SetSimulateBillRateData(editedRowRulesResponse.data);
            SetSimulateBillRateError(errors[0].message);

            return;
          }

          SetSimulatePayRateData(editedRowRulesResponse.data);
          SetSimulatePayRateError(errors[0].message);
        } else {
          if (type === "calculateBillToPay") {
            SetSimulateBillRateError(errors[0].message);
            SetSimulateBillRateData(null);

            return;
          }

          SetSimulatePayRateError(errors[0].message);
          SetSimulatePayRateData(null);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      EnableToast({
        message: "Proposed Pay Rate cannot be empty",
        type: "warning",
      });
      SetLoader(false);
    }
  };

  const workOrderHandler = (values: workOrderStateProps[]) => {
    const workOrderPayloadMapping = values.map((item) => ({
      id: item.id || null,
      description: item.description,
      amount: +item.amount,
      groupId: item.group ? (item.group as optionsProp).value : null,
      operationId: item.operation ? item.operation.value : null,
      attributionId: item.attribution ? item.attribution.value : null,
      vendorId: item.vendor ? item.vendor.value : "",
      displayOrder: null,
      isActive: false,
    }));

    setWorkOrderData(workOrderPayloadMapping);
  };

  const saveCalcRules = async (isNewRule: boolean) => {
    if (effectiveDate && isNewRule) {
      const isEffectiveDateAvailable = checkExistingDateOld(
        effectiveDate,
        !isNewRule,
        pricingCalcData,
        isEdit,
        editedRuleEffectiveDate
      );

      if (jobDivaDetails && !isEffectiveDateAvailable) {
        setValidteEffectiveDate("Effective Date is Already used");
        EnableToast({
          message: "Please correct the following errors.",
          type: "warning",
        });

        return;
      }
    }

    setValidteEffectiveDate("");
    SetLoader(true);

    if (workOrderData && workOrderData.length > 0) {
      const updatedWorkorderValidation = workOrderData.map((order) => ({
        group: !order.groupId,
        operation: !order.operationId,
        attribution: !order.attributionId,
        amount: !order.amount,
      }));

      SetRequiredWOrkorderFields(updatedWorkorderValidation);
    }

    if (inputRef.current && inputRef.current.value.trim() === "" && id) {
      inputRef.current.value = id;
    }

    const customerAppliedTableRules:
      | calcSettingsDetailsCustomerSpecificCustomAppliedRules[]
      | null =
      customerSpeicificAppliedRules?.data.customerSpecificCustomAppliedRules ||
      null;

    const customerAppliedWorkOrderRules:
      | workorderSpecificCustomAppliedRulesType[]
      | undefined =
      customerSpeicificAppliedRules?.data.workOrderSpecificCustomAppliedRules;

    const payload: SaveCalcSettingsD = {
      settingsId: isNewRule ? null : +saveEditedCalcSettingsId || null,
      startId: id ? +id : null,
      comment: comments || "",
      effectiveDate: moment(effectiveDate).format("YYYY-MM-DD"),
      recruitingServices: rsRadio[0],
      recruitingPartnerId: recutingPartner
        ? (recutingPartner as optionsProp).value
        : null,
      customerSpecificCustomAppliedRules: createCustomAppliedRulesPayload(
        customerAppliedTableRules,
        rulesTableUserSelected,
        isNewRule
      ),
      workorderSpecificCustomAppliedRules: createWorkorderAppliedRulesPayload(
        customerAppliedWorkOrderRules,
        workOrderData,
        isNewRule
      ),
    };

    if (isNewRule && rulesTableUserSelected.findIndex((e) => e.isApplied) < 0) {
      payload.customerSpecificCustomAppliedRules = [];
    }

    try {
      const request = PricingCalcApi.SaveCalcSettings(payload);
      const response = await API.performRequest(request);

      let { data, errors } = response.data;

      if (errors && Array.isArray(errors) && errors.length > 0) {
        EnableToast({
          message: errors[0].message,
          type: "error",
        });
        SetLoader(false);

        return;
      }

      data = data && {
        ...data,
        effectiveDate: {
          ...data.effectiveDate,
          raw: updateTimeStamp(data.effectiveDate.raw),
        },
      };

      if (Object.keys(data).length > 0) {
        if (
          customerSpeicificAppliedRules &&
          customerSpeicificAppliedRules.errors.length > 0 &&
          customerSpeicificAppliedRules.errors[0].code !== 70022
        ) {
          calculateProposedRate("", "calculatePayToBill");
          calculateProposedRate("", "calculateBillToPay");
        }

        EnableToast({
          message: `${
            isNewRule
              ? "Rule has been added succesfully"
              : "Rule has been updated successfully"
          }`,
          type: "success",
        });
        const calcSettingsRequest = PricingCalcApi.getCalcSettings({
          id: data.startId,
        });

        const isJobDivaDetailsAvailable =
          jobDivaDetails && Boolean(Object.keys(jobDivaDetails).length);

        const calcSettingsResponse: AxiosResponse<CalcSettings> =
          await API.performRequest(calcSettingsRequest);

        if (isJobDivaDetailsAvailable) {
          const jobIdvaResponse: ProjectDetailsAPIResponse = {
            data: jobDivaDetails,
            correlationId: "",
            errors: [],
          };

          saveDataToRouteState(
            data.startId,
            jobIdvaResponse,
            calcSettingsResponse.data
          );
        }

        setCustomerAppliedWorkOrderData(null);

        editHandler({
          effectiveDate: data.effectiveDate,
          comment: data.comment,
          lastUpdatedDate: data.effectiveDate,
          updatedBy: "",
          settingsId: data.settingsId,
        });
      } else {
        EnableToast({
          message: "Mandatory fields are either missing or empty",
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }

    SetLoader(false);
  };

  const setCommentForRule = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComments(e.target.value);
  };

  useEffect(() => {
    const userPermission =
      (validUser && validUser.length < 1) ||
      (validUser.length > 0 && +validUser[0].accessLevel < 0);

    if (userPermission) {
      history("/phase1", { replace: true });

      return;
    }

    const idSearch = location.state?.id;

    if (idSearch !== id) {
      if (inputRef.current && id) {
        inputRef.current.value =
          (inputRef.current && inputRef.current.value) || id;
      }

      id && searchHandler(+id);
    }

    if (
      jobDivaInfo &&
      jobDivaInfo.data &&
      jobDivaInfo.data.job &&
      Object.values(jobDivaInfo.data.job).length === 0
    ) {
      setTimeout(() => {
        EnableToast({ message: "Job Diva details not found", type: "error" });
      });

      history("/", { replace: true });

      return;
    }

    getClientList(jobDivaInfo?.data?.job?.client?.id);
  }, [location]);

  useEffect(() => {
    if (
      customerSpeicificAppliedRules &&
      Object.keys(customerSpeicificAppliedRules).length > 0
    ) {
      const text = customerSpeicificAppliedRules.data.comment;
      const isTrue = customerSpeicificAppliedRules.data.recruitingService;

      setRsRadio(isTrue ? [true, false] : [false, true]);

      setComments(text);
    }

    if (
      customerSpeicificAppliedRules &&
      customerSpeicificAppliedRules.errors.length > 0 &&
      customerSpeicificAppliedRules.errors[0].code === 70022
    ) {
      setViewRulesError(true);
    }
  }, [customerSpeicificAppliedRules]);

  useEffect(() => {
    if (
      customerSpeicificAppliedRules &&
      Object.keys(customerSpeicificAppliedRules.data).length > 0
    ) {
      const customerWorkOrderData =
        customerSpeicificAppliedRules.data.workOrderSpecificCustomAppliedRules;

      const customerWorkOrderDataMapped =
        customerWorkOrderData && customerWorkOrderData.length > 0
          ? customerWorkOrderData.map((item) => ({
              id: +item.id || null,
              amount:
                item.amount.localized === "percentage"
                  ? `${item.amount.raw}`
                  : `${item.amount.raw / 100}`,

              attribution:
                {
                  label: item.attribution.name,
                  value: item.attribution.id,
                } || null,
              group:
                {
                  label: item.group.name,
                  value: item.group.id,
                } || null,
              operation:
                {
                  label: item.operation.name,
                  value: item.operation.id,
                } || null,
              vendor:
                vendorHandler?.find(
                  (vendor) => `${vendor.value}` === item.vendor.id
                ) || null,
              description: item.description || "",
            }))
          : [];

      setCustomerAppliedWorkOrderData(customerWorkOrderDataMapped);
    }
  }, [vendorData, customerSpeicificAppliedRules]);

  useEffect(() => {
    if (
      customerSpeicificAppliedRules &&
      Object.keys(customerSpeicificAppliedRules.data).length > 0 &&
      partnerData &&
      partnerData?.length > 0
    ) {
      const recruitingPartner =
        customerSpeicificAppliedRules?.data.recruitingPartner;

      const selectedPartnerData = partnerData.find(
        (item) => item.name === recruitingPartner
      );

      setRercuitingPartner({
        label: selectedPartnerData?.name,
        value: selectedPartnerData?.id,
      });
    }
  }, [partnerData, customerSpeicificAppliedRules]);

  useEffect(() => {
    (async () => {
      if (effectiveDate) {
        const response = await Promise.all([
          getVendorData(),
          getdropDownData(),
          getRulesTableData(
            jobDivaDetails ? +jobDivaDetails.job.client.id : 0,
            effectiveDate
          ),
        ]);

        // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
        const optionalRulesResponse: void | null | AxiosResponse<CustSpecificRules> =
          response[2];

        if (optionalRulesResponse) {
          setRulesTableData(optionalRulesResponse.data.data);
        } else {
          setRulesTableData(null);
        }
      }

      SetLoader(false);
    })();
  }, [effectiveDate]);

  useEffect(() => {
    const dataJobDiva = jobDivaInfo?.data;

    const dataPricingCalc =
      (pricingCalcInfo &&
        pricingCalcInfo.data &&
        pricingCalcInfo.data.startIdDetails &&
        pricingCalcInfo?.data.startIdDetails.map(
          (item: { effectiveDate: { raw: number | Date } }) => {
            return {
              ...item,

              effectiveDate: {
                ...item.effectiveDate,

                raw: updateTimeStamp(item.effectiveDate.raw),
              },
            };
          }
        )) ||
      [];

    setJobDivaDetails(dataJobDiva);
    setPricingCalcData({ startIdDetails: dataPricingCalc });
  }, [jobDivaInfo, pricingCalcInfo]);

  useEffect(() => {
    if (inputRef.current && id) {
      inputRef.current.value =
        (inputRef.current && inputRef.current.value) || id;
    }

    const keyEnter = (e: KeyboardEvent) => {
      if (e.code === "Enter") {
        searchHandler();
      }
    };

    inputRef.current?.addEventListener("keypress", keyEnter);

    return () => inputRef.current?.removeEventListener("keypress", keyEnter);
  }, [inputRef]);

  useEffect(() => {
    if (toastMessage.message === "") {
      EnableToast({ message: "", type: "" });
    }
  }, [toastMessage.message]);

  const scrollToBottomHandler = () => {
    setTimeout(() => {
      if (PricingBodyRef && PricingBodyRef.current) {
        PricingBodyRef.current.scrollTop = PricingBodyRef.current.scrollHeight;
      }
    }, 100);
  };

  useEffect(() => {
    if (addingNew && jobDivaDetails) {
      const date = new Date(updateTimeStamp(jobDivaDetails.job.startDate.raw));

      const isEffectiveDateAvailable = checkExistingDateOld(
        date,
        null,
        pricingCalcData,
        isEdit,
        editedRuleEffectiveDate
      );

      if (isEffectiveDateAvailable) {
        setEffectiveDate(date);
      }

      setValidteEffectiveDate("");

      scrollToBottomHandler();
    }
  }, [addingNew]);

  const toggleRulesModel = async (open: boolean) => {
    if (
      customerSpeicificAppliedRules &&
      customerSpeicificAppliedRules.errors.length > 0 &&
      customerSpeicificAppliedRules.errors[0].code === 70022
    ) {
      setViewRulesError(true);
    }

    setRulesTable(open);
  };

  useEffect(() => {
    if (PricingBodyRef.current) {
      PricingBodyRef.current.addEventListener("contextmenu", (e) => {
        if (PricingBodyRef.current) {
          e.preventDefault();

          PricingBodyRef.current.addEventListener(
            "click",
            documentClickHandler
          );

          if (printRightClick && printRightClick.current) {
            printRightClick.current.style.left = `${e.clientX}px`;
            printRightClick.current.style.top = `${e.clientY}px`;
            printRightClick.current.style.display = "flex";
          }
        }
      });
    }

    const documentClickHandler = function (e: Event) {
      if (printRightClick && printRightClick.current) {
        printRightClick.current.style.display = "none";
      }

      if (PricingBodyRef.current) {
        PricingBodyRef.current.removeEventListener(
          "click",
          documentClickHandler
        );
      }
    };

    enablePrintFeatureHandler();

    if (printRightClick && printRightClick.current) {
      printRightClick.current.style.display = "none";
    }

    return () => {
      hotkeys.unbind("f5,command+p,ctrl+p");
    };
  }, []);

  useEffect(() => {
    enablePrintFeatureHandler();

    return () => {
      hotkeys.unbind("f5,command+p,ctrl+p");
    };
  }, [saveEditedCalcSettingsId]);

  return (
    <>
      {saveEditedCalcSettingsId && saveEditedCalcSettingsId !== "" && (
        <>
          <div
            ref={printRightClick}
            onClick={() => {
              setPrintDocument(true);

              if (printRightClick && printRightClick.current) {
                printRightClick.current.style.display = "none";
              }
            }}
            className="browser_print"
          >
            <p>Print</p> <FaPrint />
          </div>

          <FloatingActionButton
            setPrintDocument={setPrintDocument}
            openPrintDocument={openPrintDocument}
          />
        </>
      )}
      {saveEditedCalcSettingsId &&
      saveEditedCalcSettingsId !== "" &&
      openPrintDocument
        ? id && (
            <PrintWorkOrderRules
              effectiveDate={effectiveDate}
              startId={id}
              openPrintDocument={openPrintDocument}
              setPrintDocument={setPrintDocument}
              clientDetails={jobDivaDetails}
              businessRules={{
                basicInfo: [
                  {
                    effectiveDate: moment(effectiveDate).format("YYYY-MM-DD"),
                    comments,
                    recutingServices: rsRadio[0].toString(),
                    recutingPartner: recutingPartner as optionsProp,
                  },
                ],
                optionslRules: ((customerSpeicificAppliedRules &&
                  customerSpeicificAppliedRules?.data
                    .customerSpecificCustomAppliedRules) ||
                  []) as [] | TableIProps[],
                workorderRules: ((customerSpeicificAppliedRules &&
                  customerSpeicificAppliedRules?.data
                    .workOrderSpecificCustomAppliedRules) ||
                  []) as [] | TableIProps[],
              }}
              pricingCalcData={
                (pricingCalcData &&
                  pricingCalcData?.startIdDetails.filter(
                    (e) =>
                      Number(e.settingsId) === Number(saveEditedCalcSettingsId)
                  )) ||
                []
              }
              jdRate={
                jobDivaDetails && jobDivaDetails.payInfo[0].regularPayrate.raw
              }
              ptobrates={{
                jdRates: {
                  payRate: jobDivaDetails?.payInfo[0].regularPayrate.raw,
                  billRate: jobDivaDetails?.billInfo[0].regularBillrate.raw,
                  payRateOtDt: jobDivaDetails?.payInfo[0].regularPayrate.raw,
                  payRateOt:
                    jobDivaDetails &&
                    "overTimePayrate" in jobDivaDetails.payInfo[0]
                      ? jobDivaDetails.payInfo[0].overTimePayrate?.raw
                      : "",
                  payRateDt:
                    jobDivaDetails &&
                    "doubleTimePayrate" in jobDivaDetails.payInfo[0]
                      ? jobDivaDetails.payInfo[0].doubleTimePayrate?.raw
                      : "",
                  label: "Job Diva Rates",
                  index: `j${1}`,
                  isOt: jobDivaDetails
                    ? "overTimePayrate" in jobDivaDetails.payInfo[0]
                    : false,
                  isDt: jobDivaDetails
                    ? "doubleTimePayrate" in jobDivaDetails.payInfo[0]
                    : false,
                },

                ptobcalculateRates: {
                  payRate: simulatePayRateData?.data.payRate.raw,
                  netPayRate: simulatePayRateData?.data.netPayRate.raw,
                  billRate: simulatePayRateData?.data.billRate.raw,
                  payRateOtDt: simulatePayRateData?.data.payRate.raw,
                  label: "Calculated Rates",
                  index: `c${0}`,
                  isOt: false,
                  isDt: false,
                  payRateOt: "",
                  payRateDt: "",
                },
              }}
              btopjdRate={
                jobDivaDetails && jobDivaDetails.billInfo[0].regularBillrate.raw
              }
              btoprates={{
                jdRates: {
                  payRate: jobDivaDetails?.payInfo[0].regularPayrate.raw,
                  billRate: jobDivaDetails?.billInfo[0].regularBillrate.raw,
                  payRateOtDt: jobDivaDetails?.payInfo[0].regularPayrate.raw,
                  label: "Job Diva Rates",
                  index: `j${1}`,
                },
                calculateRates: {
                  payRate: simulateBillRateData?.data.payRate.raw,
                  netPayRate: simulateBillRateData?.data.netPayRate.raw,
                  billRate: simulateBillRateData?.data.billRate.raw,
                  payRateOtDt: simulateBillRateData?.data.payRate.raw,
                  label: "Calculated Rates",
                  index: `c${0}`,
                },
              }}
              payToBillAttributions={
                simulatePayRateData &&
                simulatePayRateData.data &&
                simulatePayRateData.data.attributions
                  ? simulatePayRateData.data.attributions
                  : []
              }
              billToPayAttributions={
                simulateBillRateData &&
                simulateBillRateData.data &&
                simulateBillRateData.data.attributions
                  ? simulateBillRateData.data.attributions
                  : []
              }
              workorderPayRateSimulationdata={Boolean(
                simulatePayRateData && jobDivaDetails
              )}
              workorderBillRateSimulationdata={Boolean(
                simulateBillRateData && jobDivaDetails
              )}
            />
          )
        : null}
      <div className="pricingbody" ref={PricingBodyRef}>
        <div className="pricingbody__details">
          {toastMessage.message !== "" ? <Toast /> : null}
          <div className="pricingbody__details__search">
            <Input.Search
              onChange={() => {}}
              placeholder="Start ID"
              innerRef={inputRef}
            />
            <Button
              name="Search"
              onClick={() => {
                void searchHandler();
              }}
            />
          </div>
          <div className="pricingbody__details__jobDiva section">
            <SectionConatiner title="Job Diva">
              {jobDivaDetails && Object.keys(jobDivaDetails).length > 0 && (
                <JobDiva
                  name={`${jobDivaDetails?.candidate.firstName} ${jobDivaDetails.candidate.lastName}`}
                  clientName={`${jobDivaDetails.job.client.name}`}
                  engagementType={`${jobDivaDetails.candidate.engagementType}`}
                  startDate={
                    jobDivaDetails ? jobDivaDetails.job.startDate.raw : ""
                  }
                  supplierInfo={jobDivaDetails.job.supplier}
                  sowInfo={jobDivaDetails.sowInfo}
                  location={`${jobDivaDetails.job.location}`}
                  headerA={headerA}
                  headerB={headerB}
                  billRate={jobDivaDetails ? jobDivaDetails.billInfo : []}
                  payRate={jobDivaDetails ? jobDivaDetails.payInfo : []}
                />
              )}
            </SectionConatiner>
          </div>
          <div className="pricingbody__details__pricingCalc section">
            <SectionConatiner title="Pricing Calc">
              {pricingCalcData &&
                pricingCalcData.startIdDetails &&
                Object.keys(pricingCalcData).length > 0 && (
                  <PricingCalc
                    data={pricingCalcData}
                    addButtonHandler={() => {
                      void addHandler();
                    }}
                    editButtonHandler={(item) => {
                      void editHandler(item);
                    }}
                    saveDataToRouteState={saveDataToRouteState}
                    saveEditedCalcSettingsId={saveEditedCalcSettingsId}
                    setAddingNew={setAddingNew}
                    setIsEdit={setIsEdit}
                    SetEditedCalcSettingsId={SetEditedCalcSettingsId}
                    userAccess={
                      (validUser &&
                        validUser[0].readonly.findIndex(
                          (cname) => cname === componentNames.WORKORDER
                        ) > -1 &&
                        +validUser[0].accessLevel === 1) ||
                      (validUser[0].readonly.length === 0 &&
                        +validUser[0].accessLevel < 1)
                    }
                  />
                )}
            </SectionConatiner>
          </div>
          {(addingNew || isEdit) && (
            <SectionConatiner
              className="business_rules_sectioncontainer"
              title="Business Rules"
            >
              <div className="pricingbody__details__calcContainer section">
                {openRules && (
                  <BusinessRulesComponent
                    toggleRulesModel={(open) => {
                      void toggleRulesModel(open);
                    }}
                    openRules={openRules}
                    appliedRules={
                      customerSpeicificAppliedRules &&
                      customerSpeicificAppliedRules?.data
                    }
                    clientList={clientList}
                    startId={`${id || ""}`}
                    effectiveDate={effectiveDate}
                    clientDetails={jobDivaDetails}
                    standardRuleErrorMessage={
                      (customerSpeicificAppliedRules &&
                        customerSpeicificAppliedRules.errors &&
                        customerSpeicificAppliedRules.errors.length > 0 &&
                        customerSpeicificAppliedRules.errors[0].message) ||
                      ""
                    }
                    standardRuleErrorComponent={
                      viewRulesError && (
                        <div className="pricingbody__error">
                          <Icon
                            width={100}
                            height={35}
                            name={IconsName.CANCELICON}
                          />
                          <p>
                            {parseToHtml(
                              formatText(
                                (customerSpeicificAppliedRules &&
                                  customerSpeicificAppliedRules.errors &&
                                  customerSpeicificAppliedRules?.errors[0]
                                    .message) ||
                                  ""
                              )
                            )}
                          </p>
                        </div>
                      )
                    }
                  />
                )}
                <div className="pricingbody__details__calcContainer__header">
                  <div className="pricingbody__details__calcContainer__header__section">
                    <div className="pricingbody__details__calcContainer__header__section__1">
                      <span className="label" data-name="Effective Date">
                        Effective Date
                      </span>
                      <div className="dateContainer">
                        <Input.Date
                          value={effectiveDate}
                          className="calcDatePicker"
                          onChange={(date) => {
                            void effectiveDateHandler(date);
                          }}
                        />
                        {validateEffectiveDate !== "" && (
                          <span className="pricingbody__details__calcContainer__header__section__1__error">
                            {validateEffectiveDate}
                          </span>
                        )}
                      </div>
                    </div>
                    {effectiveDate && isWorkOrderDataAvailable && (
                      <div className="pricingbody__details__calcContainer__header__section__1">
                        <span>Comments</span>
                        <div className="commentsConatiner">
                          {/* <Input.Search
                            value={comments}
                            onChange={(e) => {
                              setCommentForRule(e);
                            }}
                            placeholder=""
                            label=" "
                          /> */}
                          <Input.TextArea
                            value={comments}
                            onChange={(e) => {
                              setCommentForRule(e);
                            }}
                            placeholder=""
                            label=" "
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {effectiveDate && (
                    <div className="pricingbody__details__calcContainer__header__section">
                      <div className="pricingbody__details__calcContainer__header__section__1">
                        {partnerData && (
                          <>
                            <span>Recruiting Partner</span>
                            <div className="commentsConatiner">
                              <Input.Select
                                value={recutingPartner}
                                options={partnerHandler || null}
                                onChange={selectHandler}
                                placeholder="Pick a recruiting Partner"
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {effectiveDate && (
                  <>
                    <div className="pricingbody__details__calcContainer__body">
                      <div className="pricingbody__details__calcContainer__body__contents">
                        {rulesTableMappedData &&
                          rulesTableMappedData.length > 0 && (
                            <RulesTable
                              data={rulesTableMappedData}
                              onChange={(e) => setRulesTableUserSelected(e)}
                            />
                          )}
                      </div>
                      <div className="pricingbody__details__calcContainer__body__contents">
                        {(isWorkOrderDataAvailable || addingNew) && (
                          <WorkOrder
                            customerAppliedWorkOrderData={
                              customerAppliedWorkOrderData
                            }
                            vendorData={vendorHandler || []}
                            attributionData={attributeHandler || []}
                            operationData={operationHandler || []}
                            groupData={groupHandler || []}
                            selectedValues={(values) =>
                              workOrderHandler(values)
                            }
                            requiredWorkorderFields={requiredWorkorderFields}
                            SetRequiredWOrkorderFields={
                              SetRequiredWOrkorderFields
                            }
                            userAccess={
                              (validUser &&
                                validUser[0].readonly.findIndex(
                                  (cname) => cname === componentNames.WORKORDER
                                ) > -1 &&
                                +validUser[0].accessLevel === 1) ||
                              (validUser[0].readonly.length === 0 &&
                                +validUser[0].accessLevel < 1)
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="pricingbody__details__calcContainer__footer">
                      <Button
                        className="btn-calcButtons"
                        onClick={() => {
                          void saveCalcRules(false);
                        }}
                        name="Save calc"
                        disabled={
                          validUser &&
                          validUser[0].readonly.length === 0 &&
                          +validUser[0].accessLevel === 0
                        }
                      />
                      {isEdit && (
                        <>
                          {" "}
                          <Button
                            className="btn-calcButtons"
                            onClick={() => {
                              void saveCalcRules(true);
                            }}
                            name="Save New"
                            disabled={
                              validUser &&
                              validUser[0].readonly.length === 0 &&
                              +validUser[0].accessLevel === 0
                            }
                          />
                          <Button
                            className="btn-calcButtons"
                            onClick={() => {
                              void toggleRulesModel(true);
                            }}
                            name="View Rules"
                            disabled={
                              validUser &&
                              validUser[0].readonly.length === 0 &&
                              +validUser[0].accessLevel === 0
                            }
                          />
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
              {viewRulesError && (
                <div className="pricingbody__error">
                  <Icon width={100} height={35} name={IconsName.CANCELICON} />
                  <p>
                    {parseToHtml(
                      formatText(
                        (customerSpeicificAppliedRules &&
                          customerSpeicificAppliedRules.errors &&
                          customerSpeicificAppliedRules.errors[0].message) ||
                          ""
                      )
                    )}
                  </p>
                </div>
              )}
            </SectionConatiner>
          )}
          {saveEditedCalcSettingsId !== "" && (
            <SectionConatiner title="Simulate Pay to Bill">
              <div className="pricingbody__details__simulateSection">
                <SimulatePayBill>
                  <ProposedRateComponent
                    proporsedPayRate={payToBillProposedRate}
                    handleCalculate={(payrate, type) => {
                      void calculateProposedRate(payrate, type);
                    }}
                    changeProposedValue={changeProposedValue}
                    jdRate={validateRate(
                      effectiveDate,
                      jobDivaDetails && jobDivaDetails.payInfo,
                      "payrate"
                    )}
                    typeOfRate="Proposed Pay Rate"
                    type="calculatePayToBill"
                    payRateError={formatText(simulatePayRateError || "")}
                  />
                  {simulatePayRateData && (
                    <div className="simulatepay__body">
                      <RatesComponent
                        screen="work-order"
                        paytoBill={true}
                        rates={{
                          jdRates: {
                            payRate: Number(
                              validateRate(
                                effectiveDate,
                                jobDivaDetails && jobDivaDetails.payInfo,
                                "payrate"
                              )
                            ),
                            billRate: Number(
                              validateRate(
                                effectiveDate,
                                jobDivaDetails && jobDivaDetails.billInfo,
                                "billrate"
                              )
                            ),
                            billRateOt: Number(
                              validateRate(
                                effectiveDate,
                                jobDivaDetails && jobDivaDetails.billInfo,
                                "billrate",
                                "obr"
                              )
                            ),
                            billRateDt: Number(
                              validateRate(
                                effectiveDate,
                                jobDivaDetails && jobDivaDetails.billInfo,
                                "billrate",
                                "dbr"
                              )
                            ),
                            payRateOt: Number(
                              validateRate(
                                effectiveDate,
                                jobDivaDetails && jobDivaDetails.payInfo,
                                "payrate",
                                "opr"
                              )
                            ),
                            payRateDt: Number(
                              validateRate(
                                effectiveDate,
                                jobDivaDetails && jobDivaDetails.payInfo,
                                "payrate",
                                "dpr"
                              )
                            ),
                            isJdRate: true,
                            label: "Job Diva Rates",
                            index: `j${0}`,
                            payRateOtDt: null,
                            markUp: "",
                          },
                          calculateRates: {
                            payRate: simulatePayRateData.data.payRate.raw,
                            netPayRate: simulatePayRateData.data.netPayRate.raw,
                            billRate: simulatePayRateData.data.billRate.raw,
                            payRateOtDt: !jobDivaDetails?.billInfo[0]
                              .overTimeExempt
                              ? simulatePayRateData.data.payRate.raw
                              : null,
                            markUp:
                              (simulatePayRateData.data &&
                                simulatePayRateData.data.markup &&
                                simulatePayRateData.data.markup.value) ||
                              "",
                            payRateOt: null,
                            payRateDt: null,
                            label: "Calculated Rates",
                            index: `c${0}`,
                            isJdRate: false,
                          },
                        }}
                      />
                      <AttributionComponent
                        attributions={simulatePayRateData.data.attributions}
                        index={0}
                      />
                    </div>
                  )}
                </SimulatePayBill>
              </div>
            </SectionConatiner>
          )}
          {saveEditedCalcSettingsId !== "" && (
            <SectionConatiner title="Simulate Bill to Pay">
              <div className="pricingbody__details__simulateSection">
                <SimulatePayBill>
                  <ProposedRateComponent
                    proporsedPayRate={billToPayProposedRate}
                    handleCalculate={(payrate, type) => {
                      void calculateProposedRate(payrate, type);
                    }}
                    changeProposedValue={changeProposedValue}
                    jdRate={validateRate(
                      effectiveDate,
                      jobDivaDetails && jobDivaDetails.billInfo,
                      "billrate"
                    )}
                    typeOfRate="Proposed Bill Rate"
                    type="calculateBillToPay"
                    payRateError={formatText(simulateBillRateError || "")}
                  />
                  {simulateBillRateData && (
                    <div className="simulatepay__body">
                      <RatesComponent
                        screen="work-order"
                        rates={{
                          jdRates: {
                            payRate: Number(
                              validateRate(
                                effectiveDate,
                                jobDivaDetails && jobDivaDetails.payInfo,
                                "payrate"
                              ) || []
                            ),
                            billRate: Number(
                              validateRate(
                                effectiveDate,
                                jobDivaDetails && jobDivaDetails.billInfo,
                                "billrate"
                              ) || []
                            ),
                            payRateOt: Number(
                              validateRate(
                                effectiveDate,
                                jobDivaDetails && jobDivaDetails.payInfo,
                                "payrate",
                                "opr"
                              )
                            ),
                            payRateDt: Number(
                              validateRate(
                                effectiveDate,
                                jobDivaDetails && jobDivaDetails.payInfo,
                                "payrate",
                                "dpr"
                              )
                            ),
                            label: "Job Diva Rates",
                            index: `j${1}`,
                            isJdRate: true,
                            payRateOtDt: null,
                            markUp: "",
                          },
                          calculateRates: {
                            payRate: simulateBillRateData.data.payRate.raw,
                            netPayRate:
                              simulateBillRateData.data.netPayRate.raw,
                            billRate: simulateBillRateData.data.billRate.raw,
                            payRateOtDt: !jobDivaDetails?.payInfo[0]
                              .overTimeExempt
                              ? simulateBillRateData.data.payRate.raw
                              : null,
                            label: "Calculated Rates",
                            index: `c${0}`,
                            isJdRate: false,
                            payRateOt: null,
                            payRateDt: null,
                            markUp:
                              (simulateBillRateData.data &&
                                simulateBillRateData.data.markup &&
                                simulateBillRateData.data.markup.value) ||
                              "",
                          },
                        }}
                      />
                      <AttributionComponent
                        attributions={simulateBillRateData.data.attributions}
                        index={1}
                      />
                    </div>
                  )}
                </SimulatePayBill>
              </div>
            </SectionConatiner>
          )}
        </div>
      </div>
    </>
  );
};

export default Details;
