import React from "react";
import { GrCheckboxSelected, GrCheckbox } from "react-icons/gr";

import "./Checkbox.scss";

interface CheckBoxProps {
  value?: string;
  isChecked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  label?: string | React.ReactNode;
  disabled?: boolean;
  name?: string | number;
  labelPosition?: "left" | "right";
}

const CheckBox: React.FC<CheckBoxProps> = ({
  isChecked,
  value,
  onChange,
  className,
  label,
  disabled,
  name,
  labelPosition = "right",
}) => (
  <div className={`checkbox ${labelPosition} ${className || ""}`}>
    {label && (
      <label
        className="checkbox__label"
        htmlFor={typeof label === "string" ? label.toString() : ""}
      >
        {label}
      </label>
    )}
    <input
      id={typeof label === "string" ? label : ""}
      type="checkbox"
      className={`checkbox__input ${className || ""}`}
      onChange={onChange}
      name={`${name || ""}`}
      value={value}
      disabled={disabled}
      checked={isChecked}
    />
    {isChecked ? <GrCheckboxSelected /> : <GrCheckbox />}
  </div>
);

export default CheckBox;
