import moment from "moment";
import React, { useEffect, useState } from "react";
import { IoMdInformationCircle } from "react-icons/io";
import { RiShareBoxLine } from "react-icons/ri";

import {
  dataFlowProps,
  sowPayloadProps,
} from "../../../../../../@types/component";
import CheckBox from "../../../../../../components/Input/CheckBox/CheckBox";
import Button from "../../../../../../componentsPhase1/MBOButton/Button";
import MBOTooltip from "../../../../../../componentsPhase1/MBOTooltip";
import Clientdetails from "../../../../../../componentsPhase1/clientdetails";

import classes from "./index.module.scss";

interface Iprops {
  children?: React.ReactNode;
  setDataFlow: (e: dataFlowProps[]) => void;
  dataFlow: dataFlowProps[];
  jobDivaDetails: ProjectDetailsAPIResponse | null;
  selectedTabIndex: number;
  responseData: CalcAtributes["data"] | null;
  selectedRecordId: string;
  setOptionsRulesCheckBoxInCompareRates: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  checkOptionsRulesInCompareRtes: boolean;
  setHourlyRatesforJobDivaHandler: () => void;
  selectedRateId: number | null;
  markUpValue: string;
  deliverableType: string;
  sowJobDivapayload: sowPayloadProps;
  jobDivaList:
    | {
        id: string | number;
        value: string | number;
        startId: string | number;
        client: { id: string; name: string };
        month: string;
        day: string | number;
        year: string | number;
        selected: boolean;
      }[]
    | null;
}

const SearchClient: React.FC<Iprops> = ({
  children,
  setDataFlow,
  dataFlow,
  jobDivaDetails,
  selectedTabIndex,
  responseData,
  selectedRecordId,
  setOptionsRulesCheckBoxInCompareRates,
  checkOptionsRulesInCompareRtes,
  setHourlyRatesforJobDivaHandler,
  selectedRateId,
  markUpValue,
  deliverableType,
  sowJobDivapayload,
  jobDivaList,
}) => {
  const [selectedStartId, setSelectedStartid] = useState("");

  const billInfoDetails = jobDivaDetails?.data.billInfo.filter((e) => {
    return !e.approved && e.select;
  });

  const payInfoDetals = jobDivaDetails?.data.payInfo.filter((e) => {
    return (
      (billInfoDetails && e.recordId === billInfoDetails[0]?.recordId) || false
    );
  });

  useEffect(() => {
    setSelectedStartid(selectedRecordId);
  }, []);

  return (
    <>
      {deliverableType === "hourly/milestone" ? (
        <>
          {" "}
          <Clientdetails
            type="simulation"
            jobDivaDetails={jobDivaDetails?.data || null}
            selectedRecordId={selectedStartId}
            selectedTabIndex={selectedTabIndex}
          />
          <div
            className={classes.jobdivarates_parent}
            data-testid="ratescomponent"
          >
            <div className={classes.current_rates} data-testid="currentrates">
              <div className={classes.current_rate_values}>
                <p>{selectedTabIndex === 0 ? "Pay Rate" : "Bill Rate"}</p>
                <p>
                  {selectedTabIndex === 0
                    ? `$${
                        (payInfoDetals &&
                          payInfoDetals[0]?.regularPayrate?.raw / 100) ||
                        "0"
                      }`
                    : `$${
                        (billInfoDetails &&
                          billInfoDetails[0]?.regularBillrate?.raw / 100) ||
                        "0"
                      }`}
                </p>
              </div>
              {jobDivaDetails &&
                jobDivaDetails.data.billInfo.filter(
                  (e) =>
                    !e?.overTimeExempt && e.recordId === Number(selectedRateId)
                ).length > 0 &&
                billInfoDetails &&
                !billInfoDetails[0]?.overTimeExempt && (
                  <>
                    {selectedTabIndex === 0 && (
                      <>
                        <div className={classes.current_rate_values}>
                          <p>{"Overtime Pay"}</p>
                          <p>
                            {`$${
                              (payInfoDetals &&
                                payInfoDetals[0].overTimePayrate &&
                                payInfoDetals[0].overTimePayrate?.raw / 100) ||
                              "0"
                            }`}
                          </p>
                        </div>
                        <div className={classes.current_rate_values}>
                          <p>{"Doubletime Pay"}</p>
                          <p>
                            {`$${
                              (payInfoDetals &&
                                payInfoDetals[0]?.doubleTimePayrate &&
                                payInfoDetals[0].doubleTimePayrate?.raw /
                                  100) ||
                              "0"
                            }`}
                          </p>
                        </div>
                      </>
                    )}
                    {selectedTabIndex === 1 && (
                      <>
                        <div className={classes.current_rate_values}>
                          <p>{"Overtime Bill"}</p>
                          <p>
                            {`$${
                              (billInfoDetails &&
                                billInfoDetails[0]?.overTimeBillrate &&
                                billInfoDetails[0]?.overTimeBillrate?.raw /
                                  100) ||
                              "0"
                            }`}
                          </p>
                        </div>
                        <div className={classes.current_rate_values}>
                          <p>{"Doubletime Bill"}</p>
                          <p>
                            {`$${
                              (billInfoDetails &&
                                billInfoDetails[0]?.doubleTimeBillrate &&
                                billInfoDetails[0]?.doubleTimeBillrate?.raw /
                                  100) ||
                              "0"
                            }`}
                          </p>
                        </div>
                      </>
                    )}
                  </>
                )}
              <div className={classes.current_rate_values}>
                <p>{selectedTabIndex === 0 ? "Bill Rate" : "Pay Rate"}</p>
                <p>
                  {selectedTabIndex === 0
                    ? `$${
                        (billInfoDetails &&
                          billInfoDetails[0]?.regularBillrate?.raw / 100) ||
                        "0"
                      }`
                    : `$${
                        (payInfoDetals &&
                          payInfoDetals[0]?.regularPayrate?.raw / 100) ||
                        "0"
                      }`}
                </p>
              </div>
              {selectedTabIndex === 0 &&
                billInfoDetails &&
                !billInfoDetails[0]?.overTimeExempt && (
                  <>
                    <div className={classes.current_rate_values}>
                      <p>{"Overtime Bill"}</p>
                      <p>
                        {`$${
                          (billInfoDetails &&
                            billInfoDetails[0]?.overTimeBillrate &&
                            billInfoDetails[0]?.overTimeBillrate?.raw / 100) ||
                          "0"
                        }`}
                      </p>
                    </div>
                    <div className={classes.current_rate_values}>
                      <p>{"Doubletime Bill"}</p>
                      <p>
                        {`$${
                          (billInfoDetails &&
                            billInfoDetails[0]?.doubleTimeBillrate &&
                            billInfoDetails[0]?.doubleTimeBillrate?.raw /
                              100) ||
                          "0"
                        }`}
                      </p>
                    </div>
                  </>
                )}
              {selectedTabIndex === 1 &&
                billInfoDetails &&
                !billInfoDetails[0]?.overTimeExempt && (
                  <>
                    <div className={classes.current_rate_values}>
                      <p>{"Overtime Pay"}</p>
                      <p>
                        {`$${
                          (payInfoDetals &&
                            payInfoDetals[0].overTimePayrate &&
                            payInfoDetals[0].overTimePayrate?.raw / 100) ||
                          "0"
                        }`}
                      </p>
                    </div>
                    <div className={classes.current_rate_values}>
                      <p>{"Doubletime Pay"}</p>
                      <p>
                        {`$${
                          (payInfoDetals &&
                            payInfoDetals[0]?.doubleTimePayrate &&
                            payInfoDetals[0].doubleTimePayrate?.raw / 100) ||
                          "0"
                        }`}
                      </p>
                    </div>
                  </>
                )}
            </div>
            <div className={classes.divider}></div>
            <div className={classes.new_rates} data-testid="newrates">
              <div className={classes.new_rate_values}>
                <p>{selectedTabIndex === 0 ? "Pay Rate" : "Bill Rate"}</p>
                <p>
                  $
                  {selectedTabIndex === 0
                    ? responseData && responseData.payRate.raw / 100
                    : responseData && responseData.billRate.raw / 100}
                </p>
              </div>
              {jobDivaDetails &&
                jobDivaDetails.data.billInfo.filter(
                  (e) =>
                    !e.overTimeExempt && e.recordId === Number(selectedRateId)
                ).length > 0 && (
                  <>
                    {selectedTabIndex === 0 &&
                      billInfoDetails &&
                      !billInfoDetails[0]?.overTimeExempt && (
                        <>
                          <div className={classes.new_rate_values}>
                            <p>{"Overtime Pay"}</p>
                            <p>
                              $
                              {responseData &&
                                (
                                  (responseData.payRate.raw / 100) *
                                  1.5
                                ).toFixed(2)}
                            </p>
                          </div>
                          <div className={classes.new_rate_values}>
                            <p>{"Doubletime Pay"}</p>
                            <p>
                              $
                              {responseData &&
                                (
                                  (responseData.payRate.raw / 100) *
                                  2.0
                                ).toFixed(2)}
                            </p>
                          </div>
                        </>
                      )}

                    {selectedTabIndex === 1 &&
                      billInfoDetails &&
                      !billInfoDetails[0]?.overTimeExempt && (
                        <>
                          {" "}
                          <div className={classes.new_rate_values}>
                            <p>{"Overtime Bill"}</p>
                            <p>
                              $
                              {responseData &&
                                (
                                  (responseData.billRate.raw / 100) *
                                  1.5
                                ).toFixed(2)}
                            </p>
                          </div>
                          <div className={classes.new_rate_values}>
                            <p>{"Doubletime Bill"}</p>
                            <p>
                              $
                              {responseData &&
                                (
                                  (responseData.billRate.raw / 100) *
                                  2.0
                                ).toFixed(2)}
                            </p>
                          </div>
                        </>
                      )}
                  </>
                )}
              <div className={classes.new_rate_values}>
                <p>{selectedTabIndex === 0 ? "Bill Rate" : "Pay Rate"}</p>
                <p>
                  $
                  {selectedTabIndex === 0
                    ? responseData && responseData.billRate.raw / 100
                    : responseData && responseData.payRate.raw / 100}
                </p>
              </div>
              {selectedTabIndex === 1 &&
                billInfoDetails &&
                !billInfoDetails[0]?.overTimeExempt && (
                  <>
                    <div className={classes.new_rate_values}>
                      <p>{"Overtime Pay"}</p>
                      <p>
                        $
                        {responseData &&
                          ((responseData.payRate.raw / 100) * 1.5).toFixed(2)}
                      </p>
                    </div>
                    <div className={classes.new_rate_values}>
                      <p>{"Doubletime Pay"}</p>
                      <p>
                        $
                        {responseData &&
                          ((responseData.payRate.raw / 100) * 2.0).toFixed(2)}
                      </p>
                    </div>
                  </>
                )}

              {selectedTabIndex === 0 &&
                billInfoDetails &&
                !billInfoDetails[0]?.overTimeExempt && (
                  <>
                    {" "}
                    <div className={classes.new_rate_values}>
                      <p>{"Overtime Bill"}</p>
                      <p>
                        $
                        {responseData &&
                          ((responseData.billRate.raw / 100) * 1.5).toFixed(2)}
                      </p>
                    </div>
                    <div className={classes.new_rate_values}>
                      <p>{"Doubletime Bill"}</p>
                      <p>
                        $
                        {responseData &&
                          ((responseData.billRate.raw / 100) * 2.0).toFixed(2)}
                      </p>
                    </div>
                  </>
                )}
            </div>
          </div>
        </>
      ) : (
        <>
          <p className={classes.sow_header}>Verify markup update</p>
          <div className={classes.sow_client_details_parent}>
            {jobDivaDetails?.data?.sowInfo?.sowName && (
              <div>
                <span>SOW Name</span>
                <span>{jobDivaDetails?.data?.sowInfo?.sowName}</span>
              </div>
            )}
            {responseData?.markup.value && (
              <div>
                <span>SOW Markup</span>
                <span>{responseData?.markup.value}</span>
              </div>
            )}
            <div>
              <span>Start ID</span>
              <span>{jobDivaList?.filter((e) => e.selected)[0].startId}</span>
            </div>
            {jobDivaDetails?.data?.job?.client?.name && (
              <div>
                <span>Client</span>
                <span>{jobDivaDetails?.data?.job?.client?.name}</span>
              </div>
            )}
            <div>
              <span>Approved</span>
              <span>
                <p>
                  {moment(jobDivaDetails?.data?.job?.startDate?.raw).format(
                    "MMM"
                  )}{" "}
                  |{" "}
                  {moment(jobDivaDetails?.data?.job?.startDate?.raw).format(
                    "DD"
                  )}{" "}
                  |{" "}
                  {moment(jobDivaDetails?.data?.job?.startDate?.raw).format(
                    "YYYY"
                  )}
                </p>
              </span>
            </div>
          </div>
          <div
            className={classes.sow_jobdivarates_parent}
            data-testid="ratescomponent"
          >
            <div
              className={classes.sow_current_rates}
              data-testid="currentrates"
            >
              <div className={classes.sow_current_rate_values}>
                <p>SOW Markup</p>
                <p>{sowJobDivapayload?.jobdivaMarkup || 0}%</p>
              </div>
            </div>
            <div className={classes.sow_divider}></div>
            <div className={classes.sow_new_rates} data-testid="newrates">
              <div className={classes.sow_new_rate_values}>
                <p>SOW Markup</p>
                <p>{sowJobDivapayload?.calculatedMarkup}%</p>
              </div>
            </div>
          </div>
        </>
      )}
      <div
        className={
          deliverableType === "hourly/milestone"
            ? classes.footer
            : classes.sow_footer
        }
      >
        {deliverableType === "hourly/milestone" && (
          <CheckBox
            label={
              <div
                className={[classes.checkbox_style, classes.info_icon].join(
                  " "
                )}
              >
                Save optional Rules selections for Start ID
                <div className={classes.container_tip}>
                  <MBOTooltip arrowBottom bottom={classes.visible_tip}>
                    <div className={classes.tooltip_container}>
                      <header>Name</header>
                      <main>Description</main>

                      <a href={"#"} target="_blank" rel="noreferrer">
                        Read more
                        <RiShareBoxLine />
                      </a>
                    </div>
                  </MBOTooltip>
                  <IoMdInformationCircle fontSize={16} fill="#352281" />
                </div>
              </div>
            }
            isChecked={checkOptionsRulesInCompareRtes}
            onChange={() => {
              setOptionsRulesCheckBoxInCompareRates(
                !checkOptionsRulesInCompareRtes
              );
            }}
            value=""
          />
        )}
        <div className={classes.button_alignment}>
          {children}

          <Button
            name="Submit to JobDiva"
            className={classes.btn_update_styling}
            onClick={() => {
              setHourlyRatesforJobDivaHandler();
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SearchClient;
