import React from "react";
import { BsCalendar4 } from "react-icons/bs";

import "./index.scss";

interface iProps {
  onClick: React.MouseEventHandler;
  value: string;
  placeholder: string;
  topPlaceholderText: boolean;
}

const CustomInput: React.FC<iProps> = ({
  onClick,
  value,
  placeholder,
  topPlaceholderText,
}) => {
  return (
    <div
      className={`datepicket1_custom_input ${
        value && value !== "" ? "datePicker1_selected" : ""
      }`}
      onClick={onClick}
    >
      {value && topPlaceholderText && (
        <label className="datepicket1_custom_input_label_top">
          {placeholder}
        </label>
      )}
      <label
        className="datepicket1_custom_input_label"
        data-testid="datepicket1_custom_input"
      >
        {value || placeholder}
      </label>
      <BsCalendar4 />
    </div>
  );
};

export default CustomInput;
