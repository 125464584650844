import moment from "moment";
import React from "react";

import MBODropdown from "../../../componentsPhase1/MBODropdown";
import MboFormDate from "../../../componentsPhase1/MBOFormDate";
import MBOInput from "../../../componentsPhase1/MBOInput";

import classes from "./index.module.scss";

interface Iprops {
  name?: string;
  filterText: string;
  setFilterText: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
  ruleSetData?: {
    setName: string;
    description: string;
    createdOn: Date | null;
    createdBy: string;
    id: number;
    select: boolean;
  }[];
  removeHeader?: boolean;
  setRulesetFilter: React.Dispatch<
    React.SetStateAction<{
      createdBy: string;
      createdOn: Date | null;
      sortBy: string;
    }>
  >;
  ruleSetFilter: {
    createdBy: string;
    createdOn: Date | null;
    sortBy: string;
  };
}

const RuleFilter: React.FC<Iprops> = ({
  filterText,
  setFilterText,
  className = "",
  ruleSetData,
  removeHeader = false,
  setRulesetFilter,
  ruleSetFilter,
}) => {
  return (
    <div
      className={[
        classes.ruleset_filter,
        className,
        removeHeader ? classes.filter_border : "",
      ].join(" ")}
    >
      <MBOInput
        type="text"
        placeholder="Type a filter"
        changeHandler={(e) => {
          setFilterText(e.target.value);
        }}
        name="Filter"
        inputValue={filterText}
        clearHandler={() => setFilterText("")}
        isClearIconRequired={true}
        appendStyles={classes.alter_search_style}
      />
      <div className={classes.filters}>
        <div>
          <p>Filter by:</p>
          <MBODropdown
            removeNoDataText={true}
            name="Created by"
            clearHandler={() => {
              setRulesetFilter({ ...ruleSetFilter, createdBy: "" });
            }}
            selectedValue={{ id: 0, name: ruleSetFilter.createdBy }}
            handleValueChange={(_, option) => {
              setRulesetFilter({
                createdBy: option.name,
                createdOn: ruleSetFilter.createdOn,
                sortBy: ruleSetFilter.sortBy,
              });
            }}
            propertyName="Created by"
            tooltipContent=""
            removeSearchTextBox={false}
          />
        </div>
        <div>
          <p>Date created:</p>
          <MboFormDate
            className={classes.alter_date_picker_style}
            topPlaceholderText={false}
            value={
              (ruleSetFilter &&
                ruleSetFilter.createdOn &&
                moment(ruleSetFilter.createdOn).toDate()) ||
              null
            }
            placeholderText="Select Date"
            onChange={(item: unknown) => {
              let time = item as moment.Moment | number;

              time = moment(time).toDate().getTime();

              if (isNaN(time) || time === null || time === 0) {
                setRulesetFilter((prevState) => ({
                  ...prevState,
                  createdOn: null,
                }));

                return;
              }

              setRulesetFilter((prevState) => ({
                ...prevState,
                createdOn: time === 0 ? null : moment(time).toDate(),
              }));
            }}
          />
        </div>
        <div>
          Sort by:{" "}
          <MBODropdown
            name="Username"
            options={[
              { id: 0, name: "ASC" },
              { id: 1, name: "DESC" },
            ]}
            clearHandler={() => {
              setRulesetFilter({ ...ruleSetFilter, sortBy: "" });
            }}
            selectedValue={{ id: 0, name: ruleSetFilter.sortBy }}
            handleValueChange={(_, option) => {
              setRulesetFilter({
                createdBy: ruleSetFilter.createdBy,
                createdOn: ruleSetFilter.createdOn,
                sortBy: option.name,
              });
            }}
            tooltipContent=""
            propertyName="Username"
            removeSearchTextBox={true}
            removeNoDataText={true}
          />
        </div>
      </div>
    </div>
  );
};

export default RuleFilter;
