import moment from "moment";
import React, { useEffect, useState } from "react";

import {
  dataFlowProps,
  hourlyJobDivaRatesRequest,
  optionsProp,
  selectedOptionsRules,
  sowPayloadProps,
} from "../../../../../@types/component";
import NewAPi from "../../../../../API/PricingCalcApiPhaseone";
import { hourlyJobDivaRatesState } from "../../../../../data/dummyData";
import useQueryData from "../../../../../hooks/useQueryData";
import useStore from "../../../../../store/useStore";
import PayRateBillRate from "../../../../workOrder/Details/payRateBillRate";

import CompareRates from "./CompareRates";
import GoBack from "./GoBack";
import JobDivaList from "./JobDivaList";
import SearchClient from "./SearchClient";
import SubmitToJobdiva from "./SubmitToJobdiva";

import classes from "./index.module.scss";

interface jobDivaidList {
  id: string | number;
  value: string | number;
  startId: string | number;
  client: { id: string; name: string };
  month: string;
  day: string | number;
  year: string | number;
  selected: boolean;
  sow?: string;
}

interface Iprops {
  selectedTabIndex: number;
  responseData: CalcAtributes["data"] | null;
  setJobDiva: React.Dispatch<React.SetStateAction<boolean>>;
  selectedOptionsRules: selectedOptionsRules[] | null;
  selectedDate: Date | null;
  deliverableType: string;
  calculatedMarkup: string;
}

const JobDiva: React.FC<Iprops> = ({
  selectedTabIndex,
  responseData,
  setJobDiva,
  selectedOptionsRules,
  selectedDate,
  deliverableType,
  calculatedMarkup,
}) => {
  const [dataFlow, setDataFlow] = useState<dataFlowProps[]>([
    { level: 0, data: true },
    { level: 1, data: false },
    { level: 2, data: false },
    { level: 3, data: false },
  ]);

  const [
    checkOptionsRulesInCompareRtes,
    setOptionsRulesCheckBoxInCompareRates,
  ] = useState<boolean>(false);

  const initialClientList = {
    value: 0,
    label: "",
    clientId: 0,
  };

  const [selectedRatesRecordidNonApproved, setRatesNonApprovedRecord] =
    useState<boolean | null>(null);

  const [selectedClient, setSelectedClient] =
    useState<optionsProp>(initialClientList);

  const [submitHourlyRatesToJobDiva, setHourlyRatesforJobDiva] =
    useState<hourlyJobDivaRatesRequest>(hourlyJobDivaRatesState);

  const [selectedRateId, setSelectedRateId] = useState<number | null>(null);
  const [jobDivaList, setJobDivalist] = useState<jobDivaidList[] | null>(null);
  const setLoader = useStore((state) => state.setLoader);
  const [selectedJobdivaId, setJobDivaId] = useState<string>("");

  const [jobDivaDetails, setJobDivaDetails] =
    useState<ProjectDetailsAPIResponse | null>(null);

  const [sowJobDivapayload, setSowJobdivaPayload] = useState<sowPayloadProps>({
    startId:
      jobDivaList?.filter((e) => e?.selected)[0]?.startId?.toString() || "",
    jobdivaMarkup: "",
    calculatedMarkup: calculatedMarkup.replace("%", ""),
    sowId: "",
  });

  const [jobDivaRecordError, setJobDivaRecordError] = useState<{
    code: number;
    message: string;
  } | null>(null);

  const candidateWorkorderIdList = useQueryData(
    "candidate-workorder-id-list",

    {
      id: selectedClient ? selectedClient.value : "",
      sow: deliverableType === "sow/milestone",
    },
    false,
    NewAPi.getCandidateList
  );

  const jobDivaDataCall = useQueryData(
    "job-diva-details-in-simulation",
    { id: selectedJobdivaId },
    false,
    NewAPi.getProjectDetails
  );

  const SubmitRatesToJobDiva = useQueryData(
    "submit-rates-to-jobdiva",
    {
      payload: {
        ...submitHourlyRatesToJobDiva,
        customerSpecificCustomAppliedRules: checkOptionsRulesInCompareRtes
          ? (selectedOptionsRules && selectedOptionsRules) || []
          : [],
      },
    },
    false,
    NewAPi.submitHourlyJobDivaRats
  );

  const SubmitSowRates = useQueryData(
    "submit-sow-rates-to-jobdiva",
    {
      payload: {
        startId: Number(sowJobDivapayload?.startId) || "",
        jobdivaMarkup:
          (sowJobDivapayload &&
            sowJobDivapayload.jobdivaMarkup &&
            Number(sowJobDivapayload?.jobdivaMarkup)) ||
          0,
        calculatedMarkup: Number(sowJobDivapayload?.calculatedMarkup) || 0,
        sowId: Number(sowJobDivapayload?.sowId) || "",
      },
    },
    false,
    NewAPi.submitSowRatesToJobDiva
  );

  useEffect(() => {
    if (
      selectedClient &&
      selectedClient.clientId !== 0 &&
      selectedClient.value !== 0
    ) {
      setHourlyRatesforJobDiva((prevState: hourlyJobDivaRatesRequest) => {
        return {
          ...prevState,
          candidateId: selectedClient.value,
        };
      });

      if (!isNaN(Number(selectedClient.value))) {
        setLoader(true);
        candidateWorkorderIdList.refetch();
      }
    }
  }, [selectedClient]);

  useEffect(() => {
    if (candidateWorkorderIdList.error) {
      alert("something went wrong");
    }

    if (candidateWorkorderIdList.data) {
      setLoader(false);

      if (
        candidateWorkorderIdList.data &&
        candidateWorkorderIdList.data.data &&
        candidateWorkorderIdList.data.data.candidates
      ) {
        if (selectedClient && selectedClient.value !== 0) {
          const selectedClientWorkorder =
            candidateWorkorderIdList?.data?.data?.candidates.length > 0
              ? candidateWorkorderIdList.data.data.candidates.find(
                  (e: { id: string }) => Number(e.id) === selectedClient.value
                )
              : [];

          setJobDivalist(
            selectedClientWorkorder.workorders.map(
              (e: {
                id: string;
                value: string;
                startId: string;
                startDate: {
                  localized: string;
                  value: string;
                  culture: string;
                  timeZone: string;
                  raw: number;
                };
                sow?: {
                  id: string;
                };
              }) => {
                if (!e) {
                  return null;
                }

                return {
                  id: candidateWorkorderIdList.data.data.candidates[0].id,
                  value: e.id,
                  startId: e.id,
                  month: moment(e.startDate.raw).format("MM"),
                  day: moment(e.startDate.raw).format("DD"),
                  year: moment(e.startDate.raw).format("YYYY"),
                  isSelected: false,
                  sow: e.sow?.id || "",
                };
              }
            )
          );
        }
      } else {
        alert(`No results found for the candidate ${selectedClient.label}`);
        candidateWorkorderIdList.remove();
      }
    }
  }, [candidateWorkorderIdList.error, candidateWorkorderIdList.data]);

  useEffect(() => {
    if (jobDivaList) {
      const updatedStaet = dataFlow.map((e) => {
        return { ...e, data: e.level === 1 };
      });

      setDataFlow(updatedStaet);
    }
  }, [jobDivaList]);

  useEffect(() => {
    if (selectedJobdivaId && selectedJobdivaId !== "") {
      setJobDivaRecordError(null);

      if (deliverableType === "sow/milestone") {
        setSowJobdivaPayload((prevState: sowPayloadProps) => {
          return {
            ...prevState,
            startId: selectedJobdivaId || "",

            calculatedMarkup: calculatedMarkup.replace("%", ""),
            sowId:
              jobDivaList?.filter((e) => e.startId === selectedJobdivaId)[0]
                ?.sow || "",
          };
        });
      }

      setJobDivaDetails(null);
      setJobDivalist(
        jobDivaList &&
          jobDivaList.map((e) => {
            if (e.startId === selectedJobdivaId) {
              setHourlyRatesforJobDiva(
                (prevState: hourlyJobDivaRatesRequest) => {
                  return {
                    ...prevState,
                    startId: Number(e.startId),
                  };
                }
              );
            }

            return {
              ...e,
              selected: e.startId === selectedJobdivaId,
            };
          })
      );
    }
  }, [selectedJobdivaId]);

  useEffect(() => {
    if (jobDivaList && selectedJobdivaId !== "") {
      setRatesNonApprovedRecord(null);
      setLoader(true);
      jobDivaDataCall.refetch();
    }
  }, [jobDivaList]);

  useEffect(() => {
    if (jobDivaDataCall.error) {
      alert("not able to fecth the rates");
      jobDivaDataCall.remove();
    }

    if (jobDivaDataCall.data) {
      setLoader(false);

      if (
        "errors" in jobDivaDataCall.data &&
        jobDivaDataCall.data.errors.length > 0
      ) {
        setJobDivaRecordError({
          code: jobDivaDataCall.data.errors[0].code,
          message: jobDivaDataCall.data.errors[0].message,
        });
      }

      const updatedProjectDetails = {
        ...jobDivaDataCall.data,
        data: {
          ...jobDivaDataCall.data.data,
          billInfo: jobDivaDataCall.data.data.billInfo.map(
            (e: ProjectDetailsBillInfo) => ({
              ...e,
              select: false,
            })
          ),
        },
      };

      if (deliverableType === "sow/milestone") {
        setSowJobdivaPayload((prevState: sowPayloadProps) => {
          return {
            ...prevState,
            jobdivaMarkup:
              jobDivaDataCall.data.data.sowInfo?.sowMarkup?.raw?.toString() ||
              "",
          };
        });
      }

      setJobDivaDetails(
        (jobDivaDataCall &&
          Object.keys(jobDivaDataCall.data).length > 0 &&
          updatedProjectDetails) ||
          null
      );
      jobDivaDataCall.remove();
    }
  }, [jobDivaDataCall.error, jobDivaDataCall.data]);

  useEffect(() => {
    if (selectedClient.value === 0) {
      candidateWorkorderIdList.remove();
    }
  }, []);

  const selectRate = (id: number | null) => {
    if (jobDivaDetails) {
      const updatedProjectDetails = {
        ...jobDivaDetails,
        data: {
          ...jobDivaDetails.data,
          billInfo: jobDivaDetails.data.billInfo.map((e) => {
            if (e.recordId === id) {
              setSelectedRateId(id);
              setHourlyRatesforJobDiva(
                (prevState: hourlyJobDivaRatesRequest) => {
                  let objToAdd = prevState;

                  const jobDivaPayInfo = jobDivaDetails.data.payInfo.find(
                    (pay) => pay.recordId === e.recordId
                  );

                  if (selectedTabIndex === 0) {
                    objToAdd = {
                      ...objToAdd,
                      payInfo: {
                        id: Number(e.recordId),
                        jobdivaRate: {
                          payrate:
                            (jobDivaPayInfo &&
                              jobDivaPayInfo.regularPayrate &&
                              jobDivaPayInfo.regularPayrate.raw / 100) ||
                            0,
                        },
                        calculatedRate: {
                          payrate:
                            (responseData && responseData.payRate.raw / 100) ||
                            0,
                        },
                      },
                      billInfo: {
                        id: Number(e.recordId),
                        jobdivaRate: {
                          billrate:
                            (e &&
                              e.regularBillrate &&
                              e.regularBillrate?.raw / 100) ||
                            0,
                        },
                        calculatedRate: {
                          billrate:
                            (responseData && responseData.billRate.raw / 100) ||
                            0,
                        },
                      },
                    };

                    if (!e.overTimeExempt) {
                      objToAdd.billInfo.jobdivaRate = {
                        ...objToAdd.billInfo.jobdivaRate,
                        overtimeBillrate:
                          e &&
                          e.overTimeBillrate &&
                          e.overTimeBillrate?.raw / 100,
                        doubletimeBillrate:
                          e &&
                          e.doubleTimeBillrate &&
                          e.doubleTimeBillrate?.raw / 100,
                      };
                      objToAdd.billInfo.calculatedRate = {
                        ...objToAdd.billInfo.calculatedRate,
                        overtimeBillrate:
                          (responseData &&
                            Number(
                              (
                                (responseData?.billRate.raw / 100) *
                                1.5
                              ).toFixed(2)
                            )) ||
                          0,
                        doubletimeBillrate:
                          (responseData &&
                            Number(
                              (
                                (responseData?.billRate.raw / 100) *
                                2.0
                              ).toFixed(2)
                            )) ||
                          0,
                      };
                      objToAdd.payInfo.jobdivaRate = {
                        ...objToAdd.payInfo.jobdivaRate,
                        overtimePayrate:
                          jobDivaPayInfo &&
                          jobDivaPayInfo.overTimePayrate &&
                          jobDivaPayInfo.overTimePayrate?.raw / 100,
                        doubletimePayrate:
                          jobDivaPayInfo &&
                          jobDivaPayInfo.doubleTimePayrate &&
                          jobDivaPayInfo.doubleTimePayrate?.raw / 100,
                      };
                      objToAdd.payInfo.calculatedRate = {
                        ...objToAdd.payInfo.calculatedRate,
                        overtimePayrate:
                          (responseData &&
                            Number(
                              ((responseData?.payRate.raw / 100) * 1.5).toFixed(
                                2
                              )
                            )) ||
                          0,
                        doubletimePayrate:
                          (responseData &&
                            Number(
                              ((responseData?.payRate.raw / 100) * 2.0).toFixed(
                                2
                              )
                            )) ||
                          0,
                      };
                    }
                  } else {
                    objToAdd = {
                      ...objToAdd,
                      payInfo: {
                        id: Number(e.recordId),
                        jobdivaRate: {
                          payrate:
                            (jobDivaPayInfo &&
                              jobDivaPayInfo.regularPayrate &&
                              jobDivaPayInfo?.regularPayrate?.raw / 100) ||
                            0,
                        },
                        calculatedRate: {
                          payrate: Number(
                            (responseData &&
                              (responseData?.payRate.raw / 100).toFixed(2)) ||
                              0
                          ),
                        },
                      },
                      billInfo: {
                        id: Number(e.recordId),
                        jobdivaRate: {
                          billrate:
                            e &&
                            e.regularBillrate &&
                            e.regularBillrate.raw / 100,
                        },
                        calculatedRate: {
                          billrate:
                            (responseData &&
                              responseData.billRate &&
                              responseData.billRate?.raw / 100) ||
                            0,
                        },
                      },
                    };

                    if (!e.overTimeExempt) {
                      objToAdd.payInfo.jobdivaRate = {
                        ...objToAdd.payInfo.jobdivaRate,
                        overtimePayrate:
                          jobDivaPayInfo &&
                          jobDivaPayInfo.overTimePayrate &&
                          Number(
                            (jobDivaPayInfo.overTimePayrate?.raw / 100).toFixed(
                              2
                            )
                          ),
                        doubletimePayrate:
                          jobDivaPayInfo &&
                          jobDivaPayInfo.doubleTimePayrate &&
                          Number(
                            (
                              jobDivaPayInfo.doubleTimePayrate?.raw / 100
                            ).toFixed(2)
                          ),
                      };

                      objToAdd.payInfo.calculatedRate = {
                        ...objToAdd.payInfo.calculatedRate,
                        overtimePayrate:
                          (responseData &&
                            responseData.payRate &&
                            Number(
                              ((responseData.payRate?.raw / 100) * 1.5).toFixed(
                                2
                              )
                            )) ||
                          0,
                        doubletimePayrate:
                          (responseData &&
                            responseData.payRate &&
                            Number(
                              ((responseData.payRate?.raw / 100) * 2).toFixed(2)
                            )) ||
                          0,
                      };

                      objToAdd.billInfo.jobdivaRate = {
                        ...objToAdd.billInfo.jobdivaRate,
                        overtimeBillrate:
                          e &&
                          e.overTimeBillrate &&
                          e.overTimeBillrate?.raw / 100,
                        doubletimeBillrate:
                          e &&
                          e.doubleTimeBillrate &&
                          e.doubleTimeBillrate?.raw / 100,
                      };
                      objToAdd.billInfo.calculatedRate = {
                        ...objToAdd.billInfo.calculatedRate,
                        overtimeBillrate:
                          (responseData &&
                            Number(
                              (
                                (responseData?.billRate.raw / 100) *
                                1.5
                              ).toFixed(2)
                            )) ||
                          0,
                        doubletimeBillrate:
                          (responseData &&
                            Number(
                              (
                                (responseData?.billRate.raw / 100) *
                                2.0
                              ).toFixed(2)
                            )) ||
                          0,
                      };
                    }
                  }

                  return {
                    ...objToAdd,
                    effectiveDate: moment(selectedDate).format("YYYY-MM-DD"),
                  };
                }
              );
              setRatesNonApprovedRecord(!e.approved);
            }

            return {
              ...e,
              select: e.recordId === id,
            };
          }),
        },
      };

      setJobDivaDetails(updatedProjectDetails);
    }
  };

  const setHourlyRatesforJobDivaHandler = () => {
    setLoader(true);

    if (deliverableType === "hourly/milestone") {
      SubmitRatesToJobDiva.refetch();
    } else if (deliverableType === "sow/milestone") {
      SubmitSowRates.refetch();
    }
  };

  useEffect(() => {
    if (SubmitRatesToJobDiva.error) {
      alert("something went wrong, please try again later");

      return;
    }

    if (SubmitRatesToJobDiva.data) {
      setLoader(false);

      if (
        "errors" in SubmitRatesToJobDiva.data &&
        SubmitRatesToJobDiva.data.errors.length > 0
      ) {
        alert(SubmitRatesToJobDiva.data.errors[0].message);

        return;
      }

      const updatedState = dataFlow.map((e) => {
        return { ...e, data: e.level === 3 };
      });

      setDataFlow(updatedState);
    }
  }, [SubmitRatesToJobDiva.data, SubmitRatesToJobDiva.error]);

  useEffect(() => {
    if (SubmitSowRates.error) {
      alert("something went wrong, please try again later");

      return;
    }

    if (SubmitSowRates.data) {
      setLoader(false);

      if (
        "errors" in SubmitSowRates.data &&
        SubmitSowRates.data.errors.length > 0
      ) {
        alert(SubmitSowRates.data.errors[0].message);

        SubmitSowRates.remove();

        return;
      }

      const updatedState = dataFlow.map((e) => {
        return { ...e, data: e.level === 3 };
      });

      SubmitSowRates.remove();
      setDataFlow(updatedState);
    }
  }, [SubmitSowRates.data, SubmitSowRates.error]);

  return (
    <>
      <div className={classes.popup_blur}></div>
      <div className={classes.rules_edit_popup}>
        <div className={classes.aligning_container}>
          <div
            className={[
              classes.form_container,
              deliverableType === "sow/milestone"
                ? classes.sow_form_container_gap
                : "",
            ].join(" ")}
          >
            {dataFlow.map((e) => {
              if (e.level === 0 && e.data) {
                return (
                  <SearchClient
                    key={e.level}
                    setSelectedClient={setSelectedClient}
                    selectedClient={selectedClient}
                    setJobDiva={setJobDiva}
                  ></SearchClient>
                );
              }

              if (e.level === 1 && e.data) {
                return (
                  <JobDivaList
                    key={e.level}
                    deliverableType={deliverableType}
                    setDataFlow={setDataFlow}
                    dataFlow={dataFlow}
                    jobDivaList={jobDivaList}
                    setJobDivaId={setJobDivaId}
                    jobDivaDetails={jobDivaDetails}
                    levelToRemve={0}
                    setJobDivalist={setJobDivalist}
                    setJobDivaDetails={setJobDivaDetails}
                    selectedRatesRecordidNonApproved={
                      selectedRatesRecordidNonApproved
                    }
                    setRatesNonApprovedRecord={setRatesNonApprovedRecord}
                    jobDivaRecordError={jobDivaRecordError}
                    setJobDivaRecordError={setJobDivaRecordError}
                    setSelectedRateId={setSelectedRateId}
                  >
                    {deliverableType === "hourly/milestone" &&
                      jobDivaDetails &&
                      jobDivaDetails.data &&
                      selectedJobdivaId !== "" && (
                        <PayRateBillRate
                          ratesFixedHeightClass={
                            classes.jobdiva_list_fixed_height
                          }
                          jobDivaDetails={
                            jobDivaDetails &&
                            jobDivaDetails.data &&
                            jobDivaDetails
                          }
                          selectRate={selectRate}
                          setJobDivaDetails={setJobDivaDetails}
                          alterStyle={classes.full_width}
                          combinePayandBillTable={true}
                          selectedJobDivaId={selectedJobdivaId}
                        />
                      )}
                  </JobDivaList>
                );
              }

              if (e.level === 2 && e.data) {
                return (
                  <CompareRates
                    jobDivaList={jobDivaList}
                    key={e.level}
                    deliverableType={deliverableType}
                    setDataFlow={setDataFlow}
                    markUpValue={responseData?.markup?.value || ""}
                    dataFlow={dataFlow}
                    jobDivaDetails={jobDivaDetails}
                    selectedTabIndex={selectedTabIndex}
                    responseData={responseData}
                    selectedRecordId={selectedJobdivaId}
                    setOptionsRulesCheckBoxInCompareRates={
                      setOptionsRulesCheckBoxInCompareRates
                    }
                    sowJobDivapayload={sowJobDivapayload}
                    checkOptionsRulesInCompareRtes={
                      checkOptionsRulesInCompareRtes
                    }
                    setHourlyRatesforJobDivaHandler={
                      setHourlyRatesforJobDivaHandler
                    }
                    selectedRateId={selectedRateId}
                  >
                    <GoBack
                      setDataFlow={setDataFlow}
                      levelToRemve={1}
                      dataFlow={dataFlow}
                    />
                  </CompareRates>
                );
              }

              if (e.level === 3 && e.data) {
                return (
                  <SubmitToJobdiva
                    setJobDiva={setJobDiva}
                    key={e.level}
                    setDataFlow={setDataFlow}
                    dataFlow={dataFlow}
                    setRatesNonApprovedRecord={setRatesNonApprovedRecord}
                    setHourlyRatesforJobDiva={setHourlyRatesforJobDiva}
                    setSelectedRateId={setSelectedRateId}
                    setJobDivaDetails={setJobDivaDetails}
                    setJobDivaId={setJobDivaId}
                    setJobDivaRecordError={setJobDivaRecordError}
                    setJobDivalist={setJobDivalist}
                    SubmitRatesToJobDiva={SubmitRatesToJobDiva}
                  ></SubmitToJobdiva>
                );
              }

              return null;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default JobDiva;
