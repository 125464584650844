import { useOktaAuth } from "@okta/okta-react";
import { AxiosResponse } from "axios";
import moment from "moment";
import { useEffect, useState, useContext } from "react";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";

import API from "../../API";
import PricingCalcApi from "../../API/PricingCalcApi";
import ManageRuleAccess from "../../auth/checkUserAccesslevel";
import IconsName from "../../constants/IconsName";
import GlobalSpinnerContext from "../../contexts/LoaderContext";
import GlobalToastContext from "../../contexts/ToastContext";
import {
  businessRulesApiCallHandler,
  clientListApiHandler,
  dropdownApicallHander,
} from "../../pages/Details/details.logichandler";
import Button from "../Button/Button";
import Icon from "../Icon";
import SectionConatiner from "../SectionConatiner";
import Toast from "../Toast/Toast";

import MinInfoPopUp from "./components/MinDetalsPopUp.component";
import ClientListComponent from "./components/clientList.component";
import ManageOptionalRuleDataModel, {
  ManageStandardRulesDataModel,
} from "./components/manageRuleDataModel";
import OptionalRulesComponent from "./components/optionalRules.component";
import StandardRulesComponent from "./components/standardRules.component";
import UpdateRule from "./components/updateRule.component";

import "./manageRules.scss";

const customStyles = {
  content: {
    top: "48%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "70vw",
    height: "86%",

    boxShadow: "0 2px 13px rgb(62 48 116)",
    zIndex: 5,
    padding: "0",
    borderRadius: "5px",
    border: "none",
  },
};

const customStylesDelete = {
  content: {
    top: "48%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    height: "40vh",
    boxShadow: "0 2px 13px rgb(62 48 116)",
    zIndex: 9999,
    padding: "0",
    borderRadius: "5px",
    border: "none",
  },
};

const parentRuleComfirmationstype = {
  content: { ...customStylesDelete.content, height: "35vh", width: "35vw" },
};

export interface optionsProp {
  value: number;
  label: string;
  clientId: number;
}

export interface workOrderStateProps {
  name: string;
  description: string;
  group: optionsProp | null | unknown;
  operation: optionsProp | null;
  attribution: optionsProp | null;
  amount: string;
}

const MangeRules = () => {
  const history = useNavigate();
  const [clientList, setClientList] = useState<optionsProp[] | []>([]);

  const [selectedClientList, setSelectedClientList] = useState<
    optionsProp[] | []
  >([]);

  const [addNewRule, setAddnewRule] = useState<string>("");
  const [editRule, setEditRule] = useState<string>("");

  const [optionalRuleToManage, setOptionalRuleToManage] = useState<
    ManageOptionalRules[] | []
  >(ManageOptionalRuleDataModel);

  const [standardRuleToManage, setStandardRuleToManage] = useState<
    ManageStandardRules[] | []
  >(ManageStandardRulesDataModel);

  const { EnableToast, toastMessage } = useContext(GlobalToastContext);

  const [groupData, setGroupData] = useState<DropdownOptionsType[] | null>(
    null
  ); // Drop Down Data

  const [vendorData, setVendorData] = useState<SuppliersVendorData[] | null>(
    null
  );

  const [loadParentRules, setLoadParentRules] = useState<boolean>(false);

  const [attributionData, setAttributionData] = useState<
    DropdownOptionsType[] | null
  >(null); // attribution dropdown data

  const [operationData, setOperationData] = useState<
    DropdownOptionsType[] | null
  >(null); // operation dropdown Data

  const [engagementTypeData, setEngagementTypeData] = useState<
    DropdownOptionsType[] | null
  >(null); // engagement type dropdown Data

  const [businessStandardRules, setBusinessStandardRules] = useState<
    ManageStandardRules[] | null
  >();

  const [businessOptoinalRules, setBusinessOptionalRules] = useState<
    ManageOptionalRules[] | null
  >();

  const [toggleDeletePopup, setToggleDeletePopup] = useState<{
    [key: string]: number[];
  } | null>(null);

  const [selectedClient, setSelectedClient] = useState<optionsProp | null>(
    null
  );

  const { SetLoader } = useContext(GlobalSpinnerContext);
  const { authState } = useOktaAuth();
  const LoggedInUser = authState?.idToken?.claims.email;

  useEffect(() => {
    if (
      businessOptoinalRules &&
      businessOptoinalRules.length === 0 &&
      businessStandardRules &&
      businessStandardRules.length === 0 &&
      Boolean(selectedClientList[0].clientId)
    ) {
      setLoadParentRules(true);
    }
  }, [businessOptoinalRules, businessStandardRules]);
  useEffect(() => {
    const userPermission = ManageRuleAccess();

    if (userPermission) {
      history("/phase1", { replace: true });

      return;
    }

    (async () => {
      try {
        SetLoader(true);
        const clientListData = await clientListApiHandler();

        setClientList(
          clientListData.data.clients.map((e: ClientListObj) => ({
            label: e.jobdivaCompanyName,
            value: e.id,
            clientId: "parentClientId" in e ? e.parentClientId : "",
          }))
        );
      } catch (e) {
        EnableToast({
          message: "Something went wrong, please try again later",
          type: "error",
        });
        SetLoader(false);

        return;
      }

      SetLoader(false);
      const { attribution, group, operation, engagementTypes } =
        await dropdownApicallHander();

      const request = PricingCalcApi.getSuppliers();

      const response: AxiosResponse<Suppliers> = await API.performRequest(
        request
      );

      const vendorsData = response.data.data.vendors;

      setVendorData(vendorsData);
      setAttributionData(attribution);
      setGroupData(group);
      setOperationData(operation);
      setEngagementTypeData(engagementTypes);
    })();
  }, []);

  const clientChangeHandler = async (clientDetails: unknown) => {
    SetLoader(true);
    const clientData = clientDetails as optionsProp;

    if (!clientData) {
      setSelectedClient(null);
      SetLoader(false);
      setBusinessOptionalRules(null);
      setBusinessStandardRules(null);

      return;
    }

    setLoadParentRules(false);
    setSelectedClientList([clientData]);
    const businessRulesResponse = await businessRulesApiCallHandler(
      clientData.value
    );

    const { standardRules, optionalRules }: ManageBusinessRules["data"] =
      businessRulesResponse.data;

    setBusinessStandardRules(
      standardRules
        ? standardRules.map((rule) => {
            let amoutToFormat: ManageBusinessRuleCurrency = rule.amount;

            if (rule.amount.localized === "percentage") {
              amoutToFormat = {
                ...rule.amount,
                raw: rule.amount.raw ? rule.amount.raw : "",
              };
            } else {
              amoutToFormat = {
                ...rule.amount,
                raw: rule.amount.raw ? Number(rule.amount.raw) / 100 : "",
              };
            }

            return {
              ...rule,
              amount: { ...amoutToFormat },
            };
          })
        : []
    );
    setBusinessOptionalRules(
      optionalRules
        ? optionalRules.map((rule) => {
            let amoutToFormat: ManageBusinessRuleCurrency = rule.amount;

            if (rule.amount.localized === "percentage") {
              amoutToFormat = {
                ...rule.amount,
                raw: rule.amount.raw ? rule.amount.raw : "",
              };
            } else {
              amoutToFormat = {
                ...rule.amount,
                raw: rule.amount.raw ? Number(rule.amount.raw) / 100 : "",
              };
            }

            return {
              ...rule,
              amount: { ...amoutToFormat },
            };
          })
        : []
    );
    setSelectedClient({
      value: clientData.value,
      label: clientData.label,
      clientId: clientData.value,
    });
    SetLoader(false);
  };

  const manageRuleHandler = (recordid: number | null, type: string) => {
    let standardRuletoManage: ManageStandardRules[] = [];
    let optionalRuletoManage: ManageOptionalRules[] = [];

    if (!recordid) {
      setAddnewRule("");
      setEditRule("");
    } else {
      setEditRule(type);

      if (type === "editStandardRule") {
        standardRuletoManage = businessStandardRules
          ? businessStandardRules.filter((rule) => rule.id === recordid)
          : [];
      } else {
        optionalRuletoManage = businessOptoinalRules
          ? businessOptoinalRules.filter((rule) => rule.id === recordid)
          : [];
      }

      if (standardRuletoManage && standardRuletoManage.length > 0) {
        setStandardRuleToManage(standardRuletoManage.map((e) => ({ ...e })));
      }

      if (optionalRuletoManage && optionalRuletoManage.length > 0) {
        setOptionalRuleToManage(optionalRuletoManage.map((e) => ({ ...e })));
      }
    }
  };

  useEffect(() => {
    if (editRule === "" && addNewRule === "") {
      setOptionalRuleToManage(ManageOptionalRuleDataModel);
      setStandardRuleToManage(ManageStandardRulesDataModel);
    }
  }, [editRule, addNewRule]);

  const updateStateHandler = (obj: unknown, type: string) => {
    const objectStructureData = [
      "attribution",
      "group",
      "vendor",
      "client",
      "operation",
      "engagementType",
    ];

    const datatoAdd = obj as {
      [key: string]: string | number;
    };

    let newObject:
      | {
          [key: string]: {
            id: string | number;
            [key: string]: string | number;
          };
        }
      | null
      | { [key: string]: string | number } = null;

    const keyToUse = Object.keys(datatoAdd)[0];

    if (
      objectStructureData.includes(keyToUse.substring(0, keyToUse.length - 2))
    ) {
      const indexofKey = objectStructureData.findIndex(
        (e) => keyToUse.substring(0, keyToUse.length - 2) === e
      );

      const objvlues: [string | number, string | number] = [
        Object.values(datatoAdd)[0],
        Object.values(datatoAdd)[1],
      ];

      const objName =
        objectStructureData[indexofKey] === "vendor" ? "vendorName" : "name";

      if (objectStructureData[indexofKey] === "engagementType") {
        newObject = {
          engagementType: objvlues[1],
        };
      } else {
        newObject = {
          [objectStructureData[indexofKey]]: {
            id: objvlues[0],
            [objName]: objvlues[1],
          },
        };
      }
    }

    if (type === "editStandardRule" || type === "addStandardRule") {
      const standardRuletoManageInstance: ManageStandardRules[] =
        standardRuleToManage.map((rule) => {
          if (newObject) {
            return { ...rule, ...newObject };
          }

          return { ...rule, ...datatoAdd };
        });

      setStandardRuleToManage(standardRuletoManageInstance);
    }

    if (type === "editOptionalRule" || type === "addOptionalRule") {
      const OptionalRuletoManageInstance: ManageOptionalRules[] =
        optionalRuleToManage.map((rule) => {
          if (newObject) {
            return { ...rule, ...newObject };
          }

          return { ...rule, ...datatoAdd };
        });

      setOptionalRuleToManage(OptionalRuletoManageInstance);
    }
  };

  const deleteRuleConfirmationHandler = async (
    deleteObj: {
      [key: string]: number[];
    } | null
  ) => {
    SetLoader(true);
    const payload: ManageRulesPayloadType = {
      rules: [],
      ...deleteObj,
      performedBy: LoggedInUser || "",
    };

    const request = PricingCalcApi.submitBusinessRules(
      payload,
      "delete",
      LoggedInUser || ""
    );

    const response: AxiosResponse<ManageBusinessRules["data"]> =
      await API.performRequest(request);

    if (Object.keys(response.data).length > 0) {
      clientChangeHandler(selectedClient);
    }

    setToggleDeletePopup(null);
    SetLoader(false);
  };

  const deleteRulehandler = async (
    deleteObj: { [key: string]: number[] } | null
  ) => {
    setToggleDeletePopup(deleteObj);
  };

  const updateRuleHandler = async (typeofrule: string) => {
    SetLoader(true);
    let ruleId: number | null;
    let clientid: string;
    let standard: unknown;

    if (
      typeofrule !== "editStandardRule" &&
      typeofrule !== "editOptionalRule"
    ) {
      ruleId = null;
    } else {
      ruleId =
        (typeofrule === "editStandardRule"
          ? Number(standardRuleToManage[0].id)
          : Number(optionalRuleToManage[0].id)) || null;
    }

    if (
      typeofrule !== "editStandardRule" &&
      typeofrule !== "editOptionalRule"
    ) {
      clientid = `${selectedClientList[0].value}`;
    } else {
      clientid =
        typeofrule === "editStandardRule"
          ? `${standardRuleToManage[0].client.id}` || ""
          : `${optionalRuleToManage[0].client.id}` || "";
    }

    const expireDateWithRule =
      typeofrule === "editStandardRule" || typeofrule === "addStandardRule"
        ? standardRuleToManage[0].expireDate?.raw
        : optionalRuleToManage[0].expireDate?.raw;

    const effectiveDateWithRule =
      typeofrule === "editStandardRule" || typeofrule === "addStandardRule"
        ? standardRuleToManage[0].effectiveDate?.raw
        : optionalRuleToManage[0].effectiveDate?.raw;

    const commonRule = {
      id: ruleId,
      clientId: clientid,

      name:
        typeofrule === "editStandardRule" || typeofrule === "addStandardRule"
          ? standardRuleToManage[0].name
          : optionalRuleToManage[0].name,
      description:
        typeofrule === "editStandardRule" || typeofrule === "addStandardRule"
          ? standardRuleToManage[0].description
          : optionalRuleToManage[0].description,
      effectiveDate:
        (effectiveDateWithRule &&
          moment(effectiveDateWithRule).format("YYYY-MM-DD")) ||
        null,
      expireDate:
        (expireDateWithRule &&
          moment(expireDateWithRule).format("YYYY-MM-DD")) ||
        null,
      attributionId:
        typeofrule === "editStandardRule" || typeofrule === "addStandardRule"
          ? standardRuleToManage[0].attribution.id
          : optionalRuleToManage[0].attribution.id,
      groupId:
        typeofrule === "editStandardRule" || typeofrule === "addStandardRule"
          ? standardRuleToManage[0].group.id
          : optionalRuleToManage[0].group.id,
      operationId:
        typeofrule === "editStandardRule" || typeofrule === "addStandardRule"
          ? standardRuleToManage[0].operation.id
          : optionalRuleToManage[0].operation.id,
      amount:
        typeofrule === "editStandardRule" || typeofrule === "addStandardRule"
          ? standardRuleToManage[0].amount.raw
          : optionalRuleToManage[0].amount.raw,
      currency:
        typeofrule === "editStandardRule" || typeofrule === "addStandardRule"
          ? standardRuleToManage[0].currency || null
          : optionalRuleToManage[0].currency || null,
      s4VendorId:
        typeofrule === "editStandardRule" || typeofrule === "addStandardRule"
          ? (standardRuleToManage[0].vendor &&
              standardRuleToManage[0].vendor.id) ||
            null
          : (optionalRuleToManage[0].vendor &&
              optionalRuleToManage[0].vendor.id) ||
            null,

      ruleType:
        typeofrule === "editStandardRule" || typeofrule === "addStandardRule"
          ? "STANDARD"
          : "OPTIONAL",
    };

    if (typeofrule === "editStandardRule" || typeofrule === "addStandardRule") {
      standard = {
        ...commonRule,
        country: standardRuleToManage[0].country || null,
        mainDivision: standardRuleToManage[0].mainDivision || null,
        city: standardRuleToManage[0].city || null,
        rateLow: standardRuleToManage[0].rateLow || null,
        rateHigh: standardRuleToManage[0].rateHigh || null,
        engagementType: standardRuleToManage[0].engagementType || null,
      };
    }

    const payloadRuleToUpdate =
      typeofrule === "editStandardRule" || typeofrule === "addStandardRule"
        ? standard
        : commonRule;

    const request = PricingCalcApi.submitBusinessRules(
      payloadRuleToUpdate,
      typeofrule,
      LoggedInUser || ""
    );

    const response: AxiosResponse<BusinessRules> = await API.performRequest(
      request
    );

    if (response.data.errors && response.data.errors.length > 0) {
      SetLoader(false);
      EnableToast({
        message: response.data.errors[0].message,
        type: "error",
      });

      return;
    }

    if (Object.keys(response.data.data).length > 0) {
      EnableToast({
        message: "Rule has been updated successfully",
        type: "success",
      });
      setBusinessOptionalRules(null);
      setBusinessStandardRules(null);
      clientChangeHandler(selectedClient);
      setEditRule("");
      setAddnewRule("");
    }

    SetLoader(false);
  };

  const toggleParentRuleConrimationPopUp = () => {
    setLoadParentRules(false);
  };

  return (
    <div className="manageRulesParent">
      {toastMessage.message !== "" ? <Toast /> : null}
      <div className="manageRulesParent__main">
        <MinInfoPopUp
          styleObj={parentRuleComfirmationstype}
          isOpen={loadParentRules}
        >
          <div className="manageRulesParent__main__parentRuleconfirmation">
            <span>
              No Rules Available for
              <br />{" "}
              <h4>
                {selectedClientList &&
                  selectedClientList.length > 0 &&
                  selectedClientList[0].label}
              </h4>
            </span>
            <span>Do you want to load Parent Business Rules?</span>
            <div>
              <Button
                name="Cancel"
                onClick={() => toggleParentRuleConrimationPopUp()}
              />
              <Button
                onClick={() => {
                  const parentDetails = clientList.filter(
                    (e) => e.value === selectedClientList[0].clientId
                  );

                  clientChangeHandler(parentDetails[0]);
                }}
                name="Proceed"
              />
            </div>
          </div>
        </MinInfoPopUp>

        <MinInfoPopUp styleObj={customStylesDelete} isOpen={toggleDeletePopup}>
          <div className="manageRulesParent__main__deleteParent">
            <div>
              Delete
              {` ${
                toggleDeletePopup &&
                toggleDeletePopup?.deletedStandardRuleIds.length > 0
                  ? "Standard "
                  : "Optional "
              }`}
              Rules
            </div>
            <div>
              Are you sure you want to delete
              {` ${
                toggleDeletePopup &&
                toggleDeletePopup?.deletedStandardRuleIds.length > 0
                  ? "Standard "
                  : "Optional "
              }`}{" "}
              rule?
            </div>
            <div>
              <Button
                name="Cancel"
                onClick={() => {
                  void deleteRulehandler(null);
                }}
              />
              <Button
                onClick={() => {
                  void deleteRuleConfirmationHandler(toggleDeletePopup);
                }}
                name="Delete"
              />
            </div>
          </div>
        </MinInfoPopUp>

        <ClientListComponent
          clientList={clientList}
          selectedClient={selectedClient}
          clientChangeHandler={(item) => {
            void clientChangeHandler(item);
          }}
          clientsComponentsClasses={{
            parent: `manageRulesParent__main__clientListParent ${
              toggleDeletePopup || addNewRule !== "" || editRule !== ""
                ? "manageRulesParent__main__clientListopacity"
                : ""
            }`,
            label: "manageRulesParent__main__clientListParent__clientListlabel",
            list: "manageRulesParent__main__clientListParent__clientList",
          }}
        />

        <SectionConatiner
          className="manageRuleSectionContainer"
          title="Standard Rules"
        >
          {selectedClientList.length > 0 &&
            businessStandardRules &&
            (businessStandardRules?.length > 0 ||
              businessStandardRules?.length === 0) && (
              <Icon
                name={IconsName.ADDNEWRECORD}
                className={`btn-addnewoptional ${
                  businessStandardRules?.length === 0 ? "btn-addMargin" : ""
                }`}
                onClick={() => {
                  setStandardRuleToManage(ManageStandardRulesDataModel);
                  setAddnewRule("addStandardRule");
                }}
              />
            )}
          {((businessStandardRules && businessStandardRules.length === 0) ||
            !businessStandardRules) && (
            <div className="nodatafound_parent">
              <Icon className="nodatafound" name={IconsName.NODATAFOUND} />
              <p className="nodatafoundText">No Data </p>
            </div>
          )}

          {businessStandardRules && businessStandardRules.length > 0 && (
            <StandardRulesComponent
              businessStandardRules={businessStandardRules}
              manageRuleHandler={manageRuleHandler}
              deleteRulehandler={(item) => {
                void deleteRulehandler(item);
              }}
            />
          )}
        </SectionConatiner>

        <SectionConatiner
          className="manageRuleSectionContainer"
          title="Optional Rules"
        >
          {selectedClientList.length > 0 &&
            businessOptoinalRules &&
            (businessOptoinalRules?.length > 0 ||
              businessOptoinalRules?.length === 0) && (
              <Icon
                name={IconsName.ADDNEWRECORD}
                className={`btn-addnewoptional ${
                  businessOptoinalRules?.length === 0 ? "btn-addMargin" : ""
                }`}
                onClick={() => {
                  setOptionalRuleToManage(ManageOptionalRuleDataModel);
                  setAddnewRule("addOptionalRule");
                }}
              />
            )}
          {((businessOptoinalRules && businessOptoinalRules.length === 0) ||
            !businessOptoinalRules) && (
            <div className="nodatafound_parent">
              <Icon className="nodatafound" name={IconsName.NODATAFOUND} />
              <p className="nodatafoundText">No Data </p>
            </div>
          )}
          {businessOptoinalRules && businessOptoinalRules.length > 0 && (
            <OptionalRulesComponent
              businessOptionalRules={businessOptoinalRules}
              manageRuleHandler={manageRuleHandler}
              deleteRulehandler={(item) => {
                void deleteRulehandler(item);
              }}
            />
          )}
        </SectionConatiner>
        {(Boolean(editRule) || Boolean(addNewRule)) && (
          <ReactModal
            style={customStyles}
            isOpen
            contentLabel="Minimal Modal Example"
            ariaHideApp={false}
          >
            <div className="manageRulesParent__main__businessModal">
              <header>
                <h3>{editRule !== "" ? "Edit Rule" : "Add New Rule"}</h3>
                <div>
                  <button
                    type="button"
                    onClick={() => manageRuleHandler(null, "")}
                  >
                    x
                  </button>
                </div>
              </header>
              <UpdateRule
                optionalRuleToManage={optionalRuleToManage}
                standardRuleToManage={standardRuleToManage}
                className="manageRulesParent__main__businessModal__bodyParent"
                dropDownData={{
                  operationData,
                  groupData,
                  attributionData,
                  engagementData: engagementTypeData,
                }}
                updateStateHandler={updateStateHandler}
                vendorData={vendorData}
                updateRuleHandler={(item) => {
                  void updateRuleHandler(item);
                }}
                editRule={editRule}
                addNewRule={addNewRule}
                manageRuleHandler={manageRuleHandler}
                LoggedInUser={LoggedInUser}
              />
            </div>
          </ReactModal>
        )}
      </div>
    </div>
  );
};

export default MangeRules;
