import React, { useEffect, useState } from "react";
import { UseQueryResult } from "react-query";

import {
  engagementTypeListProps,
  getLocationProps,
  optionsProp,
  selectedItemsProps,
} from "../../../@types/component";
import API from "../../../API/PricingCalcApiPhaseone";
import useQueryData from "../../../hooks/useQueryData";
import useStore from "../../../store/useStore";
import ConfirmationPopup from "../../clientRules/manageClientRules/confirmationPopup";

import RulesTableSection from "./RulesTableSection";
import SimulateScreen from "./SimulateSection";

import classes from "./qRates.module.scss";

interface QRatesProps {
  clientList: optionsProp[];
  setSelectedClient: React.Dispatch<React.SetStateAction<optionsProp>>;
  selectedClient: optionsProp;
  engagementTypeList: engagementTypeListProps[];
  clearEngagementType: (filterType: string) => void;
  handleFilterChange: (
    filterType: string,
    filterValue: engagementTypeListProps
  ) => void;
  selectedFilters: selectedItemsProps;
  handleReset: () => void;
  selectedDate: Date | null;
  handleDateChange: (val: Date | null) => void;
  handleDateClear: () => void;
  handleRateFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  rateFilter: number | null;
  setScreen2Transition: React.Dispatch<React.SetStateAction<boolean>>;

  setSelectedTabIndex: React.Dispatch<React.SetStateAction<number>>;
  selectedTabIndex: number;
  setInput: React.Dispatch<React.SetStateAction<string>>;
  input: string;
  textTocompareAfterDebouce?: string;
  setErrorCode: React.Dispatch<React.SetStateAction<number | null>>;
  errorCode: number | null;
  setWarningMsg: React.Dispatch<React.SetStateAction<boolean>>;
  warningMsg: boolean;
  setJobDiva: React.Dispatch<React.SetStateAction<boolean>>;
  setRateFilter: React.Dispatch<React.SetStateAction<number | null>>;
  setRateFilterChange: React.Dispatch<React.SetStateAction<boolean>>;
  rateFilterChange: boolean;
  openJobDiva: boolean;
  setResponseData: React.Dispatch<
    React.SetStateAction<CalcAtributes["data"] | null>
  >;
  responseData: CalcAtributes["data"] | null;
  setSowResponseData: React.Dispatch<
    React.SetStateAction<CalcAtributes["data"] | null>
  >;
  sowResponseData: CalcAtributes["data"] | null;
  setReceivedOptionalRules: React.Dispatch<
    React.SetStateAction<ManageOptionalRules[] | null>
  >;
  receivedOptionalRules: ManageOptionalRules[] | null;
  setUpdatedOptionalRules: React.Dispatch<
    React.SetStateAction<{ id: number; checked: boolean }[] | undefined>
  >;
  updatedOptionalRules: { id: number; checked: boolean }[] | undefined;
  writeAccess?: boolean;
  rulesForClint: UseQueryResult;
  standardRules: {
    client: {
      ruleSets: { id: number; standardRules: ManageStandardRules[] }[];
    };
  };
  optionalRules: {
    client: {
      ruleSets: { id: number; optionalRules: ManageOptionalRules[] }[];
    };
  };
}

const QRates: React.FC<QRatesProps> = ({
  selectedClient,
  engagementTypeList,
  clearEngagementType,
  selectedFilters,
  handleFilterChange,
  handleReset,
  selectedDate,
  handleDateChange,
  handleDateClear,
  handleRateFilterChange,
  rateFilter,
  setSelectedClient,
  clientList,
  setScreen2Transition,
  setSelectedTabIndex,
  selectedTabIndex,
  setInput,
  input,
  setErrorCode,
  errorCode,
  setWarningMsg,
  warningMsg,
  setJobDiva,
  openJobDiva,
  setResponseData,
  responseData,
  setReceivedOptionalRules,
  receivedOptionalRules,
  setUpdatedOptionalRules,
  updatedOptionalRules,
  setSowResponseData,
  sowResponseData,
  writeAccess,
  textTocompareAfterDebouce,
  rulesForClint,
  optionalRules,
  standardRules,
  setRateFilter,
  setRateFilterChange,
  rateFilterChange,
}) => {
  const setLoader = useStore((state) => state.setLoader);
  // const { showBoundary } = useErrorBoundary();

  const [receivedStandardRules, setreceivedStandardRules] = useState<
    ManageStandardRules[] | null
  >([]);

  const [buttonClicked, setButtonClicked] = useState<boolean>(false);
  const [simulateDisable, setSimulateDisable] = useState(false);

  const [locationData, setLocationData] = useState<getLocationProps | null>(
    null
  );

  const [showConfirmationPopup, setConfiramtionPopup] = useState(false);

  const getLocation = useQueryData(
    "location-list",
    {
      clientId: `clientid in (${selectedClient?.value || ""})`,
    },
    false,
    API.getLocationOpts
  );

  useEffect(() => {
    setReceivedOptionalRules([]);
    setreceivedStandardRules([]);
  }, [selectedFilters, selectedDate, rateFilter]);

  useEffect(() => {
    rulesForClint.refetch();
    getLocation.refetch();

    return () => {
      rulesForClint.remove();
      getLocation.remove();
    };
  }, []);

  const handleFetchRules = (): void => {
    setResponseData(null);
    setSowResponseData(null);
    setSelectedTabIndex(0);
    setRateFilterChange(false);
    rulesForClint.refetch();
    setLoader(true);
    setSimulateDisable(false);
    setButtonClicked(true);
  };

  const getOptionalRules = (updatedOR: { id: number; checked: boolean }[]) => {
    setUpdatedOptionalRules(updatedOR);
  };

  useEffect(() => {
    // selectedFilters.deliverableType.name.toLowerCase() !== "sow/milestone" &&
    setSimulateDisable(true);
  }, [selectedFilters, selectedDate, rateFilter]);

  useEffect(() => {
    setLoader(getLocation.isLoading);

    if (getLocation.error) {
      console.log(getLocation.error);
    }

    if (getLocation.data && getLocation.isFetched && !getLocation.isLoading) {
      setLoader(false);
      setLocationData(getLocation.data.data);
    }
  }, [getLocation.isLoading, getLocation.error, getLocation.data]);

  useEffect(() => {
    if (rulesForClint.error) {
      console.log(rulesForClint.error);
    }

    const optionalRulesData =
      optionalRules?.client?.ruleSets?.map(
        ({ optionalRules }: { optionalRules: ManageOptionalRules[] }) => {
          return optionalRules ? [...optionalRules] : [];
        }
      ) || [];

    const standardRulesData =
      standardRules?.client?.ruleSets?.map(
        ({ standardRules }: { standardRules: ManageStandardRules[] }) => {
          return standardRules ? [...standardRules] : [];
        }
      ) || [];

    if (
      rulesForClint &&
      (!optionalRulesData || optionalRulesData.length < 1) &&
      rulesForClint &&
      (!standardRulesData || standardRulesData.length < 1) &&
      Boolean(selectedClient.clientId) &&
      !rulesForClint.isLoading &&
      !showConfirmationPopup &&
      selectedClient.clientId !== selectedClient.value
    ) {
      setConfiramtionPopup(true);

      return;
    }

    setConfiramtionPopup(false);

    if (
      rulesForClint.data &&
      rulesForClint.isFetched &&
      !rulesForClint.isLoading
    ) {
      const standardRulesData =
        Array.prototype.concat.apply(
          [],
          standardRules?.client?.ruleSets?.map(
            ({
              standardRules,
              id,
            }: {
              standardRules: ManageStandardRules[];
              id: number;
            }) => {
              return (
                standardRules?.map((e) => ({
                  ...e,
                  rulesetId: id,
                })) || []
              );
            }
          )
        ) || [];

      const optionalRulesData =
        Array.prototype.concat.apply(
          [],
          optionalRules?.client?.ruleSets?.map(
            ({
              optionalRules,
              id,
            }: {
              optionalRules: ManageOptionalRules[];
              id: number;
            }) => {
              return (
                optionalRules?.map((e) => ({
                  ...e,
                  rulesetId: id,
                })) || []
              );
            }
          )
        ) || [];

      setreceivedStandardRules(standardRulesData);

      setReceivedOptionalRules(optionalRulesData);
      setSimulateDisable(false);
    }

    setButtonClicked(false);
  }, [rulesForClint.isLoading, rulesForClint.error, rulesForClint.data]);

  const callRulesFetchQuery = () => {
    rulesForClint.refetch();
  };

  useEffect(() => {
    if (selectedClient.clientId === selectedClient.value) {
      setConfiramtionPopup(false);
      callRulesFetchQuery();
    }
  }, [selectedClient]);

  return (
    <div className={classes.container} data-testid="qrates">
      {showConfirmationPopup && (
        <ConfirmationPopup
          secondaryButton={() => {
            setSelectedClient({
              value: 0,
              label: "",
              clientId: 0,
            });
            setScreen2Transition(false);
            setConfiramtionPopup(false);
            clearEngagementType("");
          }}
          primaryButton={() => {
            rulesForClint.remove();

            const clientName = clientList.filter((e) => {
              return e.value === selectedClient.clientId;
            });

            setSelectedClient({
              value: Number(selectedClient.clientId),
              label:
                (clientName && clientName.length > 0 && clientName[0].label) ||
                "",
              clientId: selectedClient.clientId,
            });
            setConfiramtionPopup(false);
          }}
          primaryText={
            <p className={classes.alter_text_style}>
              No Rules Available for <b>&quot;{selectedClient.label}&quot;</b>
            </p>
          }
          secondaryText={`Do you want to load Parent Business Rules?`}
        />
      )}
      <div className={classes.rules}>
        <RulesTableSection
          writeAccess={writeAccess}
          selectedClient={selectedClient}
          engagementTypeList={engagementTypeList}
          clearEngagementType={clearEngagementType}
          selectedFilters={selectedFilters}
          handleFilterChange={handleFilterChange}
          handleReset={handleReset}
          selectedDate={selectedDate}
          handleDateChange={handleDateChange}
          handleDateClear={handleDateClear}
          handleFetchRules={handleFetchRules}
          receivedStandardRules={receivedStandardRules}
          receivedOptionalRules={receivedOptionalRules}
          getOptionalRules={getOptionalRules}
          locationData={locationData}
          handleRateFilterChange={handleRateFilterChange}
          rateFilter={rateFilter}
        />
      </div>
      <div className={[classes.simulate].join(" ")}>
        <SimulateScreen
          simulateDisable={simulateDisable}
          selectedFilters={selectedFilters}
          selectedClient={selectedClient}
          receivedStandardRules={receivedStandardRules}
          receivedOptionalRules={receivedOptionalRules}
          selectedDate={selectedDate}
          updatedOptionalRules={updatedOptionalRules}
          rateFilter={rateFilter}
          buttonClicked={buttonClicked}
          setSelectedTabIndex={setSelectedTabIndex}
          selectedTabIndex={selectedTabIndex}
          setInput={setInput}
          input={input}
          setErrorCode={setErrorCode}
          errorCode={errorCode}
          setWarningMsg={setWarningMsg}
          warningMsg={warningMsg}
          setJobDiva={setJobDiva}
          openJobDiva={openJobDiva}
          setResponseData={setResponseData}
          responseData={responseData}
          setSowResponseData={setSowResponseData}
          sowResponseData={sowResponseData}
          writeAccess={writeAccess}
          setRateFilter={setRateFilter}
          setRateFilterChange={setRateFilterChange}
          rateFilterChange={rateFilterChange}
        />
      </div>
    </div>
  );
};

export default QRates;
