import moment from "moment";
import { useMemo } from "react";
import { Column } from "react-table";

import Input from "../../Input";
import Table from "../../TableComponent/parentTableComponent";

interface OptionalRulesIProps {
  businessOptionalRules: QratesManageOptionalRules[] | null | undefined;
  manageRuleHandler: (records: number | string, type: string) => void;
}

interface TableDataProps {
  [key: string]: string | number | React.ReactElement | null;
}
interface TableColIProps {
  [key: string]: string | number | React.ReactNode;
}

const OptionalRules = ({
  businessOptionalRules,
  manageRuleHandler,
}: OptionalRulesIProps) => {
  let OptionalRulesHeaders: Column<TableColIProps>[] | [] = [];

  const data: TableDataProps[] = [];

  const validateAmount = (rule: QratesManageOptionalRules) => {
    if (rule.amount.localized === "percentage") {
      return rule.amount.value;
    }

    return rule.amount.raw && rule.amount.raw !== ""
      ? `$${Number(rule.amount.raw)}`
      : "";
  };

  if (businessOptionalRules) {
    businessOptionalRules.forEach((rule: QratesManageOptionalRules, i) => {
      data.push({
        id: rule.id || "",
        clientName: rule.client.name,
        name: rule.name,
        description: rule.description,
        effectiveDate: rule.effectiveDate
          ? moment(rule.effectiveDate.raw).format("MMM DD, YYYY")
          : "",
        expireDate: rule.expireDate
          ? moment(rule.expireDate.raw).local().format("MMM DD, YYYY")
          : "",
        performedBy: rule.performedBy,

        attributionId: rule.attribution.id,
        attributionName: rule.attribution.name,
        groupId: rule.group.id,
        groupName: rule.group.name,
        operationId: rule.operation.id,
        operationName: rule.operation.name,
        amount: validateAmount(rule),
        currency: rule.currency,
        s4VendorId: (rule.vendor && rule.vendor.id) || null,
        s4VendorName: (rule.vendor && rule.vendor.vendorName) || null,
        createdDate: rule.createdDate
          ? moment(rule.createdDate.raw).local().format("MMM DD, YYYY")
          : "",
        updatedDate: rule.updatedDate
          ? moment(rule.updatedDate.raw).local().format("MMM DD, YYYY")
          : "",
        action: ((record: { id: number | string; isChecked?: boolean }) => (
          <div className="action_btn_styles">
            <Input.CheckBox
              onChange={() => manageRuleHandler(record.id, "optional")}
              value={`${record.id}`}
              name={record.id}
              isChecked={record.isChecked || false}
            />
          </div>
        ))(rule),
      });
    });

    OptionalRulesHeaders =
      data && data.length > 0
        ? useMemo(
            () =>
              [...Object.keys(data[0])]
                .filter(
                  (rule) =>
                    ![
                      "id",
                      "clientId",
                      "clientName",
                      "s4VendorId",
                      "operationId",
                      "groupId",
                      "attributionId",
                      "isChecked",
                      "currency",
                    ].includes(rule)
                )
                .map((e, i) => ({
                  Header:
                    e === "action"
                      ? ""
                      : e
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^./, (str) => str.toUpperCase()),
                  accessor: e,

                  width: [
                    "name",
                    "description",
                    "attributionName",
                    "groupName",
                    "operationName",
                    "performedBy",
                  ].includes(e)
                    ? (e === "performedBy" && 350) || 250
                    : 150,
                  sticky: ((index) => {
                    if (index === 0) {
                      return "left";
                    }

                    if (index > Object.keys(data[0]).length - 9) {
                      return "right";
                    }

                    return "";
                  })(i),
                })),

            []
          )
        : [];
  }

  return <Table data={data} columns={OptionalRulesHeaders} />;
};

export default OptionalRules;
