import React from "react";
import { Column } from "react-table";

import StaticTable from "./staticTable";
import StickyTable from "./stickyTable";

interface TableIProps {
  [key: string]: string | number | React.ReactNode;
}

interface TableColIProps {
  [key: string]: string | number | React.ReactNode;
}
const Table = ({
  columns,
  data,
}: {
  columns: Column<TableColIProps>[];
  data: TableIProps[];
}) => (
  <>
    {columns.length > 8 && <StickyTable columns={columns} data={data} />}
    {columns.length <= 8 && <StaticTable columns={columns} data={data} />}
  </>
);

export default Table;
