import { useEffect, KeyboardEvent, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { JobDivaSearchProps, optionsProp } from "../../@types/component";
import NewAPi from "../../API/PricingCalcApiPhaseone";
import "../../App.css";
import AuthorizeUserHOC from "../../HOC/Authorisation/authorizeuser.component";
import ErrorBoundary from "../../HOC/ErrorBoundary/errorBoundary.component";
import { UserAccessForFeature } from "../../constants/componentLevelUserRoles";
import useQueryData from "../../hooks/useQueryData";
import useStore from "../../store/useStore";
import MBOInput from "../MBOInput";
import SuggestionWIndow from "../MBOSuggestion";
import MBOTooltip from "../MBOTooltip";

import classes from "./index.module.scss";

const SearchComponent: React.FC<JobDivaSearchProps> = ({
  setSelectedJobDiva,
  selectedJobDivaId,
  getJobDIvaDetails,
  setSelectedClient,
  selectedClient,
  isJobDiva,
  alterStyle,
}) => {
  const history = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const searchbarRef = useRef<HTMLInputElement>(null);
  const setLoader = useStore((state) => state.setLoader);

  const initialClientList = {
    value: 0,
    label: "",
    clientId: 0,
  };

  const [input, setInput] = useState<string>("");
  const [debouncedText] = useDebounce(input, 800);
  const [dropdownShow, setdropdownShow] = useState(false);
  const suggestionWindowRef = useRef<HTMLDivElement>(null);
  const [focusedIndex, setFocusedIndex] = useState<number>(-1);
  const [textboxSpinner, setTextBoxSpinner] = useState<boolean>(false);
  const [noDataFound, setNoDataFound] = useState<string>("");

  const candidateLIst = useQueryData(
    "client-list-api",
    { text: debouncedText },
    false,
    NewAPi.getCandidateList
  );

  const [candidateData, setCandidateData] = useState<optionsProp[] | []>([]);

  const [filteredData, setFilteredData] = useState<optionsProp[]>([
    initialClientList,
  ]);

  const handleDropdownShow = (val: boolean) => {
    try {
      setdropdownShow(val);
    } catch (e) {
      console.log("Something went wrong, please try again later");
    }
  };

  const selectClient = (val: optionsProp) => {
    try {
      setSelectedClient(val);
      setInput(val.label);
    } catch (e) {
      console.log("Something went wrong, please try again later");
    }
  };

  const clearSelectedClient = (): void => {
    try {
      setInput("");
      setSelectedClient(initialClientList);
      setFilteredData(candidateData);
      setFocusedIndex(-1);
    } catch (e) {
      console.log("Something went wrong, please try again later");
    }
  };

  useEffect(() => {
    try {
      setFilteredData(candidateData);
    } catch (e) {
      console.log("Something went wrong, please try again later");
    }
  }, [candidateData]);

  useEffect(() => {
    try {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node)
        ) {
          setdropdownShow(false);
          setFocusedIndex(-1);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    } catch (e) {
      console.log("Something went wrong, please try again later");
    }
  }, []);

  useEffect(() => {
    if (selectedJobDivaId === "") {
      setNoDataFound("No Start Id found");
    }
  }, [selectedJobDivaId]);

  useEffect(() => {
    try {
      setSelectedJobDiva && setSelectedJobDiva(input);

      if (input.length < 2) {
        return;
      }

      const filteredData = candidateData.filter((item) =>
        item.label.toLowerCase().includes(input.toLowerCase())
      );

      input.length > 0 && setFilteredData(filteredData);
      input.length > 0 && setFocusedIndex(-1);
    } catch (e) {
      console.log("Something went wrong, please try again later");
    }
  }, [input]);

  useEffect(() => {
    if (candidateLIst.error) {
      alert("something went wrong");
      setTextBoxSpinner(false);
    }

    if (candidateLIst.data) {
      if (
        candidateLIst.data &&
        candidateLIst.data.data &&
        candidateLIst.data.data.candidates
      ) {
        setNoDataFound("");
        setLoader(false);
        setTextBoxSpinner(false);
        setFocusedIndex(-1);
        setCandidateData(
          candidateLIst.data.data.candidates.map(
            (e: {
              [key: string]:
                | string
                | number
                | { [key: string]: string | number };
            }) => {
              const data: optionsProp = {
                value: Number(e?.candidateId),
                label:
                  typeof e?.firstName === "string" &&
                  typeof e?.lastName === "string"
                    ? `${e?.firstName.toString()} ${e?.lastName.toString()}`
                    : "",
                clientId: "",
              };

              return data;
            }
          )
        );
      } else {
        setTextBoxSpinner(false);

        if (input !== "" && isNaN(+input)) {
          setNoDataFound("No Data Found");
        }
      }

      candidateLIst.remove();
    }
  }, [candidateLIst.error, candidateLIst.data]);

  useEffect(() => {
    if (
      debouncedText &&
      debouncedText !== "" &&
      isNaN(Number(debouncedText)) &&
      debouncedText.length > 2
    ) {
      if (
        filteredData.length < 1 ||
        filteredData.findIndex((e) =>
          e.label.toLowerCase().includes(debouncedText.toLowerCase())
        ) < 0
      ) {
        setTextBoxSpinner(true);
        candidateLIst.refetch();
      }
    }
  }, [debouncedText]);

  useEffect(() => {
    if (dropdownRef.current && !textboxSpinner) {
      const inputElement = dropdownRef.current.querySelector("input");

      if (inputElement instanceof HTMLInputElement) {
        inputElement.focus();
      }
    }
  }, [searchbarRef.current, textboxSpinner]);

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "ArrowDown") {
      // Show or focus the suggestion window when down arrow is pressed
      handleDropdownShow(true);
      // Focus the suggestion window for keyboard navigation
      // Use refs or some other mechanism to focus on the suggestion window
      suggestionWindowRef.current?.focus();
      setFocusedIndex(0);
    }
  };

  return (
    <div
      className={[classes.search_screen_parent, alterStyle].join(" ")}
      data-testid="jobdiva_search"
    >
      <div className={classes.heading}>
        <h2
          className={[
            classes.main_heading,
            isJobDiva ? classes.job_diva_heading_font : "",
          ].join(" ")}
        >
          {isJobDiva
            ? "First, let's find the person on JobDiva"
            : `Search for a person or JobDiva Start ID`}
        </h2>
        {!isJobDiva && window.location.pathname === "/" && (
          <h4 className={classes.sub_heading}>
            {"If you don't have a start ID "}
            <a
              className={classes.simulation_nav}
              onClick={(e) => {
                e.preventDefault();
                history("/qrates");
              }}
            >
              click here
            </a>
            {" to have a simulation"}
          </h4>
        )}
      </div>
      <div ref={dropdownRef}>
        <MBOInput
          type="text"
          placeholder={`Search ${
            isJobDiva ? "person" : "person or JobDiva Start ID"
          } `}
          changeHandler={(e) => {
            setInput(e.target.value);
            setNoDataFound("");
          }}
          ref={searchbarRef}
          onMouseUp={getJobDIvaDetails}
          name="clientName"
          inputValue={selectedJobDivaId || input}
          clearHandler={clearSelectedClient}
          isClearIconRequired={true}
          handleDropdownShow={handleDropdownShow}
          searching={textboxSpinner}
          onKeyDown={handleKeyDown}
        />
        {dropdownShow && input.length > 2 && (
          <SuggestionWIndow
            searchedText={input}
            clients={filteredData}
            selectClient={selectClient}
            handleDropdownShow={handleDropdownShow}
            suggestionWindowRef={suggestionWindowRef}
            setFocusedIndex={setFocusedIndex}
            focusedIndex={focusedIndex}
            showNoDataFound={noDataFound}
          />
        )}
        {selectedJobDivaId === "" && input === "" && (
          <MBOTooltip
            content={`Start by selecting a ${
              window.location.pathname === "/"
                ? "JobDiva Start ID"
                : "workorder"
            }`}
            bottom={classes.tooltip}
          />
        )}
      </div>
    </div>
  );
};

const userRoles =
  window.location.pathname === "/"
    ? UserAccessForFeature.WORKORDER.CUSTOM_RULES
    : UserAccessForFeature.QUAD.ROLES;

const AuthorizeUser = AuthorizeUserHOC(SearchComponent, userRoles);

export default ErrorBoundary(AuthorizeUser);
