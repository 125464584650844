import moment from "moment";

export const pricingCalcData = [
  {
    id: 1,
    effectiveDate: {
      localized: "date",
      value: "2022-03-21T12:31:34.881Z",
      culture: "en",
      timeZone: "UTC",
      raw: 1646378122,
    },
    lastUpdated: {
      localized: "date",
      value: "2022-03-21T12:31:34.881Z",
      culture: "en",
      timeZone: "UTC",
      raw: 1646378122,
    },
    updatedBy: "Bob Lucas",
    comments: "Basic Rules",
  },
  {
    id: 2,
    effectiveDate: {
      localized: "date",
      value: "2022-03-21T12:31:34.881Z",
      culture: "en",
      timeZone: "UTC",
      raw: 1646378122,
    },
    lastUpdated: {
      localized: "date",
      value: "2022-03-21T12:31:34.881Z",
      culture: "en",
      timeZone: "UTC",
      raw: 1646378122,
    },
    updatedBy: "Bob Lucas",
    comments: "Basic Rules",
  },
];

export const JobDivaDummy = {
  payrate: [
    {
      id: 1,
      endDate: {
        localized: "date",
        value: "2022-03-21T12:31:34.881Z",
        culture: "en",
        timeZone: "UTC",
        raw: 1646378122,
      },
      startDate: {
        localized: "date",
        value: "2022-03-21T12:31:34.881Z",
        culture: "en",
        timeZone: "UTC",
        raw: 1646378122,
      },
      amount: {
        localized: "money",
        culture: "en-US",
        currency: "USD",
        value: "1000.0",
        raw: 5658,
      },
    },
    {
      id: 2,
      endDate: {
        localized: "date",
        value: "2022-03-21T12:31:34.881Z",
        culture: "en",
        timeZone: "UTC",
        raw: 1646378122,
      },
      startDate: {
        localized: "date",
        value: "2022-03-21T12:31:34.881Z",
        culture: "en",
        timeZone: "UTC",
        raw: 1646378122,
      },
      amount: {
        localized: "money",
        culture: "en-US",
        currency: "USD",
        value: "1000.0",
        raw: 5658,
      },
    },
    {
      id: 3,
      endDate: {
        localized: "date",
        value: "2022-03-21T12:31:34.881Z",
        culture: "en",
        timeZone: "UTC",
        raw: 1646378122,
      },
      startDate: {
        localized: "date",
        value: "2022-03-21T12:31:34.881Z",
        culture: "en",
        timeZone: "UTC",
        raw: 1646378122,
      },
      amount: {
        localized: "money",
        culture: "en-US",
        currency: "USD",
        value: "1000.0",
        raw: 5658,
      },
    },
    {
      id: 4,
      endDate: {
        localized: "date",
        value: "2022-03-21T12:31:34.881Z",
        culture: "en",
        timeZone: "UTC",
        raw: 1646378122,
      },
      startDate: {
        localized: "date",
        value: "2022-03-21T12:31:34.881Z",
        culture: "en",
        timeZone: "UTC",
        raw: 1646378122,
      },
      amount: {
        localized: "money",
        culture: "en-US",
        currency: "USD",
        value: "1000.0",
        raw: 5658,
      },
    },
    {
      id: 5,
      endDate: {
        localized: "date",
        value: "2022-03-21T12:31:34.881Z",
        culture: "en",
        timeZone: "UTC",
        raw: 1646378122,
      },
      startDate: {
        localized: "date",
        value: "2022-03-21T12:31:34.881Z",
        culture: "en",
        timeZone: "UTC",
        raw: 1646378122,
      },
      amount: {
        localized: "money",
        culture: "en-US",
        currency: "USD",
        value: "1000.0",
        raw: 5658,
      },
    },
  ],
  billrate: [
    {
      id: 1,
      endDate: {
        localized: "date",
        value: "2022-03-21T12:31:34.881Z",
        culture: "en",
        timeZone: "UTC",
        raw: 1646378122,
      },
      startDate: {
        localized: "date",
        value: "2022-03-21T12:31:34.881Z",
        culture: "en",
        timeZone: "UTC",
        raw: 1646378122,
      },
      amount: {
        localized: "money",
        culture: "en-US",
        currency: "USD",
        value: "1000.0",
        raw: 5658,
      },
    },
    {
      id: 2,
      endDate: {
        localized: "date",
        value: "2022-03-21T12:31:34.881Z",
        culture: "en",
        timeZone: "UTC",
        raw: 1646378122,
      },
      startDate: {
        localized: "date",
        value: "2022-03-21T12:31:34.881Z",
        culture: "en",
        timeZone: "UTC",
        raw: 1646378122,
      },
      amount: {
        localized: "money",
        culture: "en-US",
        currency: "USD",
        value: "1000.0",
        raw: 5658,
      },
    },
    {
      id: 3,
      endDate: {
        localized: "date",
        value: "2022-03-21T12:31:34.881Z",
        culture: "en",
        timeZone: "UTC",
        raw: 1646378122,
      },
      startDate: {
        localized: "date",
        value: "2022-03-21T12:31:34.881Z",
        culture: "en",
        timeZone: "UTC",
        raw: 1646378122,
      },
      amount: {
        localized: "money",
        culture: "en-US",
        currency: "USD",
        value: "1000.0",
        raw: 5658,
      },
    },
    {
      id: 4,
      endDate: {
        localized: "date",
        value: "2022-03-21T12:31:34.881Z",
        culture: "en",
        timeZone: "UTC",
        raw: 1646378122,
      },
      startDate: {
        localized: "date",
        value: "2022-03-21T12:31:34.881Z",
        culture: "en",
        timeZone: "UTC",
        raw: 1646378122,
      },
      amount: {
        localized: "money",
        culture: "en-US",
        currency: "USD",
        value: "1000.0",
        raw: 5658,
      },
    },
    {
      id: 5,
      endDate: {
        localized: "date",
        value: "2022-03-21T12:31:34.881Z",
        culture: "en",
        timeZone: "UTC",
        raw: 1646378122,
      },
      startDate: {
        localized: "date",
        value: "2022-03-21T12:31:34.881Z",
        culture: "en",
        timeZone: "UTC",
        raw: 1646378122,
      },
      amount: {
        localized: "money",
        culture: "en-US",
        currency: "USD",
        value: "1000.0",
        raw: 5658,
      },
    },
  ],
};

export const headerA = [
  { id: 1, name: "Pay Rate" },
  { id: 2, name: "Start" },
  { id: 3, name: "End" },
];

export const headerB = [
  { id: 1, name: "Bill Rate" },
  { id: 2, name: "Start" },
  { id: 3, name: "End" },
];

export const options = [
  {
    id: "1",
    value: "radio1",
  },
  {
    id: "2",
    value: "radio2",
  },
];
export const calcoptions = [
  {
    id: 3,
    value: "Yes",
    checked: false,
  },
  {
    id: 4,
    value: "No",
    checked: true,
  },
];

export const selectOptions = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

export const rulesData = [
  {
    name: "On-Tour",
    description: "On tour rate",
    operation: "Markup",
    amount: "4%",
    isApplied: true,
  },
  {
    name: "Incumbant",
    description: "were there before us",
    operation: "Markup",
    amount: "-5%",
    isApplied: false,
  },
  {
    name: "Visa",
    description: "do we manage visa",
    operation: "Markup",
    amount: "5%",
    isApplied: false,
  },
  {
    name: "Clearance",
    description: "secret or up",
    operation: "Markup",
    amount: "5%",
    isApplied: false,
  },
  {
    name: "Visa on arrival",
    description: "On Arrival",
    operation: "Markup",
    amount: "5%",
    isApplied: false,
  },
];

export const selectStartIdData = [
  {
    id: "option1",
    value: "option1",
    startId: "4903077161",
    month: "Sep",
    day: "21",
    year: "2021",
  },
  {
    id: "option2",
    value: "option2",
    startId: "372337312",
    month: "Sep",
    day: "21",
    year: "2021",
  },
  {
    id: "option3",
    value: "option3",
    startId: "6072201645",
    month: "Feb",
    day: "03",
    year: "2022",
  },
  {
    id: "option4",
    value: "option4",
    startId: "1464273142",
    month: "Mar",
    day: "03",
    year: "2022",
  },
  {
    id: "option5",
    value: "option5",
    startId: "9338186301",
    month: "Apr",
    day: "01",
    year: "2022",
  },
  {
    id: "option6",
    value: "option6",
    startId: "79112432",
    month: "Sep",
    day: "18",
    year: "2022",
  },
  {
    id: "option7",
    value: "option7",
    startId: "8641524682",
    month: "Jan",
    day: "03",
    year: "2023",
  },
  {
    id: "option8",
    value: "option8",
    startId: "2369520443",
    month: "May",
    day: "11",
    year: "2023",
  },
];

export const candidateListData = [
  {
    candidateId: "32085",
    firstName: "Ingrid",
    lastName: "Orellana",
    email: "ingrid_orellana@yahoo.com",
    alternateEmail: "None",
    addressLine1: "627 Hickey Blvd",
    city: "Daly City",
    state: "California",
    zipCode: "94015",
    country: "United States",
    workPhone: "4158064982",
    workPhone_ext: "None",
    homePhone: "4158064982",
    currentPayRate: { localized: "money", currency: "USD", raw: 0 },
    currentPayRateUom: {
      localized: "label",
      context: "unit_of_measure",
      raw: "H",
    },
    preferredPayRate: { localized: "money", currency: "USD", raw: 0 },
    preferredPayRateUom: {
      localized: "label",
      context: "unit_of_measure",
      raw: "H",
    },
    workerType: "W2",
    engagementService: "PAYROLL",
    taxClassification: "None",
    taxIdType: "None",
    businessName: "None",
    benefitsEligibility: "None",
    ncciCode: "None",
    businessType: "None",
    mboEmployeeNumber: "32085",
    marketplaceUserId: "None",
    s4VendorId: "None",
    s4CandidateId: "None",
    platformId: "None",
    mboId: "3cf60532-9d97-421c-bdb5-f0013d24e076",
    teamLeadFlag: "0",
    isTeamLead: false,
    isCandidate: true,
    isBroker: false,
  },
  {
    candidateId: "12892062710408",
    firstName: "Alan",
    lastName: "Aikens",
    email: "Auto_12892062710408@jobdiva.com",
    addressLine1: "716 Main Street",
    zipCode: "30034",
    workPhone: "3173769924",
    homePhone: "9754483533",
    cellPhone: "1823570378",
    fax: "7872690231",
    marketplaceUserId: "60abec04d2dea6acbf0614e6",
    teamLeadFlag: "1",
    isTeamLead: false,
    isCandidate: true,
    isBroker: false,
  },
  {
    candidateId: "40070",
    firstName: "Christopher",
    lastName: "Nester",
    email:
      "practionerid.b66b0526-a10f-42e9-b649-9b0345d7747b@fakeemail.mbopartners.com",
    alternateEmail: "None",
    workPhone_ext: "None",
    currentPayRate: { localized: "money", currency: "USD", raw: 0 },
    currentPayRateUom: {
      localized: "label",
      context: "unit_of_measure",
      raw: "H",
    },
    preferredPayRate: { localized: "money", currency: "USD", raw: 0 },
    preferredPayRateUom: {
      localized: "label",
      context: "unit_of_measure",
      raw: "H",
    },
    workerType: "Non-Associate",
    engagementService: "NON_ASSOCIATE",
    taxClassification: "None",
    taxIdType: "None",
    businessName: "None",
    benefitsEligibility: "None",
    ncciCode: "None",
    businessType: "None",
    mboEmployeeNumber: "40070",
    marketplaceUserId: "None",
    s4VendorId: "None",
    s4CandidateId: "None",
    platformId: "None",
    mboId: "b66b0526-a10f-42e9-b649-9b0345d7747b",
    teamLeadFlag: "0",
    isTeamLead: false,
    isCandidate: true,
    isBroker: false,
  },
  {
    candidateId: "31784",
    firstName: "Lawrence",
    lastName: "Waeltermann",
    email:
      "practionerid.f338bcb9-24db-475c-ad1f-ac6cbe8d1cc4@fakeemail.mbopartners.com",
    alternateEmail: "None",
    workPhone_ext: "None",
    currentPayRate: { localized: "money", currency: "USD", raw: 0 },
    currentPayRateUom: {
      localized: "label",
      context: "unit_of_measure",
      raw: "H",
    },
    preferredPayRate: { localized: "money", currency: "USD", raw: 0 },
    preferredPayRateUom: {
      localized: "label",
      context: "unit_of_measure",
      raw: "H",
    },
    workerType: "Non-Associate",
    engagementService: "NON_ASSOCIATE",
    taxClassification: "None",
    taxIdType: "None",
    businessName: "None",
    benefitsEligibility: "None",
    ncciCode: "None",
    businessType: "None",
    mboEmployeeNumber: "31784",
    marketplaceUserId: "None",
    s4VendorId: "None",
    s4CandidateId: "None",
    platformId: "None",
    mboId: "f338bcb9-24db-475c-ad1f-ac6cbe8d1cc4",
    teamLeadFlag: "0",
    isTeamLead: false,
    isCandidate: true,
    isBroker: false,
  },
  {
    candidateId: "6206480639438",
    firstName: "Tabit",
    lastName: "Ebie",
    email: "Auto_6206480639438@jobdiva.com",
    addressLine1: "82 Main Street",
    city: "Snellville",
    state: "GA",
    zipCode: "30078",
    workPhone: "6438215824",
    homePhone: "5164296513",
    cellPhone: "2898734662",
    fax: "5181590670",
    workerType: "1099 Independent Contractor",
    marketplaceUserId: "5e6bd1090d2d9e0011fa8f40",
    teamLeadFlag: "1",
    isTeamLead: false,
    isCandidate: true,
    isBroker: false,
  },
  {
    candidateId: "202539",
    firstName: "Jaime",
    lastName: "Mounce",
    email:
      "practitioner.39750f82-addc-41ac-a88d-1ac1e64fc84f@fakeemail.mbopartners.com",
    alternateEmail: "None",
    workPhone_ext: "None",
    currentPayRate: { localized: "money", currency: "USD", raw: 0 },
    currentPayRateUom: {
      localized: "label",
      context: "unit_of_measure",
      raw: "H",
    },
    preferredPayRate: { localized: "money", currency: "USD", raw: 0 },
    preferredPayRateUom: {
      localized: "label",
      context: "unit_of_measure",
      raw: "H",
    },
    workerType: "Non-Associate",
    engagementService: "NON_ASSOCIATE",
    taxClassification: "None",
    taxIdType: "None",
    businessName: "None",
    benefitsEligibility: "None",
    ncciCode: "None",
    businessType: "None",
    mboEmployeeNumber: "202539",
    marketplaceUserId: "None",
    s4VendorId: "None",
    s4CandidateId: "None",
    platformId: "None",
    mboId: "39750f82-addc-41ac-a88d-1ac1e64fc84f",
    teamLeadFlag: "0",
    isTeamLead: false,
    isCandidate: true,
    isBroker: false,
  },
  {
    candidateId: "84648",
    firstName: "Carol",
    lastName: "Bell",
    email: "carolbell@cbelllaw.law",
    alternateEmail: "None",
    addressLine1: "9403 Bearden Creek Lane",
    city: "Humble",
    state: "Texas",
    zipCode: "77396",
    country: "United States",
    workPhone: "8324185530",
    workPhone_ext: "None",
    homePhone: "8324185530",
    currentPayRate: { localized: "money", currency: "USD", raw: 0 },
    currentPayRateUom: {
      localized: "label",
      context: "unit_of_measure",
      raw: "H",
    },
    preferredPayRate: { localized: "money", currency: "USD", raw: 0 },
    preferredPayRateUom: {
      localized: "label",
      context: "unit_of_measure",
      raw: "H",
    },
    workerType: "W2",
    engagementService: "PAYROLL",
    taxClassification: "None",
    taxIdType: "None",
    businessName: "None",
    benefitsEligibility: "None",
    ncciCode: "None",
    businessType: "None",
    mboEmployeeNumber: "84648",
    marketplaceUserId: "None",
    s4VendorId: "None",
    s4CandidateId: "None",
    platformId: "None",
    mboId: "b070e120-213d-4cbd-ba9e-a66657f21766",
    teamLeadFlag: "0",
    isTeamLead: false,
    isCandidate: true,
    isBroker: false,
  },
  {
    candidateId: "6103713995162",
    firstName: "Avanti",
    lastName: "K",
    email: "Auto_6103713995162@jobdiva.com",
    addressLine1: "794 Main Street",
    workPhone: "2973836835",
    homePhone: "7845648463",
    cellPhone: "6294603771",
    fax: "3844139389",
    marketplaceUserId: "5fd43a9319bc38eeb346fec9",
    teamLeadFlag: "1",
    isTeamLead: false,
    isCandidate: true,
    isBroker: false,
  },
  {
    candidateId: "681398973787",
    firstName: "Daniel",
    lastName: "Kaufmann",
    email: "Auto_681398973787@jobdiva.com",
    addressLine1: "285 Main Street",
    city: "matteson",
    state: "IL",
    zipCode: "60443",
    workPhone: "1340172513",
    homePhone: "6672949359",
    cellPhone: "1511329335",
    fax: "4841121655",
    workerType: "W2",
    marketplaceUserId: "5fd44fc2801ba5fe671d4ae3",
    teamLeadFlag: "1",
    isTeamLead: false,
    isCandidate: true,
    isBroker: false,
  },
  {
    candidateId: "575134671388",
    firstName: "George",
    lastName: "Cheoros",
    email: "Auto_575134671388@jobdiva.com",
    addressLine1: "598 Main Street",
    workPhone: "9107914771",
    homePhone: "8137747840",
    cellPhone: "5194520758",
    fax: "4500220885",
    workerType: "1099 Independent Contractor",
    marketplaceUserId: "5fd3fd8108f9f3c66a02b19a",
    teamLeadFlag: "1",
    isTeamLead: false,
    isCandidate: true,
    isBroker: false,
  },
  {
    candidateId: "12115819678140",
    firstName: "Sini",
    lastName: "Kandekar",
    email: "Auto_12115819678140@jobdiva.com",
    addressLine1: "235 Main Street",
    city: "Mount Prospect",
    state: "IL",
    zipCode: "60056",
    workPhone: "7075631872",
    homePhone: "7632123859",
    cellPhone: "6370766626",
    fax: "9154295225",
    marketplaceUserId: "603e15373f03a4ceefe1bd39",
    teamLeadFlag: "1",
    isTeamLead: false,
    isCandidate: true,
    isBroker: false,
  },
  {
    candidateId: "961739615481",
    firstName: "Jerry",
    lastName: "Mendoza",
    email: "Auto_961739615481@jobdiva.com",
    addressLine1: "917 Main Street",
    city: "Honolulu",
    state: "HI",
    zipCode: "96813",
    workPhone: "4442848553",
    homePhone: "2156965048",
    cellPhone: "7798439381",
    fax: "9638431243",
    marketplaceUserId: "60198984e4c9a8d91c4eae10",
    teamLeadFlag: "1",
    isTeamLead: false,
    isCandidate: true,
    isBroker: false,
  },
  {
    candidateId: "39438",
    firstName: "Amber",
    lastName: "Leis",
    email: "amber_leis22@yahoo.com",
    alternateEmail: "None",
    addressLine1: "9085 Estes St.",
    city: "Westminster",
    state: "Colorado",
    zipCode: "80021",
    country: "United States",
    workPhone: "3035799218",
    workPhone_ext: "None",
    homePhone: "3035799218",
    currentPayRate: { localized: "money", currency: "USD", raw: 0 },
    currentPayRateUom: {
      localized: "label",
      context: "unit_of_measure",
      raw: "H",
    },
    preferredPayRate: { localized: "money", currency: "USD", raw: 0 },
    preferredPayRateUom: {
      localized: "label",
      context: "unit_of_measure",
      raw: "H",
    },
    workerType: "W2",
    engagementService: "PAYROLL",
    taxClassification: "None",
    taxIdType: "None",
    businessName: "None",
    benefitsEligibility: "None",
    ncciCode: "None",
    businessType: "None",
    mboEmployeeNumber: "39438",
    marketplaceUserId: "None",
    s4VendorId: "None",
    s4CandidateId: "None",
    platformId: "None",
    mboId: "028b50cf-d260-440d-8fc3-2a403571384a",
    teamLeadFlag: "0",
    isTeamLead: false,
    isCandidate: true,
    isBroker: false,
  },
];

export const hourlyJobDivaRatesState = {
  startId: 0,
  candidateId: 0,
  effectiveDate: "",
  comment: "update rates",
  customerSpecificCustomAppliedRules: [],
  workorderSpecificCustomAppliedRules: [],
  billInfo: {
    id: 0,
    jobdivaRate: {
      billrate: 0,
      overtimeBillrate: 0,
      doubletimeBillrate: 0,
    },
    calculatedRate: {
      billrate: 0,
      overtimeBillrate: 0,
      doubletimeBillrate: 0,
    },
  },
  payInfo: {
    id: 0,
    jobdivaRate: {
      payrate: 0,
      overtimePayrate: 0,
      doubletimePayrate: 0,
    },
    calculatedRate: {
      payrate: 0,
      overtimePayrate: 0,
      doubletimePayrate: 0,
    },
  },
};

export const applyRuleSetData = [
  {
    select: false,
    setName: "Default group, Us Based Company",
    id: 1,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "parmeshwar",
  },
  {
    select: false,
    setName: "Default rule group, Us Based Company",
    id: 2,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "vatsal",
  },
  {
    select: false,
    setName: "Default rule group, Us Based Company",
    id: 3,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "sandeep",
  },
  {
    select: false,
    setName: "Default rule group, Us Based Company",
    id: 4,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "abhishek",
  },
  {
    select: false,
    setName: "Default rule group, Us Based Company",
    id: 5,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "vinod",
  },
  {
    select: false,
    setName: "Default rule group, Us Based Company",
    id: 6,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "parmeshwar",
  },
  {
    select: false,
    setName: "Default rule group, Us Based Company",
    id: 7,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "sandeep",
  },
  {
    select: false,
    setName: "Default rule group, Us Based Company",
    id: 8,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "vatsal",
  },
  {
    select: false,
    setName: "Default rule group, Us Based Company",
    id: 9,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "trey",
  },
  {
    select: false,
    setName: "Default rule group, Us Based Company",
    id: 10,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "mohan",
  },
  {
    select: false,
    setName: "Default rule group, Us Based Company",
    id: 11,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "parmeshwar",
  },
  {
    select: false,
    setName: "Default rule group, Us Based Company",
    id: 12,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "parmeshwar",
  },
  {
    select: false,
    setName: "Default rule group, Us Based Company",
    id: 13,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "mani",
  },
  {
    select: false,
    setName: "Default rule group, Us Based Company",
    id: 14,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "mahesh",
  },
  {
    select: false,
    setName: "Default rule group, Us Based Company",
    id: 15,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "parmeshwar",
  },
  {
    select: false,
    setName: "Default rule group, Us Based Company",
    id: 16,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "Bas",
  },
  {
    select: false,
    setName: "Default rule group, Us Based Company",
    id: 17,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "sandeep",
  },
  {
    select: false,
    setName: "Default rule group, Us Based Company",
    id: 18,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "parmeshwar",
  },
  {
    select: false,
    setName: "Default rule group, Us Based Company",
    id: 19,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "sandeep",
  },
  {
    select: false,
    setName: "Default rule group, Us Based Company",
    id: 20,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "vatsal",
  },
  {
    select: false,
    setName: "Default rule group, Us Based Company",
    id: 21,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "parmeshwar",
  },
];

export const displayRuleSetData = [
  {
    name: "Default rule group, Us Based Company",
    id: 1,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "parmeshwar",
  },
  {
    name: "Default rule group, Us Based Company",
    id: 2,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "vatsal",
  },
  {
    name: "Default rule group, Us Based Company",
    id: 3,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "sandeep",
  },
  {
    name: "Default rule group, Us Based Company",
    id: 4,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "abhishek",
  },
  {
    name: "Default rule group, Us Based Company",
    id: 5,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "vinod",
  },
  {
    name: "Default rule group, Us Based Company",
    id: 6,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "parmeshwar",
  },
  {
    name: "Default rule group, Us Based Company",
    id: 7,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "sandeep",
  },
  {
    name: "Default rule group, Us Based Company",
    id: 8,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "vatsal",
  },
  {
    name: "Default rule group, Us Based Company",
    id: 9,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "trey",
  },
  {
    name: "Default rule group, Us Based Company",
    id: 10,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "mohan",
  },
  {
    name: "Default rule group, Us Based Company",
    id: 11,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "parmeshwar",
  },
  {
    name: "Default rule group, Us Based Company",
    id: 12,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "parmeshwar",
  },
  {
    name: "Default rule group, Us Based Company",
    id: 13,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "mani",
  },
  {
    name: "Default rule group, Us Based Company",
    id: 14,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "mahesh",
  },
  {
    name: "Default rule group, Us Based Company",
    id: 15,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "parmeshwar",
  },
  {
    name: "Default rule group, Us Based Company",
    id: 16,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "Bas",
  },
  {
    name: "Default rule group, Us Based Company",
    id: 17,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "sandeep",
  },
  {
    name: "Default rule group, Us Based Company",
    id: 18,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "parmeshwar",
  },
  {
    name: "Default rule group, Us Based Company",
    id: 19,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "sandeep",
  },
  {
    name: "Default rule group, Us Based Company",
    id: 20,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "vatsal",
  },
  {
    name: "Default rule group, Us Based Company",
    id: 21,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    createdOn: moment(new Date()).format("MMM | DD | YYYY"),

    createdBy: "parmeshwar",
  },
];

export const quadTableData = [
  {
    issueType: "Default rule group, Us Based Company",
    id: 1,
    field: "work address",
    message: "testing the quad table data",
    existingValue: "lorem ipsum",
  },
  {
    issueType: "rule group, Us Based Company",
    id: 2,
    field: "work address",
    message: "testing the quad table data",
    existingValue: "lorem ipsum",
  },
  {
    issueType: "Default rule group, Us Based Company",
    id: 3,
    field: "work address",
    message: "testing the quad table data",
    existingValue: "lorem ipsum",
  },
  {
    issueType: "Default rule group, Us Based Company",
    id: 4,
    field: "work address",
    message: "testing the quad table data",
    existingValue: "lorem ipsum",
  },
  {
    issueType: "Default, Us Based Company",
    id: 5,
    field: "work address",
    message: "testing the quad table data",
    existingValue: "lorem ipsum",
  },
  {
    issueType: "Default rule group, Us Based Company",
    id: 6,
    field: "work address",
    message: "testing the quad table data",
    existingValue: "lorem ipsum",
  },
  {
    issueType: "Default rule group, Us Based Company",
    id: 7,
    field: "work address",
    message: "testing the quad table data",
    existingValue: "lorem ipsum",
  },
  {
    issueType: "Default rule group, Us Based Company",
    id: 8,
    field: "work address",
    message: "testing the quad table data",
    existingValue: "lorem ipsum",
  },
  {
    issueType: "Default rule group, Us Based Company",
    id: 9,
    field: "work address",
    message: "testing the quad table data",
    existingValue: "lorem ipsum",
  },
  {
    issueType: "Default rule group, Us Based Company",
    id: 10,
    field: "work address",
    message: "testing the quad table data",
    existingValue: "lorem ipsum",
  },
  {
    issueType: "Default rule, Us Based Company",
    id: 11,
    field: "work address",
    message: "testing the quad table data",
    existingValue: "lorem ipsum",
  },
  {
    issueType: "Default rule, Us Based Company",
    id: 12,
    field: "work address",
    message: "testing the quad table data",
    existingValue: "lorem ipsum",
  },
  {
    issueType: "Default rule group, Us Based Company",
    id: 13,
    field: "work address",
    message: "testing the quad table data",
    existingValue: "lorem ipsum",
  },
  {
    issueType: "Default rule group, Us Based Company",
    id: 14,
    field: "work address",
    message: "testing the quad table data",
    existingValue: "lorem ipsum",
  },
  {
    issueType: "Default rule group, Us Based Company",
    id: 15,
    field: "work address",
    message: "testing the quad table data",
    existingValue: "lorem ipsum",
  },
  {
    issueType: "Default rule group, Us Based Company",
    id: 16,
    field: "work address",
    message: "testing the quad table data",
    existingValue: "lorem ipsum",
  },
  {
    issueType: "Default rule group, Us Based Company",
    id: 17,
    field: "work address",
    message: "testing the quad table data",
    existingValue: "lorem ipsum",
  },
  {
    issueType: "Default rule group, Us Based Company",
    id: 18,
    field: "work address",
    message: "testing the quad table data",
    existingValue: "lorem ipsum",
  },
  {
    issueType: "Default rule group, Us Based Company",
    id: 19,
    field: "work address",
    message: "testing the quad table data",
    existingValue: "lorem ipsum",
  },
  {
    issueType: "Default rule group, Us Based Company",
    id: 20,
    field: "work address",
    message: "testing the quad table data",
    existingValue: "lorem ipsum",
  },
  {
    issueType: "Default rule group, Us Based Company",
    id: 21,
    field: "work address",
    message: "testing the quad table data",
    existingValue: "lorem ipsum",
  },
];

export const RulesetCreationInitialState = {
  screen1: {
    rulesetName: "",
    rulesetDesc: "",
    id: "",
  },
  screen2: {
    optionalRules: [],
    standardRules: [],
    selectedRuleType: "",
  },
  screen3: {},
};
