interface IObjectKeys {
  [key: string]: {
    name: string;
    description: string;
  };
}

const helperText: IObjectKeys = {
  NET_PAY_RATE: {
    name: "Net pay rate",
    description:
      "Net pay rate is amount given to a candidate after all deductions",
  },
  OVERTIME_PAY: {
    name: "Overtime pay",
    description:
      "Overtime pay is the additional pay, usually 1.5 times regular pay received for working beyond their regular working hours.",
  },
  DOUBLE_TIME_PAY: {
    name: "Double time pay",
    description:
      "Double time pay is the additional pay, usually 2 times regular pay received for working beyond their regular working hours.",
  },
  OVERTIME_BILL: {
    name: "Overtime bill",
    description:
      "Overtime bill is the additional billrate, usually 1.5 times of regular billing rate charged to a client for working beyond regular hours.",
  },
  DOUBLE_TIME_BILL: {
    name: "Double time bill",
    description:
      "Double time bill is the additional billrate, usually 2 times of regular billing rate charged to a client for working beyond regular hours.",
  },
};

export const readMoreLink: string =
  "https://support.mbopartners.com/hc/en-us/articles/17788895030939";

export default helperText;
