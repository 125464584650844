import React from "react";

import { MBOButtonProps } from "../../@types/component";

import classes from "./Button.module.scss";

const Button: React.FC<MBOButtonProps> = ({
  name,
  onClick,
  className,
  disabled = false,
  icon = "",
  rightIcon = "",
}) => (
  <button
    disabled={disabled}
    type="button"
    className={[
      classes.btn,
      className || "",
      disabled ? classes.disabled : "",
    ].join(" ")}
    onClick={onClick}
  >
    {icon}
    <span>{name}</span>
    {rightIcon}
  </button>
);

export default Button;
