import { AxiosResponse } from "axios";
import moment from "moment";

import API from "../../API";
import PricingCalcApi from "../../API/PricingCalcApi";
import { rulesTableDataProp } from "../../components/RulesTable/RulesTable";

export const createCustomAppliedRulesPayload = (
  customerAppliedTableRules:
    | calcSettingsDetailsCustomerSpecificCustomAppliedRules[]
    | null,
  rulesTableUserSelectedData: rulesTableDataProp[] | [],
  isNewRule: boolean
) => {
  const isCustomerAppliedTableRulesExist =
    !customerAppliedTableRules ||
    (customerAppliedTableRules && customerAppliedTableRules.length === 0);

  const isRulesTableUserSelectedData =
    rulesTableUserSelectedData && rulesTableUserSelectedData.length;

  if (isCustomerAppliedTableRulesExist && isRulesTableUserSelectedData === 0) {
    return [];
  }

  return [
    ...(isRulesTableUserSelectedData > 0 &&
    isCustomerAppliedTableRulesExist &&
    rulesTableUserSelectedData.findIndex((e) => e.isApplied) > -1
      ? rulesTableUserSelectedData
          .filter((item) => item.isApplied)
          .map((rule, i) => ({
            id: null,
            customRuleId: +rule.id,
            displayOrder: i,
            isActive: rule.isApplied,
          }))
      : []),
    ...(isRulesTableUserSelectedData > 0 &&
    customerAppliedTableRules &&
    customerAppliedTableRules.length > 0 &&
    rulesTableUserSelectedData.findIndex((e) => e.isApplied) > -1
      ? rulesTableUserSelectedData
          .filter((item) => {
            if (item.isApplied) {
              return customerAppliedTableRules
                ? customerAppliedTableRules.findIndex(
                    (e) => e.customerSpecificOptionalRuleId === item.id
                  ) < 0
                : item;
            }

            return false;
          })
          .map((rule, i) => {
            const isRecordExist =
              customerAppliedTableRules &&
              customerAppliedTableRules.findIndex(
                (arule) => arule.customerSpecificOptionalRuleId === rule.id
              ) < 0;

            const checkForNewRecord = {
              validateId: (isnewOne: boolean) => {
                if (isnewOne) {
                  return null;
                }

                return isRecordExist ? null : +rule.id;
              },
            };

            return {
              id: checkForNewRecord.validateId(isNewRule),

              customRuleId: isNewRule
                ? +rule.id || null
                : (isRecordExist && +rule.id) || null,

              displayOrder: i,
              isActive: rule.isApplied,
            };
          })
      : []),
    ...(customerAppliedTableRules && customerAppliedTableRules.length > 0
      ? customerAppliedTableRules
          .filter(
            (item) =>
              rulesTableUserSelectedData &&
              rulesTableUserSelectedData.findIndex(
                (e) => e.id === item.customerSpecificOptionalRuleId
              ) > -1
          )
          .map((rule, i) => ({
            id: isNewRule ? null : +rule.id,
            customRuleId: +rule.customerSpecificOptionalRuleId,

            displayOrder: i,
            isActive:
              rulesTableUserSelectedData[
                rulesTableUserSelectedData.findIndex(
                  (e) => e.id === rule.customerSpecificOptionalRuleId
                )
              ].isApplied,
          }))
      : []),
  ];
};

export const createWorkorderAppliedRulesPayload = (
  customerAppliedWorkOrderRules:
    | workorderSpecificCustomAppliedRulesType[]
    | undefined,
  workOrderData: workOrderMappingPayloadTypes[] | [],
  isNewRule: boolean
) => {
  if (
    customerAppliedWorkOrderRules &&
    customerAppliedWorkOrderRules.length === 0 &&
    workOrderData.length === 0
  ) {
    return [];
  }

  return [
    ...(customerAppliedWorkOrderRules &&
    customerAppliedWorkOrderRules.length > 0 &&
    workOrderData &&
    workOrderData.length > 0
      ? customerAppliedWorkOrderRules.map((order, i) => {
          const workOrderDataIndex: number = workOrderData.findIndex(
            (e) => e.id === +order.id
          );

          const isWorkOrderExist: boolean = workOrderDataIndex > -1;

          const orderVendor =
            order.vendor.id === undefined ||
            order.vendor.id === "" ||
            (order.vendor.id && Number(order.vendor.id) === 0)
              ? ""
              : Number(order.vendor.id);

          const workOrderDataVendor =
            workOrderDataIndex < 0 ||
            (workOrderDataIndex > -1 &&
              (workOrderData[workOrderDataIndex].vendorId === undefined ||
                workOrderData[workOrderDataIndex].vendorId === "" ||
                workOrderData[workOrderDataIndex].vendorId === 0))
              ? ""
              : Number(workOrderData[workOrderDataIndex].vendorId);

          return {
            id: isNewRule ? null : +order.id,
            description: isWorkOrderExist
              ? workOrderData[workOrderDataIndex].description
              : order.description || "",
            groupId: isWorkOrderExist
              ? workOrderData[workOrderDataIndex].groupId
              : order.group.id,
            operationId: isWorkOrderExist
              ? workOrderData[workOrderDataIndex].operationId
              : order.operation.id,
            attributionId: isWorkOrderExist
              ? workOrderData[workOrderDataIndex].attributionId
              : order.attribution.id,
            vendorId: isWorkOrderExist ? workOrderDataVendor : orderVendor,

            amount:
              (isWorkOrderExist
                ? workOrderData[workOrderDataIndex].amount
                : order.amount.raw / 100) || null,
            displayOrder: i,
            isActive: isNewRule
              ? true
              : workOrderData.findIndex((e) => e.id === +order.id) > -1,
          };
        })
      : []),

    ...(workOrderData.length > 0
      ? workOrderData
          .filter((item) => item.id === null)
          .map((rule, i) => ({
            id: null,
            description: rule.description || "",
            groupId: rule.groupId,
            operationId: rule.operationId,
            attributionId: rule.attributionId,
            vendorId: Number(rule.vendorId) || "",
            amount: rule.amount || null,
            displayOrder: i,
            isActive: true,
          }))
      : []),
    ...(workOrderData &&
    workOrderData.length === 0 &&
    customerAppliedWorkOrderRules &&
    customerAppliedWorkOrderRules.length > 0
      ? customerAppliedWorkOrderRules.map((rule, i) => ({
          id: isNewRule ? null : +rule.id,
          description: rule.description || "",
          groupId: rule.group.id,
          operationId: rule.operation.id,
          attributionId: rule.attribution.id,
          vendorId: Number(rule.vendor.id) || "",
          amount: rule.amount.raw / 100 || null,
          displayOrder: i,
          isActive: false,
        }))
      : []),
  ];
};

export const getCalcSetingsDetails = async (settingsId: number) => {
  try {
    const request = PricingCalcApi.getCalcSetingsDetails(settingsId);

    const response: AxiosResponse<CalcSettingsDetails> =
      await API.performRequest(request);

    return response;
  } catch (error) {
    return console.log(error);
  }
};

export const getRulesTableData = async (clientd: number, date: Date) => {
  try {
    if (clientd && date) {
      const request = PricingCalcApi.getCustSpecificRules({
        clientd,
        effectiveDate: moment(date).format("YYYY-MM-DD"),
      });

      const response = await API.performRequest(request);

      return response;
    }

    return null;
  } catch (error) {
    return console.log(error);
  }
};

export const checkExistingDate = (
  date: moment.Moment | null,
  saveBtnClick: boolean | null,
  pricingCalcData: PricingCalcSettingsObjData | null,
  isEdit: boolean,
  preSelectedDate: moment.Moment | null
) => {
  const selectedDate = date ? date.format("MMM DD YYYY") : "";

  const isSelectedDateExist = pricingCalcData?.startIdDetails?.find(
    (item) => item.effectiveDate.value === selectedDate
  );

  const calcSettingsFilteredDate = preSelectedDate
    ? preSelectedDate.format("MMM DD YYYY")
    : "";

  const isSameDateSelectedAgain = calcSettingsFilteredDate === selectedDate;

  if (saveBtnClick) {
    return true;
  }

  if (!isEdit && isSameDateSelectedAgain) {
    return false;
  }

  return (
    !isSelectedDateExist ||
    (!isSelectedDateExist && isEdit) ||
    (isSameDateSelectedAgain &&
      (saveBtnClick === null || (saveBtnClick !== null && saveBtnClick)))
  );
};

export const checkExistingDateOld = (
  date: Date | null,
  saveBtnClick: boolean | null,
  pricingCalcData: PricingCalcSettingsObjData | null,
  isEdit: boolean,
  preSelectedDate: Date | null
) => {
  const selectedDate = moment(date).format("DD-MM-YYYY");

  const isSelectedDateExist = pricingCalcData?.startIdDetails?.find(
    (item) =>
      moment(item.effectiveDate.raw).format("DD-MM-YYYY") === selectedDate
  );

  const calcSettingsFilteredDate = moment(preSelectedDate).format("DD-MM-YYYY");
  const isSameDateSelectedAgain = calcSettingsFilteredDate === selectedDate;

  if (saveBtnClick) {
    return true;
  }

  if (!isEdit && isSameDateSelectedAgain) {
    return false;
  }

  return (
    !isSelectedDateExist ||
    (!isSelectedDateExist && isEdit) ||
    (isSameDateSelectedAgain &&
      (saveBtnClick === null || (saveBtnClick !== null && saveBtnClick)))
  );
};

export const clientListApiHandler = async () => {
  const ClientListRequest = PricingCalcApi.getClientList();
  const ClientListResponse = await API.performRequest(ClientListRequest);

  return ClientListResponse.data;
};

export function updateTimeStamp(inputDate: number | Date) {
  const dateObj = new Date(inputDate);
  const currentOffset = dateObj.getTimezoneOffset();

  dateObj.setMinutes(dateObj.getMinutes() + currentOffset);
  const updatedTimestamp = dateObj.getTime();

  return updatedTimestamp;
}

export const businessRulesApiCallHandler = async (
  clientId: number | string,
  params?: string
) => {
  const businessRulesRequest = PricingCalcApi.getBusinessRules({
    clientId: Number(clientId),
    params,
  });

  const calcSettingsResponse: AxiosResponse = await API.performRequest(
    businessRulesRequest
  );

  return calcSettingsResponse.data;
};

export const dropdownApicallHander = async (
  isAvailableCustomRule: string = "",
  engagementType?: string
) => {
  const request = PricingCalcApi.getDrpOptions(
    isAvailableCustomRule,
    engagementType && engagementType
  );

  const response: AxiosResponse<DropdownOptions> = await API.performRequest(
    request
  );

  return response.data.data;
};

const dateCheckhandler = (
  effectiveDate: number,
  startDate: number,
  endDate: number
) => {
  return effectiveDate >= startDate && effectiveDate <= endDate;
};

const validateEffeciveDate = (
  rate: unknown,
  effectiveDate: Date | null,
  isPayRate: boolean
) => {
  const dateOffset = 24 * 60 * 60 * 1000 * 6;
  const currentDateTime = new Date().getTime();
  const sixDaysDecutedDate = new Date(effectiveDate || currentDateTime);

  sixDaysDecutedDate.setTime(sixDaysDecutedDate.getTime() - dateOffset);

  if (isPayRate) {
    const PayData = rate as ProjectDetailsPayInfo[];

    const payInfo = PayData.filter((info) => {
      if (effectiveDate && info.startDate && info.endDate) {
        return dateCheckhandler(
          new Date(effectiveDate).setHours(0, 0, 0, 0),
          new Date(info.startDate.raw).setHours(0, 0, 0, 0),
          new Date(info.endDate.raw).setHours(0, 0, 0, 0)
        );
      }

      return true;
    });

    if (payInfo && payInfo.length === 0) {
      return PayData.filter((info) => {
        return dateCheckhandler(
          sixDaysDecutedDate.setHours(0, 0, 0, 0),
          new Date(info.startDate.raw).setHours(0, 0, 0, 0),
          new Date(info.endDate.raw).setHours(0, 0, 0, 0)
        );
      });
    }

    return payInfo;
  }

  const billData = rate as ProjectDetailsBillInfo[];

  const billInfo = billData.filter((info) => {
    if (effectiveDate && info.startDate && info.endDate) {
      return (
        new Date(effectiveDate).setHours(0, 0, 0, 0) >=
          new Date(info.startDate.raw).setHours(0, 0, 0, 0) &&
        new Date(effectiveDate).setHours(0, 0, 0, 0) <=
          new Date(info.endDate.raw).setHours(0, 0, 0, 0)
      );
    }

    return true;
  });

  if (billInfo && billInfo.length === 0) {
    return billData.filter((info) => {
      return (
        sixDaysDecutedDate.setHours(0, 0, 0, 0) >=
          new Date(info.startDate.raw).setHours(0, 0, 0, 0) &&
        sixDaysDecutedDate.setHours(0, 0, 0, 0) <=
          new Date(info.endDate.raw).setHours(0, 0, 0, 0)
      );
    });
  }

  return billInfo;
};

export const validateRate = (
  effectiveDate: Date | null,
  rate: unknown,
  rateType: string,
  otdt: string = ""
) => {
  if (rateType === "payrate") {
    const payInfo = validateEffeciveDate(rate, effectiveDate, true) || [];

    if (otdt === "opr") {
      return (
        (payInfo &&
          payInfo.length > 0 &&
          "overTimePayrate" in payInfo[0] &&
          payInfo[0].overTimePayrate?.raw) ||
        ""
      );
    }

    if (otdt === "dpr") {
      return (
        (payInfo &&
          payInfo.length > 0 &&
          "doubleTimePayrate" in payInfo[0] &&
          payInfo[0].doubleTimePayrate?.raw) ||
        ""
      );
    }

    return (
      (payInfo &&
        payInfo.length > 0 &&
        "regularPayrate" in payInfo[0] &&
        payInfo[0].regularPayrate.raw) ||
      ""
    );
  }

  const billInfo = validateEffeciveDate(
    rate,
    effectiveDate,
    false
  ) as ProjectDetailsBillInfo[];

  return billInfo && billInfo.length > 0 && otdt === "obr"
    ? billInfo[0]?.overTimeBillrate?.raw || ""
    : otdt === "dbr"
    ? billInfo[0]?.doubleTimeBillrate?.raw || ""
    : // "billrate" in billInfo[0] &&
      billInfo[0]?.regularBillrate?.raw || "";
};

export default { createCustomAppliedRulesPayload };
