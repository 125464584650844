import hotkeys from "hotkeys-js";
import html2canvas from "html2canvas";
import React, { ComponentType, useEffect, useRef, useState } from "react";
import { BiScreenshot } from "react-icons/bi";
import { FaCheckCircle } from "react-icons/fa";
import { TiClipboard } from "react-icons/ti";

import { CaptureScreen } from "../../helpers/captureScreenShot";
import watermark from "../../watermarkimages/watermark.module.scss";

import classes from "./takeScreenshot.module.scss";

const TakeScreenshot = <P extends object>(
  WrappedComponent: ComponentType<P>
) => {
  const WithAuthorization: React.FC<P> = (myProp) => {
    const printRightClick = useRef<HTMLInputElement>(null);
    const captureScreenDiv = useRef<HTMLDivElement>(null); // Create a new div element

    const enablePrintFeatureHandler = () => {
      hotkeys("f5,command+p,ctrl+p", function (event, handler) {
        // Prevent the default refresh event under WINDOWS system
        event.preventDefault();
      });
    };

    const handleContextMenu: EventListener = (e) => {
      if (e instanceof MouseEvent) {
        e.preventDefault();

        if (printRightClick && printRightClick.current) {
          printRightClick.current.style.left = `${e.clientX}px`;
          printRightClick.current.style.top = `${e.clientY - 90}px`;
          printRightClick.current.style.display = "flex";

          // Capture the screen when the context menu is triggered

          // Hide the context menu element
        }
      }
    };

    useEffect(() => {
      enablePrintFeatureHandler();

      window.addEventListener("contextmenu", handleContextMenu);
      window.addEventListener("click", (e) => {
        if (printRightClick.current) {
          printRightClick.current.style.display = "none";
        }
      });

      return () => {
        hotkeys.unbind("f5,command+p,ctrl+p");
        window.removeEventListener("contextmenu", handleContextMenu);
        window.removeEventListener("click", () => {});
      };
    }, []);

    const [isCopying, setIsCopying] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const applicationURl = window.location.origin;

    let env = "";

    if (applicationURl?.includes("pricing-preprod.mbopartners.com")) {
      env = "preprod";
    } else if (
      applicationURl?.includes("pricing-dev.mbopartners.com") ||
      applicationURl?.includes("localhost")
    ) {
      env = "dev";
    } else if (applicationURl?.includes("pricing-qa.mbopartners.com")) {
      env = "qa";
    } else if (applicationURl?.includes("pricing.mbopartners.com")) {
      env = "prod";
    }

    const divelement = document.createElement("div");

    function captureScreenAsBlob(): Promise<Blob> {
      divelement.classList.add(watermark.background);
      divelement.classList.add(watermark[env]);
      document.body.append(divelement);

      const captureElement = document.getElementById("browser_print");

      if (captureElement) {
        captureElement.style.display = "none";
      }

      return new Promise((resolve, reject) => {
        // Use html2canvas to capture the current screen
        html2canvas(document.body).then((canvas) => {
          // Convert the canvas to a blob
          canvas.toBlob((blob) => {
            if (!blob) {
              reject(new Error("Invalid blob"));

              return;
            }

            if (blob) {
              divelement.remove();

              resolve(blob);
            }
          }, "image/png");
        });
      });
    }

    // Copy the image blob to the clipboard
    function copyBlobToClipboard() {
      return new Promise<void>((resolve, reject) => {
        navigator.clipboard
          .write([new ClipboardItem({ "image/png": captureScreenAsBlob() })])
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
            divelement.remove();
          });
      });
    }

    const copyToClipBoard = () => {
      setIsCopying(true);
    };

    useEffect(() => {
      if (isCopying) {
        (async () => {
          try {
            setIsCopying(true);

            if (printRightClick.current) {
              printRightClick.current.style.display = "none";
            }

            await copyBlobToClipboard();
            setShowToast(true);
            setIsCopying(false);
            setTimeout(() => {
              setShowToast(false);
            }, 1200);
          } catch (error) {
            console.error("Error capturing or copying screenshot:", error);
            alert(error);
          }
        })();
      }
    }, [isCopying]);

    return (
      <>
        {showToast && (
          <div className={classes.toast_styling}>
            <FaCheckCircle />
            <p>Copied to clipboard</p>
          </div>
        )}
        <WrappedComponent ref={captureScreenDiv} {...myProp} />

        {printRightClick && (
          <>
            <div
              ref={printRightClick}
              className={classes.browser_print}
              id="browser_print"
              onMouseLeave={() => {
                if (printRightClick && printRightClick.current) {
                  printRightClick.current.style.display = "none";
                }
              }}
            >
              <div
                onClick={() => {
                  CaptureScreen("browser_print");
                }}
              >
                <BiScreenshot />
                <p>Capture Screen</p>
              </div>
              <div
                onClick={() => {
                  if (printRightClick && printRightClick.current) {
                    printRightClick.current.style.display = "none";
                  }

                  copyToClipBoard();
                }}
              >
                <TiClipboard />
                <p>Copy to clipboard</p>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  return WithAuthorization;
};

export default TakeScreenshot;
