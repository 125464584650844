function convertHttpsToHyperlink(text: string): string {
  const regex = /https?:\/\/\S+/gi;

  return text.replace(
    regex,
    (match) => `<a href="${match}" target="_blank">here</a>`
  );
}

export default convertHttpsToHyperlink;
