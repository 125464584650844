import { toRelativeUrl } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { Outlet } from "react-router-dom";

import Header from "../components/Header/Header";

const ProtectedRoutes = () => {
  const { oktaAuth, authState } = useOktaAuth();

  const login = async (): Promise<void> => {
    const originalUri = toRelativeUrl(
      window.location.href,
      window.location.origin
    );

    oktaAuth.setOriginalUri(originalUri);
    await oktaAuth.signInWithRedirect();
  };

  if (!authState) {
    return;
  }

  if (!authState?.isAuthenticated) {
    login();
  }

  return (
    authState?.isAuthenticated && (
      <>
        <Header />
        <Outlet />
      </>
    )
  );
};

export default ProtectedRoutes;
