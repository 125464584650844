export const UserAccessForFeature = {
  WORKORDER: {
    OPTIONAL_RULES: [
      { role: "pricing_lead", access: "RW" },
      { role: "pricing_user_read", access: "R" },
      { role: "pricing_user_write", access: "RW" },
      { role: "jd_program_specialist", access: "RW" },
      { role: "jd_enrollment_manager", access: "RW" },
      { role: "jd_client_pwc_care", access: "RW" },
      { role: "jd_advantage_direct", access: "RW" },
      { role: "jd_ops_manager", access: "RW" },
      { role: "jd_super_user", access: "RW" },
      { role: "jd_tier2", access: "R" },
      { role: "jd_account_director", access: "R" },
      { role: "jd_talent_advisor", access: "R" },
      { role: "jd_recruiting_manager,", access: "R" },
      { role: "jd_hr_operations_manager", access: "R" },
      { role: "jd_compliance", access: "R" },
      { role: "jd_payroll", access: "R" },
      { role: "jd_controller", access: "R" },
      { role: "jd_accounts_payable", access: "R" },
      { role: "jd_accounts_receivable", access: "R" },
      { role: "jd_integrations", access: "R" },
      { role: "jd_implementation", access: "R" },
      { role: "jd_outsourced", access: "R" },
      { role: "jd_jiffy", access: "R" },
      { role: "jd_executive", access: "R" },
      { role: "jd_reporting", access: "R" },
    ],

    CUSTOM_RULES: [
      { role: "pricing_lead", access: "RW" },
      { role: "pricing_user_read", access: "R" },
      { role: "pricing_user_write", access: "RW" },
      { role: "jd_program_specialist", access: "RW" },
      { role: "jd_enrollment_manager", access: "RW" },
      { role: "jd_client_pwc_care", access: "RW" },
      { role: "jd_advantage_direct", access: "RW" },
      { role: "jd_ops_manager", access: "RW" },
      { role: "jd_super_user", access: "RW" },
      { role: "jd_tier2", access: "R" },
      { role: "jd_account_director", access: "R" },
      { role: "jd_talent_advisor", access: "R" },
      { role: "jd_recruiting_manager,", access: "R" },
      { role: "jd_hr_operations_manager", access: "R" },
      { role: "jd_compliance", access: "R" },
      { role: "jd_payroll", access: "R" },
      { role: "jd_controller", access: "R" },
      { role: "jd_accounts_payable", access: "R" },
      { role: "jd_accounts_receivable", access: "R" },
      { role: "jd_integrations", access: "R" },
      { role: "jd_implementation", access: "R" },
      { role: "jd_outsourced", access: "R" },
      { role: "jd_jiffy", access: "R" },
      { role: "jd_executive", access: "R" },
      { role: "jd_reporting", access: "R" },
    ],

    SIMULATION: [
      { role: "pricing_lead", access: "RW" },
      { role: "pricing_user_read", access: "R" },
      { role: "pricing_user_write", access: "RW" },
      { role: "jd_program_specialist", access: "RW" },
      { role: "jd_enrollment_manager", access: "RW" },
      { role: "jd_client_pwc_care", access: "RW" },
      { role: "jd_advantage_direct", access: "RW" },
      { role: "jd_ops_manager", access: "RW" },
      { role: "jd_super_user", access: "RW" },
      { role: "jd_tier2", access: "RW" },
      { role: "jd_account_director", access: "RW" },
      { role: "jd_talent_advisor", access: "RW" },
      { role: "jd_recruiting_manager,", access: "RW" },
      { role: "jd_hr_operations_manager", access: "RW" },
      { role: "jd_compliance", access: "RW" },
      { role: "jd_payroll", access: "RW" },
      { role: "jd_controller", access: "RW" },
      { role: "jd_accounts_payable", access: "RW" },
      { role: "jd_accounts_receivable", access: "RW" },
      { role: "jd_integrations", access: "RW" },
      { role: "jd_implementation", access: "RW" },
      { role: "jd_outsourced", access: "RW" },
      { role: "jd_jiffy", access: "RW" },
      { role: "jd_executive", access: "RW" },
      { role: "jd_reporting", access: "RW" },
    ],
  },

  CLIENT_RULES_AND_RULESET: {
    MANAGE_RULES: [
      { role: "pricing_lead", access: "RW" },
      { role: "pricing_user_read", access: "R" },
      { role: "pricing_user_write", access: "R" },
      { role: "jd_program_specialist", access: "R" },
      { role: "jd_enrollment_manager", access: "R" },
      { role: "jd_client_pwc_care", access: "R" },
      { role: "jd_advantage_direct", access: "R" },
      { role: "jd_ops_manager", access: "R" },
      { role: "jd_super_user", access: "R" },
      { role: "jd_tier2", access: "R" },
      { role: "jd_account_director", access: "R" },
      { role: "jd_talent_advisor", access: "R" },
      { role: "jd_recruiting_manager,", access: "R" },
      { role: "jd_hr_operations_manager", access: "R" },
      { role: "jd_compliance", access: "R" },
      { role: "jd_payroll", access: "R" },
      { role: "jd_controller", access: "R" },
      { role: "jd_accounts_payable", access: "R" },
      { role: "jd_accounts_receivable", access: "R" },
      { role: "jd_integrations", access: "R" },
      { role: "jd_implementation", access: "R" },
      { role: "jd_outsourced", access: "R" },
      { role: "jd_jiffy", access: "R" },
      { role: "jd_executive", access: "R" },
      { role: "jd_reporting", access: "R" },
    ],
  },
  SIMULATION: {
    CLIENT_SIMULATION: [
      { role: "pricing_lead", access: "RW" },
      { role: "pricing_user_read", access: "R" },
      { role: "pricing_user_write", access: "RW" },
      { role: "jd_program_specialist", access: "R" },
      { role: "jd_enrollment_manager", access: "R" },
      { role: "jd_client_pwc_care", access: "R" },
      { role: "jd_advantage_direct", access: "R" },
      { role: "jd_ops_manager", access: "R" },
      { role: "jd_super_user", access: "R" },
      { role: "jd_tier2", access: "R" },
      { role: "jd_account_director", access: "R" },
      { role: "jd_talent_advisor", access: "R" },
      { role: "jd_recruiting_manager,", access: "R" },
      { role: "jd_hr_operations_manager", access: "R" },
      { role: "jd_compliance", access: "R" },
      { role: "jd_payroll", access: "R" },
      { role: "jd_controller", access: "R" },
      { role: "jd_accounts_payable", access: "R" },
      { role: "jd_accounts_receivable", access: "R" },
      { role: "jd_integrations", access: "R" },
      { role: "jd_implementation", access: "R" },
      { role: "jd_outsourced", access: "R" },
      { role: "jd_jiffy", access: "R" },
      { role: "jd_executive", access: "R" },
      { role: "jd_reporting", access: "R" },
    ],
  },
  QUAD: {
    ROLES: [
      {
        role: "pricing_lead",
        access: "RW",
        showQuadSubmitBtn: true,
        showQuadApproveBtn: true,
      },

      {
        role: "pricing_user_read",
        access: "RW",
        showQuadSubmitBtn: true,
        showQuadApproveBtn: false,
      },
      {
        role: "pricing_user_write",
        access: "RW",
        showQuadSubmitBtn: true,
        showQuadApproveBtn: false,
      },
      {
        role: "jd_program_specialist",
        access: "RW",
        showQuadSubmitBtn: true,
        showQuadApproveBtn: false,
      },
      {
        role: "jd_enrollment_manager",
        access: "RW",
        showQuadSubmitBtn: true,
        showQuadApproveBtn: false,
      },
      {
        role: "jd_client_pwc_care",
        access: "RW",
        showQuadSubmitBtn: true,
        showQuadApproveBtn: false,
      },
      {
        role: "jd_advantage_direct",
        access: "R",
        showQuadSubmitBtn: false,
        showQuadApproveBtn: false,
      },
      {
        role: "jd_ops_manager",
        access: "RW",
        showQuadSubmitBtn: true,
        showQuadApproveBtn: true,
      },
      {
        role: "jd_super_user",
        access: "RW",
        showQuadSubmitBtn: true,
        showQuadApproveBtn: true,
      },
      {
        role: "jd_tier2",
        access: "R",
        showQuadSubmitBtn: false,
        showQuadApproveBtn: false,
      },
      {
        role: "jd_account_director",
        access: "R",
        showQuadSubmitBtn: false,
        showQuadApproveBtn: false,
      },
      {
        role: "jd_talent_advisor",
        access: "R",
        showQuadSubmitBtn: false,
        showQuadApproveBtn: false,
      },
      {
        role: "jd_recruiting_manager,",
        access: "R",
        showQuadSubmitBtn: false,
        showQuadApproveBtn: false,
      },
      {
        role: "jd_hr_operations_manager",
        access: "R",
        showQuadSubmitBtn: false,
        showQuadApproveBtn: false,
      },
      {
        role: "jd_compliance",
        access: "R",
        showQuadSubmitBtn: false,
        showQuadApproveBtn: false,
      },
      {
        role: "jd_payroll",
        access: "R",
        showQuadSubmitBtn: false,
        showQuadApproveBtn: false,
      },
      {
        role: "jd_controller",
        access: "R",
        showQuadSubmitBtn: false,
        showQuadApproveBtn: false,
      },
      {
        role: "jd_accounts_payable",
        access: "RW",
        showQuadSubmitBtn: true,
        showQuadApproveBtn: true,
      },
      {
        role: "jd_accounts_receivable",
        access: "RW",
        showQuadSubmitBtn: true,
        showQuadApproveBtn: true,
      },
      {
        role: "jd_integrations",
        access: "R",
        showQuadSubmitBtn: false,
        showQuadApproveBtn: false,
      },
      {
        role: "jd_implementation",
        access: "R",
        showQuadSubmitBtn: false,
        showQuadApproveBtn: false,
      },
      {
        role: "jd_outsourced",
        access: "R",
        showQuadSubmitBtn: false,
        showQuadApproveBtn: false,
      },
      {
        role: "jd_jiffy",
        access: "R",
        showQuadSubmitBtn: false,
        showQuadApproveBtn: false,
      },
      {
        role: "jd_executive",
        access: "R",
        showQuadSubmitBtn: false,
        showQuadApproveBtn: false,
      },
      {
        role: "jd_reporting",
        access: "R",
        showQuadSubmitBtn: false,
        showQuadApproveBtn: false,
      },
    ],
  },
};
